import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-character-count',
  templateUrl: './character-count.component.html',
  styleUrls: ['./character-count.component.less'],
})
// Without maxCharacters parameter, component will count characters
// With maxCharacters parameter, compenent will count remaining characters
export class CharacterCountComponent implements OnInit, OnDestroy {
  @Input() control: AbstractControl;
  @Input() maxCharacters: number;
  @Input() type: string;

  valueSubscription: Subscription;
  numCharacters: number;

  countChars = text => this.numCharacters = text.length;
  countCharsRemaining = text => this.numCharacters = this.maxCharacters - text.length;

  ngOnInit() {
    if (this.control) {
      this.numCharacters = this.control.value?.length || 0;
      this.valueSubscription = this.control.valueChanges.subscribe(this.maxCharacters ? this.countCharsRemaining : this.countChars);
    }
  }

  ngOnDestroy() {
    this.valueSubscription.unsubscribe();
  }
}
