// Angular:
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// Libs:
import { from, Subscription } from 'rxjs';
import { mergeMap, toArray } from 'rxjs/operators';
// Services:
import { WizardService } from '@app/wizard/wizard.service';
import { NurtureBossModalService } from '@app/_services/modal.service';
import { PagePreviewService } from '@app/_services/pagePreview.service';
import { BroadcastsService } from '@app/_services/broadcasts.service';
import { WidgetService } from '@app/_services/widget.service';
import {
  AuthenticationService,
  GlobalDefaultsSocialLinks,
  PagesService,
  TemplatesService,
  TextMessagesService,
  ToastService,
  TokenParserService,
  UsersService,
  EmailSubjectLineSpamLanguageService,
} from '@app/_services';
import { EmailsService } from '@app/_services/emails.service';
// Directives:
import { IStickAtTopOptions, StickyMethod } from '@app/_directives/stick-at-top.directive';
// Types:
import {
  BroadcastContext,
  BroadcastDeliveryMethod,
  BroadcastExistingContactRecipient,
  BroadcastScheduleType,
  BroadcastSingleRecipient,
  BroadcastUploadedContactRecipient
} from '@app/broadcast/broadcast.types';
import { Contact } from '@nurtureboss/common/dist/types/contacts';
// Commons:
import { ITemplate } from '@nurtureboss/common/dist/types/templates';
import { FIELD_AUTO_GENERATORS } from '@nurtureboss/common/dist/utils/template';
// Utils:
import { chunkArray } from '@app/_utils/arrays';
import { deepClone } from '@app/_utils/objects';
// Constants:
import MODAL_NAMES from '@app/_components/nb-modal/modalTypes';
// Components:
import { SendTimeValidatorFn } from '@app/shared/schedule-modal/schedule-modal.component';

const SEND_SUCCESS_MODAL = 'send-broadcast-success-modal';
const SEND_STATUS_MODAL = 'send-broadcast-status-modal';

// TODO: Move to types
interface IFinalTemplate extends ITemplate, GlobalDefaultsSocialLinks {
  apartmentName?: string;
  propertyName?: string;
  clientEmailAddress?: string;
  clientPhoneNumber?: string;
  clientFirstName?: string;
  pageName?: string;
  templateId?: string;
  broadcastId?: string;
  knockProspectId?: string;
  yardiGuestCardId?: string;
  realPageGuestCardId?: string;
  entrataGuestCardId?: string;
  entrataApplicationId?: string;
  entrataApplicantId?: string;
  resmanPersonId?: string;
  lat?: string;
  long?: string;
  fheo?: boolean;
  emergency?: boolean;
}

@Component({
  selector: 'app-broadcast-scheduler',
  templateUrl: './broadcast-scheduler.component.html',
  styleUrls: ['./broadcast-scheduler.component.less']
})
export class BroadcastSchedulerComponent implements OnInit, OnDestroy {

  constructor(
    private wizardService: WizardService<BroadcastContext>,
    private nbModalService: NurtureBossModalService,
    private router: Router,
    private pagePreviewService: PagePreviewService,
    private broadcastsService: BroadcastsService,
    private authService: AuthenticationService,
    private pagesService: PagesService,
    private emailsService: EmailsService,
    private textMessageService: TextMessagesService,
    private toastService: ToastService,
    private tokenParser: TokenParserService,
    private templateService: TemplatesService,
    private widgetService: WidgetService,
    private usersService: UsersService,
    private emailSubjectLineSpamLanguageService: EmailSubjectLineSpamLanguageService,
  ) { }

  currentContext: BroadcastContext = {
    template: {} as any,
    delivery: {} as any,
    message: {} as any,
  };
  subscriptions: Subscription[] = [];
  stickyHeaderOptions: IStickAtTopOptions = {
    useParent: true,
    topThreshold: 0,
    applyStyles: {
      top: '0px',
    },
    method: StickyMethod.Sticky,
  };
  contactSelectionMessage = '';
  loading = false;
  contactCount = 0;
  userData: any;
  broadcastCreateStatus: { [step: string]: string; } = {
    broadcast: 'waiting',
    emails: 'waiting',
    nurturePages: 'waiting',
    sending: 'waiting',
  };
  createBroadcastFailed = false;
  failedPagesEmails = 0;
  previewDisplayType = '';
  filteredContactCount = 0;
  scheduleTimeValidator: SendTimeValidatorFn = (_) => ({ valid: true });
  spamWarnings = [];

  ngOnInit(): void {
    this.subscriptions.push(this.emailSubjectLineSpamLanguageService.warnings.subscribe(warnings => {
      this.spamWarnings = warnings;
    }));
    this.subscriptions.push(this.wizardService.stepChanged$.subscribe((stepId) => {
      if (stepId === 'send') {
        this.currentContext = this.wizardService.getContext();
        this.setScheduleValidator();
        this.setContactSelectionMessage();
        this.setPreviewDisplay();
      }
    }));
    this.userData = this.authService.currentUserValue.user;
    this.usersService.loadGlobalDefaults();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  setPreviewDisplay() {
    switch (this.currentContext.delivery?.method || 'unknown') {
      case BroadcastDeliveryMethod.Text:
        this.previewDisplayType = 'nurturepage';
        break;
      case BroadcastDeliveryMethod.Email:
        this.previewDisplayType = 'emailsubject';
        break;
      default:
        this.previewDisplayType = '';
        break;
    }
  }

  setScheduleValidator() {
    switch (this.currentContext.delivery?.method || 'unknown') {
      case BroadcastDeliveryMethod.Optimize:
      case BroadcastDeliveryMethod.Text:
        this.scheduleTimeValidator = ({ hour }) => {
          if (hour < 8 || hour >= 21) {
            return {
              valid: false,
              message: 'You cannot send text messages before 8am or after 9pm.',
            };
          }
          return { valid: true };
        };
        break;
      default:
        this.scheduleTimeValidator = (_) => ({ valid: true });
        break;
    }
  }

  setContactSelectionMessage() {
    if ((this.currentContext.delivery!.recipient as any).uploadedContacts) {
      this.contactCount = (this.currentContext.delivery!.recipient as any).uploadedContacts.length;
      this.contactSelectionMessage = `Number of contacts uploaded`;
    } else if ((this.currentContext.delivery!.recipient as any).contacts) {
      this.contactCount = (this.currentContext.delivery!.recipient as any).contacts.length;
      this.contactSelectionMessage = `Number of contacts selected`;
    } else {
      const singleRecipient = this.currentContext.delivery!.recipient as BroadcastSingleRecipient;
      this.contactCount = 1;
      let deliveryMethod = '';
      switch (this.currentContext.delivery!.method) {
        case BroadcastDeliveryMethod.Optimize:
          deliveryMethod = `${singleRecipient.clientPhoneNumber} or ${singleRecipient.clientEmailAddress}`;
          break;
        case BroadcastDeliveryMethod.Text:
          deliveryMethod = singleRecipient.clientPhoneNumber;
          break;
        case BroadcastDeliveryMethod.Email:
          deliveryMethod = singleRecipient.clientEmailAddress;
          break;
      }

      this.contactSelectionMessage = `Entered contact at ${deliveryMethod}`;
    }
  }

  onSendNow(event: MouseEvent) {
    event.preventDefault();

    const finalContext = {
      ...this.currentContext,
      schedule: {
        type: BroadcastScheduleType.Immediate,
        sendDateTime: new Date(),
      },
    };
    this.submitBroadcast(finalContext);
  }

  onSchedule(event: MouseEvent) {
    event.preventDefault();
    this.nbModalService.open(MODAL_NAMES.SCHEDULE_MESSAGES);
  }

  onScheduleCreated(schedule: Date) {
    this.nbModalService.close(MODAL_NAMES.SCHEDULE_MESSAGES);

    const finalContext = {
      ...this.currentContext,
      schedule: {
        type: BroadcastScheduleType.Scheduled,
        sendDateTime: schedule,
      },
    };
    this.submitBroadcast(finalContext);
  }

  onRetrySend(event: MouseEvent) {
    event.preventDefault();
    this.createBroadcastFailed = false;
    this.submitBroadcast(this.currentContext);
  }

  gatherContacts(finalContext: BroadcastContext): { contacts: Array<Partial<Contact>>; success: boolean; } {
    let contacts: Array<Partial<Contact>> = [];
    if ((finalContext.delivery.recipient as BroadcastSingleRecipient).clientFirstName) {
      const recipient = finalContext.delivery.recipient as BroadcastSingleRecipient;
      recipient.clientFullName = recipient.pageName;
      contacts = [{...finalContext.delivery.recipient as Partial<Contact>}];
    } else if ((finalContext.delivery.recipient as BroadcastUploadedContactRecipient).uploadedContacts) {
      const uploadedRecipients = finalContext.delivery.recipient as BroadcastUploadedContactRecipient;

      // Normalize contact.
      // TODO: Might want to move this to SheetJS.
      for (const person of uploadedRecipients.uploadedContacts) {
        person.clientFullName = person.pageName;
      }
      // @ts-ignore
      contacts = uploadedRecipients.uploadedContacts;
    } else if ((finalContext.delivery.recipient as BroadcastExistingContactRecipient).contacts) {
      contacts = (finalContext.delivery.recipient as BroadcastExistingContactRecipient).contacts;
    } else {
      return {
        contacts: [],
        success: false,
      };
    }
    return {
      contacts,
      success: true,
    };
  }

  verifyScheduleType(finalContext: BroadcastContext): boolean {
    // Quickly validate we have what we need for the schedule
    switch (finalContext.schedule?.type || 'unknown') {
      case BroadcastScheduleType.Immediate:
      case BroadcastScheduleType.Scheduled:
        return true;
      default:
        return false;
    }
  }

  verifyDeliveryMethod(finalContext: BroadcastContext): boolean {
    switch (finalContext.delivery?.method || 'unknown') {
      case BroadcastDeliveryMethod.Email:
      case BroadcastDeliveryMethod.Text:
      case BroadcastDeliveryMethod.Optimize:
        return true;
      default:
        return false;
    }
  }

  isSingleContact(finalContext: BroadcastContext) {
    if (
      !(finalContext.delivery.recipient as any).contacts
      && !(finalContext.delivery.recipient as any).uploadedContacts
      && (finalContext.delivery.recipient as any).pageName
    ) {
      return true;
    }
    return false;
  }

  isSingleOptimizedContact(finalContext: BroadcastContext) {
    if (
      finalContext.delivery?.method === BroadcastDeliveryMethod.Optimize
      && this.isSingleContact(finalContext)
    ) {
      return true;
    }
    return false;
  }

  async createCommunicationData(finalContext: BroadcastContext, contacts: Array<Partial<Contact>>, broadcastId: string) {
    let emails = [];
    let pages = [];
    switch (finalContext.delivery.method) {
      case 'text':
        pages = await this.createPages(finalContext, contacts, broadcastId);
        break;
      case 'email':
        emails = await this.createEmails(finalContext, contacts, broadcastId);
        break;
      case 'optimize':
        // Single recipient, we should create both email objects & pages
        if (this.isSingleOptimizedContact(finalContext)) {
          pages = await this.createPages(finalContext, contacts, broadcastId);
          emails = await this.createEmails(finalContext, contacts, broadcastId);
          return { pages, emails };
        }

        // sort contacts and create both.
        const contactsToText = [];
        const contactsToEmail = [];
        for (const contact of contacts) {
          if (this.contactTextCheck(contact)) {
            contactsToText.push(contact);
          } else if (this.contactEmailCheck(contact)) {
            contactsToEmail.push(contact);
          } else {
            this.filteredContactCount += 1;
          }
        }
        pages = await this.createPages(finalContext, contactsToText, broadcastId);
        emails = await this.createEmails(finalContext, contactsToEmail, broadcastId);
        break;
    }
    return {
      emails,
      pages,
    };
  }

  async sendSingleEmail(finalContext: BroadcastContext, emailObject: any) {
    try {
      await this.emailsService.sendEmail({
        to: emailObject.data.clientEmailAddress,
        emailId: emailObject._id,
        emailSubjectLine: finalContext.message?.emailSubjectLine,
      }).toPromise();
    } catch (error) {
      const newError = { message: error, skipFinalWarning: true };
      this.toastService.showError(error);
      throw newError;
    }
  }

  async sendSinglePage(finalContext: BroadcastContext, page: any) {
    try {
      await this.textMessageService.sendText({
        to: page.data.clientPhoneNumber,
        pageId: page._id,
        message: finalContext.message?.textMessageContent,
      }).toPromise();
      return;
    } catch (error) {
      if (error.cause) {
        this.toastService.showError(error.cause);
        error.skipFinalWarning = true;
      } else {
        this.toastService.showError(`Something went wrong sending the text to ${(finalContext.delivery.recipient as any).pageName}.`);
      }
      throw error;
    }
  }

  // TODO: we need to move some of these methods to the broadcast service here on the frontend.
  // We should use services for handling this logic, etc.
  async sendOptimizedSingleMessage(finalContext: BroadcastContext, emailObject: any, page: any) {
    try {
      await this.sendSinglePage(finalContext, page);
      return;
    } catch {}

    await this.sendSingleEmail(finalContext, emailObject);
  }

  async sendNow(finalContext: BroadcastContext, emails: any[], pages: any[]) {
    // Single contact optimized flow
    if (this.isSingleOptimizedContact(finalContext)) {
      return await this.sendOptimizedSingleMessage(finalContext, emails[0], pages[0]);
    }

    if (pages) {
      if (pages.length === 1 && this.isSingleContact(finalContext)) {
        await this.sendSinglePage(finalContext, pages[0]);
        return;
      }
      await this.deliverTexts(pages, finalContext.message.textMessageContent);
    }
    if (emails) {
      if (emails.length === 1 && this.isSingleContact(finalContext)) {
        await this.sendSingleEmail(finalContext, emails[0]);
        return;
      }
      await this.deliverEmails(emails, finalContext.message.emailSubjectLine);
    }
  }

  async scheduleNow(finalContext: BroadcastContext, emails: any[], pages: any[], broadcastId: string) {
    if (pages) {
      await this.createTextSchedules(broadcastId, pages, finalContext.message.textMessageContent, finalContext.schedule.sendDateTime);
    }
    // Scheduling will only do pages for now when the contact is optimized & a single contact.
    if (!this.isSingleOptimizedContact(finalContext) && emails) {
      await this.createEmailSchedules(broadcastId, emails, finalContext.message.emailSubjectLine, finalContext.schedule.sendDateTime);
    }
  }

  // TODO: Error when sending a single text message before 8am or after 9pm
  async submitBroadcast(context: BroadcastContext) {
    this.filteredContactCount = 0;
    this.currentContext = context;
    this.broadcastCreateStatus = {
      broadcast: 'waiting',
      emails: 'waiting',
      nurturePages: 'waiting',
      scheduling: 'waiting',
      sending: 'waiting',
    };
    this.loading = true;
    this.failedPagesEmails = 0;

    this.nbModalService.open(SEND_STATUS_MODAL);

    try {
      // Don't use the real context.
      const finalContext = deepClone(context);

      const { contacts, success } = this.gatherContacts(finalContext);
      if (!success) {
        this.toastService.showError('We could not determine the recipients of this broadcast! Please try again!');
        this.broadcastCreateStatus.broadcast = 'error';
        return;
      }

      if (!this.verifyScheduleType(finalContext)) {
        this.toastService.showError(`Invalid broadcast schedule type: ${finalContext.schedule.type}`);
        this.broadcastCreateStatus.broadcast = 'error';
        return;
      }

      if (!this.verifyDeliveryMethod(finalContext)) {
        this.toastService.showError(`Invalid broadcast delivery method: ${finalContext.delivery.method}`);
        this.broadcastCreateStatus.broadcast = 'error';
        return;
      }

      // dont create in case of success
      this.broadcastCreateStatus.broadcast = 'inprogress';
      const { result: broadcast } = await this.broadcastsService.createBroadcast(finalContext).toPromise();
      this.broadcastCreateStatus.broadcast = 'success';

      this.broadcastCreateStatus.nurturePages = 'inprogress';
      this.broadcastCreateStatus.emails = 'inprogress';
      const { emails, pages } = await this.createCommunicationData(finalContext, contacts, broadcast._id);
      this.broadcastCreateStatus.nurturePages = 'success';
      this.broadcastCreateStatus.emails = 'success';

      this.broadcastCreateStatus.sending = 'inprogress';
      if (finalContext.schedule.type === 'immediate') {
        await this.sendNow(finalContext, emails, pages);
      } else if (finalContext.schedule.type === 'scheduled') {
        await this.scheduleNow(finalContext, emails, pages, broadcast._id);
      }
      this.broadcastCreateStatus.sending = 'success';
      this.currentContext = finalContext;
      this.currentContext.isComplete = true;
      this.wizardService.setContext(this.currentContext);
      this.createBroadcastFailed = false;
      this.loading = false;
      this.closeStatusModal()();
    } catch (error) {
      this.createBroadcastFailed = true;
      for (const [step, status] of Object.entries(this.broadcastCreateStatus)) {
        if (status === 'inprogress') {
          this.broadcastCreateStatus[step] = 'error';
        }
      }

      if (!error.skipFinalWarning) {
        this.toastService.showError('Something went wrong. Please try again.');
      }
      this.loading = false;
    } finally {
      // TODO: make better
      if (this.failedPagesEmails > 0) {
        this.toastService.showError(`${this.failedPagesEmails} messages did not send properly.`);
      }
    }
  }

  async createTextSchedules(broadcastId: string, pages: any[], message: string, time: Date) {
    const arrayOfPageIds = pages.map((obj) => {
      return obj._id;
    });
    const postBody = {
      message,
      pageIds: arrayOfPageIds,
    };
    return await this.textMessageService.scheduleTextSend({
      broadcastId,
      dateTime: time,
      data: postBody
    }).toPromise();
  }

  async createEmailSchedules(broadcastId: string, emails: any[], subjectLine: string, time: Date) {
    const arrayOfEmailObjectIds = emails.map((obj) => {
      return obj._id;
    });
    const postBody = {
      pageIds: arrayOfEmailObjectIds,
      emailSubjectLine: subjectLine,
    };
    return await this.emailsService.scheduleEmailSend({
      broadcastId,
      dateTime: time,
      data: postBody
    }).toPromise();
  }

  async deliverEmails(emails, subjectLine) {
    if (!emails || !emails.length) {
      return;
    }
    const arrayOfEmailObjectIds = emails.map(function(obj) {
      return obj._id;
    });
    const postBody = {
      emailObjectIds: arrayOfEmailObjectIds,
      emailSubjectLine: subjectLine,
    };
    return await this.emailsService.sendBulkEmails(postBody).toPromise();
  }

  async deliverTexts(pages, message) {
    if (!pages || !pages.length) {
      return;
    }
    const arrayOfPageIds = pages.map((obj) => {
      return obj._id;
    });
    const postBody = {
      message,
      pageIds: arrayOfPageIds
    };
    return await this.pagesService.sendBulkText(postBody).toPromise();
  }

  contactTextCheck(contact: Partial<Contact>) {
    if (!contact.phoneNumber && !(contact as any).clientPhoneNumber) {
      return false;
    }

    let optIn = false;
    if (contact.markedForOptIn) {
      optIn = contact.markedForOptIn.toString() === 'true';
    }

    const userRequiresOptIn = this.userData.doubleOptIn || this.userData.optIn;
    const userDoesNotRequireOptIn = !this.userData.doubleOptIn && !this.userData.optIn;

    if (userRequiresOptIn && optIn) {
      return true;
    } else if (userDoesNotRequireOptIn) {
      return true;
    } else {
      return false;
    }
  }

  contactEmailCheck(contact: Partial<Contact>) {
    if (!contact.emailAddress && !(contact as any).clientEmailAddress) {
      return false;
    }
    return true;
  }

  generateFieldPageOrEmail = (fieldName: string, contact: any) => {
    if (fieldName !== 'scheduleATourUrl' || !FIELD_AUTO_GENERATORS[fieldName].isAvailableToUser(this.authService.currentUserValue.user)) {
      return '';
    }

    if (!this.widgetService.accessToken) {
      return '';
    }

    const contactData: any = {};
    contactData.firstName = contact.firstName || contact.clientFirstName;
    if (contact.lastName) {
      contactData.lastName = contact.lastName;
    } else if (contact.clientFullName) {
      const nameParts = contact.clientFullName.split(' ');
      if (nameParts.length > 1) {
        contactData.lastName = nameParts[1].trim();
      }
    }
    contactData.phoneNumber = contact.phoneNumber || contact.clientPhoneNumber;
    contactData.emailAddress = contact.emailAddress || contact.clientEmailAddress;

    return FIELD_AUTO_GENERATORS[fieldName].valueGenerator(
      { token: this.widgetService.accessToken },
      contactData,
    );
  }

  gatherContactData = (contacts, finalContext, broadcastId, isEmail = false) => {
    const communicationToCreate = [];
    const template = finalContext.template;
    for (const contact of contacts) {
      const item: IFinalTemplate = { ...finalContext.templateValues, ...this.usersService.currentGlobalDefaultSocialLinks };

      for (const fieldName of Object.keys(item)) {
        const value = this.generateFieldPageOrEmail(fieldName, contact);
        if (value) {
          item[fieldName] = value;
        }
      }

      for (const field of this.templateService.extractRequiredContactFields(template)) {
        const requiredFieldValue = contact[field.name] || contact[field.sourceProperty];
        item[field.name] = requiredFieldValue;
      }
      for (const field of this.templateService.extractOptionalContactFields(template)) {
        const optionalFieldValue = contact[field.name] || contact[field.sourceProperty];
        item[field.name] = optionalFieldValue;
      }

      // Switch propertyName to apartmentName
      item.apartmentName = item.propertyName;
      item.clientEmailAddress = contact.clientEmailAddress;
      item.clientPhoneNumber = contact.clientPhoneNumber;
      item.clientFirstName = contact.clientFirstName;
      item.pageName = contact.clientFullName;
      item.templateId = template._id.toString();
      item.templateName = template.templateName;
      item.type = template.templateName;
      item.broadcastId = broadcastId;
      item.emergency = finalContext.delivery.emergency;

      // Must have Knock override others. clientGuestCard ID was set as knock
      // so it will not work as YardiGuestCardId or others.
      if (this.userData.claims.includes('knock')) {
        item.knockProspectId = contact.clientGuestCardId;
      } else if (this.userData.claims.includes('yardi')) {
        item.yardiGuestCardId = contact.clientGuestCardId;
      } else if (this.userData.claims.includes('realpage')) {
        item.realPageGuestCardId = contact.clientGuestCardId;
      } else if (this.userData.claims.includes('entrata')) {
        item.entrataGuestCardId = contact.clientGuestCardId;
        item.entrataApplicationId = contact.clientApplicationId;
        item.entrataApplicantId = contact.clientApplicantId;
      } else if (this.userData.claims.includes('resman')) {
        item.resmanPersonId = contact.clientGuestCardId;
      }

      // User Data
      item.fheo = this.userData.fheo;
      item.lat = this.userData.lat;
      item.long = this.userData.long;

      // Skip if it's sending an email and contact does not have an email address
      if (isEmail && !item.clientEmailAddress) {
        continue;
      }

      communicationToCreate.push(item);
    }

    return communicationToCreate;
  }

  async createEmails(finalContext: BroadcastContext, contacts: any[], broadcastId: string) {
    const emailsToCreate = this.gatherContactData(contacts, finalContext, broadcastId, true);

    const dataToUse = [];
    const emailChunks = chunkArray(10, emailsToCreate);
    const aggregatedResults = await from(emailChunks).pipe(
      mergeMap((emailObjects) => this.emailsService.createEmailsInBulk(emailObjects)),
      toArray(),
    ).toPromise();
    for (const {result: results, errors} of aggregatedResults) {
      if (errors && errors.length) {
        // TODO: Handle Errors.
        this.failedPagesEmails += emailsToCreate.length;
        continue;
      }
      for (let i = 0; i < results.length; i++) {
        if (results[i].success) {
          dataToUse.push(results[i].emailObject);
        } else {
          // TODO: Handle failure.
          this.failedPagesEmails += 1;
        }
      }
    }
    return dataToUse;
  }

  async createPages(finalContext: BroadcastContext, contacts: any[], broadcastId: string) {
    const pagesToCreate = this.gatherContactData(contacts, finalContext, broadcastId);

    const dataToUse = [];
    const emailChunks = chunkArray(10, pagesToCreate);
    const aggregatedResults = await from(emailChunks).pipe(
      mergeMap((pages) => this.pagesService.createPagesInBulk(pages)),
      toArray(),
    ).toPromise();
    for (const {result: results, errors} of aggregatedResults) {
      if (errors && errors.length) {
        // TODO: Handle Errors.
        this.failedPagesEmails += pagesToCreate.length;
        continue;
      }
      for (let i = 0; i < results.length; i++) {
        if (results[i].success) {
          dataToUse.push(results[i].page);
        } else {
          // TODO: Handle failure.
          this.failedPagesEmails += 1;
        }
      }
    }
    return dataToUse;
  }

  closeSuccessModal() {
    return () => {
      this.nbModalService.close(SEND_SUCCESS_MODAL);
      this.router.navigate(['/blasts']);
    };
  }

  closeStatusModal() {
    return () => {
      // If we are still processing don't allow any action
      if (this.loading) {
        return;
      }

      // Delay so user can see blast succeeded.
      setTimeout(() => {
        this.nbModalService.close(SEND_STATUS_MODAL);

        // Don't "complete" as we didn't
        if (this.createBroadcastFailed) {
          return;
        }

        this.nbModalService.open(SEND_SUCCESS_MODAL);
      }, 1000);
    };
  }

  getRecipientData() {
    let data: any = {};
    if ((this.currentContext.delivery!.recipient as any).uploadedContacts) {
      data = {...(this.currentContext.delivery!.recipient as any).uploadedContacts[0]};
    } else if ((this.currentContext.delivery!.recipient as any).contacts) {
      data = {...(this.currentContext.delivery!.recipient as any).contacts[0]};
    } else {
      const singleRecipient = this.currentContext.delivery!.recipient as BroadcastSingleRecipient;
      data = {...singleRecipient};
    }

    delete data.type;
    delete data.communication;

    return data;
  }

  getPagePreviewUrl(template: ITemplate): string {
    if (!template || Object.keys(template).length === 0) {
      return '';
    }
    const templateValues = this.currentContext.templateValues || {};
    return this.pagePreviewService.getPagePreviewUrl(template, {...templateValues, ...this.getRecipientData()});
  }

  getEmailPreviewUrl(template: ITemplate): string {
    if (!template || Object.keys(template).length === 0) {
      return '';
    }
    const templateValues = this.currentContext.templateValues || {};
    return this.pagePreviewService.getEmailPreviewUrl(template, {
      ...templateValues,
      ...this.getRecipientData(),
      ...this.usersService.currentGlobalDefaultSocialLinks
    });
  }

  getEmailSubject() {
    if (
      !this.currentContext.template
      || !this.currentContext.delivery
      || !this.currentContext.message
      || !this.currentContext.message.emailSubjectLine
    ) {
      return '';
    }
    const templateValues = this.currentContext.templateValues || {};
    const recipientData = this.getRecipientData();
    return this.tokenParser.replaceTokens(this.currentContext.message.emailSubjectLine, {
      apartmentName: templateValues.propertyName,
      ...templateValues,
      ...recipientData
    });
  }
}
