<div *ngIf="userData.user.claims && userData.user.claims.indexOf('group') > -1" id="group-page">
  <div class="page">
    <div class="margin-bottom-24">
      <h6 class="title">Access Property</h6>
      <div>
        <div>
          <h6>Select Property:</h6>
          <div class="form-group">
            <p-autoComplete
              styleClass="form-control form-autocomplete"
              (onSelect)="onAccountSelect($event)"
              (onClear)="onAccountCleared()"
              [dropdown]="true"
              [suggestions]="suggestions"
              (completeMethod)="filterAccounts($event)"
              [forceSelection]="false"
              [completeOnFocus]="true"
              field="display"
            >
              <ng-template let-account pTemplate="item">
                <span>{{account | mimicAccountDisplay}}</span>
              </ng-template>
            </p-autoComplete>
          </div>
          <div class="button button-mimic button-dark margin-bottom-24 margin-top-12" (click)="mimic(selectedAccount)" *ngIf="selectedAccount">Access Property</div>
        </div>
      </div>
    </div>
  </div>
</div>