import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ITemplate, ITemplateField, TemplateFieldSource } from '@nurtureboss/common/dist/types/templates';
import { map } from 'rxjs/operators';
import { APIHelperService } from './apiHelper.service';

const NEW_TEMPLATE_DEFAULT_VERSION = 2;
@Injectable({ providedIn: 'root' })
export class TemplatesService {

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService
  ) {
    // no-op
  }

  getTemplateDefaults(templateName) {
    return this.http.get<any>(this.apiHelper.fillUrl('templateDefault', { templateName }, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getTemplateDefaultsById(templateId) {
    return this.http.get<any>(this.apiHelper.fillUrl('templateDefaultById', { id: templateId }, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getUserAutomationTemplateDefaults(automationId) {
    return this.http.get<any>(this.apiHelper.fillUrl('templateDefaultsForUserAutomations', { id: automationId }, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getUserAlertTemplateDefaults(alertId) {
    return this.http.get<any>(this.apiHelper.fillUrl('templateDefaultsForUserAlert', { alertId }, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  storeUserAutomationDefault(automationId, stepId, templateName, templateData) {
    return this.http.post<any>(this.apiHelper.fillUrl('templateDefaultsStoreAutomationDefault', {}, {}), { automationId, stepId, templateName, ...templateData })
      .pipe(map(data => {
        return data;
      }));
  }

  updateTemplateDefaultsById(templateId, data) {
    return this.http.put<any>(this.apiHelper.fillUrl('templateDefaultById', { templateId }, {}), data)
      .pipe(map(data => {
        return data;
      }));
  }

  updateTemplateDefaults(templateName, data) {
    return this.http.put<any>(this.apiHelper.fillUrl('templateDefault', { templateName }, {}), data)
      .pipe(map(data => {
        return data;
      }));
  }

  createTemplateDefaults(data) {
    return this.http.post<any>(this.apiHelper.fillUrl('templateDefaults', {}, {}), data)
      .pipe(map(data => {
        return data;
      }));
  }

  newTextTemplate(data) {
    return this.http.post<any>(this.apiHelper.fillUrl('textTemplates', {}, {}), data)
      .pipe(map(data => {
        return data;
      }));
  }

  getTextTemplates() {
    return this.http.get<any>(this.apiHelper.fillUrl('textTemplates', {}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  deleteTextTemplate(id) {
    return this.http.delete<any>(this.apiHelper.fillUrl('textTemplate', {id: id}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getTemplates() {
    return this.http.get<{ result: ITemplate[] }>(this.apiHelper.fillUrl('getTemplates', {}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getTemplateByName(name) {
    return this.http.get<{ result: ITemplate }>(this.apiHelper.fillUrl('getTemplatesByName', {name}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getUserGlobalDefaults() {
    return this.http.get<any>(this.apiHelper.fillUrl('templateDefaults', {}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  isTemplateDefaultValid(template: ITemplate, defaultData: any): boolean {
    if (!defaultData) {
      return false;
    }

    for (const field of this.extractFieldsToValidate(template)) {
      if (!defaultData[field]) {
        return false;
      }
    }

    // Global templates shouldn't be considered validx
    if (!defaultData.version || defaultData.version < NEW_TEMPLATE_DEFAULT_VERSION) {
      return false;
    }

    return true;
  }

  private extractTemplateFields(template: ITemplate, fieldSource: TemplateFieldSource, returnRequiredFields: boolean): ITemplateField[] {
    const returnFields: ITemplateField[] = [];
    for (const group of template.groups) {
      for (const field of group.fields) {
        const allowedField = (field.required && returnRequiredFields) || (!field.required && !returnRequiredFields);
        if (allowedField && field.dataSource === fieldSource) {
          returnFields.push(field);
        }
      }
    }
    return returnFields;
  }

  extractFieldsToValidate(template: ITemplate): string[] {
    const requiredFields: string[] = [];
    for (const group of template.groups) {
      for (const field of group.fields) {
        if (field.required && field.dataSource === TemplateFieldSource.Template) {
          requiredFields.push(field.name);
        }
      }
    }
    return requiredFields;
  }

  extractRequiredContactFields(template: ITemplate): ITemplateField[] {
    return this.extractTemplateFields(template, TemplateFieldSource.Contact, true);
  }

  extractOptionalContactFields(template: ITemplate): ITemplateField[] {
    return this.extractTemplateFields(template, TemplateFieldSource.Contact, false);
  }
}