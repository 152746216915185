import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '@app/_services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401 || err.status === 403) {
                // auto logout if 401/403 response returned from api
                this.authenticationService.logout();
                location.reload(true);
            }
            // check general error
            let error = err.error.errors || err.statusText;

            // check v2 errors
            const cause = err.error?.cause; // may or may not be available
            if (cause) {
                error = err.error;
            }

             // log error in development
             if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
                if (Array.isArray(error)) {
                    error.forEach(console.error)
                } else {
                    console.error(error)
                }
            }
            return throwError(error);
        }))
    }
}