import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { APIHelperService } from './apiHelper.service';

@Injectable({ providedIn: 'root' })
export class AdminService {

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService
  ) {
    // no-op
  }
  getClientSuccessAccountTypes() {
		return this.http.get<any>(this.apiHelper.fillUrl('adminGetClientSuccessAccountTypes', {}, {}))
      .pipe(map(data => {
        return data;
      }));
  }
}
