import { Component, Input, OnInit, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ITemplate } from '@nurtureboss/common/dist/types/templates';
import { TemplateFormService, IFormData } from '../../_services/template-form.service';
import { TemplateDefault } from '@nurtureboss/common/dist/types/templateDefaults';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-template-form-component',
  templateUrl: './template-form.component.html',
  styleUrls: ['./template-form.component.less'],
  providers: [TemplateFormService],
})
export class TemplateFormComponent implements OnInit, OnDestroy {
  @Input() template: ITemplate;
  @Input() templateDefaults: TemplateDefault;
  @Input() submitButtonLabel = 'Save';
  @Input() showCancelButton: boolean = true;
  @Input() showSubmitButton: boolean = true;
  @Output() formSubmitEvent = new EventEmitter<any>();
  @Output() formChangeEvent = new EventEmitter<{values: any, valid: boolean}>();
  @Input() enableDefaults = false;
  @Input() useSpamLanguageService = true;
  
  userDefaults: any = {};
  customForm: FormGroup;
  form: FormGroup;
  sortedForm: IFormData[];
  activePanelId = 'ngb-panel-0'; // will always start at 0
  formChangeSub: Subscription;

  constructor(
    private templateFormService: TemplateFormService,
    private cdr: ChangeDetectorRef,
    public modalService: NgbModal,
  ) {
    //no-op
  }

  ngOnInit(): void {
    this.userDefaults = {...this.templateDefaults};
    this.form = this.templateFormService.makeForm(this.template, this.templateDefaults);
    this.sortedForm = this.templateFormService.getSortedFormData(this.template);

    this.formChangeEvent.emit({
      values: this.templateFormService.getRawValues(),
      valid: this.form.valid,
    });
    this.formChangeSub = this.form.valueChanges.subscribe((_) => {
      this.formChangeEvent.emit({
        values: this.templateFormService.getRawValues(),
        valid: this.form.valid,
      });
    });
  }

  ngOnDestroy(): void {
    this.formChangeSub.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  getTitle(key: string): string {
    return this.templateFormService.getTemplateGroup(key).display;
  }

  safelyAdjustClassName(querySelector: string, unset: boolean): void {
    if (document.querySelector(querySelector) && unset) {
      document.querySelector(querySelector).className = 'card-header';
    } else if (document.querySelector(querySelector) && !unset) {
      document.querySelector(querySelector).className = 'card-header valid-section';
    }
  }

  checkSectionValidity(controlKey: string, sectionId: string): boolean {
    const control = this.templateFormService.getFormGroupByKey(controlKey);
    if (control.valid) {
      this.safelyAdjustClassName(sectionId, false);
    } else {
      this.safelyAdjustClassName(sectionId, true);
    }
    return true;
  }

  handlePanelChange(event) {
    this.activePanelId = event.panelId;
  }

  onSubmit(): void {
    if (!this.form.valid) {
      return;
    }

    this.formSubmitEvent.emit(this.templateFormService.getRawValues());
  }
}
