import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { NurtureBossModalService } from '@app/_services/modal.service';
import MODAL_NAMES from '@app/_components/nb-modal/modalTypes';

@Component({
  selector: 'app-generate-ai-text',
  templateUrl: './generate-ai-text.component.html',
  styleUrls: ['./generate-ai-text.component.less'],
})
export class GenerateAITextComponent {
  // ##########################################################################################
  // Hardcoded value for quickly disabling AI components, they will be hidden from UI
  disabled = false; 
  // ##########################################################################################

  @Input() control: FormControl;
  @Input() controlName: string;

  // Different than disabled above ^^^
  // Disabled here triggers if user has reached token limit OR if AI services in the backend have been disabled
  // If disabled here, buttons still show, but display tooltip explaining why
  disabledText = ''; 

  constructor(
    private nbModalService: NurtureBossModalService,
  ) {
    // no-op
  }

  onDisabledTextChange(text) {
    this.disabledText = text;
  }

  openGenerateTextModal(event) {
    event.preventDefault();
    event.stopPropagation();
    this.nbModalService.open(MODAL_NAMES.GENERATE_AI_TEXT + this.controlName);
  };
}
