import { Component, Input} from '@angular/core';
import {
  AuthenticationService,
  ToastService,
  LoaderService,
  ContactsService,
  UsersService,
  AlertsService,
  IntegrationService,
} from '@app/_services';
import { AutomationSettingsService } from '@app/_services/automationSettings.service';
import { Router } from '@angular/router';
import { BuildYourOwnAutomation } from '@app/byoa/byoa.types';
import { NurtureBossModalService } from '@app/_services/modal.service';

// Types
import { AutomationOriginator, SystemAutomation } from '@nurtureboss/common/dist/types/automations';
import { IHyperPersonalizationSettings } from '@nurtureboss/common/dist/types/settings';
import { AlertNames, Alert } from '@nurtureboss/common/dist/types/alerts';
import { IProperty } from '@app/_types/integration.types';

@Component({
  selector: 'app-automation',
  templateUrl: './automation.component.html',
  styleUrls: ['./automation.component.less']
})
export class AutomationComponent {

  @Input() subPage: string;

  userData: any;
  loading: boolean = true;
  realEstateAutomationActive: any = false;
  availableLabels: Array<any> = [];
  buildYourOwnAutomations: BuildYourOwnAutomation[] = [];
  systemAutomations: BuildYourOwnAutomation[] = [];
  selectedAutomationName: string = '';
  selectedAutomationId: string = '';
  showAlerts = false;
  userHpData: IHyperPersonalizationSettings = null;
  alerts: { [key in AlertNames]?: Alert } | null = null;
  property: IProperty;

  constructor(
    private loaderService: LoaderService,
    private authService: AuthenticationService,
    private toastService: ToastService,
    private automationSettingsService: AutomationSettingsService,
    private router: Router,
    private contactsService: ContactsService,
    private nbModalService: NurtureBossModalService,
    private usersService: UsersService,
    private alertsService: AlertsService,
    private integrationService: IntegrationService,
  ) {
    this.loadData();
  }

  userHasClaim(claim) {
    return this.userData.user.claims.includes(claim);
  }

  isUserIntegrated() {
    return (
      this.userData.user.claims.includes('yardi') ||
      this.userData.user.claims.includes('realpage') ||
      this.userData.user.claims.includes('entrata') ||
      this.userData.user.claims.includes('knock') ||
      this.userData.user.claims.includes('resman')
    );
  }

  isUserPartiallyIntegrated() {
    return this.userData.user.claims.includes('parser') || this.userData.user.claims.includes('ilm');
  }

  isUserRealEstate() {
    return this.userData.user.claims.includes('real estate');
  }

  canUserUseAutomations() {
    return this.isUserIntegrated() || this.isUserRealEstate() || this.isUserPartiallyIntegrated();
  }

  loadBuildYourOwnAutomations() {
    this.loaderService.triggerLoader();
    try {
      this.automationSettingsService.getBuildYourOwnAutomations().subscribe(async ({ result, errors }) => {
        if (errors && errors.length) {
          throw new Error(errors);
        }

        // Logic for displaying Custom Automations and System Automations
        if (result && result.length) {

          // NOTE: subPage is a property on the app-automation component that can be passed in to show that
          // this component is on a page where only some automations should render. We will support other variations
          // besides "vla" in the future as we continue to segment our application by "product".
          this.buildYourOwnAutomations = result.filter(({originator}) => {
            if (this.subPage !== 'vla') {
              return originator === AutomationOriginator.User
            } else {
  
              // Currently VLA does not support BYOA
              this.buildYourOwnAutomations = [];
            }
          });
          this. systemAutomations = result.filter((automation) => {
            if (this.subPage === 'vla' && automation.originator === AutomationOriginator.System) {
              return automation.isVLA;
            } else {
              return (automation.originator === AutomationOriginator.System && !automation.isVLA);
            }
          });

          // Show automations in alphabetical order.
          this.buildYourOwnAutomations = this.buildYourOwnAutomations.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
          this.systemAutomations = this.systemAutomations.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        } else {
          this.buildYourOwnAutomations = [];
          this. systemAutomations = [];
        }

        // Load alerts here since loadBuildYourOwnAutomations acts as the load function for the page
        if (this.showAlerts && this.subPage !== 'vla') {
          try {
            const hpData = (await this.usersService.getHyperpersonalizationData().toPromise()).result;
            this.userHpData = hpData;
            await this.loadAlerts();
          } catch(err) {
            this.toastService.showError('Error retrieving user alerts.');
          }
        }

        this.loaderService.stopLoader();
        this.loading = false;
      }, (err) => {
        this.toastService.show('There was an error loading your integration', { classname: 'bg-danger text-light', delay: 5000 });
        this.loaderService.stopLoader();
        this.loading = false;
      });
    } catch (e) {
      this.toastService.show('There was an error loading your automation settings', { classname: 'bg-danger text-light', delay: 5000 });
      this.loaderService.stopLoader();
      this.loading = false;
    }
  }

  async loadAlerts() {
    const alerts = (await this.alertsService.getUserAlerts().toPromise()).result;
    const alertsNameMap = {};
    alerts.forEach(alert => {
      alertsNameMap[alert.name.toLowerCase().split(' ').join('-')] = alert;
    })
    this.alerts = alertsNameMap;
  }

  loadRealEstateAutomations() {
    this.loaderService.triggerLoader();
    this.automationSettingsService.getRealEstateAutomation().subscribe((data) => {
      this.realEstateAutomationActive = data.result;
      this.contactsService.getLabels().subscribe((labels) => {
        this.availableLabels = [];
        for (var i = 0; i < labels.result.length; i++) {
          this.availableLabels.push({
            label: labels.result[i],
            value: labels.result[i]
          });
        }
        this.loaderService.stopLoader();
        this.loading = false;
      }, (error) => {
        this.toastService.show('There was an error loading your automation settings', { classname: 'bg-danger text-light', delay: 5000 });
        this.loaderService.stopLoader();
        this.loading = false;
      });
      this.loaderService.stopLoader();
      this.loading = false;
    }, (err) => {
      this.toastService.show('There was an error loading your automation settings', { classname: 'bg-danger text-light', delay: 5000 });
      this.loading = false;
    });
  }

  loadAlertStatus() {
    this.showAlerts = 
      this.userData.user.claims.includes('rentcafe') ||
      this.userData.user.claims.includes('realpage') ||
      this.userData.user.claims.includes('entrata') ||
      this.userData.user.claims.includes('resman')
  }

  async loadData() {

    // Get user settings.
    this.loading = true;
    this.userData = this.authService.currentUserValue;
    this.loadAlertStatus();
    if (this.canUserUseAutomations()) {
      this.loadBuildYourOwnAutomations();
    }
    if (this.isUserRealEstate()) {
      this.loadRealEstateAutomations();
    }
    if (!this.canUserUseAutomations()) {
      this.loading = false;
    }
    await this.loadPropertyInfo(this.userData?.user?.integrationPropertyId);
  }

  async loadPropertyInfo(integrationPropertyId: string) {
    this.property = await this.integrationService.getPropertyInfo(integrationPropertyId).toPromise();
  }

  configureBuildYourOwn(automationId) {
    this.router.navigate(['/automation/byoa'], { queryParams: { id: automationId }});
  }

  configureAlert(alertQueryStringName) {
    this.router.navigate([`/alert`], { queryParams: { name: alertQueryStringName }});
  }

  deleteBuildYourOwn(automation) {
    this.selectedAutomationName = automation.name;
    this.selectedAutomationId = automation.id;
    this.nbModalService.open('delete-byoa-modal');
  }

  confirmDeleteAutomation(automationId) {
    this.loaderService.triggerLoader();
    this.automationSettingsService.deleteBuildYourOwnAutomation(automationId).subscribe(() => {
      location.reload();
    }, () => {
      this.loaderService.stopLoader();
      this.toastService.showError('Could not delete automation, please try again.');
    });
  }

  closeModal(name: string) {
    this.selectedAutomationName = null;
    this.selectedAutomationId = null;
    this.nbModalService.close(name);
  }

  newBuildYourOwn() {
    this.router.navigate(['/automation/byoa']);
  }

  updateRealEstateConfiguration() {
    this.automationSettingsService.updateRealEstateAutomation(this.realEstateAutomationActive).subscribe((data) => {
      this.toastService.show('Automation updated!', {
        classname: 'bg-success text-light',
        delay: 5000
      });
      this.realEstateAutomationActive = data.result;
    }, (err) => {
      this.toastService.showError('Error saving configuration.');
    });
  }

  assignLabels(event) {
    this.realEstateAutomationActive.labels = event.value;
    this.updateRealEstateConfiguration();
  }
}
