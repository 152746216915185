<div [stickAtTop]="stickyHeaderOptions" class="step-header">
  <h5 class="pt-4 pb-2 ml-4 pl-1">Customize Messaging</h5>
  <button
    [attr.disabled]="canMoveNext() ? undefined : 'disabled'"
    class="button button-dark next-button mb-4 mr-2"
    (click)="onMoveNext($event)"
  >
    Next
  </button>
</div>
<div class="col-12 pb-4 mb-4 d-flex" style="min-height: 750px;" *ngIf="setupComplete">
  <div class="col-8 panes-container">
    <form [formGroup]="form" class="d-flex">
      <div [ngClass]="{'left-pane': showEmailComponent && showTextComponent}">
        <app-send-text-message
          *ngIf="showTextComponent"
          formControlName="textMessageContent"
          textElementId="textMessageContent"
          [formGroup]="form"
          [maxLength]="290"
          [totalContacts]="totalContacts"
          [tokens]="textMessageTemplateValues"
          [hideRightPane]="showEmailComponent && showTextComponent"
          (inputOnEnterEvent)="handleInputActive('text')"
        ></app-send-text-message>
        <app-send-email-component
          *ngIf="showEmailComponent"
          [formGroup]="form"
          [totalContacts]="totalContacts"
          [tokens]="templateValues"
          [hideRightPane]="showEmailComponent && showTextComponent"
          (inputOnEnterEvent)="handleInputActive('email')"
        ></app-send-email-component>
      </div>
      <div class="right-pane mt-4 pt-2" *ngIf="showEmailComponent && showTextComponent">
        <app-card-total [title]="'Number of Contacts Selected'" [total]="totalContacts" [fixedWidth]="150"></app-card-total>
      </div>
    </form>
  </div>
  <div class="col-4 mt-4 iphone-preview">
    <div>
      <app-iphone-preview
        [stickAtTop]="stickyPhoneOptions"
        [textContent]="getTextContent()"
        [emailSubjectLine]="getEmailSubjectLine()"
        helperText="Could not render preview"
        errorText="Could not render preview"
        [emailUrl]="previewUrl"
        [allowEmailPreviewAndTextMessageToggle]="showTextComponent && showEmailComponent"
        [forceDisplayType]="previewDisplayType"
      ></app-iphone-preview>
    </div>
  </div>
  <div>
  </div>
</div>
