import { Component, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { NurtureBossModalService } from '@app/_services/modal.service';
import { ToastService, OpenAIService } from '@app/_services';
import MODAL_NAMES from '@app/_components/nb-modal/modalTypes';
import { IPrimaryButtonOptions } from '@app/_components/nb-modal/nb-modal.component';
import { FormControl } from '@angular/forms';

@Component({ 
  selector: 'app-generate-ai-text-modal', 
  templateUrl: 'generate-ai-text-modal.component.html', 
  styleUrls: ['generate-ai-text-modal.component.less'],
  encapsulation: ViewEncapsulation.None,
  providers: [NgbActiveModal],
})
export class GenerateAITextModalComponent {
  @Input() controlName: string;
  @Input() outputControl: FormControl;
  @Input() disabledText: string;
  @Output() disabledTextChange = new EventEmitter<string>();

  generateAITextModalPrimaryButton: IPrimaryButtonOptions;
  prompt = new FormControl('');
  name: string = MODAL_NAMES.GENERATE_AI_TEXT;

  constructor(
    private nbModalService: NurtureBossModalService,
    private openAIService: OpenAIService,
    private toastService: ToastService,
  ) {
    this.generateAITextModalPrimaryButton = {
      label: 'Generate Text',
      disabled: false,
      action: this.generateTextConstructor(),
      showSpinner: false,
    };
  }

  async generateText() {
    try {
      this.generateAITextModalPrimaryButton.disabled = true;
      this.generateAITextModalPrimaryButton.showSpinner = true;

      const res = await this.openAIService.generateTextWithPropertyContext(this.prompt.value).toPromise();

      this.prompt.setValue('');
      this.outputControl.setValue(res.result.text);
      this.toastService.showSuccess('Text generated successfully!');
    } catch(err) {
      if (Array.isArray(err) && err.includes('User AI Request Limit Reached')) {
        this.toastService.showError('User Request Limit Reached, please wait to use AI services again.');
        this.disabledTextChange.emit('API request limit reached, please wait to use AI services again');
      } else if (Array.isArray(err) && err.includes('Open AI services are currently disabled.')) {
        this.toastService.showError('AI services are currently disabled.');
        this.disabledTextChange.emit('AI services are currently disabled.');
      } else {
        this.toastService.showError('There was an error generating AI text.');
      }
    }
    this.generateAITextModalPrimaryButton.disabled = false;
    this.generateAITextModalPrimaryButton.showSpinner = false;
    this.nbModalService.close(this.name + this.controlName);
  };

  generateTextConstructor(): () => void {
    return () => this.generateText();
  }

  // TODO: make this not required
  buildGenerateAITextModalDismissedBuilder(): (event: Event) => void {
    return () => {};
  }
}