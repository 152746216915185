<div class="mt-4" *ngIf="keys.length === 0"></div>
<div id="nbTokenContainer" *ngIf="keys.length > 0">
    <strong class="title">{{title}}</strong>
    <div class="tokens">
        <span *ngFor="let key of keys; let i = index">
            <span *ngIf="showToken(key)" value="[{{key}}]" (click)="handleTokenClick($event)" class="token" title="Click here to insert">
                [{{key}}]<span *ngIf="i !== (keys.length - 1)">,</span>
            </span>
        </span>
    </div>
</div>
