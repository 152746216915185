<div class="card mt-4" id="broadcast-component">
  <div class="card-body">
    <div class="overflow-auto">
      <div class="card-actions pull-right">
        <div class="button button-dark" (click)="handleNewBroadcastClick()">
          Create New Blast
        </div>
    </div>
      <p class="table-row-indicator pull-left" *ngIf="totalRecords > 0">
        <strong>Showing {{pagination.first}} to {{pagination.last}} of {{totalRecords}} rows</strong>
      </p>
      <p class="table-row-indicator" *ngIf="totalRecords === 0">
        <strong>No tracking info found</strong>
      </p>
    </div>
    <p-table #dt 
      [responsive]="true"
      [value]="broadcasts"
      class="broadcast-table"
      styleClass="ui-table-blasts"
      [lazy]="true"
      [paginator]="true"
      rows="25"
      totalRecords="{{totalRecords}}"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {{totalRecords}} entries"
      [rowsPerPageOptions]="[25,50,100,250]"
      (onPage)="paginate($event)"
    >
      <ng-template pTemplate="header">
          <tr>
            <th>
              Delivery Date
            </th>  
            <th>
              Template Name
            </th>
            <th>
              Delivery Method
            </th>
            <th></th>
          </tr>
          <tr>
            <th>
              <p-calendar #deliveredCalendar
                selectionMode="range"
                (onSelect)="onDateSelect($event, 'scheduleSettings.date')"
                (onClearClick)="onDateClear($event)"
                [showButtonBar]="true"
                styleClass="ui-column-filter"
                placeholder="Date"
                [readonlyInput]="true"
                dateFormat="mm-dd-yy"
              ></p-calendar>
            </th>
            <th>
              <input pInputText type="text" placeholder="Search" [(ngModel)]="templateNameSearchText" (ngModelChange)="debouncedSearch(templateNameSearchText, 'templateName')">
            </th>
            <th>
              <input pInputText type="text" placeholder="Search" [(ngModel)]="deliveryMethodSearchText" (ngModelChange)="debouncedSearch(deliveryMethodSearchText, 'deliveryMethod')">
            </th>
            <th></th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-broadcast let-rowData>
        <tr>
          <td>
            <span *ngIf="futureCheck(broadcast.scheduleSettings.date)">
              <i placement="right" ngbTooltip="Upcoming Blast! {{broadcast.scheduleSettings.date | date:'MMM d, y, h:mm a'}}" class="far fa-alarm-clock"></i>
            </span>
            {{broadcast.scheduleSettings.date | date}}
          </td>
          <td>
            {{broadcast.templateName }}
          </td>
          <td>
            <span *ngIf="broadcast.deliveryMethod === 'text'"
            [class]="'delivery-badge text'">{{broadcast.deliveryMethod | titlecase}}</span>
            <span *ngIf="broadcast.deliveryMethod === 'email'"
            [class]="'delivery-badge email'">{{broadcast.deliveryMethod | titlecase}}</span>
            <span *ngIf="broadcast.deliveryMethod === 'optimize'"
            [class]="'delivery-badge optimize'">{{broadcast.deliveryMethod | titlecase}}</span>
          </td>
          <td class="text-center action-icons">
            <i *ngIf="futureCheck(broadcast.scheduleSettings.date)" class="far fa-trash-alt delete-blast" (click)="deleteScheduledBroadcast(broadcast)"></i>
            <i [ngbTooltip]="'View Recipients'" class="far fa-users margin-left-12" (click)="previewRecipients(broadcast)"></i>
            <i [ngbTooltip]="'Preview Blast'" class="margin-left-12 far fa-eye" (click)="previewBlastTemplate(broadcast)"></i>
            <i [ngbTooltip]="'Analytics'" class="margin-left-12 far fa-chart-column" (click)="viewBlastAnalytics(broadcast)"></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<nb-modal
  [name]="'preview-template-modal'"
  [header]="'Template Preview'"
  [confetti]="false"
  [doOnDismiss]="resetTemplatePreviewModal"
  [doOnClose]="resetTemplatePreviewModal"
  class="template-preview-modal-wrapper"
>
  <div>
    <app-iphone-preview
      [emailUrl]="emailPreviewUrl"
      [nurturePageUrl]="pagePreviewUrl"
      [forceDisplayType]="forcePreviewType"
      helperText="Loading..."
      errorText="Something went wrong when generating your preview!"
    ></app-iphone-preview>
  </div>
</nb-modal>

<nb-modal
  [name]="'preview-recipients-modal'"
  [header]="'Blast Recipients'"
  [confetti]="false"
  [doOnDismiss]="resetTemplatePreviewModal"
  [doOnClose]="resetTemplatePreviewModal"
  class="template-recipients-modal-wrapper"
>
  <div>
    <app-contacts-table
    [user]="currentUser"
    [linkToContactPage]="false"
    [valuesOverride]="recipientData"
    [hasValueOverride]="true"
    [allowRowSelect]="false"
  >
  </app-contacts-table>
  </div>
</nb-modal>

<nb-modal
  [name]="'view-blast-analytics-modal'"
  [header]="'Blast Analytics'"
  [confetti]="false"
  [doOnDismiss]="resetAnalyticsModal"
  [doOnClose]="resetAnalyticsModal"
  class="blast-analytics-modal-wrapper"
>
  <div id="broadcast-analytics-loader"></div>
</nb-modal>