// Angular:
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Libs:
import { map } from 'rxjs/operators';
// Services:
import { APIHelperService } from './apiHelper.service';
// Schemas:
import { Notifications } from '@nurtureboss/common/dist/types/notifications';

export { Notifications };

export interface ResponseNotifications {
  errors: [];
  result: Notifications;
}

@Injectable({ providedIn: 'root' })
export class NotificationsService {

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService
  ) {
    // no-op
  }

  getNotifications() {
    return this.http.get<ResponseNotifications>(this.apiHelper.fillUrl('notifications', {}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  updateNotification(id, payload) {
    return this.http.put<ResponseNotifications>(this.apiHelper.fillUrl('notification', {id}, {}), payload)
      .pipe(map(data => {
        return data;
      }));
  }
}
