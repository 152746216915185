<div id="user-audits-widget" *ngIf="userAudits.length">
  <div class="backlay" *ngIf="showOverlay" (click)="toggleOverlay()"></div>
  <div class="overlay" *ngIf="showOverlay">
    <div class="exit-button" (click)="toggleOverlay()">
      <i class="far fa-times"></i>    
    </div>
    <div class="audit-list-container">
      <div class="audit-list-row" *ngFor="let userAudit of userAudits">
        <div class="audit-list-icon-col">
          <i [ngClass]="[userAudit.icon, 'fa-solid', 'priority-1']" *ngIf="userAudit.auditType.priority === 1"></i>
          <i [ngClass]="[userAudit.icon, 'fa-solid', 'priority-2']" *ngIf="userAudit.auditType.priority === 2"></i>
          <i [ngClass]="[userAudit.icon, 'fa-solid', 'priority-3']" *ngIf="userAudit.auditType.priority === 3"></i>
        </div>
        <div class="audit-list-content-col">
          <div class="audit-item-description">{{userAudit.auditType.description}} <br/> <a (click)="onUrlClick(userAudit.url)">Click Here To Fix!</a></div>
        </div>
      </div>
    </div>
  </div>
  <button class="widget-button" (click)="toggleOverlay()">
    <div class="notification-number">{{userAudits.length}}</div>
    <i class="fa-solid fa-bell-exclamation"></i>
  </button>
</div>