import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

/**
 * Implement this interface on a route component to use the CanDeactivate guard on a specific route.
 * Note: You cannot customize the message when the browser redirects or refreshes outside of your app
 * https://stackoverflow.com/questions/42142053/candeactivate-confirm-message/42207299#42207299
 */
export interface CanDeactivateComponent {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({ providedIn: 'root' })
export class PendingChangesGuard implements CanDeactivate<CanDeactivateComponent> {
  constructor() {}

  canDeactivate(component: CanDeactivateComponent): boolean | Observable<boolean> {
    return component.canDeactivate();
  }
}