<div id="delivery">  
  <div [stickAtTop]="stickyHeaderOptions" class="step-header">
    <h5 class="pt-4 pb-2 ml-4 pl-1">Select Delivery Options and Recipient</h5>
    <button
      [attr.disabled]="canMoveNext() ? undefined : 'disabled'"
      class="button button-dark next-button mb-4 mr-2"
      (click)="onMoveNext($event)"
    >
      Next
    </button>
  </div>
  <div class="d-flex ml-4">
    <input type="checkbox" name="emergency" id="emergency-checkbox" [formControl]="emergency">
    <label id="emergency-checkbox-label" for="emergency">Emergency Message</label>
  </div>
  <div id="delivery-method">
    <div class="delivery">
      <h6>Delivery options</h6>
      <div
        class="form-group option-card {{selectedDeliveryMethod === 'optimize' ? 'active' : ''}}{{supportsOptimize ? '' : 'disabled'}}"
        (click)="onDeliveryMethodClick('optimize')"
        [ngbTooltip]="optimizeTooltip"
      >
        <div class="option-info">
          <p><strong>Optimized</strong></p>
          <p>Let Nurture Boss text those who have opted-in and email everyone else!</p>
        </div>
        <div class="option-icon">
          <i class="far fa-bolt"></i>
        </div>
      </div>

      <div
        class="form-group option-card {{selectedDeliveryMethod === 'text' ? 'active' : ''}}{{supportsText ? '' : 'disabled'}}"
        (click)="onDeliveryMethodClick('text')"
        [ngbTooltip]="textTooltip"
      >
        <div class="option-info">
          <p><strong>Text / SMS</strong></p>
          <p>Send a text to your opt-in contacts!</p>
        </div>
        <div class="option-icon">
          <i class="far fa-comment-alt-lines"></i>
        </div>
      </div>

      <div
        class="form-group option-card {{selectedDeliveryMethod === 'email' ? 'active' : ''}}{{supportsEmail ? '' : 'disabled'}}"
        (click)="onDeliveryMethodClick('email')"
        [ngbTooltip]="emailTooltip"
      >
        <div class="option-info">
          <p><strong>Email</strong></p>
          <p>Send an email to applicable contacts!</p>
        </div>
        <div class="option-icon">
          <i class="far fa-envelope"></i>
        </div>
      </div>
    </div>

    <div class="recipients">
      <h6>Recipients</h6>
      <!-- Intentionally do not expose to Partners. -->
      <div
        class="form-group recipient-card{{(!selectedDeliveryMethod || (selectedDeliveryMethod === 'text'  && isAdmin() === false) || selectedDeliveryMethod === 'optimize') ? ' disabled' : ''}}{{recipientSelection === 'upload' ? ' active' : ''}}"
        (click)="openUploadModal(selectedDeliveryMethod === 'text')"
        [ngbTooltip]="uploadTooltip"
      >
        <div class="recipient-info">
          <p><strong>Upload Spreadsheet</strong></p>
          <span class="icon-hyperlink">Browse for a File</span>
        </div>
        <div class="recipient-icon">
          <i class="far fa-file-upload"></i>
        </div>
      </div>

      <div
        class="form-group recipient-card{{!selectedDeliveryMethod ? ' disabled' : ''}}{{recipientSelection === 'existing-contacts' ? ' active' : ''}}"
        (click)="openContactSelectionModal()"
      >
        <div class="recipient-info">
          <p><strong>Select From Prospects & Residents</strong></p>
          <span class="icon-hyperlink">Browse Guest Cards</span>
        </div>
        <div class="recipient-icon">
          <i class="far fa-address-book"></i>
        </div>
      </div>

      <div
        class="form-group recipient-card{{!selectedDeliveryMethod || selectedDeliveryMethod === 'optimize' ? ' disabled' : ''}}{{recipientSelection === 'single' ? ' active' : ''}}"
        (click)="openSingleContactModal()"
      >
        <div class="recipient-info">
          <p><strong>Single Contact Entry</strong></p>
          <span class="icon-hyperlink">Enter Contact Info</span>
        </div>
        <div class="recipient-icon">
          <i class="far fa-id-card"></i>
        </div>
      </div>
    </div>

    <div class="details" *ngIf="!!selectedDeliveryMethod && !!recipientSelection">
      <h6>Details</h6>
      <div class="details-content">
        <app-card-total
          *ngIf="recipientSelection === 'existing-contacts'"
          title="{{selectedContacts.length === 1 ? 'Selected Contact' : 'Selected Contacts'}}"
          [total]="selectedContacts.length"
          [fixedWidth]="150"
        >
        </app-card-total>
        <app-card-total
          *ngIf="recipientSelection === 'upload'"
          title="{{uploadedContacts.length === 1 ? 'Uploaded Contact' : 'Uploaded Contacts'}}"
          [total]="uploadedContacts.length"
          [fixedWidth]="150"
        >
        </app-card-total>
        <p class="warning" *ngIf="recipientSelection === 'upload' && uploadFailureCount > 0">
          {{uploadFailureCount}} contact(s) failed to upload.
        </p>
        <div class="single-contact-form" *ngIf="recipientSelection === 'single' && singleContactForm.valid">
          <div class="form-group" *ngFor="let requiredField of templateRequiredFields">
            <span><strong>{{requiredField.display}}</strong></span>
            <br/>
            <span>{{singleContactForm.value[requiredField.name]}}</span>
          </div>

          <div class="form-group" *ngFor="let optionalField of templateOptionalFields">
            <span><strong>{{optionalField.display}}</strong></span>
            <br/>
            <span>{{singleContactForm.value[optionalField.name]}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<nb-modal
  name="upload-contacts-broadcast"
  header="Upload Contacts"
  [confetti]="false"
  class="upload-contacts-modal"
>
  <div appComponentInjector *ngIf="recipientSelection === 'upload'"></div>
</nb-modal>

<nb-modal
  name="enter-single-contact-broadcast"
  header="Enter Contact Information"
  [confetti]="false"
  class="enter-contact-modal"
  footer="true"
  [primaryButtonOptions]="primaryButtonOptionsSingleContact"
>
  <form [formGroup]="singleContactForm" *ngIf="recipientSelection === 'single'">
    <div class="form-group" *ngFor="let requiredField of templateRequiredFields">
      <label [for]="requiredField.name">{{requiredField.display}}</label>
      <input
        class="form-control"
        [formControlName]="requiredField.name"
        [id]="requiredField.name"
        [name]="requiredField.name"
        type="text"
        [mask]="requiredField.name === 'clientPhoneNumber' ? '(000) 000-0000' : ''"
        [placeholder]="requiredField.meta.placeholder"
      />
      <app-print-errors [controlName]="requiredField.display" [control]="singleContactForm.controls[requiredField.name]"></app-print-errors>
    </div>

    <div class="form-group" *ngFor="let optionalField of templateOptionalFields">
      <label [for]="optionalField.name">{{optionalField.display}} - <em>Optional</em></label>
      <input
        class="form-control"
        [formControlName]="optionalField.name"
        [id]="optionalField.name"
        [name]="optionalField.name"
        type="text"
        [placeholder]="optionalField.meta.placeholder"
      />
    </div>
  </form>
</nb-modal>

<nb-modal
  name="select-contacts-broadcast"
  header="Select Prospects & Residents"
  [confetti]="false"
  [doOnDismiss]="buildResetContacts()"
  class="contact-selection-modal"
  footer="true"
  [primaryButtonOptions]="primaryButtonOptionsContactTable"
>
  <div class="contact-selection" *ngIf="recipientSelection === 'existing-contacts'">
    <app-contacts-table
      [user]="currentUser"
      [linkToContactPage]="false"
      (onSelectionChanged)="onContactSelectionChange($event)"
      [textOnly]="selectedDeliveryMethod === 'text'"
    >
    </app-contacts-table>
  </div>
</nb-modal>

<nb-modal
  [name]="'confirm-emergency-message'"
  [header]="'Confirm Emergency Message'"
  [confetti]="false"
  class="confirm-emergency-message-modal"
  [additionalStyles]="'max-width: 415px; margin: 0 auto; transform: translate(-50%)'"
>
  <p class="warning-text">
    Are you sure you want make this an emergency message? This will ignore email unsubscribes!
  </p>
  <div class="d-flex p-2 justify-content-end">
    <button class="button button-danger no-margin" (click)="emergencyConfirmAction()">Confirm</button>
  </div>
</nb-modal>