import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthenticationService,
  LoaderService,
  ToastService,
  WebsocketsService,
  TextMessagesService,
  UsersService,
  PartnerDetailsService
} from './_services';
import { SwPush, SwUpdate } from '@angular/service-worker';
import * as FullStory from '@fullstory/browser';
import { WidgetService } from './_services/widget.service';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { Branding } from '@nurtureboss/common/dist/types/brandings';

let self;
const DEFAULT_BRANDING = {
  logoUrl: '/assets/nurturebossFULLcolor.png',
  primaryColor: 'rgb(98, 45, 136)',
};

@Component({
  selector: 'app',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, OnDestroy {
  loaderActive: boolean;
  longLoaderActive: boolean;
  showTosUpdate = false;
  noSideBarStates: Array<string> = [
    '/login',
    '/forgot-password',
    '/password-reset',
    '/dashboard-auth'
  ];
  unreadMessages = 0;
  websocketSubscription: Subscription;
  unreadDictionary: any = {};
  showMimicWarning: string;
  isMimicAccount = false;
  showUpdateUsageModal = false;
  newUsageAmount: any = null;
  claimsData: any = [];
  unreadTextSubscription: Subscription;
  hasSetUp3PServices = false;
  isAdmin = false;
  isPartner = false;
  isPartnerUser = false;
  isRealEstate = false;
  isGroup = false;
  brandingData: Partial<Branding> & {secondaryColor?: string} = DEFAULT_BRANDING;
  adjustedHeight = '100vh';
  fullySupportedIntegrationUserValue = false;
  dropdownMenus = {
    other: {
      active: false,
    },
  };

  constructor(
    public router: Router,
    private authenticationService: AuthenticationService,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private websocketsService: WebsocketsService,
    private textMessagesService: TextMessagesService,
    private swPush: SwPush,
    private usersService: UsersService,
    private updates: SwUpdate,
    private widgetService: WidgetService,
    private partnerDetailsService: PartnerDetailsService,
  ) {
    self = this;
  }

  toggleDropdown(menuItem) {
    this.dropdownMenus[menuItem].active = !this.dropdownMenus[menuItem].active;
  }

  showChangeUsage() {
    this.showUpdateUsageModal = !this.showUpdateUsageModal;
    this.newUsageAmount = null;
  }

  updateUsage() {
    this.usersService.updateUsage({
      amount: this.newUsageAmount
    }).subscribe(() => {
      this.showChangeUsage();
      this.newUsageAmount = null;
      this.toastService.show('Available usage updated!', {
        classname: 'bg-success text-light',
        delay: 5000
      });
    }, (err) => {
      this.showChangeUsage();
      this.newUsageAmount = null;
      this.toastService.show('There was an error updating available usage', {
        classname: 'bg-danger text-light',
        delay: 5000
      });
    });
  }

  updateUxWithMessageCount(unreadMessages: number) {
    const favicon1 = document.getElementById('favicon-small') as HTMLAnchorElement;
    const favicon2 = document.getElementById('favicon-large') as HTMLAnchorElement;
    const favicon3 = document.getElementById('apple-icon') as HTMLAnchorElement;
    // Dynamically set favicon based on unreadcount
    if (unreadMessages > 0) {
      favicon1.href = '/assets/favicon-16x16-notify.png';
      favicon2.href = '/assets/favicon-32x32-notify.png';
      favicon3.href = '/assets/apple-touch-icon-notify.png';
    } else {
      favicon1.href = '/assets/favicon-16x16.png';
      favicon2.href = '/assets/favicon-32x32.png';
      favicon3.href = '/assets/apple-touch-icon.png';
    }
  }

  setUpServiceWorker(user) {
    if (!environment.production || !this.swPush.isEnabled) {
      // Service workers don't work in dev, skip.
      return;
    }

    // Subscribe to push notifications
    this.swPush.requestSubscription({
      serverPublicKey: 'BN5fR-d_RIaFgnO2jzR0-uzaQerECUhiPdv1qOy8sgYseWYtAXgI_kR1JKTtTrn9w8n6ONc67UT-NAvmTtdAZ3g'
    })
      .then((sub) => {
        this.usersService.updateUser(user._id, { browserPushData: sub }, true).subscribe();
      })
      .catch((_err) => {

        // Set temporary flag to not show this error again for 24 hours.
        const alertFlag: {
          value: boolean,
          expiry: number,
        } = JSON.parse(localStorage.getItem('notificationSubscribeAlert'));
        if (!alertFlag?.expiry || new Date().getTime() > alertFlag?.expiry) {
          this.toastService.showError('Could not subscribe to notifications');
          const data = {
            value: true,
            expiry: new Date().getTime() + 86400000, // 24 hours.
          };
          localStorage.setItem('notificationSubscribeAlert', JSON.stringify(data));
        }
      });
  }

  setUpMessageListeners() {
    this.unreadTextSubscription?.unsubscribe();
    this.unreadDictionary = {};
    this.unreadMessages = 0;

    // Get unread message cout.
    this.textMessagesService.getUnreadCount().subscribe((data) => {
      // Normalize Unread messages
      let unreadMessages = 0;
      for (let i = 0; i < data.result.length; i++) {
        this.unreadDictionary[data.result[i]._id] = data.result[i].read;
        // We can't have a conversation if we don't have a contact.
        if (data.result[i].contactId !== 'NONE' && !data.result[i].read) {
          unreadMessages++;
        }
      }
      this.unreadMessages = unreadMessages;
      this.textMessagesService.updateLocalUnreadCount(this.unreadMessages);

      this.updateUxWithMessageCount(unreadMessages);
    }, (err) => {
      this.toastService.showError('Could not retrieve unread messages.');
    });

    // Listen for updates to unread count.
    this.unreadTextSubscription = this.textMessagesService.unreadMessageCount$.subscribe((message) => {
      if (message && message.conversationId) {
        this.unreadDictionary[message.conversationId] = !!message.read;
      }

      // TODO: This is duplicated, we need to optimize, make DRY.
      let unreadMessages = 0;
      for (let i in this.unreadDictionary) {
        if (!this.unreadDictionary[i]) {
          unreadMessages++;
        }
      }
      this.unreadMessages = unreadMessages;
      this.textMessagesService.updateLocalUnreadCount(this.unreadMessages);

      this.updateUxWithMessageCount(unreadMessages);
    });
  }

  regexIndexOf(array, str) {
    for (let i = 0; i < array.length; i++) {
      const indexOf = str.search(new RegExp(array[i]));
      if (indexOf >= 0) {
        return indexOf;
      }
    }
    return -1;
  }

  logout() {
    this.hasSetUp3PServices = false;
    this.authenticationService.logout();
    this.brandingData = DEFAULT_BRANDING;
    this.changeTheme(this.brandingData.primaryColor as string, this.brandingData.secondaryColor as string);
    this.router.navigate(['/login']);

    // TODO: Kill websocket connection.
  }

  agreeToTerms() {
    this.authenticationService.agreeToTerms().subscribe((data) => {
      const user = this.authenticationService.currentUserValue.user;
      user.claimsAgreed = data.result;
      this.authenticationService.updateGlobalUserValue(user);
      this.showTosUpdate = false;
    }, () => {
      this.toastService.show('There was an error, please contact support@nurtureboss.io', {
        classname: 'bg-danger text-light',
        delay: 5000
      });
    });
  }

  setUpLoaders() {
    this.loaderService.loaderActive.subscribe((y: boolean) => {
      setTimeout(() => {
        this.loaderActive = y;
      });
    });
    this.loaderService.longLoaderActive.subscribe((z: boolean) => this.longLoaderActive = z);
  }

  startAutoUpdates() {
    if (!this.updates.isEnabled) {
      console.warn('Service worker not enabled');
      return;
    }

    // Forces update check on load
    this.updates.checkForUpdate().then(() => {
      // no-op
    }).catch((err) => {
      this.toastService.showError('Could not check for application updates.');
    });

    // When an update is available then activate
    this.updates.available.subscribe(() => {
      this.updates.activateUpdate().then(() => {
        document.location.reload();
      });
    });
  }

  setUp3PServices(user) {
    if (!user || this.hasSetUp3PServices) {
      return;
    }

    let userId;
    let userEmail;
    if (
      user.claims.includes('admin') ||
      user.claims.includes('partner')
    ) {
      userId = 'admin';
      userEmail = 'admin@nurtureboss.io';
    } else {
      userId = user._id;
      userEmail = user.email;
    }

    try {
      FullStory.identify(userId, {
        email: userEmail,
        displayName: user.propertyName
      });
    } catch (e) {
      console.warn('Error setting up Fullstory', e);
    }
    this.hasSetUp3PServices = true;
  }

  ngOnInit() {
    this.usersService.loadUser();
    this.changeTheme(this.brandingData.primaryColor as string, this.brandingData.secondaryColor as string);
    this.authenticationService.currentUser.subscribe(async (userData) => {
      if (!userData || !userData.user) {
        this.showMimicWarning = '';
        this.isMimicAccount = false;
        this.claimsData = [];
        this.showTosUpdate = false;
        this.websocketSubscription?.unsubscribe();
        this.unreadTextSubscription?.unsubscribe();
        this.hasSetUp3PServices = false;
        return;
      }
      const user = userData.user;
      this.fullySupportedIntegrationUserValue = 
      !!(
        user.claims.includes('yardi')
        || user.claims.includes('realpage')
        || user.claims.includes('knock')
        || user.claims.includes('entrata')
        || user.claims.includes('resman')
      );
      this.isPartnerUser = !!user.partnerOwnerId;
      this.isPartner = this.claimsData.includes('partner');

      // Fetch Partner Details
      if (this.isPartner || this.isPartnerUser) {
        const partnerDetails = await this.partnerDetailsService.getPartnerDetails(user.partnerOwnerId || user._id).toPromise();
        this.brandingData = partnerDetails.result.brandings;
        this.changeTheme(this.brandingData.primaryColor as string, this.brandingData.secondaryColor as string); // Set default theme
      }
      this.setUp3PServices(user);
      this.widgetService.resetAccessToken();

      this.websocketSubscription?.unsubscribe();
      this.websocketSubscription = this.websocketsService.websocketMessages().subscribe((message) => {
        // If we are on messages screen don't do anything, messages component will handle.
        if (this.router && this.router.url.indexOf('conversations') === -1) {
          if (message && message._id) {
            this.textMessagesService.updateUnreadCountInternally(message);
          }
        }
      });

      if (user.twilioAccountId) {
        this.setUpMessageListeners();
      }
      this.setUpServiceWorker(user);

      this.claimsData = user.claims;

      this.isAdmin = this.claimsData.includes('admin');
      this.isRealEstate = this.claimsData.includes('real estate');
      this.isGroup = this.claimsData.includes('group');

      // Should we show ToS to user?
      if (!this.isAdmin && !this.isPartner && (!user.claimsAgreed || new Date(user.claimsAgreed) < new Date('Aug 6 2020'))) {
        this.showTosUpdate = true;
      } else if (this.isAdmin || this.isPartner) {
        this.showTosUpdate = false;
      }
      if (this.authenticationService.mimickingAccountValue) {
        this.showMimicWarning = this.authenticationService.mimickingAccountValue;
        this.isMimicAccount = true;
      } else {
        this.showMimicWarning = '';
        this.isMimicAccount = false;
      }

      // Fetch Partner Details
      if (this.isPartner) {
        const partnerDetails = await this.partnerDetailsService.getPartnerDetails(user.partnerOwnerId || user._id).toPromise();
        this.brandingData = partnerDetails.result.brandings;
        this.changeTheme(this.brandingData.primaryColor as string, this.brandingData.secondaryColor as string); // Set default theme
      }

      // Adjust CSS to give CS a good experience too :)
      if (this.isAdmin) {
        this.adjustedHeight = 'calc(100vh - 82px)';
      }
    });

    this.setUpLoaders();
    this.startAutoUpdates();
    this.websocketsService.startWebsocketListener();
  }

  ngOnDestroy() {
    this.websocketSubscription.unsubscribe();
  }

  changeTheme(primary: string, secondary: string) {
    document.documentElement.style.setProperty('--primary-color', primary);
    document.documentElement.style.setProperty('--secondary-color', secondary);
    // Set opactiy colors
    if (primary) {
      document.documentElement.style.setProperty('--primary-color-90', primary.replace(/rgb\([0-9]{1,3},\s[0-9]{1,3},\s[0-9]{1,3}/, "$&, 0.90").replace("rgb(", "rgba("));
      document.documentElement.style.setProperty('--primary-color-80', primary.replace(/rgb\([0-9]{1,3},\s[0-9]{1,3},\s[0-9]{1,3}/, "$&, 0.80").replace("rgb(", "rgba("));
      document.documentElement.style.setProperty('--primary-color-70', primary.replace(/rgb\([0-9]{1,3},\s[0-9]{1,3},\s[0-9]{1,3}/, "$&, 0.70").replace("rgb(", "rgba("));
      document.documentElement.style.setProperty('--primary-color-60', primary.replace(/rgb\([0-9]{1,3},\s[0-9]{1,3},\s[0-9]{1,3}/, "$&, 0.60").replace("rgb(", "rgba("));
      document.documentElement.style.setProperty('--primary-color-50', primary.replace(/rgb\([0-9]{1,3},\s[0-9]{1,3},\s[0-9]{1,3}/, "$&, 0.50").replace("rgb(", "rgba("));
      document.documentElement.style.setProperty('--primary-color-40', primary.replace(/rgb\([0-9]{1,3},\s[0-9]{1,3},\s[0-9]{1,3}/, "$&, 0.40").replace("rgb(", "rgba("));
      document.documentElement.style.setProperty('--primary-color-30', primary.replace(/rgb\([0-9]{1,3},\s[0-9]{1,3},\s[0-9]{1,3}/, "$&, 0.30").replace("rgb(", "rgba("));
      document.documentElement.style.setProperty('--primary-color-20', primary.replace(/rgb\([0-9]{1,3},\s[0-9]{1,3},\s[0-9]{1,3}/, "$&, 0.20").replace("rgb(", "rgba("));
      document.documentElement.style.setProperty('--primary-color-10', primary.replace(/rgb\([0-9]{1,3},\s[0-9]{1,3},\s[0-9]{1,3}/, "$&, 0.10").replace("rgb(", "rgba("));
      document.documentElement.style.setProperty('--primary-color-5', primary.replace(/rgb\([0-9]{1,3},\s[0-9]{1,3},\s[0-9]{1,3}/, "$&, 0.05").replace("rgb(", "rgba("));
    }
  }
}
