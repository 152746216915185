import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { ChangeStep, WizardService } from './wizard.service';

import {
  WizardNavigationEvent,
  WizardCompleteEvent,
  WizardNavigationOptions,
} from './wizard.types';

@Component({
  selector: 'app-wizard-step',
  template: `<ng-container *ngIf="isActiveStep"><ng-content></ng-content></ng-container>`,
})
export class WizardStepComponent<TContext extends {}> implements OnInit {
  
  @Input() stepId: string;
  @Input() label: string;

  isActiveStep = false;

  wizardComponent: WizardComponent<TContext>;

  constructor(
    @Inject(forwardRef(() => WizardComponent)) wizard: WizardComponent<TContext>,
    private wizardService: WizardService<TContext>,
  ) {
    this.wizardComponent = wizard;
  }

  ngOnInit(): void {
    this.wizardService.registerStep(this.stepId);
    this.wizardService.stepChanged$.subscribe((stepId) => {
      this.isActiveStep = stepId === this.stepId;
    });
  }
}

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.less'],
  providers: [WizardService]
})
export class WizardComponent<TContext extends {}> implements OnInit, AfterContentInit, OnDestroy {

  @ContentChildren(WizardStepComponent) stepsQueryList!: QueryList<WizardStepComponent<TContext>>;

  @Input() initialContext: TContext;

  @Output() onWizardNavigate = new EventEmitter<WizardNavigationEvent<TContext>>();
  @Output() onWizardComplete = new EventEmitter<WizardCompleteEvent<TContext>>();
  @Output() onWizardExit = new EventEmitter<WizardCompleteEvent<TContext>>();

  activeIndex = 0;
  isLoading = false;
  steps: WizardStepComponent<TContext>[] = [];
  stepsChangeSubscription: Subscription;
  completeStepSubscription: Subscription;

  constructor(public wizardService: WizardService<TContext>) { }

  ngOnInit(): void {
    this.wizardService.setContext(this.initialContext);
    this.completeStepSubscription = this.wizardService.nextStep$.subscribe(this.onNext.bind(this));
  }

  ngOnDestroy(): void {
    this.stepsChangeSubscription?.unsubscribe();
    this.completeStepSubscription?.unsubscribe();
  }

  ngAfterContentInit(): void {
    this.initSteps();
    this.stepsChangeSubscription = this.stepsQueryList.changes.subscribe((_) => {
      // I don't think this would happen, but... 👀
      this.initSteps();
    });
  }

  initSteps() {
    this.steps = this.stepsQueryList.toArray();
    this.activeIndex = 0;
    this.wizardService.initialize(this.initialContext, this.steps[this.activeIndex].stepId);
  }

  onNext(event: ChangeStep<TContext>) {
    // Is this the last step?
    if (this.activeIndex === this.steps.length - 1) {
      this.onWizardComplete.emit({
        finalContext: event.context,
      });
      return;
    }

    const continueNavigation = (options: WizardNavigationOptions = {}) => {
      const nextStepId = options.nextStepOverride;
      if (nextStepId) {
        this.activeIndex = this.steps.findIndex((step) => step.stepId === nextStepId);

        if (this.activeIndex < 0) {
          // The parent component should handle this error
          return false;
        }
      } else {
        this.activeIndex += 1;
      }

      this.wizardService.nextStep(this.steps[this.activeIndex].stepId);
      return true;
    };

    this.onWizardNavigate.emit({
      continueNavigation,
      currentContext: event.context,
      currentStepId: event.stepId,
      nextStepId: this.steps[this.activeIndex + 1].stepId,
    });
  }

  onPrevious(event: MouseEvent) {
    event.preventDefault();

    if (this.activeIndex === 0) {
      return;
    }

    this.activeIndex -= 1;
    this.wizardService.previousStep();
  }

  onExit(event: MouseEvent) {
    event.preventDefault();

    this.onWizardExit.emit({
      finalContext: this.wizardService.getContext(),
    });
  }
}
