import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-print-errors',
  templateUrl: './print-errors.component.html',
  styleUrls: ['./print-errors.component.less']
})
export class PrintErrorsComponent implements OnInit, OnDestroy {

  @Input() controlName: string;
  @Input() control: FormControl;
  changeSubscription: Subscription;
  errorMessages: string[] = [];

  constructor() { }

  ngOnInit(): void {
    this.changeSubscription = this.control.valueChanges.pipe(
      debounceTime(300),
    ).subscribe(this.generateErrorMessages.bind(this));
  }

  generateErrorMessages() {
    this.errorMessages = [];
    if (!this.control.errors || !Object.keys(this.control.errors).length) {
      return;
    }

    if (typeof this.control.errors === 'string') {
      this.errorMessages.push(this.control.errors);
      return;
    }

    for (const errorKey of Object.keys(this.control.errors)) {
      switch (errorKey) {
        case 'required':
          this.errorMessages.push(`${this.controlName} is required.`);
          break;
        case 'minlength':
          this.errorMessages.push(`${this.controlName} must be greater than or equal to ${this.control.errors[errorKey].requiredLength} characters.`);
          break;
        case 'maxlength':
          this.errorMessages.push(`${this.controlName} must be less than or equal to ${this.control.errors[errorKey].requiredLength} characters.`);
          break;
        case 'pattern':
          this.errorMessages.push(`${this.controlName} contains invalid characters.`);
          break;
        case 'max':
          this.errorMessages.push(`${this.controlName} cannot exceed ${this.control.errors[errorKey].max}`);
          break;
        case 'min':
          this.errorMessages.push(`${this.controlName} cannot be less than ${this.control.errors[errorKey].min}`);
          break;
        case 'mask':
          this.errorMessages.push(`${this.controlName} must be in the form ${this.control.errors[errorKey].requiredMask}`);
          break;
        case 'invalidTokens':
          this.errorMessages.push(`Invalid Tokens: ${this.control.errors[errorKey].value.join(', ')}`);
          break;
        default:
          const value = this.control.errors[errorKey];
          if (typeof value === 'string') {
            this.errorMessages.push(value);
          } else {
            this.errorMessages.push(JSON.stringify(value));
          }
          break;
      }
    }
  }

  ngOnDestroy(): void {
    this.changeSubscription?.unsubscribe();
  }
}
