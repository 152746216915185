<div id="contacts">
    <div class="card mt-4">
        <div class="card-body contacts-table">
            <div class="card-actions pull-right">
                <div class="item" (click)="onTypeChange($event, 'hotleads')" [ngClass]="{'active': activeState === 'hotleads'}">
                    Hot Leads
                </div>
                <div class="item" (click)="onTypeChange($event, 'allcontacts')" [ngClass]="{'active': activeState === 'allcontacts'}">
                    All Contacts
                </div>
            </div>
            <div class="contacts-wrapper" *ngIf="activeState === 'hotleads'">
                <div class="hot-leads-table-head" [ngStyle]="{'grid-template-columns': tableColumnStyle}">
                    <span class="text-left"><strong>Full Name</strong></span>
                    <span><strong>Type</strong></span>
                    <span><strong>Stage</strong></span>
                    <span><strong>Status</strong></span>
                    <span><strong>Source</strong></span>
                    <span *ngIf="!isKnockUser"><strong>Last Activity Date</strong></span>
                    <span><strong>Lead Score</strong></span>
                </div>
                <div *ngFor="let contact of hotLeadData" class="single-contact" [ngStyle]="{'grid-template-columns': tableColumnStyle}" [ngClass]="{'pulse': contact.leadScore > 14}">
                    <span class="text-left" (click)="viewContact(contact._id, $event)">🔥 <strong class="hyperlink">{{contact.contactIdentifier}}</strong></span>
                    <span>{{contact.type | titlecase}}</span>
                    <span>
                        <span *ngIf="contact.stage === 'Pre Tour'"
                            [class]="'customer-badge status-tour'">{{contact.stage}}</span>
                        <span *ngIf="contact.stage === 'Pre Application'"
                            [class]="'customer-badge status-application'">{{contact.stage}}</span>
                    </span>
                    <span>{{contact.status | titlecase}}</span>
                    <span>{{contact.source}}</span>
                    <span *ngIf="!isKnockUser">{{contact.recentActivityDate | date:'short'}}</span>
                    <span>
                        <span class="badge lead-score">{{contact.leadScore}}</span>
                    </span>
                </div>
            </div>
            <div *ngIf="activeState === 'allcontacts'">
                <app-contacts-table
                    [user]="user"
                    [isPrimaryEnabled]="false"
                    [isSecondaryEnabled]="false"
                    [isTernaryEnabled]="false"
                    [linkToContactPage]="true"
                    (onSelectionChanged)="onContactSelectionChange($event)"
                    [allowRowSelect]="false"
                >
                </app-contacts-table>
            </div>
        </div>
    </div>
</div>

<!-- LEGACY CODE FOR CONTACT MANAGEMENT --- KEEP -->
<!-- Add Label Modal -->
<!-- <ng-template #addLabelModal let-addLabelModal>
    <div class="modal-header">
        <h5 class="modal-title">Add Contact Labels</h5>
        <div class="float-right">
            <button type="button" class="button button-white button-small" (click)="exitModal($event, addLabelModal)"><i class="far fa-times" aria-hidden="true"></i></button>
        </div>
    </div>
    <div class="schedule-text-modal modal-body">
        <p class="alert alert-danger">This label will be added to <strong>{{selectedRows.length}} contacts.</strong></p>
        <div class="row">
            <div class="col-md-12">
                <div class="input-group mb-3">
                    <p-chips [(ngModel)]="newLabelData" placeholder="Type New Label Here"></p-chips>
                </div>
            </div>
        </div>
        <button [disabled]="!newLabelData || !newLabelData.length || labelSaveInProgress" (click)="saveNewLabel($event, addLabelModal)" class="button button-dark">
            <span *ngIf="labelSaveInProgress" class="spinner-border spinner-border-sm mr-1"></span>
            Add Label
        </button>
    </div>
</ng-template> -->
<!-- Add Contacts Modal -->
<!-- <ng-template #addContactsModal let-addContactsModal>
    <div class="modal-header">
        <h5 class="modal-title">Add New Contacts</h5>
        <div class="float-right">
            <button type="button" class="button button-white button-small" (click)="exitModal($event, addContactsModal)"><i class="far fa-times" aria-hidden="true"></i></button>
        </div>
    </div>
    <div class="schedule-text-modal modal-body">
        <div *ngIf="activeStep === 1">
            <p class="step-instructions">Upload your spreadsheet to get started!</p>
            <br />
            <div>
                <label class="button button-white" for="data-upload"><i class="far fa-file-search"></i> Select file</label>
                <input type="file" class="bulk-upload-input" id="data-upload" value="" accept="text/csv" />
                <span *ngIf="fileUploaded"><i (click)="refresh()" class="far fa-trash-alt"></i> {{fileUploaded.name}}</span><button class="button button-dark pull-right" *ngIf="stepSuccess" (click)="nextStep($event)"> Continue</button>
            </div>
            <div>
                <a href="https://app.nurtureboss.io/api/docs/contacts-template" target="_blank">Download Template</a>
            </div>
            <div class="alert alert-danger" *ngIf="bulkUploadError">
                {{bulkUploadError}}
            </div>
            <button class="button button-dark pull-right" (click)="processUploadedFile($event)" [disabled]="!fileUploaded">Upload File</button>
        </div>
        <div *ngIf="activeStep === 2">
            <p class="step-instructions">Sample data. Does everything look like it's in the right spot?</p>
            <table>
                <thead>
                    <th>Full Name</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Labels</th>
                    <th>Phone Number</th>
                    <th>Email Address</th>
                    <th>Opt In</th>
                </thead>
                <tbody>
                    <tr>
                        <td>{{sampleData.firstName}} {{sampleData.lastName}}</td>
                        <td>{{sampleData.firstName}}</td>
                        <td>{{sampleData.lastName}}</td>
                        <td>{{sampleData.labels}}</td>
                        <td>{{sampleData.phoneNumber}}</td>
                        <td>{{sampleData.emailAddress}}</td>
                        <td>{{sampleData.optIn}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="alert alert-danger" *ngIf="contactUploadError">
                There was an error uploading some of your contacts.
            </div>
            <button class="button button-dark pull-right"  *ngIf="!contactUploadError" (click)="uploadContacts($event, addContactsModal)">Create Contacts</button>
            <button class="button button-white pull-right"  *ngIf="contactUploadError" (click)="exitModal($event, addContactsModal)">Okay</button>
        </div>
    </div>
</ng-template> -->
