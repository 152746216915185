import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { NurtureBossModalService } from '@app/_services/modal.service';
import * as confetti from 'canvas-confetti';

interface PrimaryButtonSuccessFunc {
  ();
}

export interface IPrimaryButtonOptions {
  label: string,
  action: PrimaryButtonSuccessFunc,
  disabled: boolean;
  showSpinner?: boolean;
};

@Component({
  selector: 'nb-modal',
  templateUrl: './nb-modal.component.html',
  styleUrls: ['./nb-modal.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class NurtureBossModalComponent implements OnInit {

  @Input('confetti') confetti = false;
  @Input('name') name;
  @Input('doOnDismiss') doOnDismiss;
  @Input('doOnClose') doOnClose;
  @Input('header') header;
  @Input('footer') footer;
  @Input('primaryButtonOptions') primaryButtonOptions: IPrimaryButtonOptions;
  @Input('additionalStyles') additionalStyles;
  
  @ViewChild('modalBody') modalBody: ElementRef;

  myConfetti: any;
  private element: any;

  constructor(
    private renderer2: Renderer2,
    private el: ElementRef,
    private nbModalService: NurtureBossModalService,
  ) {
    this.element = this.el.nativeElement;
  }

  ngOnInit(): void {
    if (!this.name) {
      return;
    }
    document.body.appendChild(this.element);
    this.element.addEventListener('click', el => {
      if (el.target.className === 'nb-modal') {
        this.dismiss();
      }
    });
    this.nbModalService.add(this);
  }

  triggerConfetti() {
    let count = 200;
    let defaults = {
      origin: { y: 0.7 }
    };
    const myCanvas = this.renderer2.createElement('canvas');
    this.renderer2.appendChild(this.modalBody.nativeElement, myCanvas);
    this.myConfetti = confetti.create(myCanvas, {
      resize: true,
      useWorker: true
    });
    
    const fire = (particleRatio, opts) => {
      this.myConfetti(Object.assign({}, defaults, opts, {
        particleCount: Math.floor(count * particleRatio)
      }));
    };

    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    fire(0.2, {
      spread: 60,
    });
    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }

  ngOnDestroy(): void {
    this.nbModalService.remove(this.name);
    this.element.remove();
  }

  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('nb-modal-open');
    if (this.confetti) {
      setTimeout(() => {
        this.triggerConfetti();
      }, 300);
    }

    this.modalBody.nativeElement.focus();
  }

  dismiss(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('nb-modal-open');
    if (this.doOnDismiss && typeof this.doOnDismiss === 'function') {
      this.doOnDismiss();
    }
  }

  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('nb-modal-open');
    if (this.doOnClose && typeof this.doOnClose === 'function') {
      this.doOnClose();
    }
  }

  onPrimaryButtonClick(e) {
    e.preventDefault();
    if (this.primaryButtonOptions) {
      if (this.primaryButtonOptions.disabled) {
        return;
      }
      this.primaryButtonOptions?.action()
    }
  }
}
