import {
  AfterViewInit,
  Component,
  ElementRef,
  OnChanges,
  OnInit
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  NgbModal,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import {
  PagesService,
  ToastService,
  TokenParserService,
  AuthenticationService,
  TemplatesService,
  LoaderService,
  TextMessagesService
} from '@app/_services';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { TemplateRef, ViewChild } from '@angular/core';
import {NgbDate, NgbCalendar, NgbDateParserFormatter, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
import { NurtureBossModalService } from '@app/_services/modal.service';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { APIHelperService } from '../_services/apiHelper.service';

let self;

@Component({
  templateUrl: 'pages.component.html',
  styleUrls: ['pages.component.less']
})
export class PagesComponent implements OnInit, AfterViewInit {
  textMessageContentSub: Subscription;

  @ViewChild('datepicker') datePicker: NgbInputDatepicker;
  @ViewChild('sentTextModal') private sentTextModal: TemplateRef<any>;
  @ViewChild('dt') table: Table;

  objectKeys = Object.keys;
  sendTextMessageForm: FormGroup;
  pages: Array < any > = [];
  modalOptions: NgbModalOptions;
  activeTextData: any = {
    data: {}
  };
  sendingText = false;
  userData: any;
  selectedPages: Array<string> = [];
  selectedColumns: any;
  bulkText = false;
  bulkTextSendPages: Array<any> = [];
  pagesRemoved = false;
  cols: Array<any>;
  errorMessage: any = null;
  totalRecords: any;
  debouncedSearch: any;
  pageNameSearchText = '';
  labelSearchText = '';
  pageTypeSearchText = '';
  activeQuery: any = {
    query: '',
    property: ''
  };
  pagination: any = {
    first: 1,
    last: 25
  };
  showAllPages = false;
  defaultMessageDict: any = {
    'Apartment Concession': 'Hey [clientFirstName]! [apartmentName] is offering [concession] on new move ins! Take a look at this deal we\'ve made just for you! [shortUrl]',
    'Guest Card': 'Hey [clientFirstName]! [apartmentName] has some new availability and rates! Take a look at this page we made just for you! [shortUrl]',
    'Apartment Tour': 'Hey [clientFirstName]! This is the team @ [apartmentName]. I wanted to follow up on our tour, I\'ve prepared this page just for you! [shortUrl]',
    'Move In Checklist': 'Hey [clientFirstName]! Congratulations on your new apartment at [apartmentName]! Click this link for the move in checklist I created for you! [shortUrl]',
    'Apartment Renewal': 'Hi [clientFirstName]! As a reminder, your lease at [apartmentName] is set to expire soon. Click here for a renewal offer created just for you! [shortUrl]',
    'Apartment Maintenance Followup': 'Hey [clientFirstName]! I wanted to follow up on your recent maintenance request, please share your experience and any feedback! [shortUrl]',
    'Apartment Event Reminder': 'Hey [clientFirstName]! [apartmentName] is hosting [eventName] on [eventDate] and you\'re invited! Click here for more details! [shortUrl]',
    'The Royce Rent Reminder': 'Hey [clientFirstName]! This is a reminder that rent is due on the 1st, click here to look at our Flex options! [shortUrl]',
    'Rent Reminder': 'Hey [clientFirstName]! This is a friendly rent reminder, click here to see more details! [shortUrl]',
    'Revere Rent Reminder': 'Hey [clientFirstName]! This is a friendly rent reminder, click here to see more details! [shortUrl]',
    'Apartment Appointment Reminder': 'Hey [clientFirstName]! This is a friendly reminder of your upcoming appointment on [appointmentDate] at [appointmentTime]! Click here for more details [shortUrl]',
    'Master Nurture Template': 'Hey [clientFirstName]!, This is the team @ [apartmentName], take a look at this page I made just for you! [shortUrl]',
    'Digital Business Card': 'Hi [clientFirstName]! The team @ [apartmentName] here, it was great connecting today, you can find all my contact info here: [shortUrl]',
    'Real Estate General Communication': 'Hi [clientFirstName]! The team @ [apartmentName] here, it was great connecting today, you can find all my contact info here: [shortUrl]',
    'Apartment Interest List': 'Hi [clientFirstName]! [apartmentName] would like to thank you for joining our VIP waiting list! Click here to learn more [shortUrl]',
    'Apartment General Template': 'Hi [clientFirstName]! Hello from [apartmentName]! Take a look at this page we made just for you! [shortUrl]',
    'Apartment Covid Screening': 'Hi [clientFirstName]! Please take a look at our COVID screening checklist before your appointment at [apartmentName]: [shortUrl]',
    'Apartment Accepting Applications': 'Hi [clientFirstName]!  [apartmentName] is excited to announce that we are now accepting applications! Click here for more info [shortUrl]',
    'Apartment Schedule A Tour': 'Hi [clientFirstName]! [apartmentName] is excited to invite you to tour your new home! Click here to learn more: [shortUrl]',
    'MST Schedule A Tour': 'Hi [clientFirstName]! [apartmentName] is excited to invite you to tour your new home! Click here to learn more: [shortUrl]',
    'Admin Apartmentalize': 'Hi [clientFirstName]! The team @ [apartmentName] from Nurture Boss here! It was greating meeting you today! Click here to see this page I made just for you! [shortUrl]',
  };
  modalActionTitle: string;
  fromCreateState: any;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  sendDate: any;
  sendTime: any;
  schedules: any;
  activeSchedules: number;
  scheduledPages: number;
  schedSaveInProgress = false;
  scheduleSaved = false;
  selectedTokens: string[];
  textMessageMaxLength = 290;

  messagePreview: () => string = () => {
    return '' || 'Add a message!';
  }

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private pagesService: PagesService,
    private toastService: ToastService,
    private tokenService: TokenParserService,
    private authService: AuthenticationService,
    private templatesService: TemplatesService,
    private loaderService: LoaderService,
    private activatedRoute: ActivatedRoute,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private textMessageService: TextMessagesService,
    private nbModalService: NurtureBossModalService,
    public apiHelper: APIHelperService,
  ) {
    this.modalOptions = {
      windowClass: 'custom-modal-styles-new text-modal'
    };
    this.userData = this.authService.currentUserValue;
    this.debouncedSearch = _.debounce(this.searchFunc, 300);
    this.fromDate = this.calendar.getPrev(calendar.getToday(), 'd', 30);
    this.toDate = this.calendar.getToday();
    self = this;
  }

  searchFunc(query, property) {
    this.activeQuery = {
      query,
      property,
    };
    this.showAllPages = true;
    this.loadData(query, property);
  }

  loadData(query?, property?, load?) {
    this.loaderService.triggerLoader();
    this.pagesService.getAll(0, 25, query, property, this.showAllPages).subscribe(pages => {
      this.pages = pages.result.data;
      this.totalRecords = pages.result.totalPages;
      this.setTableHeading();
      this.loaderService.stopLoader();
    }, (error) => {
      this.loaderService.stopLoader();
      this.toastService.showError('There was an error loading your pages');
    });
  }

  ngOnInit() {
    this.loadData(null, null, true);
    this.pagesService.retrieveSchedules().subscribe((data) => {
      this.schedules = data.result;
      this.activeSchedules = 0;
      this.scheduledPages = 0;
      for (let i = 0; i < this.schedules.length; i++) {
        if (!this.schedules[i].acknowledged) {
          this.activeSchedules++;
          this.scheduledPages = this.scheduledPages + this.schedules[i].numberOfPages;
        }
      }
    }, (_err) => {
      // no-op
    });
  }

  ngAfterViewInit() {
    // Capture the page type we are creating!
    this.activatedRoute.queryParams.subscribe(params => {
      // tslint:disable-next-line:no-string-literal
      if (params['fromCreate'] === 'true' && window.history.state.pagesCreated) {
        this.sendNewText(this.sentTextModal, window.history.state.pagesCreated);
      }
    });
  }

  paginate(event) {
    this.loaderService.triggerLoader();
    this.pagination.first = event.first;
    this.pagination.last = event.first + event.rows;
    this.pagesService.getAll(event.first, event.rows, this.activeQuery.query, this.activeQuery.property, this.showAllPages)
      .subscribe(pages => {
        this.pages = pages.result.data;
        this.totalRecords = pages.result.totalPages;
        this.setTableHeading();
        this.loaderService.stopLoader();
    }, (error) => {
      this.loaderService.stopLoader();
      this.toastService.showError('There was an error loading your pages');
    });
  }

  setTableHeading() {
    this.pagination.first = this.table._first;
    this.pagination.last = this.table._rows;

    if (this.pagination.first === 0) {
      this.pagination.first = 1;
    }
  }

  sendNewText(textContent, page) {
    // Create copy as to not affect original.
    const pageCopy = JSON.parse(JSON.stringify(page));
    this.errorMessage = null;
    this.pagesRemoved = false;

    if (pageCopy.length) {
      const pages = pageCopy;

      // We are bulk sending texts.
      this.bulkText = true;
      this.bulkTextSendPages = [];

      // First weed out pages that do not have a clientPhoneNumber
      for (let i = 0; i < pages.length; i++) {

        // Add sanitized number to array.
        if (pages[i].data.clientPhoneNumber && pages[i].data.clientPhoneNumber.toString().length > 0) {
          pages[i].data.clientPhoneNumber = pages[i].data.clientPhoneNumber.toString().replace(/\D/g, '');
          this.bulkTextSendPages.push(pages[i]);
        } else {
          this.pagesRemoved = true;
        }
      }

      // Now get all unique tokens.
      for (let i = 0; i < this.bulkTextSendPages.length; i++) {
        this.bulkTextSendPages[i].data.shortUrl = this.bulkTextSendPages[i].shortUrl;
        for (const prop in this.bulkTextSendPages[i].data) {
          if (!this.activeTextData.data[prop]) {
            this.activeTextData.data[prop] = this.bulkTextSendPages[i].data[prop];
          }
        }
      }

      // Remove properties we do not want to include.
      delete this.activeTextData.data.bannerImage;
      delete this.activeTextData.data.centerImage;
      delete this.activeTextData.data.centerLogo;
      delete this.activeTextData.data.leftLogo;
      delete this.activeTextData.data.onlineApplicationUrl;
      delete this.activeTextData.data.onlineApplicationLinkText;
      delete this.activeTextData.data.footerContent;
      delete this.activeTextData.data.type;
      delete this.activeTextData.data.created;
      delete this.activeTextData.data.updated;
      delete this.activeTextData.data.__v;
      delete this.activeTextData.data._id;
      delete this.activeTextData.data.ownerId;
      delete this.activeTextData.data.templateName;
      delete this.activeTextData.data.pageName;
      delete this.activeTextData.data.label;
      delete this.activeTextData.data.yardiGuestCardId;
      delete this.activeTextData.data.realPageGuestCardId;
      delete this.activeTextData.data.knockProspectId;
      delete this.activeTextData.data.entrataGuestCardId;
      delete this.activeTextData.data.entrataApplicationId;
      delete this.activeTextData.data.entrataApplicantId;
      delete this.activeTextData.data.resmanPersonId;
      delete this.activeTextData.data.yardiTenantId;
      delete this.activeTextData.data.realPageTenantId;
      delete this.activeTextData.data.entrataTenantId;
      delete this.activeTextData.data.resmanLeaseId;
      delete this.activeTextData.data.mainText;
      delete this.activeTextData.data.headerText;
      delete this.activeTextData.data.lat;
      delete this.activeTextData.data.long;
      delete this.activeTextData.data.clientPhoneNumber; // not available for bulk

      this.sendTextMessageForm = this.formBuilder.group({
        textMessageContent: ['', Validators.required]
      });

      // Set default message depending on what pages are selected.
      let defaultMessage;
      let sameType = true;
      for (let i = 0; i < pages.length; i++) {
        if (pages[0].data.type !== pages[i].data.type) {
          sameType = false;
          break;
        }
      }
      if (sameType) {
        // Load default message for this type
        defaultMessage = this.defaultMessageDict[pages[0].data.type];
      } else {

        // Generic default message.
        defaultMessage = 'Hello [clientFirstName]! [apartmentName] has some new availability and rates! Take a look at this page we made just for you! [shortUrl]';
      }
      this.sendTextMessageForm.controls.textMessageContent.setValue(defaultMessage);

      this.selectedTokens = this.activeTextData.data;

      this.messagePreview = function() {
        return this.tokenService.replaceTokens(this.sendTextMessageForm.controls.textMessageContent.value, this.selectedTokens) || 'Add a message!';
      };

      this.modalService.open(textContent, this.modalOptions).result.then((result) => {
        this.activeTextData = {
          data: {}
        };
      }, () => {
        this.activeTextData = {
          data: {}
        };
      });
    } else {

      this.bulkText = false;
      this.bulkTextSendPages.length = 0;

      // Set default message depending on what page is selected.
      const defaultMessage = this.defaultMessageDict[pageCopy.data.type];

      // Remove properties we do not want to include.
      delete pageCopy.data.bannerImage;
      delete pageCopy.data.centerImage;
      delete pageCopy.data.centerLogo;
      delete pageCopy.data.leftLogo;
      delete pageCopy.data.onlineApplicationUrl;
      delete pageCopy.data.onlineApplicationLinkText;
      delete pageCopy.data.footerContent;
      delete pageCopy.data.type;
      delete pageCopy.data.created;
      delete pageCopy.data.updated;
      delete pageCopy.data.__v;
      delete pageCopy.data._id;
      delete pageCopy.data.ownerId;
      delete pageCopy.data.templateName;
      delete pageCopy.data.pageName;
      delete pageCopy.data.label;
      delete pageCopy.data.yardiGuestCardId;
      delete pageCopy.data.realPageGuestCardId;
      delete pageCopy.data.knockProspectId;
      delete pageCopy.data.entrataGuestCardId;
      delete pageCopy.data.entrataApplicationId;
      delete pageCopy.data.entrataApplicantId;
      delete pageCopy.data.resmanPersonId;
      delete pageCopy.data.yardiTenantId;
      delete pageCopy.data.realPageTenantId;
      delete pageCopy.data.entrataTenantId;
      delete pageCopy.data.resmanLeaseId;
      delete pageCopy.data.mainText;
      delete pageCopy.data.headerText;
      delete pageCopy.data.lat;
      delete pageCopy.data.long;

      // Add shortUrl as token.
      pageCopy.data.shortUrl = page.shortUrl;
      if (pageCopy.data.clientPhoneNumber) {
        pageCopy.data.clientPhoneNumber = page.data.clientPhoneNumber.toString().replace(/\D/g, '');
      }
      this.selectedTokens = pageCopy.data;

      this.activeTextData = page;
      this.sendTextMessageForm = this.formBuilder.group({
        recipientPhoneNumber: [page.data.clientPhoneNumber || '', Validators.required],
        textMessageContent: ['', Validators.required]
      });
      this.sendTextMessageForm.controls.textMessageContent.setValue(defaultMessage);
      this.messagePreview = function() {
        return this.tokenService.replaceTokens(this.sendTextMessageForm.controls.textMessageContent.value, this.selectedTokens) || 'Add a message!';
      };
      this.modalService.open(textContent, this.modalOptions).result.then((result) => {
        this.activeTextData = {
          data: {}
        };
      }, () => {
        this.activeTextData = {
          data: {}
        };
      });
    }
  }

  deliverText(modal, bulk) {
    if (this.sendTextMessageForm.invalid) {
      return;
    } else {
      this.errorMessage = null;
      this.sendingText = true;
      let postBody;
      if (bulk) {
        const arrayOfPageIds = this.bulkTextSendPages.map((obj) => {
          return obj._id;
        });
        postBody = {
          message: this.sendTextMessageForm.controls.textMessageContent.value,
          pageIds: arrayOfPageIds
        };
        this.pagesService.sendBulkText(postBody).subscribe((data) => {
          this.sendingText = false;
          this.toastService.show('Text message sent.', {
            classname: 'bg-success text-light',
            delay: 5000
          });
          modal.close();
          this.openModal('send-text-success');
        }, (err) => {
          this.sendingText = false;
          this.toastService.showError(err.cause);
        });
      } else {
        postBody = {
          to: this.sendTextMessageForm.controls.recipientPhoneNumber.value,
          message: this.sendTextMessageForm.controls.textMessageContent.value,
          pageId: this.activeTextData._id
        };
        this.textMessageService.sendText(postBody).subscribe(() => {
          this.sendingText = false;
          this.toastService.show('Text message sent.', {
            classname: 'bg-success text-light',
            delay: 5000
          });
          modal.close();
          this.openModal('send-text-success');
        }, (err) => {
          this.sendingText = false;
          this.toastService.show(err.cause || 'There was an error sending your text message', {
            classname: 'bg-danger text-light',
            delay: 5000
          });
          this.errorMessage = err.cause;
        });
      }
    }
  }

  openModal(name: string) {
    this.nbModalService.open(name);
  }

  closeModal(name: string) {
    this.nbModalService.close(name);
    this.scheduleSaved = false;
    // Sometimes this doesn't exist, so don't force unsub
    this.textMessageContentSub?.unsubscribe();
  }

  getButtonsDisabledState(): boolean {
    if (this.sendingText) { return true; }

    const previewText: string = this.tokenService
      .replaceTokens(this.sendTextMessageForm.controls.textMessageContent.value, this.selectedTokens);

    if (previewText.length >= this.textMessageMaxLength) { return true; }

    return false;
  }

  openScheduleModal(e, modal, textModal) {
    e.preventDefault();
    this.modalService.open(modal, {
      windowClass: 'custom-modal-styles-new schedule-text-modal'
    }).result.then(() => {
      textModal.close();
      this.sendDate = null;
      this.sendTime = null;
    }, () => {
      this.sendDate = null;
      this.sendTime = null;
    });
  }

  exitModal(e, modal) {
    e.preventDefault();
    modal.dismiss();
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isToday(date: NgbDate) {
    return date.equals(this.toDate);
  }

  saveSchedule(modal) {
    let postBody: any;
    this.schedSaveInProgress = true;
    if (this.bulkText) {
      const arrayOfPageIds = this.bulkTextSendPages.map((obj) => {
        return obj._id;
      });
      postBody = {
        message: this.sendTextMessageForm.controls.textMessageContent.value,
        pageIds: arrayOfPageIds
      };
    } else {
      postBody = {
        message: this.sendTextMessageForm.controls.textMessageContent.value,
        pageIds: [this.activeTextData._id]
      };
    }
    const dateString = `${this.sendDate.month}/${this.sendDate.day}/${this.sendDate.year} ${this.sendTime.hour}:${this.sendTime.minute}`;
    this.textMessageService.scheduleTextSend({
      dateTime: new Date(dateString),
      data: postBody
    }).subscribe((data) => {
      this.schedSaveInProgress = false;
      this.scheduleSaved = true;
      modal.close();
      this.openModal('send-text-success');
    }, (err) => {
      this.schedSaveInProgress = false;
      this.toastService.showError('There was an error creating your schedules');
    });
  }

  deletePage(page, e) {
    e.preventDefault();
    this.loaderService.triggerLoader();

    // Check that page has no texts
    if (page.textCount === 0) {
      this.pagesService.deletePage(page._id).subscribe(() => {

        // Deleted page
        this.loaderService.stopLoader();
        this.loadData();
      }, (error) => {
        this.loaderService.stopLoader();
        this.toastService.showError('There was an error deleting your page');
      });
    }
  }

  bulkDeletePages(pages, e) {
    self = this;
    e.preventDefault();
    self.loaderService.triggerLoader();
    const errorArr = [];

    // Create promis array of deletion calls and verify no texts sent.
    const promiseArray: Array<any> = [];
    for (let i = 0; i < pages.length; i++) {

      if (pages[i].textCount === 0) {
        self.selectedPages = self.selectedPages.filter(page => page._id !== pages[i]._id);

        promiseArray.push(new Promise((resolve, reject) => {
          self.pagesService.deletePage(pages[i]._id).subscribe(() => {
            resolve('');
          }, () => {
            reject();
          });
        }));
      } else {
        errorArr.push(pages[i]);
      }
    }

    if (errorArr.length > 0) {
        self.toastService.show(`${errorArr.length} page(s) have associated messages, cannot delete.`, {
          classname: 'bg-danger text-light',
          delay: 5000
        });
    }

    Promise.all(promiseArray).
      then(() => {
        self.loaderService.stopLoader();
        self.loadData();
      }).
      catch(() => {
        self.loaderService.stopLoader();
        self.toastService.show('Some of your pages couldn\'t be deleted', {
          classname: 'bg-danger text-light',
          delay: 5000
        });
        setTimeout(() => {
          self.loadData();
        }, 2000);
      });
  }
}
