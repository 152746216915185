<div id="alert-page">
  <form [formGroup]="alertForm" class="form margin-bottom-48">
    <div class="header">
      <div class="alert-name">
        <input
          autofocus
          class="form-control"
          type="text"
          value="{{alert?.name || ''}}"
          id="{{alert?.name || ''}}"
          [attr.disabled]="'disabled'"
        />
        <i placement="right" ngbTooltip="Alert Name" class="far fa-info-circle"></i>
      </div>
      <div class="actions-wrapper">
        <h6>Off/On</h6>
        <label class="switch mr-2">
          <input type="checkbox" formControlName="active" id="active" (change)="onToggleChanged()">
          <span class="slider round"></span>
        </label>
        <button class="button button-dark pull-right" type="submit" [disabled]="alertForm.invalid" (click)="updateAlert()">Save Alert</button>
      </div>
    </div>
    <div class="alert-wrapper" *ngIf="saveErrorMessages.length > 0">
      <div class="save-errors alert alert-danger">
        <ul class="error-list">
          <li *ngFor="let error of saveErrorMessages">{{error}}</li>
        </ul>
      </div>
    </div>
      <div class="row alert-header" >
        <div class="col-4"><strong>{{alert?.name || ''}}</strong></div>
        <div class="col-4">
          <strong>Template:&nbsp;{{selectedTemplate?.templateName || 'Not Selected'}}</strong>
        </div>
        <div class="col-2">
          <strong>Messages Sent:&nbsp;{{sentAlertsCount || 0}}</strong>
        </div>
      </div>
    <div id="steps-wrapper">
      <div class="row steps-container" [formGroup]="alertForm">
        <div class="col-8">
          <div class="scheduling-mode">
            <label class="block">Scheduling Mode 
              <i 
                placement="bottom" 
                ngbTooltip="Sending mode can be 'Immediate' or 'Optimized'. When Immediate is chosen the alert will be sent as soon as it is triggered. When Optimized is chosen, the alert will be sent at the optimal time after it has been triggered. This can be up to 24 hours after triggering the alert."
                class="far fa-info-circle"
              ></i>
            </label>
            <label class="margin-right-12">Immediate</label>
            <label class="switch" for="schedulingMode">
              <input type="checkbox" formControlName="schedulingMode" id="schedulingMode" (change)="onToggleChanged()">
              <span class="slider round"></span>
            </label>
            <label class="margin-left-12">Optimized</label>
          </div>
          <div class="form-group">
            <label for="templateId">Template <i placement="right" ngbTooltip="The template to use for this alert" class="far fa-info-circle"></i></label>
            <select
              class="form-control"
              id="templateId"
              formControlName="templateId"
            >
              <option *ngFor="let template of templates" [value]="template._id">{{template.templateName}}</option>
            </select>
            <app-print-errors controlName="Template" [control]="alertForm.controls.templateId"></app-print-errors>
          </div>
          <div *ngIf="templateSupportsEmail" class="form-group">
            <label for="emailSubjectLine">Email Subject Line <i placement="right" ngbTooltip="The email subject line sent with emails" class="far fa-info-circle"></i></label>
            <input
              class="form-control"
              id="emailSubjectLine"
              formControlName="emailSubjectLine"
              type="text"
            />
            <app-character-count [control]="alertForm.controls.emailSubjectLine"></app-character-count>
            <app-print-errors controlName="Email Subject Line" [control]="alertForm.controls.emailSubjectLine"></app-print-errors>
            <app-token-container
              *ngIf="alertForm.controls.templateId.value"
              title="Use tokens to customize your message"
              [formGroup]="alertForm"
              controlName="emailSubjectLine"
              textSourceElementId="emailSubjectLine"
              [tokensToHide]="getTokensToHide()"
              [validatorsList]="[{name: 'required', value: true}]"
              [tokensArray]="getTokensArray(alertForm.controls.templateId.value, ['apartmentName'])"
            >
            </app-token-container>
          </div>
          <div *ngIf="templateSupportsText" class="form-group">
            <label for="textMessage">Text Message Content <i placement="right" ngbTooltip="The text message content for text messages" class="far fa-info-circle"></i></label>
            <textarea
              class="form-control"
              id="textMessage"
              formControlName="textMessage"
            ></textarea>
            <app-character-count [control]="alertForm.controls.textMessage"></app-character-count>
            <app-print-errors controlName="Text Message Content" [control]="alertForm.controls.textMessage"></app-print-errors>
            <app-token-container
              *ngIf="alertForm.controls.templateId.value"
              title="Use tokens to customize your message"
              [formGroup]="alertForm"
              controlName="textMessage"
              textSourceElementId="textMessage"
              [tokensToHide]="getTokensToHide()"
              [validatorsList]="[{name: 'required', value: true}]"
              [tokensArray]="getTokensArray(alertForm.controls.templateId.value, ['apartmentName', 'shortUrl'])"
            >
            </app-token-container>
          </div>
          <div *ngIf="!alertForm.controls.templateId.value" class="required-template-defaults">
            <div class="alert alert-danger">
              Select a template to set defaults.
            </div>
          </div>
          <div *ngIf="alertForm.controls.templateId.value" class="required-template-defaults">
            <div *ngIf="templateDefaultIsValid">
              <button (click)="openDefaults(defaults)" class="button button-light button-small margin-top-12">
                <span><i class="far fa-save"></i></span>
                Update Follow Up Defaults
              </button>
              <div class="alert alert-success">
                Template defaults set.
              </div>
            </div>
            <div *ngIf="!templateDefaultIsValid">
              <button (click)="openDefaults(defaults)" class="button button-light button-small margin-top-12">
                <span><i class="far fa-save"></i></span>
                Set Defaults
              </button>
              <div class="alert alert-danger">
                Template defaults not set.
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 iphone-preview">
          <app-iphone-preview
            [emailUrl]="previewUrl"
            [nurturePageUrl]="pagePreviewUrl"
            [forceDisplayType]="forcedTemplateDisplay"
            helperText="Please select a template and set defaults"
            errorText="Something went wrong when generating your preview!"
          ></app-iphone-preview>
        </div>
      </div>
    
      <ng-template #defaults let-modal>
        <div class="modal-header">
          <h5 class="modal-title" id="template-defaults-picker">Automation Template Defaults</h5>
          <div class="float-right">
              <button type="button" class="button button-white button-small" (click)="exitModal($event, modal)"><i class="far fa-times" aria-hidden="true"></i></button>
          </div>
        </div>
        <div class="modal-body">
          <app-template-form-component
            submitButtonLabel="Set Template Defaults"
            [template]="selectedTemplate"
            [templateDefaults]="selectedTemplateDefault"
            (formSubmitEvent)="handleFormSubmitEvent($event)"
          ></app-template-form-component>
        </div>
      </ng-template>
    </div>
  </form>
</div>