// Angular:
import { Component, Input, OnChanges, OnDestroy, OnInit, SecurityContext, SimpleChanges, Injector } from '@angular/core';

@Component({
  selector: 'app-spam-warnings',
  styleUrls: ['./spam-warnings.component.less'],
  templateUrl: './spam-warnings.component.html',
})
export class SpamWarningsComponent {

  @Input() warningText: string = '';
  @Input() spamWarnings = [];
  @Input() emailSubjectLineSpamWarnings = [];
  @Input() shouldDisplay = true;

  constructor() {
    // no-op
  }
}
