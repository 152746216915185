import { Injectable } from '@angular/core';

let self;

@Injectable({ providedIn: 'root' })
export class TokenParserService {

  constructor() {
    self = this;
  }

  replaceTokens(Str, Context) {
    return self.parseTokens(self.extractTokens(Str, /\[(.+?)\]/gi), Context, Str);
  }
  /**
      Will match all tokens "{{ some_token }}, {{some_token}},
      {{ Some_ToKen   }}" within a body of text, extracting all
      matches ( Tokens ), while pruning each match, removing
      the opening and closing curly brakets, as well as strip out any
      whitespace, so we have text that can be used to lookup props
      on an object.

      Will return an empty array, if no tokens are found.
  */
  extractTokens(str, pattern) {
    pattern = pattern ? pattern : /\{([^}]+)\}/ig;
    const matches = str.match(pattern);
    if (!matches) {
      return [];
    }
    return self.pruneTokens(matches);
  }

  /**
      Returns the count of Tokens that exist within a
      string body.
  */
  tokenCount(str, pattern) {
    pattern = pattern ? pattern : /\{([^}]+)\}/ig;
    return str.match(pattern).length;
  }

  /**
      Removes the leading and trailing wrapping-chars from
      a token match, as well as strip out all whitespace.
  */
  pruneTokens(Tokens) {
    Tokens.forEach((token, idx, tokens) => {
      tokens[idx] = token.slice(1, -1).replace(/\s+/g, '');
    });
    return Tokens;
  }

  /**
      Checks to see if some reasonable version of a token exists,
      within our context and returns the actual match. Otherwise returns
      null.
  */
  recognizedIn(token, context) {
    if (context[token]) { return token; }
    if (context[token.toLowerCase()]) { return token.toLowerCase(); }
    if (context[token.toUpperCase()]) { return token.toUpperCase(); }
    // Last ditch effort to find matches
    for (const prop in context) { if (token.toLowerCase() === prop.toLowerCase()) { return prop; } }
    return null;
  }

  /**
      Will loop through a set of tokens, replacing all matches within a
      string body, with the values supplied via a context.
  */
  parseTokens(Tokens, Context, Str) {
    Tokens.forEach((token, idx) => {
      const TOKEN = self.recognizedIn(token, Context);
      if (TOKEN !== null) {
        Str = self.parseToken(TOKEN, Context[TOKEN], Str);
      }
    });

    return Str;
  }

  /**
      Will automaticly escape Character Classes, for use by the RegExp
      Contructor. For when composing RegExps dynamicly the symantics
      of (\s) and other character classes can become very messy.

      "/\s/"+some_var+"/\s/" must be written as "/\\s/"+some_var+"/\\s/"

      You end up having to perform extra escaping, not for your pattern but
      for the RegExp constructor, this becomes very messy, is
      easy to forget and a little tricky to debug.

  */
  escapeCharacterClasses(value: string) {
    return value.replace(new RegExp(/[\\s|\\S|\\w|\\W|\\d|\\D]/, 'g'), '\\s');
  }

  /**
      Wraps the value of a variable into a Regex that selects the whole
      token, including the curly brackets.
  */
  makeTokenPattern(variable, left?, right?, flags?) {

    left = left ? left : '\\[(';
    right = right ? right : ')\\]';
    flags = flags ? flags : 'ig';

    return new RegExp(left + variable + right, flags);
  }

  /**
      Within a string body, does the actual replacement of all instances of a token, with a
      supplied value.
  */
  parseToken(Token, Value, Str) {
    return Str.replace(self.makeTokenPattern(Token), Value.replace(new RegExp('\\$', 'g'), '$$$'));
  }
}
