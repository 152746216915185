<form [formGroup]="form" (onSubmit)="onSubmit()">
  <div *ngFor="let data of sortedForm; index as index">
    <ngb-accordion [closeOthers]="true" [activeIds]="[activePanelId]" (panelChange)="handlePanelChange($event)">
      <ngb-panel *ngIf="checkSectionValidity(data.name, '#ngb-panel-'+index+'-header')" id="ngb-panel-{{index}}">
        <ng-template ngbPanelTitle>
          <span>{{data.display}}</span>
          <i class="fa fa-chevron-down pull-right"></i>
          <div *ngIf="templateFormService.getFormGroupByKey(data.name).valid" class="pull-right valid-icon mr-2">
            <i class="far fa-check"></i>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <app-template-form-group
            [useSpamLanguageService]="useSpamLanguageService" 
            [activeControl]="data.name" 
            [template]="template" 
            [viewData]="data" 
            [enableDefaults]="enableDefaults" 
            [userDefaults]="userDefaults">
          </app-template-form-group>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
  <div class="form-group">
    <button *ngIf="showSubmitButton" [disabled]="!form.valid" class="button button-dark mt-4" type="button" (click)="onSubmit()">{{submitButtonLabel}}</button>
    <button *ngIf="showCancelButton" type="button" class="button button-white ml-2" (click)="modalService.dismissAll()">
      Cancel
    </button>
  </div>
</form>
