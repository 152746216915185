import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { APIHelperService } from './apiHelper.service';
import { UserAuditWithType } from '@app/_types/userAudits.types';

@Injectable({ providedIn: 'root' })
export class UserAuditsService {

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService
  ) {
    // no-op
  }

  public userAudits: BehaviorSubject<UserAuditWithType[]> = new BehaviorSubject<UserAuditWithType[]>([]);

  refreshTriggeredUserAudits() {
    this.getTriggeredUserAudits().subscribe(res => {
      this.userAudits.next(res.result.sort((a, b) => a.auditType.priority - b.auditType.priority));
    });
  }

  getUserAudits() {
    return this.http.get<any>(this.apiHelper.fillUrl('userAudits', {}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getTriggeredUserAudits() {
    return this.http.get<any>(this.apiHelper.fillUrl('triggeredUserAudits', {}, {}))
      .pipe(map(data => {
        return data;
      }))
  }

  createUserAudit(payload) {
    return this.http.post<any>(this.apiHelper.fillUrl('userAudits', {}, {}), payload)
      .pipe(map(data => {
        return data;
      }));
  }

}
