/**
 * Get an string with respective os.
 *
 * @returns - String with current user OS by default return Unknown.
 */

enum OSOptions {
  MAC_OS = 'MacOS',
  IOS = 'IOS',
  WINDOWS = 'Windows',
  ANDROID = 'Android',
  LINUX = 'Linux',
  UNKNOWN = 'Unknown'
}

// Note: a better implementation would be window.navigator.userAgentData but it is not yet compatible with many browsers and ts
const getOS = () => {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

  if (macosPlatforms.indexOf(platform) !== -1) {
    return OSOptions.MAC_OS;
  }
  if (iosPlatforms.indexOf(platform) !== -1) {
    return OSOptions.IOS;
  }
  if (windowsPlatforms.indexOf(platform) !== -1) {
    return OSOptions.WINDOWS;
  }
  if (/Linux/.test(platform)) {
    return OSOptions.LINUX;
  }
  if (/Android/.test(userAgent)) {
    return OSOptions.ANDROID;
  }
  return OSOptions.UNKNOWN;
};

export default getOS;

export {
  OSOptions
};
