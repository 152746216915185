// Angular:
import { Component, Input, Inject, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.less']
})
export class QuestionComponent implements OnInit {
  @Input() confirmDeleteQuestion: () => void;
  @Input() duplicateQuestion: () => void;
  @Input() isDisabled: boolean;
  @Input() question: FormGroup;

  hasDuplicateOptions = false;
  options = [];
  id = uuid(); // not question id, only component identifier for DOM manipulation

  constructor(@Inject(DOCUMENT) private document: Document) {
    // no-op
  }

  getQuestionTypeDisplay(questionType) {
    switch(questionType) {
      case 'shortanswer':
        return 'Short Answer';
      case 'longanswer':
        return 'Long Answer';
      case 'singlechoice':
        return 'Single Choice';
      case 'checkbox':
        return 'Checkbox';
      case 'dropdown':
        return 'Dropdown';
      case 'linear':
        return 'Linear';
    }
  }

  ngOnInit() {
    if (this.isDisabled) {
      this.question.disable();
    }
  }

  getOptionControls() {
    return (this.question.get('options') as FormArray).controls;
  }

  getControlValue(controlName) {
    return this.question.get(controlName).value;
  }

  addOption(i) {
    const options = this.question.get('options') as FormArray;
    const newOption = new FormControl('', [Validators.required,Validators.minLength(1)]);

    options.insert(i || options.length, newOption);
  }

  removeOption(index) {
    (this.question.get('options') as FormArray).removeAt(index);
    const options = this.question.getRawValue().options;
    // Set cannot include duplicate values, if length/size is different
    // it means there is a duplicate option
    if ((new Set(options)).size === options.length) {
      this.hasDuplicateOptions = false;
    }
  }

  onInput(_event, optionControl) {
    const options = this.question.getRawValue().options;
    let errors = optionControl.errors;

    // Remove value from options to avoid matching self
    options.splice(options.indexOf(optionControl.value), 1);

    // Check for duplicate option
    if (options.includes(optionControl.value)) {
      // Set field to show warning
      this.hasDuplicateOptions = true;

      // Set formControl error
      if (!errors) {
        errors = {
          duplicateOption: true,
        }
      } else {
        errors.duplicateOption = true;
      }
    } else {
      // If no duplicate is found delete possible formControl error
      if (errors?.duplicateOption) {
        delete errors.duplicateOption;
      }
      // Set cannot include duplicate values, if length/size is different
      // it means there is a duplicate option
      if ((new Set(options)).size === options.length) {
        // Remove warning if there are no other duplicate
        this.hasDuplicateOptions = false;
      }
    }
    optionControl.setErrors(errors);
  }

  addOptionOnPressEnter(e, i) {
    if (e.key === 'Enter' && this.getOptionControls().length < 20) {
      this.addOption(i + 1);
      setTimeout(() => {
        this.document.getElementById(`${this.id + (i + 1)}`).focus();
      }, 0);
    }
  }
}
