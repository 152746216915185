/*
   *** See SpamLanguageGroupService for info about this service ***
*/
// Libs
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { checkTextForSpamLanguage } from '@nurtureboss/common/dist/utils/spamLanguage';

// Services
import { TokenParserService } from './tokenParser.service';

// Used so that token parser ignores these value
const ignoreTokens = {
  clientFirstName: '',
}

interface SpamLanguageWarning {
  field: string,
  violations: string[],
}

@Injectable({ providedIn: 'any' })
export class SpamLanguageService {
  constructor() {
    // no-op
  }

  private _context: { [key: string]: string; } = { ...ignoreTokens };
  private _shouldDisplay: boolean = false;
  private _tokenParser = new TokenParserService();
  private _warningsMap: { [key: string]: string[]; } = {};
  public detectedSpamLanguage = new BehaviorSubject<string[]>([]);
  public warningText = '';
  public warnings = new BehaviorSubject<SpamLanguageWarning[]>([]);

  private setWarning(key, value): void {
    this._warningsMap[key] = value;
    this.emitWarnings();
  };

  private removeWarning(key): void {
    delete this._warningsMap[key];
    this.emitWarnings();
  };

  emitWarnings(): void {
    const { warningsArray, spamLanguage } = this.getWarnings();

    this.warnings.next(warningsArray);
    this.detectedSpamLanguage.next(spamLanguage);
  }

  getWarnings(): {
    warningsArray: SpamLanguageWarning[];
    spamLanguage: string[];
  } {
    const warningsArray: SpamLanguageWarning[] = [];
    const spamLanguage: string[] = [];

    for (const field in this._warningsMap) {
      warningsArray.push({
        field,
        violations: this._warningsMap[field],
      })

      spamLanguage.push(...this._warningsMap[field]);
    }

    return { 
      warningsArray,
      spamLanguage,
    };
  }

  resetWarnings(): void {
    this._warningsMap = {};
    this.warnings.next([]);
  }

  getDetectedSpamLanguage(): string[] {
    const spamLanguage: string[] = [];
    for (const field in this._warningsMap) {
      spamLanguage.push(...this._warningsMap[field]);
    }
    return spamLanguage;
  }

  setWarningText(text: string): void {
    this.warningText = text;
  }

  getWarningText(): string {
    return this.warningText;
  }

  setContext(context: { [key: string]: string; }): void {
    this._context = { ...context, ...ignoreTokens };
  }
  
  addContext(context: { [key: string]: string; }): void {
    this._context = { ...this._context, ...context, ...ignoreTokens };
  }

  setShouldDisplay(val: boolean): void {
    this._shouldDisplay = val;
  }

  shouldDisplayWarning(): boolean {
    return this._shouldDisplay && !!Object.values(this._warningsMap).length;
  }

  checkForSpamLanguage(key: string, text: string): void {
    // Don't check URL text
    if (key === 'Your Website') {
      return;
    }

    if (!text) {
      this.removeWarning(key);
      return;
    }

    text = this._tokenParser.replaceTokens(text, this._context)

    const spamLanguage = checkTextForSpamLanguage(text);

    if (spamLanguage.length) {
      this.setWarning(key, spamLanguage);
    } else {
      this.removeWarning(key);
    }
  }
}
