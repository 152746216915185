import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITemplate } from '@nurtureboss/common/dist/types/templates';

export enum LegacyTemplateOption {
  Bulk = 'bulk',
  Single = 'single',
  None = 'none',
}

export interface TemplateSelectEvent {
  template: ITemplate;
  legacyOption: LegacyTemplateOption;
}

@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.less']
})
export class TemplateListComponent {

  @Input() templates: ITemplate[] = [];
  @Input() chooseImageSourceFunction: (template: ITemplate) => string = (template) => template.previewPageImageUrl;
  @Input() isLegacy = false;

  @Output() templateClick = new EventEmitter<TemplateSelectEvent>();
  @Output() previewClick = new EventEmitter<ITemplate>();

  constructor() { }

  onPreviewClick(event: MouseEvent, template: ITemplate) {
    event.stopImmediatePropagation();
    this.previewClick.emit(template);
  }

  onTemplateClick(event: MouseEvent, template: ITemplate, legacyOption: LegacyTemplateOption) {
    // Very important for legacy mode, otherwise we will get two click events
    event.stopImmediatePropagation();
    this.templateClick.emit({
      template,
      legacyOption,
    });
  }
}
