<div class="page-title">
  <h2>Defaults</h2>
</div>

 <!-- Hyper-Personalization -->
 <div class="page">
  <div class="template-defaults-title">
    <h6 class="title pull-left">Hyper-Personalization</h6>
  </div>
  <div class="row hp-thumbnails">
    <div class="col-md-2">
      <div class="hp-thumbnail">
        <img src="/assets/dog-icon.png" />
        <h6 class="text-center">Pets</h6>
        <button class="button button-dark button-small" (click)="configureHpData('pets', configurepethpmodal)">
          <i class="far fa-robot" aria-hidden="true"></i> Configure
        </button>
      </div>
    </div>
    <div class="col-md-2" *ngIf="canUseAvailabilityHP">
      <div class="hp-thumbnail">
        <img src="/assets/availability-hyper-personalization.png" />
        <h6 class="text-center">Availability</h6>
        <button class="button button-dark button-small" (click)="configureHpData('availability', configureavailabilityhpmodal)">
          <i class="far fa-robot" aria-hidden="true"></i> Configure
        </button>
      </div>
    </div>
  </div>
</div>

<!-- USER TEMPLATE DEFAULTS -->
<div class="page">
  <div class="template-defaults-title">
    <h6 class="title pull-left">Global Defaults</h6>
    <button class="button pull-right button-light" [disabled]="savingDefaults" (click)="openSaveDefaultsModal($event)">
      <span *ngIf="savingDefaults" class="spinner-border spinner-border-sm mr-1"></span>
      Save/Reset Defaults
    </button>
  </div>
  <app-spam-warnings
    [warningText]="'WARNING: Your Global Defaults contain words that are known to trigger Spam Filters for various Email Service Providers. Problomatic words found:'"
    [spamWarnings]="spamWarnings"
  ></app-spam-warnings>
  <div class="global-default-table user-table no-border">
    <form [formGroup]="globalDefaults">
      <div class="row">
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label for="bannerImage">Banner Image</label>
            <div *ngIf="!globalDefaults.controls.bannerImage.value && !loadingImage.bannerImage" class="missing-image">* Missing Banner Image Default</div>
            <img class="preview-image" *ngIf="globalDefaults.controls.bannerImage.value && !loadingImage.bannerImage" [src]="globalDefaults.controls.bannerImage.value" />
            <p *ngIf="globalDefaults.controls.bannerImage.value && !loadingImage.bannerImage" (click)="resetImage('bannerImage', 'globalDefaults')" class="remove-default"><i class="far fa-trash-alt"></i> Remove Image</p>
            <div *ngIf="loadingImage.bannerImage" class="spinner-border spinner-border-sm"></div>
            <app-file-type-image
              inputId="bannerImageUpload"
              *ngIf="!globalDefaults.controls.bannerImage.value && !loadingImage.bannerImage"
              (onFilesPicked)="uploadMedia($event.target.files, 'bannerImage', 'globalDefaults')"
            ></app-file-type-image>
            <p *ngIf="!globalDefaults.controls.bannerImage.value && !loadingImage.bannerImage" class="media-manager-helper-text">or</p>
            <button *ngIf="!globalDefaults.controls.bannerImage.value && !loadingImage.bannerImage" (click)="openMediaModal($event, 'bannerImage', 'globalDefaults')" class="button button-white"><i class="far fa-image"></i> Select From Media Manager</button>
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label for="bannerImage">Logo</label>
            <div *ngIf="!globalDefaults.controls.leftLogo.value && !loadingImage.leftLogo" class="missing-image">* Missing Logo Default</div>
            <img class="preview-image" *ngIf="globalDefaults.controls.leftLogo.value && !loadingImage.leftLogo" [src]="globalDefaults.controls.leftLogo.value" />
            <p *ngIf="globalDefaults.controls.leftLogo.value && !loadingImage.leftLogo" (click)="resetImage('leftLogo', 'globalDefaults')" class="remove-default"><i class="far fa-trash-alt"></i> Remove Image</p>
            <div *ngIf="loadingImage.leftLogo" class="spinner-border spinner-border-sm"></div>
            <app-file-type-image
              inputId="leftLogoUpload"
              *ngIf="!globalDefaults.controls.leftLogo.value && !loadingImage.leftLogo"
              (onFilesPicked)="uploadMedia($event.target.files, 'leftLogo', 'globalDefaults')"
            ></app-file-type-image>
            <p *ngIf="!globalDefaults.controls.leftLogo.value && !loadingImage.leftLogo" class="media-manager-helper-text">or</p>
            <button *ngIf="!globalDefaults.controls.leftLogo.value && !loadingImage.leftLogo" (click)="openMediaModal($event, 'leftLogo', 'globalDefaults')" class="button button-white"><i class="far fa-image"></i> Select From Media Manager</button>
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label>Current Concession</label>
            <input type="text" class="form-control" formControlName="concession" id="concession" aria-describedby="concession" placeholder="Enter property concession">
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label>Property Website URL</label>
            <input type="text" class="form-control" formControlName="yourWebsite" id="yourWebsite" aria-describedby="yourWebsite" placeholder="Enter property website">
          </div>
        </div>
        <div class="field col-md-6 mb-4">
          <label for="concessionExpirationDate">Concession Expiration Date (Optional)</label>
          <p-calendar
            [showIcon]="true"
            inputId="concessionExpirationDate"
            formControlName="concessionExpirationDate"
            [minDate]="tomorrow"
            placeholder="Concession Expiration Date"
            [readonlyInput]="true"
            dateFormat="mm-dd-yy"
            [showButtonBar]="true"
            todayButtonStyleClass="d-none"
            [style]="{ width: '100%', border: '1px solid #ced4da' }"
            [inputStyle]="{
              width: 'calc(100% - 33px)',
              border: 'none',
              fontFamily: 'Lato, sans-serif',
              fontSize: '14px',
              padding: '.375rem .75rem'
            }"
            styleClass="calendar-input"
          ></p-calendar>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label>Property Email Address (Tracking Email Preferred)</label>
            <input type="text" class="form-control" formControlName="yourEmailAddress" id="yourEmailAddress" aria-describedby="yourEmailAddress" placeholder="Enter property email address">
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label>Property Phone Number (Tracking Number Preferred)</label>
            <input type="text" class="form-control" formControlName="yourPhoneNumber" id="yourPhoneNumber" aria-describedby="yourPhoneNumber" placeholder="Enter property phone number">
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label>Resident Portal URL</label>
            <input type="text" class="form-control" formControlName="residentPortalUrl" id="residentPortalUrl" aria-describedby="residentPortalUrl" placeholder="Enter online resident portal URL">
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label>Schedule a Tour URL</label>
            <input type="text" class="form-control" formControlName="scheduleATourUrl" id="scheduleATourUrl" aria-describedby="scheduleATourUrl" placeholder="Enter schedule a tour url">
          </div>
        </div>

        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label>Apply Online URL</label>
            <input type="text" class="form-control" formControlName="applyOnlineUrl" id="applyOnlineUrl" aria-describedby="applyOnlineUrl" placeholder="Enter online application url">
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label>Online Review URL</label>
            <input type="text" class="form-control" formControlName="onlineReviewUrl" id="onlineReviewUrl" aria-describedby="onlineReviewUrl" placeholder="Enter online review url">
          </div>
        </div>  <div class="col-md-6 mb-4">
          <div class="form-group">
            <label>Amenities URL</label>
            <input type="text" class="form-control" formControlName="amenitiesUrl" id="amenitiesUrl" aria-describedby="amenitiesUrl" placeholder="Enter amenities page url">
          </div>
        </div>  <div class="col-md-6 mb-4">
          <div class="form-group">
            <label>Floor Plans URL</label>
            <input type="text" class="form-control" formControlName="floorPlansUrl" id="floorPlansUrl" aria-describedby="floorPlansUrl" placeholder="Enter floor plans page url">
          </div>
        </div>  <div class="col-md-6 mb-4">
          <div class="form-group">
            <label>Image Gallery URL</label>
            <input type="text" class="form-control" formControlName="imageGalleryUrl" id="imageGalleryUrl" aria-describedby="imageGalleryUrl" placeholder="Enter gallery page url">
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label>Instagram Handle <i class="fab fa-instagram"></i></label>
            <input type="text" class="form-control" formControlName="instagramHandle" id="instagramHandle" aria-describedby="instagramHandle" placeholder="@nurtureboss">
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label>Facebook URL <i class="fab fa-facebook"></i></label>
            <input type="text" class="form-control" formControlName="facebookUrl" id="facebookUrl" aria-describedby="facebookUrl" placeholder="https://www.facebook.com/nutureboss">
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- ADMIN TEMPLATE DEFAULTS -->
<!-- Intentionally do not expose to Partner. -->
<!-- <div class="page" *ngIf="isAdmin()">
  <div class="alert alert-danger mt-0">
    <strong>Defaults saved here will overwrite ALL templates (Blasts and Automation Steps)</strong>
  </div>
  <div class="template-defaults-title">
    <h6 class="title pull-left">Admin Template Defaults</h6>
    <button class="button pull-right button-light" [disabled]="savingDefaults" (click)="saveDefault()">
      <span *ngIf="savingDefaults" class="spinner-border spinner-border-sm mr-1"></span>
      Admin Save/Reset Defaults
    </button>
  </div>
  <div class="admin-default-table user-table no-border">
    <div class="form-group">
      <label for="bannerImage">Banner Image</label>
      <img class="preview-image" *ngIf="templateDefaults.bannerImage && !loadingImage.bannerImage" [src]="templateDefaults.bannerImage" />
      <p *ngIf="templateDefaults.bannerImage && !loadingImage.bannerImage" (click)="resetImage('bannerImage')" class="remove-default"><i class="far fa-trash-alt"></i> Remove Image</p>
      <div *ngIf="loadingImage.bannerImage" class="spinner-border spinner-border-sm"></div>
      <app-file-type-image
        inputId="bannerImageUpload"
        *ngIf="!templateDefaults.bannerImage && !loadingImage.bannerImage"
        (onFilesPicked)="uploadMedia($event.target.files, 'bannerImage')"
      ></app-file-type-image>
      <p *ngIf="!templateDefaults.bannerImage && !loadingImage.bannerImage" class="media-manager-helper-text">or</p>
      <button *ngIf="!templateDefaults.bannerImage && !loadingImage.bannerImage" (click)="openMediaModal($event, 'bannerImage')" class="button button-white"><i class="far fa-image"></i> Select From Media Manager</button>
    </div>
    <div class="form-group">
      <div class="form-group">
        <label for="bannerImage">Logo</label>
        <img class="preview-image" *ngIf="templateDefaults.leftLogo && !loadingImage.leftLogo" [src]="templateDefaults.leftLogo" />
        <p *ngIf="templateDefaults.leftLogo && !loadingImage.leftLogo" (click)="resetImage('leftLogo')" class="remove-default"><i class="far fa-trash-alt"></i> Remove Image</p>
        <div *ngIf="loadingImage.leftLogo" class="spinner-border spinner-border-sm"></div>
        <app-file-type-image
          inputId="leftLogoUpload"
          *ngIf="!templateDefaults.leftLogo && !loadingImage.leftLogo"
          (onFilesPicked)="uploadMedia($event.target.files, 'leftLogo')"
        ></app-file-type-image>
        <p *ngIf="!templateDefaults.leftLogo && !loadingImage.leftLogo" class="media-manager-helper-text">or</p>
        <button *ngIf="!templateDefaults.leftLogo && !loadingImage.leftLogo" (click)="openMediaModal($event, 'leftLogo')" class="button button-white"><i class="far fa-image"></i> Select From Media Manager</button>
      </div>
    </div>
    <div class="form-group">
      <label>Current Concession</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.concession" [ngModelOptions]="{standalone: true}" id="concession" aria-describedby="concession" placeholder="Enter property concession">
    </div>
    <div class="form-group">
      <label>Property Website URL</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.yourWebsite" [ngModelOptions]="{standalone: true}" id="yourWebsite" aria-describedby="yourWebsite" placeholder="Enter property website">
    </div>
    <div class="form-group">
      <label>Online Application URL</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.onlineApplicationUrl" [ngModelOptions]="{standalone: true}" id="onlineApplicationUrl" aria-describedby="onlineApplicationUrl" placeholder="Enter online application URL">
    </div>
    <div class="form-group">
      <label>Online Application URL Link Text</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.onlineApplicationLinkText" [ngModelOptions]="{standalone: true}" id="onlineApplicationLinkText" aria-describedby="onlineApplicationLinkText" placeholder="Enter online application url link text">
    </div>
    <div class="form-group">
      <label>Resident Portal URL</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.residentPortalUrl" [ngModelOptions]="{standalone: true}" id="residentPortalUrl" aria-describedby="residentPortalUrl" placeholder="Enter online resident portal URL">
    </div>
    <div class="form-group">
      <label>Property Email Address (Tracking Email Preferred)</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.yourEmailAddress" [ngModelOptions]="{standalone: true}" id="yourEmailAddress" aria-describedby="yourEmailAddress" placeholder="Enter property email address">
    </div>
    <div class="form-group">
      <label>Property Phone Number (Tracking Number Preferred)</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.yourPhoneNumber" [ngModelOptions]="{standalone: true}" id="yourPhoneNumber" aria-describedby="yourPhoneNumber" placeholder="Enter property phone number">
    </div>
    <div class="form-group">
      <label>Renters Insurance Website</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.specialInsuranceInstructions" [ngModelOptions]="{standalone: true}" id="specialInsuranceInstructions" aria-describedby="specialInsuranceInstructions" placeholder="Enter the renters insurance website">
    </div>
    <div class="form-group">
      <label>Power Provider Website</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.specialPowerProviderInstructions" [ngModelOptions]="{standalone: true}" id="specialPowerProviderInstructions" aria-describedby="specialPowerProviderInstructions" placeholder="Enter power provider website">
    </div>
    <div class="form-group">
      <label>Power Provider</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.powerProvider" [ngModelOptions]="{standalone: true}" id="powerProvider" aria-describedby="powerProvider" placeholder="Enter power provider">
    </div>
    <div class="form-group">
      <label>Special Move in Cost Instructions</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.specialPaymentInstructions" [ngModelOptions]="{standalone: true}" id="specialPaymentInstructions" aria-describedby="specialPaymentInstructions" placeholder="Enter special move in cost instructions">
    </div>
    <div class="form-group">
      <label>Costs Due at Move In</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.moveInCosts" [ngModelOptions]="{standalone: true}" id="moveInCosts" aria-describedby="moveInCosts" placeholder="Enter cost due at move in">
    </div>
    <div class="form-group">
      <label>Renewal Special</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.renewalSpecial" [ngModelOptions]="{standalone: true}" id="renewalSpecial" aria-describedby="renewalSpecial" placeholder="Enter renewal special">
    </div>
    <div class="form-group">
      <label>Maintenance Follow Up Survey Link</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.surveyLink" [ngModelOptions]="{standalone: true}" id="surveyLink" aria-describedby="surveyLink" placeholder="Enter survey link">
    </div>
    <div class="form-group">
      <label>Schedule a Tour URL</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.scheduleATourUrl" [ngModelOptions]="{standalone: true}" id="scheduleATourUrl" aria-describedby="scheduleATourUrl" placeholder="Enter schedule a tour url">
    </div>
    <div class="form-group">
      <label>Event Name</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.eventName" [ngModelOptions]="{standalone: true}" id="eventName" aria-describedby="eventName" placeholder="Enter event name">
    </div>
    <div class="form-group">
      <label>Event Time</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.eventTime" [ngModelOptions]="{standalone: true}" id="eventTime" aria-describedby="eventTime" placeholder="Enter event time">
    </div>
    <div class="form-group">
      <label>Event Text</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.eventText" [ngModelOptions]="{standalone: true}" id="eventText" aria-describedby="eventText" placeholder="Enter event text">
    </div>
    <div class="form-group">
      <label>Event Date</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.eventDate" [ngModelOptions]="{standalone: true}" id="eventDate" aria-describedby="eventDate" placeholder="Enter event date">
    </div>
    <div class="form-group">
      <label>Event Location</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.eventLocation" [ngModelOptions]="{standalone: true}" id="eventLocation" aria-describedby="eventLocation" placeholder="Enter event location">
    </div>
    <div class="form-group">
      <label for="eventImage">Event Image</label>
      <img class="preview-image" *ngIf="templateDefaults.eventImage && !loadingImage.eventImage" [src]="templateDefaults.eventImage" />
      <p *ngIf="templateDefaults.eventImage && !loadingImage.eventImage" (click)="resetImage('eventImage')" class="remove-default"><i class="far fa-trash-alt"></i> Remove Image</p>
      <div *ngIf="loadingImage.eventImage" class="spinner-border spinner-border-sm"></div>
      <app-file-type-image
        inputId="eventImageUpload"
        *ngIf="!templateDefaults.eventImage && !loadingImage.eventImage"
        (onFilesPicked)="uploadMedia($event.target.files, 'eventImage')"
      ></app-file-type-image>
      <p *ngIf="!templateDefaults.eventImage && !loadingImage.eventImage" class="media-manager-helper-text">or</p>
      <button *ngIf="!templateDefaults.eventImage && !loadingImage.eventImage" (click)="openMediaModal($event, 'eventImage')" class="button button-white"><i class="far fa-image"></i> Select From Media Manager</button>
    </div>
    <div class="form-group">
      <label for="fileAttachment">File Attachment</label>
      <app-file-preview [value]="templateDefaults.fileAttachment"></app-file-preview>
      <p *ngIf="templateDefaults.fileAttachment && !loadingImage.fileAttachment" (click)="resetImage('fileAttachment')" class="remove-default"><i class="far fa-trash-alt"></i> Remove Attachment</p>
      <div *ngIf="loadingImage.fileAttachment" class="spinner-border spinner-border-sm"></div>
      <app-file-type-all
        *ngIf="!templateDefaults.fileAttachment && !loadingImage.fileAttachment"
        (onFilesPicked)="uploadMedia($event.target.files, 'fileAttachment')"
      ></app-file-type-all>
      <p *ngIf="!templateDefaults.fileAttachment && !loadingImage.fileAttachment" class="media-manager-helper-text">or</p>
      <button *ngIf="!templateDefaults.fileAttachment && !loadingImage.fileAttachment" (click)="openMediaModal($event, 'fileAttachment')" class="button button-white"><i class="far fa-image"></i> Select From Media Manager</button>
    </div>
    <div class="form-group">
      <label>File Attachment Text</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.fileAttachmentText" [ngModelOptions]="{standalone: true}" id="fileAttachmentText" aria-describedby="fileAttachmentText" placeholder="Enter file attachment text">
    </div>
    <div class="form-group"> -->
      <!-- Empty to create visual pleasantries -->
    <!-- </div>
    <div class="form-group">
      <label>Main Message</label>
      <textarea rows="3" type="text" class="form-control" [(ngModel)]="templateDefaults.mainText" [ngModelOptions]="{standalone: true}" id="mainText" aria-describedby="mainText" placeholder="Enter main message"></textarea>
      <app-token-container
        [tokenSource]="generalTokens"
        textSourceElementId="mainText"
        [model]="templateDefaults"
        modelPropertyName="mainText"
        [validatorsList]="[]"
      >
      </app-token-container>
    </div>
    <div class="form-group"> -->
      <!-- Empty to create visual pleasantries -->
    <!-- </div>
    <div class="form-group">
      <label>Main Header</label>
      <input type="text" maxlength="100" class="form-control" [(ngModel)]="templateDefaults.headerText" (ngModelChange)="onHeaderTextChange($event)" [ngModelOptions]="{standalone: true}" id="headerText" aria-describedby="headerText" placeholder="Enter main header">
      <span
        class="helper-text pl-2"
        [ngClass]="{'text-warning': headerTextRemainingChars < 25, 'text-danger': headerTextRemainingChars <= 0}"
      >{{headerTextRemainingChars}} characters remaining</span>
      <app-token-container
        [tokenSource]="generalTokens"
        textSourceElementId="headerText"
        maxLength="100"
        [model]="templateDefaults"
        modelPropertyName="headerText"
        (textLengthChange)="onHeaderTextLengthChange($event)"
        [validatorsList]="[]"
      >
      </app-token-container>
    </div>
  </div>
</div>  -->

<app-media-modal (imageSubmittedEvent)="handleImageSubmitEvent($event)"></app-media-modal>
<app-save-defaults-modal [globalDefaults]="globalDefaults"></app-save-defaults-modal>

<!-- Edit PET HP Data Configuration -->
<ng-template #configurepethpmodal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Configure Hyper Personalization</h5>
    <div class="float-right">
        <button type="button" class="button button-white button-small" (click)="exitModal($event, modal)">
          <i class="far fa-times" aria-hidden="true"></i>
        </button>
    </div>
  </div>
  <div class="modal-body configure-hp-modal">
    <div class="col-md-12 no-margin no-padding">
      <form [formGroup]="hyperpersonalizationData" (ngSubmit)="saveHyperpersonalization(modal)">
        <div class="form-group">
          <label for="petImage">Pet Image</label>
          <img class="preview-image" *ngIf="hyperpersonalizationData.controls.petImage.value && !loadingImage.petImage" [src]="hyperpersonalizationData.controls.petImage.value" />
          <p *ngIf="hyperpersonalizationData.controls.petImage.value && !loadingImage.petImage" (click)="resetImage('petImage', 'hyperpersonalizationData')" class="remove-default"><i class="far fa-trash-alt"></i>Remove Image</p>
          <div *ngIf="loadingImage.petImage" class="spinner-border spinner-border-sm"></div>
          <app-file-type-image
            inputId="petImageUpload"
            *ngIf="!hyperpersonalizationData.controls.petImage.value && !loadingImage.petImage"
            (onFilesPicked)="uploadMedia($event.target.files, 'petImage', 'hyperpersonalizationData')"
          ></app-file-type-image>
          <p *ngIf="!hyperpersonalizationData.controls.petImage.value && !loadingImage.petImage" class="media-manager-helper-text">or</p>
          <button *ngIf="!hyperpersonalizationData.controls.petImage.value && !loadingImage.petImage" (click)="openMediaModal($event, 'petImage', 'hyperpersonalizationData')" class="button button-white"><i class="far fa-image"></i> Select From Media Manager</button>
        </div>
        <div class="form-group" [formGroup]="hyperpersonalizationData">
          <label>Pet Image Label</label>
          <input type="text" class="form-control" formControlName="petImageLabel" id="petImageLabel" aria-describedby="petImageLabel" placeholder="Enter pet image label">
        </div>
        <div class="form-group" [formGroup]="hyperpersonalizationData">
          <label>Pet Policy</label>
          <textarea class="form-control" formControlName="petPolicy" id="petPolicy" aria-describedby="petPolicy" placeholder="Enter pet policy"></textarea>
        </div>
        <div>
          <label for="petActive">Enabled</label>
          <label class="switch">
            <input type="checkbox" formControlName="petActive" id="petActive">
            <span class="slider round"></span>
          </label>
        </div>
        <div class="pull-right">
          <button class="button button-dark margin-bottom-24 margin-top-12">
            <span *ngIf="savingDefaults" class="spinner-border spinner-border-sm mr-1"></span>
            Save Hyper Personalization Configuration
          </button>
        </div>

      </form>
    </div>
  </div>
</ng-template>

<!-- Edit AVAILABILITY HP Data Configuration -->
<ng-template #configureavailabilityhpmodal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Configure Hyper Personalization</h5>
    <div class="float-right">
        <button type="button" class="button button-white button-small" (click)="exitModal($event, modal)">
          <i class="far fa-times" aria-hidden="true"></i>
        </button>
    </div>
  </div>
  <div class="modal-body configure-hp-modal">
    <div class="col-md-12 no-margin no-padding">
      <div class="alert alert-primary">Enabling Availability Hyper-Personalization will allow us to dynamically insert information into follow-ups we send of available units that match the bedroom/bathroom count and desired move in date of each prospect. If no desired bedroom count is specified on the guest card no availability will be shows to that prospect.</div>
      <form [formGroup]="hyperpersonalizationData" (ngSubmit)="saveHyperpersonalization(modal)">
        <div class="form-group">
          <label for="availabilityActive">
              Enabled
              <i *ngIf="!propertyInfo.availabilitySource" placement="right" class="far fa-info-circle"></i>
          </label>
          <label class="switch">
            <input type="checkbox" formControlName="availabilityActive" id="availabilityActive">
            <span class="slider round"></span>
          </label>
        </div>
        <div class="pull-right">
          <button class="button button-dark margin-bottom-24 margin-top-12">
            <span *ngIf="savingDefaults" class="spinner-border spinner-border-sm mr-1"></span>
            Save Hyper Personalization Configuration
          </button>
        </div>

      </form>
    </div>
  </div>
</ng-template>
