// Angular:
import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

// Libs
import { Chart } from 'chart.js';
import * as _ from 'lodash';

// Services:
import {
  ToastService,
  LoaderService,
  FormsService,
} from '@app/_services';

// Types
import { FormResponse } from '@nurtureboss/common/dist/types/formResponses';
import { IForm, QuestionType } from '@nurtureboss/common/dist/types/forms';

@Component({
  selector: 'app-view-form-results',
  templateUrl: './view-form-results.component.html',
  styleUrls: ['./view-form-results.component.less']
})
export class ViewFormResultsComponent implements OnInit {
  charts: any = [];
  debouncedResponsesSearch: any;
  form: IForm;
  formResponses: FormResponse[] = [];
  isLoading = true;
  primaryColor: string = '#235587';
  questionResponseAggregateData: any = [];
  responseSearchResults: FormResponse[] = [];
  responsesSearchText = '';
  stagedResponse: { [key: string]: string; } = null;
  chartQuestions = [QuestionType.SingleChoice, QuestionType.Dropdown, QuestionType.Linear, QuestionType.Checkbox];
  barChartQuestions = [QuestionType.Linear, QuestionType.Checkbox];
  chartColors = [
    '#577EB7',
    '#58907C',
    '#BEB0DC',
    '#F2BF63',
    '#C84D5A',
    '#B0E4E8',
    '#EDE6F3',
    '#FDE4B1',
    '#D2F1CD',
    '#6C3392',
    '#66C08E',
    '#A9CFA2',
    '#B9D4F2',
    '#3DB5C0',
    '#BEA2CF',
    '#A285D9',
    '#6B9465',
    '#FEB0B8',
    '#945CBA',
  ];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private formsService: FormsService,
    private loaderService: LoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
  ) {
    this.debouncedResponsesSearch = _.debounce(this.searchFn, 400);
  }

  ngOnInit(): void {
    this.loadFormResults();
  }

  async loadFormResults() {
    this.loaderService.triggerLoader();

    const formId = this.route.snapshot.params['id'];

    try {
      this.form = await this.formsService.getForm(formId).toPromise();
      if (!this.form) {
        throw new Error('Error getting survey');
      }

      const res = await this.formsService.getFormResponsesWithAggregateData(formId).toPromise();

      this.formResponses = res.formResponses;

      if (!this.formResponses.length) {
        this.loaderService.stopLoader();
        this.isLoading = false;
        return;
      }

      this.responseSearchResults = this.formResponses.slice(); // Init search results with all responses
      this.questionResponseAggregateData = res.questionResponseAggregateData;
      
      this.isLoading = false;
      // Load charts slightly after load to allow DOM to be set before attaching charts
      setTimeout(() => {
        this.loadCharts();
        this.loaderService.stopLoader();
      }, 100);
    } catch(err) {

     // Reroute if no form is found
     this.loaderService.stopLoader();
     this.toastService.showError('Error getting survey results.');
     this.router.navigate([`/forms/view`], {});
    }
  }

  searchFn(query: string): void {
    if (!query) {
      // Sets search results to all responses as default if there is no search query
      this.responseSearchResults = this.formResponses.slice();
    } else {
      query = query.toLowerCase().replace(/\s/g, ""); // Remove spaces
      this.responseSearchResults = this.formResponses.filter(response => {
        // @ts-ignore
        return response.searchKeyword.toLowerCase().includes(query);
      });
    }
  }

  generateChartColors(num) {
    const generateColor = () => {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }

    if (this.chartColors.length < num) {
      let colorArr = this.chartColors.slice();
      for (let i = colorArr.length; i <= num; i++) {
        colorArr.push(generateColor());
      }
  
      return colorArr;
    } else {
      return this.chartColors.slice(0, num);
    }
  }

  loadCharts() {
    this.questionResponseAggregateData.forEach(questionResponseData => {
      const ctx = this.document.getElementById(questionResponseData.questionId);
      let chart;
      switch (questionResponseData.questionType) {
        case QuestionType.SingleChoice:
        case QuestionType.Dropdown:
          chart = this.generatePieChart(ctx, this.getDataAndLabels(questionResponseData.answerData));
          this.document.getElementById('legend' + questionResponseData.questionId).innerHTML = chart.generateLegend();
          break;
        case QuestionType.Checkbox:
          chart = this.generateHorizontalBarChart(ctx, this.getDataAndLabels(questionResponseData.answerData));
          break;
        case QuestionType.Linear:
          chart = this.generateVerticalBarChart(ctx, this.getDataAndLabels(questionResponseData.answerData));
      }
    });
  }

  getDataAndLabels(answerData) {
    return { data: Object.values(answerData), labels: Object.keys(answerData) };
  }

  generatePieChart(ctx, { data, labels }) {
    const chartColors = this.generateChartColors(labels.length)
    return new Chart(ctx, {
      type: 'pie',
      data: {
        datasets: [{
          data,
          backgroundColor: chartColors,
        }],
        labels,
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        legendCallback: chart => {
          // Inline styles since .less classes don't seem to affect these elements when rendered through this callback
          let html = `<div style="display:grid; grid-template-columns:repeat(2, 1fr); gap:4px; column-gap:12px; max-width:800px; max-height:250px;">`;  

          labels.forEach((label, i) => {
            // Broken down for legibility
            html += `<div style="position:relative; max-width:295px;" onmouseover="const elem = this.children[0]; if(elem.scrollHeight > elem.clientHeight || elem.scrollWidth > elem.clientWidth) { this.children[1].style.display = 'block';}" onMouseOut="this.children[1].style.display = 'none';">`
            html += `<div style="overflow:hidden; white-space:nowrap; text-overflow:ellipsis;">`
            html += `<i class="fa-solid fa-circle" style="color:${chartColors[i]}; margin-right:8px;"></i>${label}</div>`
            html += `<div style="position:absolute; z-index:1; bottom:24px; display:none; background-color:black; color:#fff; font-weight:normal; text-align:center; padding:5px; border-radius:4px;">${label}`
            html += `<i style="position:absolute; bottom:-14px; left:50%; color:black; font-size:26px;" class="fa-sharp fa-solid fa-caret-down"></i></div></div>`
          });

          html += '</div>';

          return html;
        },
        tooltips: {
          callbacks: {
            // Add % values to chart tooltip
            label: function(tooltipItem, data) {
              var allData = data.datasets[tooltipItem.datasetIndex].data;
              var tooltipLabel = data.labels[tooltipItem.index];
              var tooltipData = allData[tooltipItem.index];
              var total = 0;
              for (var i in allData) {
                total += allData[i];
              }
              var tooltipPercentage = Math.round((tooltipData / total) * 100);
              if (tooltipLabel.length < 90) {
                return '  ' + tooltipLabel + ': ' + tooltipData + ' (' + tooltipPercentage + '%)';
              } else {
                let linebreaklabel = tooltipLabel.match(/.{1,80}(?:\s|$)/g);
                linebreaklabel.push('')
                linebreaklabel.push(tooltipData + ' (' + tooltipPercentage + '%)');
                // Add spaces for formatting
                return linebreaklabel.map(ln => { return '  ' + ln });
              }
            }
          }
        },
      },
    })
  }

  generateVerticalBarChart(ctx, { data, labels }) {
    return new Chart(ctx, {
      type: 'bar',
      data: {
        datasets: [{
          data,
          backgroundColor: '#694382',
          borderColor: '#694382',
        }],
        labels,
      },
      options: {
        maintainAspectRatio: false,
        title: {
					display: false
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            gridLines: {
              color: '#f4f4f4',
            },
            ticks: {
                beginAtZero: true,
                stepSize: 1
            }
          }],
          xAxes: [{
            gridlines: {
              color: '#f4f4f4',
            }
          }]
        },
        tooltips: {
          callbacks: {
            // Add % values to chart tooltip
            label: function(tooltipItem, data) {
              var allData = data.datasets[tooltipItem.datasetIndex].data;
              var tooltipLabel = data.labels[tooltipItem.index];
              var tooltipData = allData[tooltipItem.index];
              var total = 0;
              for (var i in allData) {
                total += allData[i];
              }
              var tooltipPercentage = Math.round((tooltipData / total) * 100);
              return '  ' + tooltipLabel + ': ' + tooltipData + ' (' + tooltipPercentage + '%)';
            }
          }
        },
      },
    })
  }

  generateHorizontalBarChart(ctx, { data, labels }) {
    return new Chart(ctx, {
      type: 'horizontalBar',
      data: {
        datasets: [{
          data,
          backgroundColor: '#694382',
        }],
        labels,
      },
      options: {
        maintainAspectRatio: false,
        title: {
					display: false
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            gridLines: {
              color: '#f4f4f4',
            },
            ticks: {
                beginAtZero: true,
                stepSize: 1
            }
          }],
          xAxes: [{
            gridlines: {
              color: '#f4f4f4',
            }
          }]
        },
        tooltips: {
          callbacks: {
            // Add % values to chart tooltip
            label: function(tooltipItem, data) {
              var allData = data.datasets[tooltipItem.datasetIndex].data;
              var tooltipLabel = data.labels[tooltipItem.index];
              var tooltipData = allData[tooltipItem.index];
              var total = 0;
              for (var i in allData) {
                total += allData[i];
              }
              var tooltipPercentage = Math.round((tooltipData / total) * 100);
              return '  ' + tooltipLabel + ': ' + tooltipData + ' (' + tooltipPercentage + '%)';
            }
          }
        },
      },
    })
  }

  viewResponse(response) {
    // Use questiondIdMap to organize response data instead of answerData array
    // To avoid issues in case the array is out of order
    const questionIdMap = {};

    response.answers.forEach(answerData => {
      questionIdMap[answerData.questionId] = answerData.answer;
    })
    this.stagedResponse = { ...response, questionIdMap };
  }

  clearStagedResponse() {
    this.stagedResponse = null;
  }

  // Needed since certain globals are not recognized in angular interpolation
  isArray(val) {
    return Array.isArray(val);
  }
}
