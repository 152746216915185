// Components:
import { Component } from '@angular/core';
// Services:
import { MediaService, LoaderService, ToastService } from '@app/_services';
// Utils:
import { getAllowedImageExtensions, getFileIconClass, isFile } from '@app/_utils/fileIcons';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.less']
})
export class MediaComponent {

  media: Array<any>;
  loadingImage = false;
  isFile = isFile;
  getFileIconClass = getFileIconClass;

  constructor(
    private mediaService: MediaService,
    private toastService: ToastService,
    private loaderService: LoaderService,
  ) {
    this.init();
  }

  init() {
    this.loaderService.triggerLoader();
    this.mediaService.getAllMedia().subscribe((data) => {
      this.media = data.result.sort((a, b) => {
        a.url.replace(' ', '%20');
        return +new Date(b.created) - +new Date(a.created);
      });
      this.loaderService.stopLoader();
    }, (err) => {
      this.loaderService.stopLoader();
      this.toastService.showError('There was an error loading your media');
    });
  }

  getAcceptedFileTypes(): string[] {
    return getAllowedImageExtensions();
  }

  openImage(url) {
    window.open(url);
  }

  deleteImage(image, i) {
    if (!image.loading) {
      image.loading = true;
      this.mediaService.deleteMedia(image._id).subscribe(() => {
        this.media.splice(i, 1);
      });
    }
  }

  onUploadMediaClicked() {
    document.getElementById('upload-media-input').click();
  }

  uploadMedia(event) {
    this.loadingImage = true;
    this.mediaService.getSignedRequestHelper(event.target.files, null, null).
        then(() => {
          this.loadingImage = false;
          this.init();
        }).
        catch((err) => {
          this.loadingImage = false;
          this.toastService.showError('There was an error uploading media');
        });
  }
}
