// Libs:
import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTrim]'
})
export class TrimDirective {
  constructor(
    private control: NgControl
  ) { }

  @HostListener('keyup', ['$event'])
  @HostListener('blur', ['$event'])
  onFieldUpdate() {
    let value = this.control.value;
    try {
      value = value.trim();
      this.control.control.setValue(value);
    } catch (e) {
      //no-op. Fail silently.
    }
  }
}
