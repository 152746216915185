import { Component } from '@angular/core';
import { AuthenticationService, ToastService } from '@app/_services';
  
@Component({
  templateUrl: 'knowledge-base-login.component.html',
  styleUrls: ['knowledge-base-login.component.less']
})
export class KnowledgeBaseLoginComponent {

  constructor(
    private authService: AuthenticationService,
    private toastService: ToastService
  ) {
    this.authService.generateKnowledgeBaseJWT().subscribe((results) => {
      if (results.data && results.valid && results.data[0].token) {
        window.location.href = 'https://nurtureboss.knowledgeowl.com/help/remote-auth?n=' + results.data[0].token;
      } else {
				this.toastService.showError('There was an error accessing the Knowledge Base');
      }
    }, (err) => {
      this.toastService.showError('There was an error accessing the Knowledge Base');
    });
  }
}
