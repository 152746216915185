import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { APIHelperService } from './apiHelper.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastService } from '@app/_services/toast.service';

var self;

@Injectable({ providedIn: 'root' })
export class MediaService {

  public currentMediaSubject: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  public currentMedia: Observable<Array<any>>;

  constructor(
      private http: HttpClient,
      private apiHelper: APIHelperService,
      private toastService: ToastService
  ) {
    self = this;
    this.currentMedia = this.currentMediaSubject.asObservable();
  }

  normalizeFileName(fileName: string): string {
    // extract file ext to add at the end
    const fileExt: RegExpMatchArray = fileName.split('.');
    // remove file extention from the name
    const newName: string = fileName.replace(/\.[0-9a-z]+$/gi, '');
    // remove all special characters, replace with _ and add extention back
    return newName.replace(/[^a-z0-9]/gi, '_') + `.${fileExt[fileExt.length - 1]}`;
  }

  getSignedRequest(file) {
    var queryParams = {
      'file-name': this.normalizeFileName(file.name),
      'file-type': file.type,
      'file-size': file.size
    };
    return this.http.get<any>(this.apiHelper.fillUrl('s3SignRequest', {}, queryParams)).
        pipe(map(data => {
          return data;
        }));
  }

  getAllMedia() {
    return this.http.get<any>(this.apiHelper.fillUrl('media', {}, {})).
        pipe(map(data => {
          this.currentMediaSubject.next(data.result);
          return data;
        }));
  }

  getById(id: string) {
    return this.http.get<any>(this.apiHelper.fillUrl('mediaById', { id }, {})).
      pipe(map(data => {
        return data;
      }));
  }

  deleteMedia(id) {
    return this.http.delete<any>(this.apiHelper.fillUrl('singleMedia', {id: id}, {})).
        pipe(map(data => {
          return data;
        }));
  }

  uploadFile(file, signedRequest, url, formModel, property) {
    return new Promise((resolve, reject) => {
      const testForFile = () => {
        setTimeout(() => {
          fetch(url)
            .then((response) => {
              if (!response.ok || response.status === 404) {
                return testForFile();
              }
              this.getAllMedia().toPromise();
              resolve(url);
            })
            .catch(() => testForFile());
        }, 1000);
      }

      const xhr = new XMLHttpRequest();
      xhr.open('PUT', signedRequest);
      xhr.onreadystatechange = () => {
        if(xhr.readyState === 4){
          if(xhr.status === 200){
            if (formModel) {
              formModel.value[property] = url;
            }
          } else {
            this.toastService.showError('Could not upload file.')
          }
        }
      };
      xhr.send(file)
      testForFile();
    });
  }

  /**
   * Uploads an file to the media manager.
   * 
   * @param fileItem - An array of files. The first file is uploaded.
   * @param formModel - The ng model to automatically update.
   * @param property - The property on the ng model to update
   * @returns The file url
   */
  getSignedRequestHelper(fileItem, formModel, property) {
    return new Promise((resolve, reject) => {
      if (fileItem) {
          self.getSignedRequest(fileItem[0]).subscribe((data) => {
              let url = data.result.finalUrl;
              if (property === 'bannerImage') {
                url = data.result.bannerUrl;
              } else if (property === 'leftLogo') {
                url = data.result.logoUrl;
              }
              self.uploadFile(fileItem[0], data.result.signedRequest, url, formModel, property).
                  then((data) => {
                    resolve(data);
                  }).
                  catch((err) => {
                    reject(err);
                  })
          });
      } else {
        resolve(null);
      }
    })
  };
}