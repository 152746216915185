<div id="template-picker">
  <app-sidebar-filter
    header="Template Gallery"
    subheader="Select a template to start"
    [initialFilter]="selectedFilter"
    [filters]="templateFilters"
    (selectionChanged)="onFilterSelected($event)"
    [useInverseColors]="!isLegacy"
  ></app-sidebar-filter>

  <main>
    <header>
      <h5>{{selectedFilter}}</h5>
      <div class="form-group">
        <button (click)="onSearchClick($event)"><i class="far fa-search"></i></button>
        <input #searchInput class="form-control" type="text" placeholder="Search" (input)="onSearchChange($event.target.value)"/>
      </div>
    </header>

    <app-template-list
      [templates]="filteredTemplates"
      [chooseImageSourceFunction]="chooseImageSourceFunction"
      (templateClick)="onTemplateClick($event)"
      (previewClick)="openTemplatePreview($event)"
      [isLegacy]="isLegacy"
    ></app-template-list>
  </main>
</div>

<nb-modal
  [name]="'preview-template-modal'"
  [header]="'Template Preview'"
  [confetti]="false"
  class="template-preview-modal-wrapper"
>
  <div>
    <app-iphone-preview
      [emailUrl]="emailPreviewUrl"
      [nurturePageUrl]="pagePreviewUrl"
      [forceDisplayType]="forcePreviewType"
      helperText="Loading..."
      errorText="Something went wrong when generating your preview!"
    ></app-iphone-preview>
  </div>
</nb-modal>