import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tips-and-tricks',
  templateUrl: './tips-and-tricks.component.html',
  styleUrls: ['./tips-and-tricks.component.less'],
})

export class TipsAndTricksComponent {
  @Input() links: string[];

  constructor() {}
}
