<div id="sidebar-filter" class="{{useInverseColors ? 'inverse-colors': ''}}">
  <div class="header">
    {{header}}
  </div>
  <div class="subheader margin-top-12" *ngIf="subheader">
    {{subheader}}
  </div>
  
  <ul class="filter-list margin-top-24">
    <hr/>
    <div>
      Filter
    </div>
    <hr/>
    <li *ngFor="let filter of filters" (click)="onClickFilter(filter)" [ngClass]="{'active': filter === selectedFilter}">
      <i class="far fa-tag"></i><span class="margin-left-12">{{filter}}</span>
    </li>
  </ul>
</div>