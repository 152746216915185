import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/_services';

@Component({
  templateUrl: 'dashboard.component.html',
  styleUrls: ['dashboard.component.less']
})

export class DashboardComponent implements OnInit {

  yardiActive = false;
  realPageActive = false;
  entrataActive = false;
  knockActive = false;
  resmanActive = false;
  userToken: string;
  claims: Array<string>;
  activeState: string = 'none';
  adjustedHeight: string;
  knowiApi = (window as any).Knowi;
  dashboardId: string;
  appBreadcrumbId: string | null;
  tourBreadcrumbId: string | null;
  tourDataId: string | null;
  applicationDataId: string | null;
  leaseDataId: string | null;


  constructor(
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.currentUser.subscribe((userStore) => {
      if (!userStore) {
        return;
      }
      this.claims = [...userStore.user.claims];
      this.userToken = userStore.token;
    });
    this.realPageActive = this.claims.includes('realpage');
    this.entrataActive = this.claims.includes('entrata');
    this.yardiActive = this.claims.includes('yardi');
    this.knockActive = this.claims.includes('knock');
    this.resmanActive = this.claims.includes('resman');
  }

  isIntegrated() {
    return !this.knockActive && (this.yardiActive || this.realPageActive || this.entrataActive || this.resmanActive);
  }

  isKnock() {
    return this.knockActive;
  }

  async loadScorecard() {
    const hash = await this.authenticationService.generateDashboardHash({
      token: this.userToken,
      dateFilter: 'filterdate',
    }).toPromise();
    this.knowiApi.render('#scorecard-loader', {
        type: 'secure', //share for dashboard, secure for secure hash dashboard, shareWidget for widget, shareWidgetSecure for secure hash widget, single for single sign-on, nlp for natural language query
        dashboard: this.dashboardId, //share URL of dashboard/widget
        // dashboardId: '', //ID of dashboard/widget
        hash: hash, //Encrypted hash from secure share dashboard/widget. Must be used with type: secure, shareWidgetSecure
        // token: '', //session token from Single Sign-On. Needed with type: single
        // renderType: '', //specifies how embed will be rendered. Options are object, iframe. Defaults to iframe
        url: "https://www.knowi.com/", //url for on-premise deployments. Defaults to www.knowi.com
        view: { //customizable options for the embedded display. 
          title: true,
          header: true,
          filter: true,
          setting: false,
          chat: false,
          dashFiltersBar: false,
        }
    }, function () {
      //no-op
    });
  }

  async loadTourData() {
    const hash = await this.authenticationService.generateDashboardHash({
      token: this.userToken,
      dateFilter: 'filterdate',
      lookback: 90,
    }).toPromise();
    this.knowiApi.render('#tourdata-loader', {
        type: 'secure', //share for dashboard, secure for secure hash dashboard, shareWidget for widget, shareWidgetSecure for secure hash widget, single for single sign-on, nlp for natural language query
        dashboard: this.tourDataId, //share URL of dashboard/widget
        // dashboardId: '', //ID of dashboard/widget
        hash: hash, //Encrypted hash from secure share dashboard/widget. Must be used with type: secure, shareWidgetSecure
        // token: '', //session token from Single Sign-On. Needed with type: single
        // renderType: '', //specifies how embed will be rendered. Options are object, iframe. Defaults to iframe
        url: "https://www.knowi.com/", //url for on-premise deployments. Defaults to www.knowi.com
        view: { //customizable options for the embedded display. 
          title: true,
          header: true,
          filter: true,
          setting: false,
          chat: false,
          dashFiltersBar: false,
        }
    }, function () {
      //no-op
    });
  }

  async loadApplicationData() {
    const hash = await this.authenticationService.generateDashboardHash({
      token: this.userToken,
      dateFilter: 'filterdate',
      lookback: 90,
    }).toPromise();
    this.knowiApi.render('#applicationdata-loader', {
        type: 'secure', //share for dashboard, secure for secure hash dashboard, shareWidget for widget, shareWidgetSecure for secure hash widget, single for single sign-on, nlp for natural language query
        dashboard: this.applicationDataId, //share URL of dashboard/widget
        // dashboardId: '', //ID of dashboard/widget
        hash: hash, //Encrypted hash from secure share dashboard/widget. Must be used with type: secure, shareWidgetSecure
        // token: '', //session token from Single Sign-On. Needed with type: single
        // renderType: '', //specifies how embed will be rendered. Options are object, iframe. Defaults to iframe
        url: "https://www.knowi.com/", //url for on-premise deployments. Defaults to www.knowi.com
        view: { //customizable options for the embedded display. 
          title: true,
          header: true,
          filter: true,
          setting: false,
          chat: false,
          dashFiltersBar: false,
          resize: false,
          autoHeight: false,
        }
    }, function () {
      //no-op
    });
  }

  async loadLeaseData() {
    const hash = await this.authenticationService.generateDashboardHash({
      token: this.userToken,
      dateFilter: 'filterdate',
      lookback: 90,
    }).toPromise();
    this.knowiApi.render('#leasedata-loader', {
        type: 'secure', //share for dashboard, secure for secure hash dashboard, shareWidget for widget, shareWidgetSecure for secure hash widget, single for single sign-on, nlp for natural language query
        dashboard: this.leaseDataId, //share URL of dashboard/widget
        // dashboardId: '', //ID of dashboard/widget
        hash: hash, //Encrypted hash from secure share dashboard/widget. Must be used with type: secure, shareWidgetSecure
        // token: '', //session token from Single Sign-On. Needed with type: single
        // renderType: '', //specifies how embed will be rendered. Options are object, iframe. Defaults to iframe
        url: "https://www.knowi.com/", //url for on-premise deployments. Defaults to www.knowi.com
        view: { //customizable options for the embedded display. 
          title: true,
          header: true,
          filter: true,
          setting: false,
          chat: false,
          dashFiltersBar: false,
          resize: false,
          autoHeight: false,
        }
    }, function () {
      //no-op
    });
  }

  async loadApplicationsConversions() {
    const hash = await this.authenticationService.generateDashboardHash({
      token: this.userToken,
      dateFilter: 'applicationdate',
    }).toPromise();
    this.knowiApi.render('#applications-loader', {
        type: 'secure', //share for dashboard, secure for secure hash dashboard, shareWidget for widget, shareWidgetSecure for secure hash widget, single for single sign-on, nlp for natural language query
        dashboard: this.appBreadcrumbId, //share URL of dashboard/widget
        // dashboardId: '', //ID of dashboard/widget
        hash: hash, //Encrypted hash from secure share dashboard/widget. Must be used with type: secure, shareWidgetSecure
        // token: '', //session token from Single Sign-On. Needed with type: single
        // renderType: '', //specifies how embed will be rendered. Options are object, iframe. Defaults to iframe
        url: "https://www.knowi.com/", //url for on-premise deployments. Defaults to www.knowi.com
        view: { //customizable options for the embedded display. 
          title: true,
          header: true,
          filter: true,
          setting: false,
          chat: false,
          dashFiltersBar: false,
        }
    }, function () {
      //no-op
    });
  }

  async loadToursConversions() {
    const hash = await this.authenticationService.generateDashboardHash({
      token: this.userToken,
      dateFilter: 'tourdate',
    }).toPromise();
    this.knowiApi.render('#tours-loader', {
        type: 'secure', //share for dashboard, secure for secure hash dashboard, shareWidget for widget, shareWidgetSecure for secure hash widget, single for single sign-on, nlp for natural language query
        dashboard: this.tourBreadcrumbId, //share URL of dashboard/widget
        // dashboardId: '', //ID of dashboard/widget
        hash: hash, //Encrypted hash from secure share dashboard/widget. Must be used with type: secure, shareWidgetSecure
        // token: '', //session token from Single Sign-On. Needed with type: single
        // renderType: '', //specifies how embed will be rendered. Options are object, iframe. Defaults to iframe
        url: "https://www.knowi.com/", //url for on-premise deployments. Defaults to www.knowi.com
        view: { //customizable options for the embedded display. 
          title: true,
          header: true,
          filter: true,
          setting: false,
          chat: false,
          dashFiltersBar: false,
        }
    }, function () {
      //no-op
    });
  }

  async loadDashboards() {
    this.loadScorecard();
    this.loadTourData();
    this.loadApplicationsConversions();
    this.loadToursConversions();
  }

  changeDashboard(name) {
    switch (name) {
      case 'application-conversions':
        this.activeState = name;
        this.loadApplicationsConversions();
        break;
      case 'tour-conversions':
        this.activeState = name;
        this.loadToursConversions();
        break;
      case 'tourdata':
        this.activeState = 'tourdata';
        this.loadTourData();
        break;
      case 'applicationdata':
        this.activeState = 'applicationdata';
        this.loadApplicationData();
        break;
      case 'leasedata':
        this.activeState = 'leasedata';
        this.loadLeaseData();
        break;
      case 'scorecard':
        default:
        this.activeState = 'scorecard';
        this.loadScorecard();
        break;
    }
  }

  ngOnInit(): void {
    if (this.isIntegrated()) {
      this.activeState = 'scorecard';
      this.dashboardId = 'sgs9pvdB33kquaXfxI0spqdXle5wpFipRY6rtIxtQaHEie';
      this.tourDataId = '0ipXHPDs6TZAILET2ipktLvRWSYF8XLff2seipF33JW8TUie';
      this.applicationDataId = 'QDFa16LAfWgPfZgczFVPXS5o96VZqKoxRI0rDWj73c4ie';
      this.leaseDataId = 'iirAK5OUA2SPEzg7EPFIa3EvRDMyMKzOvQpzEOTqtxs8ie';
      this.appBreadcrumbId = '547UTbHrg6UYiizgnkPYs9L5ql2Rhw4YayMmisisUvSlisUie';
      this.tourBreadcrumbId= 'hkyusrCUlMoskEv1Rd10riiEV7mZiivuKAMF5U17jjAiiAie';
    } else if (this.isKnock()) {
      this.activeState = 'scorecard';
      this.dashboardId = 'rVll6rkdH1ZZW4G6syKO8tBYsmJ3IHWlLRAt7SlSHVMie';
      this.tourDataId = 'zCos7tfupt4Mt3n6pKp1Ah6haipiiN45oW5y5u7qA6JOAie';
      this.applicationDataId = 'IqVjDGl0FTe92lm41Cy9s95yygDXbnvUip9LmtvsuMtgie';
      this.leaseDataId = 'sK97wQrDGTTzbx7SUNNaTXWFz5IZVCvUrWciil1gnSZgie';
      this.appBreadcrumbId = 'fVvpU6ZUJisbkBkCmAuZPbZ83LFZFtz5fqO8ECbDMGX4ie';
      this.tourBreadcrumbId= 'dOdmh0RmD859zs1lF4XxnUaVaNGz9DbMmTo677ipmblgie';
    } else if (!this.isIntegrated() && !this.isKnock()) {
      this.activeState = 'scorecard';
      this.dashboardId = 'DkNPOc1NszbxOFdFCvnsWeGN57iiDurcO7R5lROipdm1sie';
      this.tourDataId = null;
      this.applicationDataId = null;
      this.leaseDataId = null;
      this.appBreadcrumbId = null;
      this.tourBreadcrumbId= null;
    }
    if (this.claims.includes('admin')) {
      this.adjustedHeight = 'calc(100vh - 202px)';
    } else {
      this.adjustedHeight = 'calc(100vh - 118px)';
    }
    this.loadDashboards();
  }
}
  