import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { User } from '@nurtureboss/common/dist/types/users';

import { WizardService } from '@app/wizard/wizard.service';
import { AuthenticationService, LoaderService, TemplatesService } from '@app/_services';
import { BasicUploadedContact, BroadcastContext, BroadcastDeliveryMethod } from '../broadcast.types';
import { Contact } from '@nurtureboss/common/dist/types/contacts';
import { NurtureBossModalService } from '@app/_services/modal.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ITemplateField, TemplateMessagingTypes } from '@nurtureboss/common/dist/types/templates';
import { SheetJSComponent } from '@app/sheetjs';
import { ComponentInjectorDirective } from '@app/_helpers/component-injector.directive';
import { IStickAtTopOptions, StickyMethod } from '@app/_directives/stick-at-top.directive';
import { IPrimaryButtonOptions } from '@app/_components/nb-modal/nb-modal.component';

const CONTACT_SELECTION_MODAL = 'select-contacts-broadcast';
const ENTER_CONTACT_MODAL = 'enter-single-contact-broadcast';
const UPLOAD_CONTACT_MODAL = 'upload-contacts-broadcast';

enum RecipientSelection {
  Upload = 'upload',
  ExistingContacts = 'existing-contacts',
  Single = 'single',
}

@Component({
  selector: 'app-delivery-method',
  templateUrl: './delivery-method.component.html',
  styleUrls: ['./delivery-method.component.less'],
})
export class DeliveryMethodComponent implements OnInit, OnDestroy {

  @ViewChild(ComponentInjectorDirective, { static: false }) componentInjector!: ComponentInjectorDirective;
  @ViewChild('#emergency-checkbox', { static: false }) emergencyCheckbox!: HTMLInputElement;

  emergency = new FormControl(false);
  selectedDeliveryMethod: BroadcastDeliveryMethod | null = null;
  recipientSelection: RecipientSelection | null = null;
  currentUser: User;
  uploadTooltip = '';
  selectedContacts: Contact[] = [];
  singleContactForm = new FormGroup({});
  uploadedContacts: BasicUploadedContact[] = [];
  uploadFailureCount: number = 0;
  selectedTemplateName = '';
  templateValues: any = {};
  templateRequiredFields: ITemplateField[] = [];
  templateOptionalFields: ITemplateField[] = [];
  nextStepSubscription: Subscription;
  stepChangesSubscription: Subscription;

  supportsEmail = true;
  supportsText = true;
  supportsOptimize = true;

  optimizeTooltip = '';
  textTooltip = '';
  emailTooltip = '';
  stickyHeaderOptions: IStickAtTopOptions = {
    useParent: true,
    topThreshold: 0,
    applyStyles: {
      top: '0px',
    },
    method: StickyMethod.Sticky,
  };
  primaryButtonOptionsContactTable: IPrimaryButtonOptions;
  primaryButtonOptionsSingleContact: IPrimaryButtonOptions;
  singleContactFormSubscription: Subscription;

  constructor(
    private wizardService: WizardService<BroadcastContext>,
    private authService: AuthenticationService,
    private loaderService: LoaderService,
    private nbModalService: NurtureBossModalService,
    private templateDefaultsService: TemplatesService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.primaryButtonOptionsContactTable = {
      label: 'Select Contacts',
      disabled: true,
      action: this.buildOnContactsSelected(),
    }
    this.primaryButtonOptionsSingleContact = {
      label: 'Continue',
      disabled: true,
      action: this.buildOnContactEntered(),
    }
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue.user;
    this.stepChangesSubscription = this.wizardService.stepChanged$.subscribe((stepId) => {
      if (this.wizardService.isCurrentStepBefore('delivery')) {
        this.initializeStep();
      }
    });
    this.nextStepSubscription = this.wizardService.nextStep$.subscribe(({ context }) => {
      if (context.template) {
        // We need to know if we can even email or text
        this.supportsEmail = context.template.supportedMessaging.includes(TemplateMessagingTypes.Email);
        this.supportsText = context.template.supportedMessaging.includes(TemplateMessagingTypes.NurturePage);
        this.supportsOptimize = this.supportsEmail && this.supportsText;

        if (!this.supportsEmail) {
          this.emailTooltip = 'The template you have selected does not support email!';
          this.optimizeTooltip = 'The template you have selected only supports text/sms messaging!';
        }
        if (!this.supportsText) {
          this.textTooltip = 'The template you have selected does not support text/sms messaging!';
          this.optimizeTooltip = 'The template you have selected only supports email!';
        }
      }
    });

    this.emergency.valueChanges.subscribe((value) => {
      this.emergencyChange();
    })
  }

  initializeStep() {
    this.selectedDeliveryMethod = null;
    this.recipientSelection = null;
    this.uploadTooltip = '';
    this.selectedContacts = [];
    this.singleContactForm = new FormGroup({});
    this.singleContactFormSubscription?.unsubscribe();
    this.singleContactFormSubscription = this.singleContactForm.valueChanges.subscribe(
      () => this.primaryButtonOptionsSingleContact.disabled = this.singleContactForm.invalid
    );
    this.uploadedContacts = [];
    this.uploadFailureCount = 0;
    this.selectedTemplateName = '';
    this.templateValues = {};
    this.templateRequiredFields = [];
    this.templateOptionalFields = [];
  }

  ngOnDestroy() {
    this.nextStepSubscription?.unsubscribe();
    this.singleContactFormSubscription.unsubscribe();
    this.stepChangesSubscription?.unsubscribe();
  }

  initContactForm() {
    const context = this.wizardService.getContext();
    this.templateRequiredFields = this.templateDefaultsService.extractRequiredContactFields(context.template!);

    if (this.selectedDeliveryMethod === BroadcastDeliveryMethod.Text) {
      this.templateRequiredFields = this.templateRequiredFields.filter((field) => field.name !== 'clientEmailAddress')
    }
    if (this.selectedDeliveryMethod === BroadcastDeliveryMethod.Email) {
      this.templateRequiredFields = this.templateRequiredFields.filter((field) => field.name !== 'clientPhoneNumber')
    }

    this.templateOptionalFields = this.templateDefaultsService.extractOptionalContactFields(context.template!);

    for (const controlName in this.singleContactForm.controls) {
      this.singleContactForm.removeControl(controlName);
    }

    for (const requiredField of this.templateRequiredFields) {
      this.singleContactForm.addControl(requiredField.name, new FormControl('', [Validators.required]));
    }
    for (const optionalField of this.templateOptionalFields) {
      this.singleContactForm.addControl(optionalField.name, new FormControl(''));
    }
  }

  onDeliveryMethodClick(method: string) {
    this.recipientSelection = null;
    switch (method.toLowerCase()) {
      case 'optimize':
        if (!this.supportsOptimize) {
          return;
        }
        this.selectedDeliveryMethod = BroadcastDeliveryMethod.Optimize;
        this.uploadTooltip = 'Spreadsheet upload is not available when using "optimize" delivery option.';
        break;
      case 'text':
        if (!this.supportsText) {
          return;
        }
        this.selectedDeliveryMethod = BroadcastDeliveryMethod.Text;
        // Intentionally do not expose to Partners.
        if (!this.isAdmin()) {
          this.uploadTooltip = 'Spreadsheet upload is not available when sending text messages.';
        }
        break;
      case 'email':
        if (!this.supportsEmail) {
          return;
        }
        this.selectedDeliveryMethod = BroadcastDeliveryMethod.Email;
        this.uploadTooltip = '';
        break;
      default:
        this.selectedDeliveryMethod = null;
        this.uploadTooltip = '';
        break;
    }
  }

  canMoveNext() {
    if (!this.selectedDeliveryMethod || !this.recipientSelection) {
      return false;
    }

    if (this.recipientSelection === RecipientSelection.Single && this.singleContactForm.invalid) {
      return false;
    } else if (this.recipientSelection === RecipientSelection.ExistingContacts && !this.selectedContacts.length) {
      return false;
    } else if (this.recipientSelection === RecipientSelection.Upload && !this.uploadedContacts.length) {
      return false;
    }

    return true;
  }

  focusOnSingleContactForm() {
    setTimeout(() => {
      const elements = document.getElementsByName('pageName');
      if (elements.length) {
        const pageName = elements[0];
        pageName.focus();
      }
    }, 100);
  }

  openSingleContactModal() {
    if (!this.selectedDeliveryMethod || this.selectedDeliveryMethod === BroadcastDeliveryMethod.Optimize) {
      return;
    }

    if (this.recipientSelection === RecipientSelection.Single) {
      this.nbModalService.open(ENTER_CONTACT_MODAL);
      this.focusOnSingleContactForm();
      return;
    }

    this.recipientSelection = RecipientSelection.Single;
    this.resetContacts();
    this.initContactForm();
    this.nbModalService.open(ENTER_CONTACT_MODAL);
    this.focusOnSingleContactForm();
  }

  onContactEntered() {
    this.nbModalService.close(ENTER_CONTACT_MODAL);
  }

  buildOnContactEntered() {
    return () => {
      this.onContactEntered()
    };
  }

  resetContacts() {
    this.selectedContacts = [];
    this.uploadFailureCount = 0;
    this.uploadedContacts = [];
  }

  isAdmin() {
    return this.currentUser.claims.includes('admin');
  }

  openUploadModal(isText) {
    if (!this.selectedDeliveryMethod || (this.selectedDeliveryMethod === BroadcastDeliveryMethod.Text && !this.isAdmin())  || this.selectedDeliveryMethod === BroadcastDeliveryMethod.Optimize) {
      return;
    }

    if (this.recipientSelection === RecipientSelection.Upload) {
      this.nbModalService.open(UPLOAD_CONTACT_MODAL);
      return;
    }

    this.recipientSelection = RecipientSelection.Upload;
    // Required as the injector ditive is hidden behind the upload flag. We need to
    // force change detection so we can properly use the injector instance.
    this.changeDetector.detectChanges();
    // The SheetsJSComponent does some funky stuff to the global scope.
    // It's not so simple to just "refersh" so we need to instead fully
    // unload the component from the UI and reinject it dynamically,
    // hence the component injector directive.
    const createSheetComponent = (isText: boolean) => {
      this.resetContacts();
      const context = this.wizardService.getContext();
      this.selectedTemplateName = context.template!.templateName;
      this.templateValues = {};
      if (isText) {
        this.templateRequiredFields = this.templateDefaultsService.extractRequiredContactFields(context.template!).filter((field) => field.name !== 'clientEmailAddress');
      } else {
        // Exclude phone number on spreadsheet upload.
        this.templateRequiredFields = this.templateDefaultsService.extractRequiredContactFields(context.template!).filter((field) => field.name !== 'clientPhoneNumber');
      }
      this.templateOptionalFields = this.templateDefaultsService.extractOptionalContactFields(context.template!);

      const injectSub = this.componentInjector.injectComponent(SheetJSComponent).subscribe((sheetRef) => {
        injectSub.unsubscribe();
        sheetRef.instance.activePageType = this.selectedTemplateName;
        sheetRef.instance.userDefaults = this.templateValues;
        sheetRef.instance.requiredFields = this.templateRequiredFields.map(({name}) => name);
        sheetRef.instance.optionalFields = this.templateOptionalFields.map(({name}) => name);
        sheetRef.instance.localRefresh = true;
        sheetRef.instance.createPageHandler = this.buildCompleteUploadHandler();
        const sub = sheetRef.instance.onRefresh.subscribe(() => {
          sub.unsubscribe();
          createSheetComponent(isText);
        });
      })
    };
    createSheetComponent(isText);
    this.nbModalService.open(UPLOAD_CONTACT_MODAL);
  }

  buildCompleteUploadHandler() {
    return (uploadedData: BasicUploadedContact[], _sampleData: BasicUploadedContact, skipped: number) => {
      this.uploadedContacts = [...uploadedData];
      this.uploadFailureCount = skipped;
      this.nbModalService.close(UPLOAD_CONTACT_MODAL);
    };
  }

  openContactSelectionModal() {
    if (!this.selectedDeliveryMethod) {
      return;
    }

    this.recipientSelection = RecipientSelection.ExistingContacts;
    // this.loaderService.triggerLoader(true);
    this.nbModalService.open(CONTACT_SELECTION_MODAL);
    // this.loaderService.stopLoader();
  }

  onContactSelectionChange(selection: Contact[]) {
    this.selectedContacts = [...selection];
    this.primaryButtonOptionsContactTable.disabled = !(this.selectedContacts.length > 0);
  }

  buildOnContactsSelected() {
    return () => {
      this.nbModalService.close(CONTACT_SELECTION_MODAL);
    };
  }

  buildResetContacts() {
    return () => {
      this.resetContacts();
    };
  }

  onMoveNext(event: MouseEvent) {
    event.preventDefault();
    const currentContext = this.wizardService.getContext();

    // Transform our outputs to the proper format.
    if (this.recipientSelection === RecipientSelection.Single) {
      currentContext.delivery = {
        method: this.selectedDeliveryMethod,
        recipient: { ...this.singleContactForm.value },
        emergency: this.emergency.value,
      };
    } else if (this.recipientSelection === RecipientSelection.Upload) {
      currentContext.delivery = {
        method: this.selectedDeliveryMethod,
        recipient: {
          uploadedContacts: [...this.uploadedContacts],
        },
        emergency: this.emergency.value,
      };
    } else {
      currentContext.delivery = {
        method: this.selectedDeliveryMethod,
        recipient: {
          contacts: this.selectedContacts,
        },
        emergency: this.emergency.value,
      };
    }

    this.wizardService.completeStep(currentContext);
  }

  emergencyConfirmAction() {
    this.emergency.setValue(true, { emitEvent: false });
    this.nbModalService.close('confirm-emergency-message');
  }

  emergencyChange() {
    if (this.emergency.value) {
      this.emergency.setValue(false);
      this.nbModalService.open('confirm-emergency-message');
    }
  }
}
