import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { IComplianceSource, IContactSource } from './source-types';

@Component({
  selector: 'app-source-picker',
  templateUrl: './source-picker.component.html',
  styleUrls: ['./source-picker.component.less']
})
export class SourcePickerComponent implements OnChanges {

  @Input() storedSources: IComplianceSource[] = [];
  @Input() potentialSources: IContactSource[] = [];
  @Input() integrationType: string = 'your integration';
  @Input() allowCustomSources: boolean = false;
  @Output() complianceSourceUpdated = new EventEmitter<IComplianceSource & { index: number; }>();
  @Output() complianceSourceRemoved = new EventEmitter<IComplianceSource & { index: number; }>();

  constructor() {}

  ngOnChanges() {
    // Identify "corrupted" sources (e.g. sources
    // that cannot be found in the integration). Unless they have the ILM claim. Then sources are custom
    for (const source of this.storedSources) {
      if (!this.potentialSources.find(({id}) => id === source.id) && !this.allowCustomSources) {
        source.isCorrupted = true;
      }
    }
  }

  onRemoveSource(index: number) {
    const removedSource = this.storedSources[index];
    this.storedSources.splice(index, 1);
    this.complianceSourceRemoved.emit({...removedSource, index});
  }

  onSourceChanged(sourceId: string, index: number) {
    let newSourceInfo = this.potentialSources.find(({ id }) => id === sourceId);
    // for ILM integration
    if (!newSourceInfo) {
      newSourceInfo = {
        name: sourceId,
        id: sourceId,
      };
    }
    const storedSource = this.storedSources[index];
    storedSource.id = newSourceInfo.id;    
    storedSource.name = newSourceInfo.name;
    delete storedSource.isCorrupted;
    this.complianceSourceUpdated.emit({...storedSource, index});
  }

  onSourceDateChanged(newDate: string, index: number) {
    const storedSource = this.storedSources[index];
    storedSource.date = new Date(newDate);
    this.complianceSourceUpdated.emit({...storedSource, index});
  }

  addNewSource() {
    this.storedSources.push({
      name: '',
      id: '',
      isCorrupted: false,
    });
  }
}
