import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/_services';
import { User } from '@nurtureboss/common/dist/types/users';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.less']
})
export class ContactComponent implements OnInit {

  activeContactId: String;
  userData: User;
  adjustedHeight = 'calc(100vh - 105px)';

  constructor(
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private authService: AuthenticationService,
  ) {
    this.userData = this.authService.currentUserValue.user;
    this.activatedRoute.queryParams.subscribe(params => {
      this.activeContactId = params['id'];
    });
  }

  ngOnInit(): void {
    // We dynamically set height here to give CS team a good experience too :)
    if (this.userData.claims.includes('admin')) {
      this.adjustedHeight = 'calc(100vh - 182px)';
    } else {
      this.adjustedHeight = 'calc(100vh - 118px)';
    }
  }
  navigateTo(route) {
    this.router.navigate(['contact', route], {
      queryParams: {
        id: this.activeContactId
      },
      skipLocationChange: false,
    });
  }
}
