/**
 * Splits an array into multiple arrays.
 * 
 * @param chunkSize - The max length of each individual chunk array.
 * @param listOfThings - The original list of items to be chunked.
 * @returns - A list of lists with a max length matching the chunkSize.
 */
export const chunkArray = (chunkSize, listOfThings) => {
  const listOfChunks = [];
  const totalThings = listOfThings.length;
  let pointer = 0;
  while (pointer < totalThings) {
    const currentChunk = (pointer + chunkSize) > totalThings ? (totalThings - pointer) : chunkSize;
    const chunk = [];
    for (let i = pointer; i < pointer + currentChunk; i++) {
      chunk.push(listOfThings[i]);
    }
    listOfChunks.push(chunk);
    pointer += currentChunk;
  }
  return listOfChunks;
}

/**
 * Checks if key exists and removes it from an array of strings
 * @param originalArray - the initial array of strings
 * @param keysToRemove - an array of strings to remove
 * @returns - The new array of strings
 */
export const removeKeysFromStringArray = (originalArray: string[], keysToRemove: string[]): string[] => {
  return originalArray.filter((item: string) => {
    return !keysToRemove.includes(item);
  });
};

/**
 * Checks if doesn't exists and adds it to a string array
 * @param originalArray - the initial array of strings
 * @param keysToAdd - an array of strings to add
 * @returns - The new array of strings with the new values without new duplicates
 */
export const addKeysToStringArray = (originalArray: string[], keysToAdd: string[]): string[] => {
  return Array.from(new Set<string>([...originalArray, ...keysToAdd]).values());
};
