import { Component, ViewEncapsulation, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MediaService } from '@app/_services';
import { getFileIconClass, isFile } from '../../_utils/fileIcons';
import { NurtureBossModalService } from '@app/_services/modal.service';
import MODAL_NAMES from '@app/_components/nb-modal/modalTypes';
import { IPrimaryButtonOptions } from '@app/_components/nb-modal/nb-modal.component';
import { Subscription } from 'rxjs';

@Component({ 
  selector: 'app-media-modal', 
  templateUrl: 'media-modal.component.html', 
  styleUrls: ['media-modal.component.less'],
  encapsulation: ViewEncapsulation.None,
  providers: [NgbActiveModal],
})
export class MediaModalComponent implements OnInit, OnDestroy {

  @Output() imageSubmittedEvent = new EventEmitter<any>();
  media:Array<any> = [];
  loading:boolean = false;
  selectedImage: any;
  getFileIconClass = getFileIconClass;
  isFile = isFile;
  name = MODAL_NAMES.MEDIA_MANAGER;
  mediaModalPrimaryButton: IPrimaryButtonOptions;
  private mediaSub: Subscription;

  constructor(
    private mediaService: MediaService,
    private nbModalService: NurtureBossModalService,
  ) {
    this.mediaModalPrimaryButton = {
      label: 'Select Media',
      disabled: true,
      action: this.buildSubmitSelection(),
    };
  }

  ngOnInit(): void {
    this.init();
    this.mediaSub = this.mediaService.currentMediaSubject.subscribe((media) => {
      if (media.length > 0) {
        this.media = media;
      }
    });
  }

  async init() {
    this.loading = true;
    await this.mediaService.getAllMedia().toPromise()
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.mediaSub?.unsubscribe();
  }

  selectImage(image:any): void {
    this.mediaModalPrimaryButton.disabled = false;
    this.selectedImage = image;
  }

  buildSubmitSelection() {
    return () => {
      this.submitSelection();
    }
  }

  submitSelection(): void {
    this.imageSubmittedEvent.emit(this.selectedImage);
    this.mediaModalPrimaryButton.disabled = true;
    this.selectedImage = null;
    this.nbModalService.close(this.name);
  }

  buildMediaModalDismissed() {
    return (event) => {
      this.mediaModalDismissed(event);
    }
  }

  mediaModalDismissed(event): void {
    this.mediaModalPrimaryButton.disabled = true;
    this.selectedImage = null;
  }
}