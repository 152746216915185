import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

import { AnyKey } from '@nurtureboss/common/dist/types/utility';
import MODAL_NAMES from '@app/_components/nb-modal/modalTypes';

@Component({ 
  selector: 'app-contact-table-column-selector', 
  templateUrl: 'contact-table-column-selector.component.html', 
  styleUrls: ['contact-table-column-selector.component.less'],
  encapsulation: ViewEncapsulation.None,
  providers: [NgbActiveModal],
})
export class ContactTableColumnSelectorComponent {
  @Input() columns: AnyKey;
  @Output() toggleColumn: EventEmitter<any> = new EventEmitter();

  name: string = MODAL_NAMES.CONTACT_TABLE_COLUMN_SELECTOR;

  constructor() {
    // no-op
  }

  toggleCheckbox(val) {
    this.toggleColumn.emit({ ...this.columns, [val]: !this.columns[val] });
  }

  // TODO: make this not required
  contactTableColumnSelectorDismissedBuilder(): (event: Event) => void {
    return () => {};
  }
}