// Angular:
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// Libs:
import { forkJoin } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// Services:
import {
  UsersService,
  AuthenticationService,
  ToastService,
  LoaderService,
  BrandingsService,
  ICurrentBranding,
  RealPageService,
  UserAuditsService,
  SettingsService,
  SettingType,
  AutomationSettingsService,
} from '@app/_services';
import { YardiService } from '@app/_services/yardi.service';
import { WidgetService } from '@app/_services/widget.service';
import { IntegrationService } from '@app/_services/integration.service';
import { EntrataService } from '@app/_services/entrata.service';
import { NurtureBossModalService } from '@app/_services/modal.service';
// Consts:
import MODAL_NAMES from '@app/_components/nb-modal/modalTypes';
import {
  INTEGRATION_TYPES,
  TOUR_SCHEDULING_ENABLED_INTEGRATIONS,
  WIDGET_BASE_URL,
  INTEGRATIONS_SERVICES,
  INTEGRATION_SERVICE_TYPES,
  INTEGRATION_DESCRIPTIONS,
  RESIDENT_SOURCE_ENABLED_INTEGRATIONS,
  PROSPECT_SOURCE_ENABLED_INTEGRATIONS,
  AVAILABILITY_SOURCE_ENABLED_INTEGRATIONS,
  LEDGER_BALANCE_SOURCE_ENABLED_INTEGRATIONS,
  DISPLAY_NAME_INTEGRATION_SERVICE,
  DISPLAY_NAME_INTEGRATION
} from '@app/_consts/integration.consts';
// Common:
import { FIELD_AUTO_GENERATORS } from '@nurtureboss/common/dist/utils/template';
import { WidgetScreenPositionX, WidgetScreenPositionY } from '@nurtureboss/common/dist/types/brandings';
import { User } from '@nurtureboss/common/dist/types/users';
// Types:
import {
  IAgent,
  IEventResults,
  IEventTypes,
  IILSConfig,
  IIntegrationDetails,
  IProperty,
  ISource,
  ILostReason,
  RentgrataListing,
  IntegrationPayload,
  IResidentConfig,
  ILeaseRenewalConfig,
} from '@app/_types/integration.types';
import { IntegrationCard } from '@app/settings/integrations/integration-card/integration-card.component';
// Directives
import { RentCafeValidator } from '@app/_directives/rentCafe-validator.directive';
import { ResmanService } from '@app/_services/resman.service';

export interface RentgrataSuggestionListing extends RentgrataListing {
  display?: string;
}

export interface IntegrationServiceConflict {
  currentService: string;
  currentIntegration: string;
  newIntegration: string;
}

const MODAL_OPTIONS = { windowClass : 'custom-modal-styles-new'};
const CUSTOM_MODAL_STYLE = 'custom-modal-styles-new enable-integration-modal';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.less']
})
export class IntegrationsComponent implements OnInit {
  integrationsLoadError: Boolean = false;
  userData: Partial<User> = {};
  savingWidgetSettings = false;
  savingTourSchedulingSettings = false;
  selectedIntegrationType: string;
  validatingIntegration = false;
  sources: Array<ISource> = [];
  agents: Array<IAgent> = [];
  lostReasons: Array<ILostReason> = [];
  eventTypes: Array<IEventTypes> = [];
  eventResults: Array<IEventResults> = [];
  integrationToCreateForm: FormGroup;
  integrationForm: FormGroup;
  widgetScript: string;
  widgetFeaturesForm = new FormGroup({
    'nbw-lead-capture': new FormControl(false),
    'nbw-schedule-a-tour': new FormControl(false),
    bot: new FormControl(false),
  });
  showTourFeatures = false;
  tourAvailabilityForm = new FormGroup({
    'same-day-tours': new FormControl(false),
    'black-out-dates': new FormControl(null),
    'black-out-days': new FormControl(null),
    'tour-interval': new FormControl(null),
  });
  daysOfTheWeek = [
    { label: 'Monday', value: 'monday' },
    { label: 'Tuesday', value: 'tuesday' },
    { label: 'Wednesday', value: 'wednesday' },
    { label: 'Thursday', value: 'thursday' },
    { label: 'Friday', value: 'friday' },
    { label: 'Saturday', value: 'saturday' },
    { label: 'Sunday', value: 'sunday' },
  ];
  tourIntervals = [
    { label: '30', value: 30 },
    { label: '60', value: 60 },
    { label: '90', value: 90 },
    { label: '120', value: 120 },
  ]
  brandings: ICurrentBranding = {
    _id: '',
    fontFamily: 'Alegreya Sans',
    primaryColor: '#235587',
    widget: {
      iconMediaId: null,
      screenPositionX: WidgetScreenPositionX.Right,
      screenPositionY: WidgetScreenPositionY.Bottom,
      xOffset: null,
      yOffset: null,
    }
  };
  brandingWidgetUrl = '';
  integrationConfiguration: IILSConfig;
  residentConfig: IResidentConfig;
  leaseRenewalConfig: ILeaseRenewalConfig;
  filterTerm: string | null;
  allowBuildAutomations = false;
  scheduleATourUrl = '';
  botUrl = '';
  rentgrataListings: RentgrataListing[] = [];
  rentgrataSuggestionsListings: RentgrataSuggestionListing[] = [];
  selectedRentgrataListing: RentgrataListing = null;
  integrationProperty: IProperty;
  integrationDetails: IIntegrationDetails;
  propertyInfo: IProperty;
  integrationCards: IntegrationCard[] = [];
  displayNameIntegrationService = DISPLAY_NAME_INTEGRATION_SERVICE
  displayNameIntegration = DISPLAY_NAME_INTEGRATION
  // Integrations Services Conflict
  missingIntegrationsConfig: string[] = [];
  missingIntegrationService: string[] = [];
  integrationsServicesConflict: IntegrationServiceConflict = null;
  availableAutomations: string[] = [];

  // TODO: Type these.
  localUserData: any;
  knockCommunities: any = null;
  knockAgents: any = null;
  entrataProperties: any = null;
  missingDefaults: string[] = [];

  // Add consts to have access in html file
  getTourSchedulingIntegrations() {
    return TOUR_SCHEDULING_ENABLED_INTEGRATIONS;
  }
  getProspectSourceIntegrations() {
    return PROSPECT_SOURCE_ENABLED_INTEGRATIONS;
  }
  getResidentSourceIntegrations() {
    return RESIDENT_SOURCE_ENABLED_INTEGRATIONS;
  }
  getAvailabilitySourceIntegrations() {
    return AVAILABILITY_SOURCE_ENABLED_INTEGRATIONS;
  }
  getLedgerBalanceSourceIntegrations() {
    return LEDGER_BALANCE_SOURCE_ENABLED_INTEGRATIONS;
  }

  constructor(
    private userService: UsersService,
    private authService: AuthenticationService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private yardiService: YardiService,
    private entrataService: EntrataService,
    private widgetService: WidgetService,
    private brandingsService: BrandingsService,
    private integrationService: IntegrationService,
    private modalService: NgbModal,
    private nbModalService: NurtureBossModalService,
    private realPageService: RealPageService,
    private rentCafeValidator: RentCafeValidator,
    private userAuditsService: UserAuditsService,
    private resmanService: ResmanService,
    private settingsService: SettingsService,
    private automationSettingsService: AutomationSettingsService,
  ) {
    this.enableNewIntegration = this.enableNewIntegration.bind(this);
    this.createIntegration = this.createIntegration.bind(this);
    this.configureIntegration = this.configureIntegration.bind(this);
  }

  ngOnInit() {
    this.localUserData = this.authService.currentUserValue;
    this.loadData();
  }

  loadData() {
    this.loaderService.triggerLoader();
    forkJoin([

      // Get fresh user data.
      this.userService.getUserData(this.localUserData.user._id), //[0]

      // Generate widget token for UI
      this.widgetService.generateWidgetToken(), // [1]

      // Load user brandings for widget.
      this.brandingsService.getBrandings(), // [2]

      // Load global defaults to see if we can auto-build automations.
      this.userService.getGlobalDefaults(), // [3]

      // Get tour schedule settings [4]
      this.settingsService.getSettingsByType(SettingType.TourScheduling)

    // TODO: Type these outputs.
    ]).subscribe((output: [any, any, any, any, any]) => {
      this.userData = output[0].result;
      this.showTourFeatures = this.userData.widgetFeatures.includes('nbw-schedule-a-tour')
      this.updateTourScheduleFeaturesForm(output[4].result);

      // Setup UI for Widget Customization
      this.updateWidgetFeaturesForm();
      const widgetData = output[1].result;
      const { token } = widgetData;
      if (token) {
        this.widgetScript = this.buildWidgetScript(token);
      }

      // Get property info
      this.integrationService.getPropertyInfo(this.userData.integrationPropertyId).subscribe((propertyInfo) => {
        this.propertyInfo = propertyInfo;
        this.loadAvailableAutomations(propertyInfo);
        this.integrationCards = Object.entries(INTEGRATION_DESCRIPTIONS).map(integration => ({
          title: integration[0],
          description: integration[1],
          type: integration[0]?.toLowerCase(),
          img: `/assets/${ integration[0]?.toLowerCase() }-logo.png`,
          enableNewIntegration: this.enableNewIntegration,
          createIntegration: this.createIntegration,
          configureIntegration: this.configureIntegration,
        }))
        this.calcMissingIntegrationsConfig(this.integrationService, this.propertyInfo, this.userData);
      });

      this.brandings = output[2].result;

      // Setup UI for Schedule Tour URL
      this.scheduleATourUrl =  this.generateScheduleUrl({
        isEnabledScheduleATour: this.localUserData.user.widgetFeatures.includes('nbw-schedule-a-tour'),
        isEnabledBuildTourUrl: FIELD_AUTO_GENERATORS.scheduleATourUrl.isAvailableToUser(this.localUserData.user)
      });

      // Setup UI for Bot URL
      this.botUrl =  this.generateBotUrl(this.hasWidgetPermissions() && this.hasBotPermissions());

      if ((this.userHasIntegration() || this.userHasCrmIntegration()) && this.userHasAutomationRequiredDefaults(output[3].result)) {
        this.allowBuildAutomations = true;
      }

      this.loaderService.stopLoader();
    }, (err) => {
      this.toastService.showError('There was an error loading the property\'s integration information. Please refresh the page and try again.');
      this.integrationsLoadError = true;
      this.loaderService.stopLoader();
    });
  }

  /**
   * Generate URL used to send user to NB powered tour scheduling
   *
   * @param param0 (Object) Takes 2 arguments, 1 checks widget feature on user, other check Common for needed URL params
   * @returns string URL used for scheduling a tour.
   */
  generateScheduleUrl({ isEnabledScheduleATour, isEnabledBuildTourUrl }) {
    if (isEnabledScheduleATour && isEnabledBuildTourUrl) {
      this.showTourFeatures = true;
      return FIELD_AUTO_GENERATORS.scheduleATourUrl.valueGenerator({ token: this.widgetService.accessToken }, {});
    }
    this.showTourFeatures = false;
    return '';
  }

  /**
   * Generate URL used to send user to NB powered bot
   *
   * @param param0 (Object) Takes 2 arguments, 1 checks widget feature on user, other check Common for needed URL params
   * @returns string URL used for scheduling a tour.
   */
    generateBotUrl(canEnable: boolean) {
      if (canEnable) {

        // We do not use FIELD_AUTO_GENERATORS here b/c we will never insert this URL into a default.
        return `http://apt-living.co/bot-chat?token=${this.widgetService.accessToken}`;
      }
      return '';
    }

  /**
   * Method to generate a script for the user to put the NB widget on their website.
   *
   * @param token string JWT that should be used in widget script
   * @returns string Script that can be copy/pasted by a user on their website
   */
  buildWidgetScript(token): string {

    // script needs to defer to cover the use case where the client pastes the widget above the body
    return `<script defer src="${WIDGET_BASE_URL}" id="nb-widget-script" data-token="${token}" data-defaultopen="false"></script>`;
  }

  /**
   * Logic used to tie UI components to form controls used for updating user settings/permissions.
   */
  updateWidgetFeaturesForm(): void {
    const { widgetFeatures } = this.userData;
    for (const feature of widgetFeatures) {
      if (this.widgetFeaturesForm.controls[feature]) {
        this.widgetFeaturesForm.controls[feature].setValue(true);
      }
    }
  }

  /**
   * Set the form values for tour scheduling features.
   * @param formValues 
   */
  updateTourScheduleFeaturesForm(tourSettings: { sameDay?: boolean, blackOutDates?: Date[], blackOutDays?: string[], tourInterval?: number}): void {
    if (tourSettings.sameDay) {
      this.tourAvailabilityForm.controls['same-day-tours'].setValue(tourSettings.sameDay);
    }
    if (tourSettings.blackOutDays  && tourSettings.blackOutDays.length) {
      this.tourAvailabilityForm.controls['black-out-days'].setValue(tourSettings.blackOutDays);
    }
    if (tourSettings.tourInterval) {
      this.tourAvailabilityForm.controls['tour-interval'].setValue(tourSettings.tourInterval);
    }
    if (tourSettings.blackOutDates && tourSettings.blackOutDates.length) {
      this.tourAvailabilityForm.controls['black-out-dates'].setValue(tourSettings.blackOutDates.map(stringDate => new Date(stringDate)));
    };
  }

  /**
   * Save tour scheduling settings
   */
  async updateTourScheduleSettings(): Promise<void> {
    this.savingTourSchedulingSettings = true;
    const settingsToSave = {
      sameDay: this.tourAvailabilityForm.value['same-day-tours'],
      blackOutDates: this.tourAvailabilityForm.value['black-out-dates'],
      blackOutDays: this.tourAvailabilityForm.value['black-out-days'],
      tourInterval: this.tourAvailabilityForm.value['tour-interval']
    }
    try {
      await this.settingsService.saveSettingsByType(SettingType.TourScheduling, settingsToSave).toPromise();
      this.toastService.showSuccess('Tour scheduling settings saved successfully.');
    } catch (e) {
      this.toastService.showError('There was an error saving tour scheduling settings. Please try again.');
    }
    this.savingTourSchedulingSettings = false;
  }

  /**
   * Get automations available to the user
   */
  loadAvailableAutomations(integrationProperty): void {
    const availableAutomations = [];
    if (integrationProperty.prospectSource || integrationProperty.residentSource) {
      availableAutomations.push('text-opt-in');
    }
    if (integrationProperty.prospectSource) {
      availableAutomations.push('pre-tour', 'post-tour', 'interest-list', 'lost-lead', 'appointment-reminder');
    }
    if (integrationProperty.residentSource) {
      availableAutomations.push('rent-reminder', 'resident-outreach', 'lease-renewal', 'delinquency');
    } 

    this.availableAutomations = availableAutomations;
  }

  /**
   *
   * @param type (string) Type of integration we are enabling.
   * @param modal (Modal) The modal we want to open for integration enablement.
   */
  enableNewIntegration(type, modal) {
    this.selectedIntegrationType = type;

    // Build form used for data capture when configuration is selected.
    if (type === INTEGRATION_TYPES.Yardi) {
      this.integrationToCreateForm = new FormGroup({
        wsdlUrl: new FormControl('', [Validators.required]),
        serverName: new FormControl('', [Validators.required]),
        databaseName: new FormControl('', [Validators.required]),
        username: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
        propertyId: new FormControl('', [Validators.required]),
        hasResidentData: new FormControl(false),
      });
    } else if (type === INTEGRATION_TYPES.Entrata) {
      this.integrationToCreateForm = new FormGroup({
        url: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
        username: new FormControl('', [Validators.required]),
        propertyId: new FormControl('', [Validators.required]),
        hasResidentData: new FormControl(false),
      });

    } else if (type === INTEGRATION_TYPES.RealPage) {
      this.integrationToCreateForm = new FormGroup({
        pmcId: new FormControl('', [Validators.required]),
        siteId: new FormControl('', [Validators.required]),
      });
    } else if (type === INTEGRATION_TYPES.Resman) {
      this.integrationToCreateForm = new FormGroup({
        accountId: new FormControl('', [Validators.required]),
        propertyId: new FormControl('', [Validators.required]),
      });
    } else if (type === INTEGRATION_TYPES.RentCafe) {
      // no-op, instead we just straight to createIntegration here when the enable button is clicked.
    } else if (type === INTEGRATION_TYPES.Knock) {
      // no-op, instead we just straight to createIntegration here when the enable button is clicked.
    } else if (type === INTEGRATION_TYPES.RentDynamics) {
      // no-op, instead we just straight to createIntegration here when the enable button is clicked.
    } else if (type === INTEGRATION_TYPES.Rentgrata) {
      // no-op, instead we just straight to createIntegration here when the enable button is clicked.
    }
    this.modalService.open(modal, { windowClass : CUSTOM_MODAL_STYLE});
  }

  /**
   * Logic ran to validate a new integration that is being enabled.
   *
   * @param e (Event) DOM event passed in to stop propogation and prevent default.
   * @param type (string) Type of integration we are enabling.
   * @param modal (Modal) Modal we are passing in to dismiss when validataed.
   */
  async createIntegration(e, type, _modal?) {
    e.preventDefault();
    e.stopPropagation();

    try {
      this.validatingIntegration = true;
      let res = null;

      switch(type) {
        // TODO: Type result responses.
        case INTEGRATION_TYPES.Yardi:
          res = await this.yardiService.verifyAndCreateYardiIntegration(this.integrationToCreateForm.value).toPromise();
          break;
        case INTEGRATION_TYPES.Entrata:
          res = await this.entrataService.verifyAndCreateEntrataIntegration(this.integrationToCreateForm.value).toPromise();
          break;
        case INTEGRATION_TYPES.RealPage:
          res = await this.realPageService.verifyAndCreateRealPageIntegration(this.integrationToCreateForm.value).toPromise();
          break;
        case INTEGRATION_TYPES.Resman:
          res = await this.resmanService.verifyAndCreateResmanIntegration(this.integrationToCreateForm.value).toPromise();
          break;
        case INTEGRATION_TYPES.RentCafe:
          res = await this.integrationService.createRentCafeIntegration().toPromise();
          break;
        case INTEGRATION_TYPES.RentDynamics:
          res = await this.integrationService.createRentDynamicsIntegration().toPromise();
          break;
        case INTEGRATION_TYPES.Knock:
          res = await this.integrationService.createKnockIntegration().toPromise();
          break;
        case INTEGRATION_TYPES.Rentgrata:
          res = await this.integrationService.createRentgrataIntegration().toPromise();
          break;
        case INTEGRATION_TYPES.ACE:
          res = await this.integrationService.createACEIntegration().toPromise();
        default:
          // no-op
      }

      if (res.result && res.result.valid !== false) {
        this.toastService.showSuccess('Integration is valid!');
        // Mimic your own account so your new claims and user data is updated in the JWT and local storage.
        await this.authService.mimic(res.result._id).toPromise();
        document.location.reload();
      } else {
        this.toastService.showError('Integration is not valid!');
      }
    } catch (err) {
      this.toastService.showError('Integration is not valid!');
    }
    this.validatingIntegration = false;
  }

  /**
   * Used to exit integration configuration and enablement modals.
   *
   * @param e (Event) DOM event used to prevent default acitons.
   * @param modal (Modal) Modal that you are dismissing.
   */
  exitModal(e, modal) {
    e.preventDefault();
    modal.dismiss();
  }

  /**
   * Load data and open modal for integration configuration. This is
   * an improvement from loading all data on page load before.
   *
   * @param type {string} type of integration
   * @param modal {NbModal} modal to open
   */
  async configureIntegration(type, modal) {
    this.loaderService.triggerLoader();
    this.selectedIntegrationType = type;
    const integrationServices: {
      tourScheduling?: FormControl,
      prospectSource?: FormControl,
      residentSource?: FormControl,
      availabilitySource?: FormControl,
      ledgerBalanceSource?: FormControl,
    } = {};

    if (TOUR_SCHEDULING_ENABLED_INTEGRATIONS.includes(type)) {
      integrationServices.tourScheduling = new FormControl(this.propertyInfo?.tourScheduling === type);
    }

    if (AVAILABILITY_SOURCE_ENABLED_INTEGRATIONS.includes(type)) {
      integrationServices.availabilitySource = new FormControl(this.propertyInfo?.availabilitySource === type);
    }

    if (LEDGER_BALANCE_SOURCE_ENABLED_INTEGRATIONS.includes(type)) {
      integrationServices.ledgerBalanceSource = new FormControl(this.propertyInfo?.ledgerBalanceSource === type);
    }

    if (RESIDENT_SOURCE_ENABLED_INTEGRATIONS.includes(type)) {
      integrationServices.residentSource = new FormControl(this.propertyInfo?.residentSource === type);
    }

    if (PROSPECT_SOURCE_ENABLED_INTEGRATIONS.includes(type)) {
      integrationServices.prospectSource = new FormControl(this.propertyInfo?.prospectSource === type);
    }

    if (type === INTEGRATION_TYPES.Yardi) {
      try {
        // Load data needed to configure Yardi integration.
        const integrationDetails: IIntegrationDetails = await this.integrationService.getYardiIntegrationDetails(this.userData.integrationPropertyId).toPromise();

        const defaultAgent = this.propertyInfo?.yardi?.defaultAgent?.toString() || '';
        this.integrationForm = new FormGroup({
          defaultAgent: new FormControl(defaultAgent, [Validators.required]),
          defaultSource: new FormControl(this.propertyInfo?.yardi?.defaultSource, [Validators.required]),
          pmsCustomSyncFrequency: new FormControl(this.userData.pmsCustomSyncFrequency, [Validators.required]),
          textEventId: new FormControl(this.propertyInfo?.yardi?.textEventId, []),
          emailEventId: new FormControl(this.propertyInfo?.yardi?.emailEventId, []),
          defaultUnitId: new FormControl(this.propertyInfo?.yardi?.defaultUnitId || ''),
          ...integrationServices,
        });
        this.sources = integrationDetails.sources.sort((x, y) => x.name.localeCompare(y.name));
        this.agents = integrationDetails.agents.sort((x, y) => x.name.localeCompare(y.name));
        this.integrationConfiguration = integrationDetails.ILSConfig;
        this.residentConfig = integrationDetails.residentPermissions;
        this.leaseRenewalConfig = integrationDetails.leaseRenewalPermissions;
        this.loaderService.stopLoader();
        this.modalService.open(modal, { windowClass : CUSTOM_MODAL_STYLE});
      } catch (e) {
        // no-op
      }
    } else if (type === INTEGRATION_TYPES.RealPage) {
      try {
        // Load data needed to configure RealPage integration.
        const integrationDetails: IIntegrationDetails = await this.integrationService.getRealPageIntegrationDetails(this.userData.integrationPropertyId).toPromise();

        // Need agent ID to be a string for UI to work.
        this.integrationForm = new FormGroup({
          defaultAgentId: new FormControl(this.propertyInfo?.realPage?.defaultAgentId?.toString(), [Validators.required]),
          defaultSourceId: new FormControl(this.propertyInfo?.realPage?.defaultSourceId, [Validators.required]),
          pmsCustomSyncFrequency: new FormControl(this.userData.pmsCustomSyncFrequency, [Validators.required]),
          ...integrationServices,
        });
        this.sources = integrationDetails.sources.sort((x, y) => x.name.localeCompare(y.name));
        this.agents = integrationDetails.agents.sort((x, y) => x.name.localeCompare(y.name));
        this.loaderService.stopLoader();
        this.modalService.open(modal, { windowClass : CUSTOM_MODAL_STYLE});
      } catch (e) {
        // no-op
      }
    } else if (type === INTEGRATION_TYPES.Resman) {
      try {

        // Load data needed to configure Entrata integration.
        const integrationDetails: IIntegrationDetails = await this.integrationService.getResmanIntegrationDetails(this.userData.integrationPropertyId).toPromise();
        this.integrationForm = new FormGroup({
          defaultAgentId: new FormControl(this.propertyInfo?.resman?.defaultAgentId, [Validators.required]),
          defaultSourceId: new FormControl(this.propertyInfo?.resman?.defaultSourceId, [Validators.required]),
          defaultLostReasonId: new FormControl(this.propertyInfo?.resman?.defaultLostReasonId, [Validators.required]),
          pmsCustomSyncFrequency: new FormControl(this.userData.pmsCustomSyncFrequency, [Validators.required]),
          ...integrationServices,
        });
        this.sources = integrationDetails.sources.sort((x, y) => x.name.localeCompare(y.name));
        this.agents = integrationDetails.agents.sort((x, y) => x.name.localeCompare(y.name));
        this.lostReasons = integrationDetails.lostReasons.sort((x, y) => x.name.localeCompare(y.name));
        this.loaderService.stopLoader();
        this.modalService.open(modal, { windowClass : CUSTOM_MODAL_STYLE});
      } catch (e) {
        // no-op
      }
    } else if (type === INTEGRATION_TYPES.Entrata) {
      try {

        // Load data needed to configure Entrata integration.
        const integrationDetails: IIntegrationDetails = await this.integrationService.getEntrataIntegrationDetails(this.userData.integrationPropertyId).toPromise();

        this.integrationForm = new FormGroup({
          defaultAgentId: new FormControl(this.propertyInfo?.entrata?.defaultAgentId, [Validators.required]),
          defaultSourceId: new FormControl(this.propertyInfo?.entrata?.defaultSourceId, [Validators.required]),
          pmsCustomSyncFrequency: new FormControl(this.userData.pmsCustomSyncFrequency, [Validators.required]),
          textEventId: new FormControl(this.propertyInfo?.entrata?.textEventId, [Validators.required]),
          inboundEmailEventId: new FormControl(this.propertyInfo?.entrata?.inboundEmailEventId, [Validators.required]),
          inboundTextEventId: new FormControl(this.propertyInfo?.entrata?.inboundTextEventId, [Validators.required]),
          inboundCallEventId: new FormControl(this.propertyInfo?.entrata?.inboundCallEventId, [Validators.required]),
          textEventResultId: new FormControl(this.propertyInfo?.entrata?.textEventResultId, [Validators.required]),
          inboundTextEventResultId: new FormControl(this.propertyInfo?.entrata?.inboundTextEventResultId, [Validators.required]),
          emailEventId: new FormControl(this.propertyInfo?.entrata?.emailEventId, [Validators.required]),
          ...integrationServices,
        });
        this.sources = integrationDetails.sources.sort((x, y) => x.name.localeCompare(y.name));
        this.agents = integrationDetails.agents.sort((x, y) => x.name.localeCompare(y.name));
        this.eventTypes = integrationDetails.eventTypeLists?.eventTypes.sort((x, y) => x.name.localeCompare(y.name));
        this.eventResults = integrationDetails.eventTypeLists?.eventResults.sort((x, y) => x.name.localeCompare(y.name));
        this.loaderService.stopLoader();
        this.modalService.open(modal, { windowClass : CUSTOM_MODAL_STYLE});
      } catch (e) {
        // no-op
      }
    } else if (type === INTEGRATION_TYPES.RentCafe) {
      try {
        this.integrationForm = new FormGroup({
          companyCode: new FormControl(this.propertyInfo?.rentCafe?.companyCode, [Validators.required]),
          propertyId: new FormControl(this.propertyInfo?.rentCafe?.propertyId),
          propertyCode: new FormControl(this.propertyInfo?.rentCafe?.propertyCode),
          apiKey: new FormControl(this.propertyInfo?.rentCafe?.apiKey, [Validators.required]),
          ...integrationServices,
        },
        {
          validators: [this.rentCafeValidator.validateRentCafeForm()],
          updateOn: 'change',
        }
        );
        this.loaderService.stopLoader();
        this.modalService.open(modal, { windowClass : CUSTOM_MODAL_STYLE});
      } catch (e) {
        // no-op
      }
    } else if (type === INTEGRATION_TYPES.Knock) {
      try {
        this.integrationForm = new FormGroup({
          agentId: new FormControl(this.propertyInfo?.knock?.agentId, [Validators.required]),
          communityId: new FormControl(this.propertyInfo?.knock?.communityId, [Validators.required]),
          ...integrationServices,
        });
        this.loaderService.stopLoader();
        this.modalService.open(modal, { windowClass : CUSTOM_MODAL_STYLE});
      } catch (e) {
        // no-op
      }
    } else if (type === INTEGRATION_TYPES.RentDynamics) {
      try {
        this.integrationForm = new FormGroup({
          communityId: new FormControl(this.propertyInfo?.rentDynamics?.communityId, [Validators.required]),
          communityGroupId: new FormControl(this.propertyInfo?.rentDynamics?.communityGroupId, [Validators.required]),
          adSourceId: new FormControl(this.propertyInfo?.rentDynamics?.adSourceId, [Validators.required]),
          ...integrationServices,
        });
        this.loaderService.stopLoader();
        this.modalService.open(modal, { windowClass : CUSTOM_MODAL_STYLE});
      } catch (e) {
        // no-op
      }
    } else if (type === INTEGRATION_TYPES.Rentgrata) {
      try {
        // Load admin tools for seeing all available listings.
        // Knock and Entrata have similar functionality but it's done in modals,
        // consider moving this to that pattern.
        if (this.isAdmin() || this.isPartner()) {
          let listings;
          try {
            listings = await this.integrationService.getRentgrataListings().toPromise();
            this.rentgrataListings = listings;
            this.rentgrataSuggestionsListings = listings.map((listing) => this.formatListingDisplay(listing));
            const findCurrentListing = this.rentgrataSuggestionsListings.find((listing) => listing?.listing_id === this.propertyInfo?.rentgrata?.listingId);
            this.selectedRentgrataListing = findCurrentListing || null;
          } catch (e) {
            // no-op
          }
        }
        this.integrationForm = new FormGroup({
          widgetKey: new FormControl(this.propertyInfo.rentgrata?.widgetKey, [Validators.required]),
          listingId: new FormControl(this.propertyInfo.rentgrata?.listingId, [Validators.required]),
          ...integrationServices,
        });
        this.loaderService.stopLoader();
        this.modalService.open(modal, { windowClass : CUSTOM_MODAL_STYLE});
      } catch (e) {
        // no-op
      };
    } else if (type === INTEGRATION_TYPES.ACE) {
      try {
        this.integrationForm = new FormGroup({
          aceId: new FormControl(this.userData.aceId, [Validators.required]),
          ...integrationServices,
        });
        this.loaderService.stopLoader();
        this.modalService.open(modal, { windowClass : CUSTOM_MODAL_STYLE});
      } catch (e) {
        // no-op
      }
    } else {
      this.loaderService.stopLoader();
    }
  }

  /**
   * Code used to save an integration when configuring existing integration.
   *
   * @param type (string) The type of integration you are saving.
   * @param isProspectIntegration (boolean) is this a prospect integration? (e.g. Knock)
   */
  async saveIntegrationConfigUpdate(type, isProspectIntegration?) {
    if ([INTEGRATION_TYPES.ACE].includes(type)) {
      this.saveUserOnlyConfig(type);
      return;
    }

    this.loaderService.triggerLoader();

    const {
      pmsCustomSyncFrequency,
      tourScheduling,
      prospectSource,
      residentSource,
      availabilitySource,
      ledgerBalanceSource,
      ...integrationData
    } = this.integrationForm.getRawValue();

    let tourSchedulingValue: string = null;
    let prospectSourceValue: string = null;
    let residentSourceValue: string = null;
    let availabilitySourceValue: string = null;
    let ledgerBalanceSourceValue: string = null;

    // Set tourScheduling to integration if activated
    if (tourScheduling && this.propertyInfo.tourScheduling !== type) {
      tourSchedulingValue = type;
    }
    // If tourScheduling is set to integration name and incoming value is false we know it was turned off
    else if (tourScheduling === false && this.propertyInfo.tourScheduling === type) {
      tourSchedulingValue = '';
    }

    // Set availabilitySource to integration if activated
    if (availabilitySource && this.propertyInfo.availabilitySource !== type) {
      availabilitySourceValue = type;
    }
    // If tourScheduling is set to integration name and incoming value is false we know it was turned off
    else if (availabilitySource === false && this.propertyInfo.availabilitySource === type) {
      availabilitySourceValue = '';
    }

    // Set ledgerBalance to integration if activated
    if (ledgerBalanceSource && this.propertyInfo.ledgerBalanceSource !== type) {
      if (!(await this.hasLedgerDataPermission(type))) {
        this.integrationForm.get('ledgerBalanceSource').setValue('');
        this.toastService.showError('Cannot activate Ledger Balance Source, integration does not have permission to read ledger data.');
        this.loaderService.stopLoader();
        return;
      }
      ledgerBalanceSourceValue = type;
    }
    // If lederBalance is set to integration name and incoming value is false we know it was turned off
    else if (ledgerBalanceSource === false && this.propertyInfo.ledgerBalanceSource === type) {
      ledgerBalanceSourceValue = '';
    }

    // Set prospectSource to integration if activated
    if (prospectSource && this.propertyInfo.prospectSource !== type) {
      prospectSourceValue = type;
    }
    // If prospectSource is set to integration name and incoming value is false we know it was turned off
    else if (prospectSource === false && this.propertyInfo.prospectSource === type) {
      prospectSourceValue = '';
    }

    // Set residentSource to integration if activated
    if (residentSource && this.propertyInfo.residentSource !== type) {
      residentSourceValue = type;
    }
    // If residentSource is set to integration name and incoming value is false we know it was turned off
    else if (residentSource === false && this.propertyInfo.residentSource === type) {
      residentSourceValue = '';
    }

    const integrationPayload: IntegrationPayload = {
      [this.formatIntegrationSubDocumentName(type)]: integrationData,
      ...(tourSchedulingValue !== null && { tourScheduling: tourSchedulingValue }),
      ...(prospectSourceValue !== null && { prospectSource: prospectSourceValue }),
      ...(residentSourceValue !== null && { residentSource: residentSourceValue }),
      ...(availabilitySourceValue !== null && { availabilitySource: availabilitySourceValue }),
      ...(ledgerBalanceSourceValue !== null && { ledgerBalanceSource: ledgerBalanceSourceValue }),

      // Continue to update v1 data until prospect and resident source update is done
      ...(isProspectIntegration ? { prospectIntegrationType: type } : { integrationType: type }),
    };

    this.integrationService.saveIntegrationDetails(
      this.userData.integrationPropertyId,
      type,
      integrationPayload
    ).subscribe(async (property: IProperty) => {
      this.toastService.showSuccess('Successfully saved the integration configuration.');

      // Turn off Delinquency Automation if ledgerBalanceSource is turned off
      if (this.propertyInfo.ledgerBalanceSource && !property.ledgerBalanceSource) {
        await this.deactivateDelinquencyAutomation();
      }

      this.propertyInfo = property;
      this.loadAvailableAutomations(property);

      // Create and send payload to update user
      let userPayload: Partial<User> = {};

      // Payload on user still uses v1 intgration naming
      // A future refactor may be to remove all integration data from user and use the property as the single source of info

      try {
        switch (type) {
          case INTEGRATION_TYPES.Yardi:
            userPayload = {
              yardiAgentName: integrationData.defaultAgent,
              pmsCustomSyncFrequency,
            };
            break;
          case INTEGRATION_TYPES.RealPage:
            userPayload = {
              realPageAgentId: integrationData.defaultAgentId,
              pmsCustomSyncFrequency,
            };
            break;
          case INTEGRATION_TYPES.Entrata:
            userPayload = {
              entrataAgentId: integrationData.defaultAgentId,
              textEventId: integrationData.textEventId,
              textEventResultId: integrationData.textEventResultId,
              emailEventId: integrationData.emailEventId,
              pmsCustomSyncFrequency,
            };
            break;
          case INTEGRATION_TYPES.Knock:
            userPayload = {
              knockCommunityId: integrationData.communityId,
              knockAgentId: integrationData.agentId,
            };
            break
          case INTEGRATION_TYPES.Resman:
            userPayload = {
              pmsCustomSyncFrequency,
            };
            break
          case INTEGRATION_TYPES.Rentgrata:
            // In hp-processor and common rentgrata info is taken from property
            // TODO: make sure rentgrata info is not taken from user in any other place
            // userPayload = {
            //   rentgrataWidgetKey: integrationData.widgetKey,
            //   rentgrataListingId: integrationData.listingId,
            // };
            break;
          case INTEGRATION_TYPES.RentDynamics:
            // no-op
            break;
          case INTEGRATION_TYPES.RentCafe:
            // no-op
            break;
          default:
            throw new Error('Invalid integration type');
        }

        // Update 'schedule-a-tour' claim if necessary
        if (TOUR_SCHEDULING_ENABLED_INTEGRATIONS.includes(type) && tourSchedulingValue !== null) {
          const newClaims = this.toggleScheduleATourClaim(tourScheduling, this.userData);
          if (newClaims) {
            userPayload.claims = newClaims;
          }
        }

        if (property.residentSource && !this.userData.claims.includes('resident data')) {
          if (userPayload.claims) {
            userPayload.claims.push('resident data');
          } else {
            userPayload.claims = [...this.userData.claims, 'resident data'];
          }
        }

        if (Object.keys(userPayload).length) {
          const userRes = await this.userService.updateUser(this.userData._id, userPayload).toPromise();
          this.userData = userRes.result;
        }

        this.integrationService.getPropertyInfo(this.userData.integrationPropertyId).subscribe((propertyInfo) => {
          this.propertyInfo = propertyInfo;
          this.calcMissingIntegrationsConfig(this.integrationService, this.propertyInfo, this.userData);
        });

        this.userAuditsService.refreshTriggeredUserAudits();

        this.loaderService.stopLoader();
      } catch(_error) {
        this.toastService.showError('There was an error saving your integration configuration. Please try again.');
        this.loaderService.stopLoader();
      }
    }, (_error) => {
      this.toastService.showError('There was an error saving your integration configuration. Please try again.');
      this.loaderService.stopLoader();
    });
  }

  /**
   * Method to save integration config that lives only on the user
   *
   * @param type (string) The type of integration you are saving.
   */
  async saveUserOnlyConfig(type) {
    this.loaderService.triggerLoader();
    const integrationData = this.integrationForm.getRawValue();
    const userPayload: Partial<User> = {};

    switch(type) {
      case INTEGRATION_TYPES.ACE:
        userPayload.aceId = integrationData.aceId;
        break;
      default:
        throw new Error(`Invalid type: "${type}" to save user only config.`)
    }

    try {
      const userRes = await this.userService.updateUser(this.userData._id, userPayload).toPromise();
      this.userData = userRes.result;
      this.calcMissingIntegrationsConfig(this.integrationService, this.propertyInfo, this.userData);
    } catch (err) {
      this.toastService.showError('There was an error saving your integration configuration. Please try again.');
    }
    
    this.toastService.showSuccess('Successfully saved the integration configuration.');
    this.loaderService.stopLoader();
  }

  /**
   * Method to determine if Global Defaults needed to build automations are present.
   *
   * @param defaults Global Defaults for the user.
   * @returns boolean TRUE = has required defaults and can build automations.
   */
  userHasAutomationRequiredDefaults(defaults) {
    this.missingDefaults = [];
    if (!defaults.bannerImage) {
      this.missingDefaults.push('Banner Image');
    }
    if (!defaults.leftLogo) {
      this.missingDefaults.push('Logo');
    }
    if (!defaults.yourWebsite) {
      this.missingDefaults.push('Property Website Url');
    }
    if (!defaults.yourEmailAddress) {
      this.missingDefaults.push('Property Email Address');
    }
    if (!defaults.yourPhoneNumber) {
      this.missingDefaults.push('Property Phone Number');
    }
    if (!defaults.residentPortalUrl) {
      this.missingDefaults.push('Resident Portal URL');
    }
    if (!(defaults.scheduleATourUrl || FIELD_AUTO_GENERATORS.scheduleATourUrl.isAvailableToUser(this.localUserData.user))) {
      this.missingDefaults.push('Schedule a Tour URL');
    }
    return !this.missingDefaults.length;
  }

  /**
   * Method to build tooltip to inform user of missing Global Defaults needed to build automations.
   *
   * @returns string Dynamic tooltip to list out missing Global Defaults needed to build automations.
   */
  generateMissingDefaultsToolTop() {
    return this.missingDefaults.length ? `Missing defaults: ${this.missingDefaults.join(', ')}` : null;
  }

  /**
   * Callback to invoke when a Rentgrata listing ID from auto-complete input is selected.
   * http://primefaces.org/primeng/autocomplete
   *
   * @param event (Event) Data passed in from primeNg component.
   */
  onRentgrataListingSelect(event) {
    this.selectedRentgrataListing = event;
    this.integrationForm.controls.listingId.setValue(this.selectedRentgrataListing.listing_id);
  }

  /**
   * Callback to invoke when Rentgrata listing ID from auto-complete input is cleared.
   * http://primefaces.org/primeng/autocomplete
   *
   */
  onRentgrataListingCleared() {
    this.selectedRentgrataListing = null;
    this.integrationForm.controls.rentgrataListingId.setValue('');
  }

  /**
   * Callback to invoke to search for Rentgrata listing ID from auto-complete input.
   * http://primefaces.org/primeng/autocomplete
   *
   * @param completeMethodObject ({
   * event.originalEvent: browser event
   * event.query: Value to search with
   * })
   */
  filterRentgrataAccounts({ query }) {
    const normalQuery = (query || '').toLowerCase();
    this.rentgrataSuggestionsListings = this.rentgrataListings.filter((listing) => {
      const listingName = (listing.listing_name || '').toLowerCase();
      const managementCompanyName = (listing.management_company_name || '').toLowerCase();
      return listingName.includes(normalQuery) || managementCompanyName.includes(normalQuery);
    }).map((listing) => this.formatListingDisplay(listing));
  }

  /**
   * Update users widgetFeatures array used by the nb-web-widget.
   *
   * @param event (Event) event passed through to stop default behavior.
   */
  handleWidgetFeatureChange(event: Event): void {
    event.preventDefault();
    this.loaderService.triggerLoader();
    const features: Set<string> = new Set(this.localUserData.user.widgetFeatures);
    const formValues = this.widgetFeaturesForm.getRawValue();
    for (const value in formValues) {
      if (formValues[value]) {
        features.add(value);
      } else {
        features.delete(value);
      }
    }
    this.scheduleATourUrl =  this.generateScheduleUrl({
      isEnabledScheduleATour: features.has('nbw-schedule-a-tour'),
      isEnabledBuildTourUrl: FIELD_AUTO_GENERATORS.scheduleATourUrl.isAvailableToUser(this.localUserData.user)
    });
    this.botUrl =  this.generateBotUrl(this.hasWidgetPermissions() && this.hasBotPermissions());
    this.userService.updateWidgetFeatures(Array.from(features)).subscribe((data) => {
      this.toastService.showSuccess('Widget Settings Updated Successfully!');
      this.loaderService.stopLoader();
    }, ((error) => {
      this.toastService.showError(error);
      this.loaderService.stopLoader();
    }));
  }

  /**
   * Update users brandings where widget positioning is stored.
   */
  updateWidgetConfig() {
    this.savingWidgetSettings = true;
    this.brandingsService.updateBranding(this.brandings._id, this.brandings).subscribe((_) => {
      this.savingWidgetSettings = false;
      this.toastService.showSuccess('Widget Settings Updated Successfully!');
    }, (err) => {
      this.savingWidgetSettings = false;
      this.toastService.showError('There was an error updating the widget settings.');
    });
  }

  /**
   * Method used to copy value to computer clipboard.
   *
   * @param type (string) What block of text are you copying?
   */
  async copyToClipboard(type) {
    let elementToHighlight;
    if (type === 'widget') {
      await navigator.clipboard.writeText(this.widgetScript as any);
      this.toastService.showSuccess('Copied to clipboard!');
      elementToHighlight = 'widget-src';
    } else if (type === 'tour') {
      await navigator.clipboard.writeText(this.scheduleATourUrl);
      this.toastService.showSuccess('Copied to clipboard!');
      elementToHighlight = 'schedule-url';
    } else if (type === 'bot') {
      await navigator.clipboard.writeText(this.botUrl);
      this.toastService.showSuccess('Copied to clipboard!');
      elementToHighlight = 'bot-url';
    }
    const range = document.createRange();
    const widgetCode = document.getElementById(elementToHighlight);
    range.selectNodeContents(widgetCode);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }

  /**
   * Method used to trigger Admin data retrieval for Knock and Entrata setup shown in modal.
   * Consider moving Rentgrata listings here.
   *
   * @param e (Event) DOM event, used to stop default behavior.
   * @param type (string) Integration type.
   * @param content (Modal) Modal to open.
   * @param knockCommunity (string) Direction on if we should be retireving community IDs.
   * @param knockAgent  (string) Direction on if we should be retireving agent IDs.
   */
  async openModal(e, type, content, knockCommunity, knockAgent) {
    e.preventDefault();
    if (type === INTEGRATION_TYPES.Knock) {
      if (knockCommunity) {
        this.loaderService.triggerLoader();
        try {
          const data = await this.integrationService.getKnockCommunities().toPromise();
          this.knockCommunities = data;
          this.loaderService.stopLoader();
          this.modalService.open(content, MODAL_OPTIONS).result.then(() => {
            this.knockCommunities = null;
            this.filterTerm = null;
          }, () => {
            this.knockCommunities = null;
            this.filterTerm = null;
          });
        } catch (e) {
          this.toastService.showError('There was an error retrieving Knock Community IDs');
          this.loaderService.stopLoader();
        }
      } else if (knockAgent) {
        this.loaderService.triggerLoader();
        try {
          const data = await this.integrationService.getKnockCommunityAgents(this.integrationForm.controls.communityId.value).toPromise();
          this.knockAgents = data;
          this.loaderService.stopLoader();
          this.modalService.open(content, MODAL_OPTIONS).result.then(() => {
            this.knockAgents = null;
            this.filterTerm = null;
          }, () => {
            this.knockAgents = null;
            this.filterTerm = null;
          });
        } catch (e) {
          this.toastService.showError('There was an error retrieving Knock Community IDs');
          this.loaderService.stopLoader();
        }
      }
    } else if (type === INTEGRATION_TYPES.Entrata) {
      this.loaderService.triggerLoader();
      try {
        const data = await this.integrationService.getEntrataProperties(this.integrationToCreateForm.getRawValue()).toPromise();
        this.entrataProperties = data;
        this.loaderService.stopLoader();
        this.modalService.open(content, MODAL_OPTIONS).result.then(() => {
          this.entrataProperties = null;
          this.filterTerm = null;
        }, () => {
          this.entrataProperties = null;
          this.filterTerm = null;
        });
      } catch (e) {
        this.toastService.showError('There was an error retrieving Entrata Property IDs');
        this.loaderService.stopLoader();
      }
    }
  }

  /**
   * Opens warning modal to alert user before rebuilding automations.
   *
   * @param event (Event) Event passed in from DOM to stop default actions.
   */
  openBuildAutomationsModal(event) {
    event.preventDefault();
    event.stopPropagation();
    this.nbModalService.open(MODAL_NAMES.BUILD_AUTOMATIONS);
  }


  // Helper Methods For Determining Claims
  isYardiUser() {
    return this.integrationService.isYardiUser;
  }

  isRealPageUser() {
    return this.integrationService.isRealPageUser;
  }

  isRentCafeUser() {
    return this.integrationService.isRentCafeUser;
  }

  isRentgrataUser() {
    return this.integrationService.isRentgrataUser;
  }

  isEntrataUser() {
    return this.integrationService.isEntrataUser;
  }

  isKnockUser() {
    return this.integrationService.isKnockUser;
  }

  isRentDynamicsUser() {
    return this.integrationService.isRentDynamicsUser;
  }

  isResmanUser() {
    return this.integrationService.isResmanUser;
  }

  isAdmin() {
    return this.integrationService.isAdmin;
  }

  isPartner() {
    return this.integrationService.isPartner;
  }

  hasBotPermissions() {
    return this.integrationService.hasBot;
  }

  hasScheduleATourPermission() {
    return this.integrationService.hasScheduleATourPermission;
  }

  hasWidgetPermissions() {
    return this.integrationService.hasWidgetPermissions;
  }

  userHasIntegration() {
    return this.isYardiUser() || this.isRealPageUser() || this.isEntrataUser() || this.isResmanUser();
  }

  // Used for allowing users to build automations, so we don't add anything we don't get prospects from.
  userHasCrmIntegration() {
    return this.isKnockUser();
  }

  // Special method to format Rentgrata listings for admin view.
  formatListingDisplay(listing) {
    return ({ ...listing, display: `${listing?.management_company_name} - ${listing.listing_name}` })
  }

  toggleScheduleATourClaim(tourScheduling, user) {
    if (tourScheduling && !user.claims.includes('schedule-a-tour')) {
      return [...user.claims, 'schedule-a-tour'];
    } else if (!tourScheduling && user.claims.includes('schedule-a-tour')) {
      return user.claims.filter((claim) => claim !== 'schedule-a-tour');
    } else {
      return user.claims;
    }
  };

  formatIntegrationSubDocumentName(name: string): string {
    switch (name) {
      case INTEGRATION_TYPES.RentDynamics:
        return 'rentDynamics';
      case INTEGRATION_TYPES.RealPage:
        return 'realPage';
      case INTEGRATION_TYPES.RentCafe:
        return 'rentCafe';
      default:
        return name;
    }
  }

  isMissingIntegrationService(integration: string, property: IProperty): string[] {
    const integrationsServices = []
    if (INTEGRATIONS_SERVICES?.[this.formatIntegrationSubDocumentName(integration)]?.residentSource && !property.residentSource) {
      integrationsServices.push(INTEGRATION_SERVICE_TYPES.residentSource);
    }
    if (INTEGRATIONS_SERVICES?.[this.formatIntegrationSubDocumentName(integration)]?.prospectSource && !property.prospectSource) {
      integrationsServices.push(INTEGRATION_SERVICE_TYPES.prospectSource);
    }
    if (INTEGRATIONS_SERVICES?.[this.formatIntegrationSubDocumentName(integration)]?.availabilitySource && !property.availabilitySource) {
      integrationsServices.push(INTEGRATION_SERVICE_TYPES.availabilitySource);
    }
    if (INTEGRATIONS_SERVICES?.[this.formatIntegrationSubDocumentName(integration)]?.ledgerBalanceSource && !property.ledgerBalanceSource) {
      integrationsServices.push(INTEGRATION_SERVICE_TYPES.ledgerBalanceSource);
    }
    if (INTEGRATIONS_SERVICES?.[this.formatIntegrationSubDocumentName(integration)]?.tourScheduling && !property.tourScheduling) {
      integrationsServices.push(INTEGRATION_SERVICE_TYPES.tourScheduling);
    }
    return integrationsServices;
  }

  validateIntegrationFields (integration: string, property: IProperty, user: User) {
    switch (integration) {
      case INTEGRATION_TYPES.Yardi:
        if (property?.yardi?.defaultAgent && property?.yardi?.defaultSource && user.pmsCustomSyncFrequency) {
          return true;
        } else if (property?.defaultAgent && property?.defaultSource && user.pmsCustomSyncFrequency) { // TODO: Deprecated structure, remove when no longer being used
          return true;
        }
        break;
      case INTEGRATION_TYPES.RealPage:
        if ((property?.realPage?.defaultAgentId || property?.realPage?.defaultAgentId === 0) && property?.realPage?.defaultSourceId && user.pmsCustomSyncFrequency) {
          return true;
        } else if (property?.defaultAgent && property?.defaultSource && user.pmsCustomSyncFrequency) { // TODO: Deprecated structure, remove when no longer being used
          return true;
        }
        break;
      case INTEGRATION_TYPES.Entrata:
        if (
          property?.entrata?.defaultAgentId &&
          property?.entrata?.defaultSourceId &&
          property?.entrata?.textEventId &&
          property?.entrata?.defaultSourceId &&
          property?.entrata?.textEventResultId &&
          property?.entrata?.emailEventId &&
          user.pmsCustomSyncFrequency
        ) {
          return true;
        } else if ( // TODO: Deprecated structure, remove when no longer being used
          property?.defaultAgentId &&
          property?.defaultSourceId &&
          property?.textEventId &&
          property?.defaultSourceId &&
          property?.textEventResultId &&
          property?.emailEventId &&
          user.pmsCustomSyncFrequency
        ) {
          return true;
        }
        break;
      case INTEGRATION_TYPES.RentCafe:
        if (
          property?.rentCafe?.companyCode &&
          property?.rentCafe?.apiKey &&
          (property?.rentCafe?.propertyId || property?.rentCafe?.propertyCode)
        ) {
          return true;
        }
        break;
      case INTEGRATION_TYPES.Knock:
        if (property?.knock?.agentId && property?.knock?.communityId) {
          return true;
        } else if (property?.knockAgentId && property?.knockCommunityId) { // TODO: Deprecated structure, remove when no longer being used
          return true;
        }
        break;
      case INTEGRATION_TYPES.RentDynamics:
        if (
          property?.rentDynamics?.communityId &&
          property?.rentDynamics?.communityGroupId &&
          property?.rentDynamics?.adSourceId
        ) {
          return true;
        } else if ( // TODO: Deprecated structure, remove when no longer being used
          property?.rentDynamicsCommunityId &&
          property?.rentDynamicsCommunityGroupId &&
          property?.rentDynamicsAdSourceId
        ) {
        return true;
      }
      break;
      case INTEGRATION_TYPES.Rentgrata:
        if (property?.rentgrata?.widgetKey && property?.rentgrata?.listingId) {
          return true;
        } else if (property?.rentgrataWidgetKey && property?.rentgrataListingId) { // TODO: Deprecated structure, remove when no longer being used
          return true;
        }
        break;
      case INTEGRATION_TYPES.ILM:
        if (
          property?.ilm?.emailAddress &&
          property?.ilm?.ftpHostName &&
          property?.ilm?.ftpUserName &&
          property?.ilm?.ftpPassword &&
          property?.ilm?.fptFileName &&
          property?.ilm?.defaultSource
        ) {
          return true;
        } else if (
          property?.ilmEmailAddress &&
          property?.ftpHostName &&
          property?.ftpUserName &&
          property?.ftpPassword &&
          property?.fptFileName &&
          property?.defaultSource
        ) { // TODO: Deprecated structure, remove when no longer being used
          return true;
        }
        break;
      case INTEGRATION_TYPES.Resman:
        if (property?.resman?.defaultAgentId && property?.resman?.defaultSourceId && user.pmsCustomSyncFrequency) {
          return true;
        }
        break;
      case INTEGRATION_TYPES.ACE:
        if (user.aceId) {
          return true;
        }
    }

    return false;
  }

  calcMissingIntegrationsConfig(integrationService, property, user) {
    this.missingIntegrationsConfig = [];
    this.missingIntegrationService = [];

    Object.values(INTEGRATION_TYPES).forEach((integration) => {
      const hasIntegration = integrationService.hasIntegration(integration);
      const missingIntegrationService = this.isMissingIntegrationService(integration, property);
      if (hasIntegration && missingIntegrationService.length) {
        missingIntegrationService.forEach(missingIntegration => {
          // Prevent duplicate alerts
          if (!this.missingIntegrationService.includes(missingIntegration)) {
            this.missingIntegrationService?.push(missingIntegration);
          }
        })
      }

      if (hasIntegration && !this.validateIntegrationFields(integration, property, user)) {
        this.missingIntegrationsConfig?.push(DISPLAY_NAME_INTEGRATION[integration]);
      }
    })
  }

  // Missing config alert
  removeMissingIntegrationsConfig(index) {
    this.missingIntegrationsConfig.splice(index, 1);
  }

  removeMissingIntegrationService(index) {
    this.missingIntegrationService.splice(index, 1);
  }

  // Integration Service Conflict Modal
  closeModal(name: string) {
    // if user close the modal without confirm the change it reverts the change and close the modal
    this.integrationForm.controls[this.integrationsServicesConflict.currentService].setValue(false);
    this.integrationsServicesConflict = null;
    this.nbModalService.close(name);
  }

  confirmIntegrationServiceChange() {
    // if user confirm the change, just save the change on the front and clean the data from the modal
    this.integrationsServicesConflict = null;
    this.nbModalService.close('integration-service-conflict');
  }

  onChangeIntegrationServiceToggle(integrationsServicesConflict: IntegrationServiceConflict) {
    // If user have that service with another integration and they want to change the integration for that service, you need to confirm the action.
    if (
      !!this.propertyInfo[integrationsServicesConflict.currentService] &&
      this.propertyInfo[integrationsServicesConflict.currentService] !== this.selectedIntegrationType &&
      this.integrationForm.controls[integrationsServicesConflict.currentService].value
    ) {
      this.integrationForm.controls[integrationsServicesConflict.currentService].setValue(true);
      this.integrationsServicesConflict = integrationsServicesConflict;
      this.nbModalService.open('integration-service-conflict');
    } else {
      // In any other case it just inverts the value
      this.integrationForm.controls[integrationsServicesConflict.currentService].setValue(this.integrationForm.controls[integrationsServicesConflict.currentService].value);
    }
  }

  async hasLedgerDataPermission(integrationType) {
    const propertyId = this.authService.currentUserValue.user.integrationPropertyId;
    try {
      // Return await so that error handling happens here
      return await this.integrationService.getLedgerDataPermission(propertyId, integrationType).toPromise();
    } catch (err) {
      return false;
    }
  }

  async deactivateDelinquencyAutomation() {
    try {
      await this.automationSettingsService.deactivateDelinquencyAutomation().toPromise();
    } catch (err) {
      // no-op
    }
  }
}
