// Angular:
import { Component, Input, OnChanges, OnDestroy, OnInit, SecurityContext, SimpleChanges, Injector } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
//Libs
import { Subscription } from 'rxjs';
// Utils:
import getOS, { OSOptions } from '@app/_utils/getOS';
// Services:
import { SpamLanguageService } from '@app/_services';

export enum PreviewView {
  Email = 'email',
  EmailSubject = 'emailsubject',
  NurturePage = 'nurturepage',
  TextContent = 'textcontent',
}

@Component({
  selector: 'app-iphone-preview',
  styleUrls: ['./iphone-preview.component.less'],
  templateUrl: './iphone-preview.component.html',
})
export class IphonePreviewComponent implements OnInit, OnChanges, OnDestroy {
  // TODO: also support passing data and having the component build the template
  @Input() forceDisplayType: PreviewView | null = null;
  @Input() helperText: string = 'Please select a template to continue';
  @Input() errorText: string = 'Please select a template to continue';
  @Input() emailUrl: string = '';
  @Input() nurturePageUrl: string = '';
  @Input() textContent: string = '';
  @Input() emailSubjectLine: string = '';
  @Input() allowEmailPreviewAndTextMessageToggle: boolean = false;
  @Input() removeNurturePagePreview: boolean = false;
  @Input() _spamLanguageService: SpamLanguageService | null = null;

  hasError: boolean = true;
  loading: boolean = false;
  safeUrl: SafeUrl | null;
  currentTime: string;
  displayType: string = PreviewView.Email;
  timeoutId = null;
  isWindows = false;
  spamWarnings = [];
  spamLanguageService: SpamLanguageService;
  subscription: Subscription;

  constructor(
    private injector: Injector,
    protected _sanitizer: DomSanitizer
  ) {
    // no-op
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }
    this.timeoutId = window.setTimeout(() => {
      this.setup();
    }, 1000);
    if (
      changes?.forceDisplayType?.currentValue &&
      ((
        changes?.forceDisplayType?.previousValue &&
        changes.forceDisplayType.currentValue !== changes.forceDisplayType.previousValue
      ) ||
      ( 
        !changes?.forceDisplayType?.previousValue
      ))
    ) {
      this.toggleDisplayType(changes.forceDisplayType.currentValue);
    }
  }

  ngOnInit() {
    // When using the SpamLanguageGroupService the SpamLanguageService needs to passed as a param
    // since there are several instances and not one "global" instance
    if (this._spamLanguageService) {
      // Use service passed as a param
      this.spamLanguageService = this._spamLanguageService;
    } else {
      // Inject "global" instance
      this.spamLanguageService = this.injector.get(SpamLanguageService);
    }

    this.subscription = this.spamLanguageService.warnings.subscribe(warnings => {
      this.spamWarnings = warnings;
    });

    // Force above subscribe callback to run in case there is any spam language already in the service
    this.spamLanguageService.emitWarnings();

    if (this.allowEmailPreviewAndTextMessageToggle) {
      this.displayType = PreviewView.TextContent;
    }
    this.setup();
    this.isWindows = getOS() === OSOptions.WINDOWS;
  }

  ngOnDestroy() {
    this.safeUrl = null;
    this.subscription?.unsubscribe();
  }

  private setup(): void {
    this.setLoading(true);
    this.setSafeUrl();
    this.setLoading(false);
  }

  private setSafeUrl(): void {
    if (this.forceDisplayType) {
      this.displayType = this.forceDisplayType;
    }
    if (this.displayType === PreviewView.TextContent) {
      return;
    }

    let urlToUse;
    switch (this.displayType) {
      case PreviewView.Email:
      case PreviewView.EmailSubject:
        urlToUse = this.emailUrl;
        break;
      case PreviewView.NurturePage:
        urlToUse = this.nurturePageUrl;
        break;
      default:
        break;
    }
    if (urlToUse) {
      this.safeUrl = this.makeUrlSafe(urlToUse);
      this.errorText = '';
      this.hasError = false;
    } else {
      this.hasError = true;
      this.errorText = 'No template to display.';
      this.safeUrl = null;
    }
  }

  toggleDisplayType(type: PreviewView) {
    this.displayType = type;
    this.setSafeUrl();
  }

  private setLoading(loading: boolean): void {
    this.loading = loading;
  }

  private makeUrlSafe(url): SafeUrl | null {
    const sanitizedUrl = this._sanitizer.sanitize(SecurityContext.URL, url);

    if (!sanitizedUrl) {
      this.hasError = true;
      this.errorText = 'Unable to parse content provided';
      return null;
    }

    this.hasError = false;
    this.errorText = '';
    return this._sanitizer
      .bypassSecurityTrustResourceUrl(sanitizedUrl);
  }
}
