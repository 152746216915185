<div id="wizard">
  <div class="wizard-header">
    <span class="wizard-previous">
      <button type="submit" [attr.disabled]="activeIndex === 0 || undefined" class="wizard-button pull-left" (click)="onPrevious($event)">
        <i class="far fa-chevron-left"></i>
      </button>

      Back
    </span>

    <div class="wizard-step-view">
      <ul class="wizard-step-list">
        <li class="wizard-step-item {{step.isActiveStep ? 'active' : ''}} {{i < activeIndex ? 'completed' : ''}}" *ngFor="let step of steps; let i = index">
          <span class="wizard-step-item-node"></span>
          {{step.label}}
        </li>
      </ul>
    </div>

    <span class="wizard-exit">
      <button type="submit" class="wizard-button pull-right" (click)="onExit($event)">
        <i class="far fa-times"></i>
      </button>
    </span>
  </div>

  <div class="wizard-content">
    <ng-content></ng-content>
  </div>
</div>
