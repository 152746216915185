<button
  *ngIf="!disabled"
  class="button button-dark button-small generate-button"
  [ngClass]="{ disabled: !!disabledText }"
  (click)="openGenerateTextModal($event)"
  [disabled]="!!disabledText"
  [ngbTooltip]="disabledText"
>
<i class="fa-regular fa-microchip-ai microchip-icon"></i>
  Generate With AI
</button>

<app-generate-ai-text-modal
  [controlName]="controlName"
  [outputControl]="control"
  (disabledTextChange)="onDisabledTextChange($event)"
></app-generate-ai-text-modal>