import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { APIHelperService } from './apiHelper.service';
import { of } from 'rxjs';

interface IFormQuery {
  skip: number;
  take: number;
  query?: string;
  property?: string;
  startingDate?: string;
  endingDate?: string;
  paginate: boolean;
};

@Injectable({ providedIn: 'root' })
export class FormsService {

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService
  ) {
    // no-op
  }

  createForm(formData) {
    return this.http.post<any>(this.apiHelper.fillUrl('forms', {}, {}), formData)
      .pipe(map(response => {
        return response.result;
      }));
  }

  getForm(id: string) {
    return this.http.get<any>(this.apiHelper.fillUrl('form', { id }, {}))
      .pipe(map(response => {
        return response.result;
      }));
  }

  getForms() {
    return this.http.get<any>(this.apiHelper.fillUrl('forms', {}, {}))
      .pipe(map(response => {
        return response.result;
      }));
  }

  getPaginatedForms(skip, take, query?, property?, startingDate?, endingDate?) {
    const payload: IFormQuery = {
      skip: skip,
      take: take,
      paginate: true,
    };
    if (query && property) {
      payload.query = query;
      payload.property = property;
    }
    if (startingDate && endingDate) {
      payload.startingDate = startingDate;
      payload.endingDate = endingDate;
    }
    return this.http.get<any>(this.apiHelper.fillUrl('forms', {}, payload))
      .pipe(map(data => {
        return data;
      }));
  }

  getFormResponses(id: string) {
    return this.http.get<any>(this.apiHelper.fillUrl('formResponses', { id }, {}))
      .pipe(map(response => {
        return response.result;
      }));
  }

  getFormResponsesWithAggregateData(id: string) {
    return this.http.get<any>(this.apiHelper.fillUrl('formResponses', { id }, { returnAggregateData: true }))
      .pipe(map(response => {
        return response.result;
      }));
  }

  updateForm(id: string, data: any) {
    return this.http.put<any>(this.apiHelper.fillUrl('form', { id }, {}), data)
      .pipe(map((response) => {
        return response.result;
      }));
  }

  cloneForm(id: string) {
    return this.http.post<any>(this.apiHelper.fillUrl('form', { id }, {}), {})
    .pipe(map((response) => {
      return response.result;
    }));
  }
}
