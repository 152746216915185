<div class="page-title">
  <h2>Integrations</h2>
</div>

<div class="page" *ngIf="!integrationsLoadError">
  <div class="row">
    <div *ngFor="let integration of missingIntegrationsConfig; let index = index" class="col-md-12">
      <div class="alert alert-danger d-flex justify-content-between align-items-center">
        <p class="mb-0 text-danger">
          The <span class="text-capitalize text-danger"> {{ integration }}</span> Integration is missing information needed to work properly.
          <!-- TODO: When we finish implementing ilm remove the validation -->
          <a *ngIf="integration !== displayNameIntegration.ilm" (click)="configureIntegration(integration.toLowerCase(), configureintegration)" class="margin-left-12 text-primary">Configure Integration</a>
        </p>
        <button (click)="removeMissingIntegrationsConfig(index)" type="button" class="close d-flex" data-dismiss="alert"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>

    <div *ngFor="let serviceType of missingIntegrationService; let index = index" class="col-md-12">
      <div class="alert alert-warning d-flex justify-content-between align-items-center">
        <p class="mb-0 text-warning">
          <span class="text-capitalize text-warning">{{ displayNameIntegrationService[serviceType] }}</span> is not currently configured, please ensure
          <span class="text-capitalize text-warning">{{ displayNameIntegrationService[serviceType] }}</span> is enabled within an Integration below.
        </p>
        <button (click)="removeMissingIntegrationService(index)" type="button" class="close d-flex" data-dismiss="alert"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>

    <div *ngFor="let integrationCard of integrationCards" class="col-md-3 margin-bottom-12 margin-top-12">
      <app-integration-card
        [integration]="integrationCard"
        [tourScheduling]="propertyInfo.tourScheduling"
        [prospectSource]="propertyInfo.prospectSource"
        [residentSource]="propertyInfo.residentSource"
        [availabilitySource]="propertyInfo.availabilitySource"
        [ledgerBalanceSource]="propertyInfo.ledgerBalanceSource"
        [enableintegration]="enableintegration"
        [configureintegration]="configureintegration"
      ></app-integration-card>
    </div>
  </div>
</div>

<!-- ERROR LOADING INTEGRATIONS -->
<div class="page" *ngIf="integrationsLoadError && userHasIntegration()">
  <div class="row">
    <div class="col-md-12">
      <p class="text-center full-width margin-top-48">
        <img src="/assets/no-messaging.png" class="integrations-page-placeholder" />
      </p>
      <h3 class="font-18 text-center full-width margin-top-48">Something went wrong loading your integration information. Please refresh to try again. If this continues to happen, please contact <a href="mailto:customersuccess@nurtureboss.io">customersuccess@nurtureboss.io</a> to help fix the issue!</h3>
    </div>
  </div>
</div>

<!-- Build Automations -->
<div class="page" *ngIf="isAdmin() || isPartner()">
  <div class="row">
    <div class="col-md-12">
      <h6 class="title">System Automations</h6>
      <div style="display: inline-block" class="margin-bottom-12 margin-top-12" [ngbTooltip]="generateMissingDefaultsToolTop()" tooltipClass="build-automation-tooltip">
        <button
          [disabled]="!allowBuildAutomations || !availableAutomations.length"
          class="button button-dark"
          (click)="openBuildAutomationsModal($event)"
        >
          Build Automations
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Nurture Boss Widget -->
<div class="page">
  <div class="user-table">
    <div class="no-border">

      <!-- WIDGET FEATURES -->
      <div class="mb-4 d-flex pl-0">
        <div class="feature-switch-container col-4 no-padding" [formGroup]="widgetFeaturesForm">
          <h6 class="title">Widget Features</h6>
          <div class="no-border">
            <h6>Contact Us</h6>
            <label class="switch">
              <input
                formControlName="nbw-lead-capture"
                type="checkbox"
                (change)="handleWidgetFeatureChange($event)"
              />
              <span class="slider round"></span>
            </label>
          </div>
          <div class="no-border" *ngIf="hasWidgetPermissions() && hasScheduleATourPermission()">
            <h6>Schedule a Tour</h6>
            <label class="switch">
              <input
                type="checkbox"
                formControlName="nbw-schedule-a-tour"
                (change)="handleWidgetFeatureChange($event)"
              />
              <span class="slider round"></span>
            </label>
          </div>
          <div class="no-border" *ngIf="hasWidgetPermissions() && hasBotPermissions()">
            <h6>ChatBot</h6>
            <label class="switch">
              <input
                type="checkbox"
                formControlName="bot"
                (change)="handleWidgetFeatureChange($event)"
              />
              <span class="slider round"></span>
            </label>
          </div>
        </div>

        <!-- WIDGET POSITION SETTINGS -->
        <form class="widget-settings-form col-8 no-padding">
          <h6 class="title">Widget Display Settings</h6>
          <div class="row">
            <div class="col">
              <h6>Widget Horizontal Position:</h6>
              <div class="form-group">
                <select class="form-control" [(ngModel)]="brandings.widget.screenPositionX" [ngModelOptions]="{standalone: true}"
                  aria-describedby="widgetHorizontalPosition">
                  <option value="right">Right</option>
                  <option value="left">Left</option>
                </select>
              </div>
            </div>
            <div class="col">
              <h6>Widget Vertical Position:</h6>
              <div class="form-group">
                <select class="form-control" [(ngModel)]="brandings.widget.screenPositionY" [ngModelOptions]="{standalone: true}"
                  aria-describedby="widgetVeritcalPosition">
                  <option value="bottom">Bottom</option>
                  <option value="top">Top</option>
                </select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <h6>Widget Horizontal Offset (pixels):</h6>
              <div class="form-group">
                <input type="number" class="form-control" [(ngModel)]="brandings.widget.xOffset"
                  [ngModelOptions]="{standalone: true}" aria-describedby="widgetHorizontalOffset" />
              </div>
            </div>
            <div class="col">
              <h6>Widget Vertical Offset (pixels):</h6>
              <div class="form-group">
                <input type="number" class="form-control" [(ngModel)]="brandings.widget.yOffset"
                  [ngModelOptions]="{standalone: true}" aria-describedby="widgetVerticalOffset" />
              </div>
            </div>
          </div>

          <button class="button button-dark pull-right" [disabled]="savingWidgetSettings" (click)="updateWidgetConfig()">
            <span *ngIf="savingWidgetSettings" class="spinner-border spinner-border-sm mr-1"></span>
            Update Settings
          </button>
        </form>
      </div>

      <!-- WIDGET CODE SNIPPET -->
      <h6 class="title">Widget Code Snippet</h6>
      <div class="widget-display d-flex mb-4">
        <pre id="widget-src">{{ widgetScript }}</pre>
        <i
          class="fal fa-copy fa-lg ml-2"
          (click)="copyToClipboard('widget')"
          placement="left"
          ngbTooltip="Click to Copy"
        ></i>
      </div>
    </div>

    <!-- SCHEDULE TOUR URL -->
    <div class="no-border" *ngIf="scheduleATourUrl">
      <h6 class="title">Copy Schedule Tour URL</h6>
      <div class="widget-display d-flex mb-4" *ngIf="scheduleATourUrl">
        <pre id="schedule-url">{{ scheduleATourUrl }}</pre>
        <i class="fal fa-copy fa-lg ml-2" (click)="copyToClipboard('tour')" placement="left" ngbTooltip="Click to Copy"></i>
      </div>
    </div>

    <!-- BOT URL -->
    <div class="no-border" *ngIf="hasWidgetPermissions() && hasBotPermissions()">
      <h6 class="title">Copy ChatBot URL</h6>
      <div class="widget-display d-flex mb-4">
        <pre id="bot-url">{{ botUrl }}</pre>
        <i class="fal fa-copy fa-lg ml-2" (click)="copyToClipboard('bot')" placement="left" ngbTooltip="Click to Copy"></i>
      </div>
    </div>
  </div>
</div>

<!-- Tour Features -->
<div class="page pb-4 mb-4" *ngIf="showTourFeatures">
  <div class="user-table pb-5" [formGroup]="tourAvailabilityForm">

      <button class="button button-dark pull-right" [disabled]="savingTourSchedulingSettings" (click)="updateTourScheduleSettings()">
        <span *ngIf="savingTourSchedulingSettings" class="spinner-border spinner-border-sm mr-1"></span>
        Update Settings
      </button>

      <h6 class="title">Tour Scheduling Features</h6>

      <div class="p-2">
        <h6>Same Day Tours <i placement="top" ngbTooltip="When disabled, your prospects will not be able to schedule a tour for the same day. The soonest available tour shown to them will be tomorrow." class="far fa-info-circle"></i></h6>
        <label class="switch">
          <input
            formControlName="same-day-tours"
            type="checkbox"
            (change)="updateTourScheduleSettings()"
          />
          <span class="slider round"></span>
        </label>
      </div>

    <div class="p-2">
      <h6>Blackout Dates <i placement="top" ngbTooltip="These are specific days that you do not want tours to be available. For example a federal holiday. You can select as many days as you'd like." class="far fa-info-circle"></i></h6>
      <p-calendar
        styleClass="black-out-dates-calendar"
        formControlName="black-out-dates"
        selectionMode="multiple"
        dateFormat="mm-dd-yy"
      ></p-calendar>
    </div>

    <div class="p-2">
      <h6>Blackout Days  <i placement="top" ngbTooltip="These are specific days of the week that you always want unavailable for tours. For example no one is available on Sundays." class="far fa-info-circle"></i></h6>
      <p-multiSelect styleClass="black-out-days-multiselect" [options]="daysOfTheWeek" formControlName="black-out-days" [virtualScroll]="true" [filter]="false" itemSize="30"></p-multiSelect>
    </div>

    <div class="p-2 w-100">
      <h6>Tour Interval (In Minutes)  <i placement="top" ngbTooltip="How many minutes do you want displayed between each tour time? If the number you choose is smaller that your system supports, we will use your system time gaps instead." class="far fa-info-circle"></i></h6>
      <p-dropdown styleClass="tour-interval-dropdown" [options]="tourIntervals" formControlName="tour-interval" [filter]="false"></p-dropdown>
    </div>
  </div>
</div>

<!-- Alert integration source already set -->
<nb-modal [name]="'integration-service-conflict'" [confetti]="false" class="integration-service-conflict-wrapper">
  <div class="delete-automation">
    <div class="alert alert-warning" *ngIf="integrationsServicesConflict">
      <strong>Warning:</strong>
      {{ displayNameIntegrationService[integrationsServicesConflict?.currentService] }} is currently enabled for {{ displayNameIntegration[integrationsServicesConflict?.currentIntegration] }}.
      By enabling {{ displayNameIntegrationService[integrationsServicesConflict?.currentService] }} for {{ displayNameIntegration[integrationsServicesConflict?.newIntegration] }},
      you will disable it for {{ displayNameIntegration[integrationsServicesConflict?.currentIntegration] }}
    </div>
    <div class="d-flex p-2 justify-content-end">
      <button class="button button-white no-margin margin-right-12" (click)="closeModal('integration-service-conflict');">Cancel</button>
      <button class="button button-dark no-margin margin-left-24" (click)="confirmIntegrationServiceChange()">Continue</button>
    </div>
  </div>
</nb-modal>

<!-- Knock Integration Data Modals -->
<ng-template #knockIntegrationData let-modal>
  <div class="modal-header">
      <h5 class="modal-title">Knock Integration Data</h5>
  </div>
  <div class="knock-integration-data-modal modal-body">
    <!-- Community Lookup -->
    <div class="form-group" *ngIf="knockCommunities">
      <label>Search For Community</label>
      <input type="text" class="form-control" [(ngModel)]="filterTerm" [ngModelOptions]="{standalone: true}" placeholder="Enter community name">
    </div>
    <table class="table" *ngIf="knockCommunities">
      <thead>
        <tr>
          <th scope="col">Community Name</th>
          <th scope="col">PMC Name</th>
          <th scope="col">Community ID</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let comm of knockCommunities | listfilter: filterTerm: 0">
          <th scope="row">{{comm[0]}}</th>
          <td>{{comm[1]}}</td>
          <td>{{comm[2]}}</td>
        </tr>
      </tbody>
    </table>
    <!-- Agent Lookup -->
    <div class="form-group" *ngIf="knockAgents">
      <label>Search For Agent</label>
      <input type="text" class="form-control" [(ngModel)]="filterTerm" [ngModelOptions]="{standalone: true}" placeholder="Enter agent name">
    </div>
    <table class="table" *ngIf="knockAgents">
      <thead>
        <tr>
          <th scope="col">Agent Name</th>
          <th scope="col">Agent ID</th>
          <th scope="col">Is Enabled?</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let agent of knockAgents | listfilter:filterTerm:0">
          <th scope="row">{{agent[0]}}</th>
          <td>{{agent[1]}}</td>
          <td>{{agent[2]}}</td>
        </tr>
      </tbody>
    </table>

    <button (click)="modal.dismiss()" class="button button-white margin-top-12 margin-left-12">
      Exit
    </button>
  </div>
</ng-template>

<!-- Entrata Integration Data Modals -->
<ng-template #entrataIntegrationData let-modal>
  <div class="modal-header">
      <h5 class="modal-title">Entrata Integration Data</h5>
  </div>
  <div class="knock-integration-data-modal modal-body">
    <!-- Property Lookup -->
    <div class="form-group" *ngIf="entrataProperties">
      <label>Search For Community</label>
      <input type="text" class="form-control" [(ngModel)]="filterTerm" [ngModelOptions]="{standalone: true}" placeholder="Enter property name">
    </div>
    <table class="table" *ngIf="entrataProperties">
      <thead>
        <tr>
          <th scope="col">Property Name</th>
          <th scope="col">Property ID</th>
          <th scope="col">Is Disabled?</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let comm of entrataProperties | filter:filterTerm:'propertyName'">
          <th scope="row">{{comm.propertyName}}</th>
          <td>{{comm.propertyId}}</td>
          <td>{{comm.disabled}}</td>
        </tr>
      </tbody>
    </table>
    <button (click)="modal.dismiss()" class="button button-white margin-top-12 margin-left-12">
      Exit
    </button>
  </div>
</ng-template>

<!-- Build Automations Modal -->
<app-build-automations-modal [availableAutomations]="availableAutomations"></app-build-automations-modal>

<!-- Enable New Integration Modal -->
<ng-template #enableintegration let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Enable New Integration</h5>
    <div class="float-right">
        <button type="button" class="button button-white button-small" (click)="exitModal($event, modal)">
          <i class="far fa-times" aria-hidden="true"></i>
        </button>
    </div>
  </div>
  <div class="modal-body enable-integration">

    <!-- Setup Yardi Integration -->
    <div class="row max-100 margin-top-24 margin-left-0" *ngIf="selectedIntegrationType === 'yardi'">
      <div class="col-md-12 no-margin no-padding">
        <img class="logo" src="/assets/yardi-logo.png" />
        <div class="alert alert-warning margin-top-0 margin-bottom-36">
          Please ensure the Nurture Boss Interface has been added to your property. <a href="https://info.nurtureboss.io/hubfs/Yardi-Onboarding_Setup-for-Standard-Interfaces.pdf" target="_blank">Click here for the setup guide.</a>
        </div>
        <form [formGroup]="integrationToCreateForm" (ngSubmit)="createIntegration($event, 'yardi', modal)">
          <h6 class="title">Yardi WSDL Url:</h6>
          <div class="form-group">
            <input appTrim type="text" autocomplete="off" formControlName="wsdlUrl" class="form-control" id="wsdlUrl">
          </div>
          <h6 class="title">Yardi Server:</h6>
          <div class="form-group">
            <input appTrim type="text" autocomplete="off" class="form-control" formControlName="serverName" id="serverName">
          </div>
          <h6 class="title">Yardi Database:</h6>
          <div class="form-group">
            <input appTrim type="text" autocomplete="off" class="form-control" formControlName="databaseName" id="databaseName">
          </div>
          <h6 class="title">Yardi Username:</h6>
          <div class="form-group">
            <input appTrim type="text" autocomplete="off" class="form-control" formControlName="username" id="username">
          </div>
          <h6 class="title">Yardi Password:</h6>
          <div class="form-group">
            <input appTrim type="text" autocomplete="off" class="form-control" formControlName="password" id="password">
          </div>
          <h6 class="title">Yardi Property ID:</h6>
          <div class="form-group">
            <input appTrim type="text" autocomplete="off" class="form-control" formControlName="propertyId" id="propertyId">
          </div>
          <h6 class="title">Has Resident Data:</h6>
          <label class="switch">
            <input type="checkbox" formControlName="hasResidentData" id="hasResidentData">
            <span class="slider round"></span>
          </label>
          <div class="pull-right">
            <button [disabled]="integrationToCreateForm.invalid || validatingIntegration" class="button button-dark margin-bottom-24 margin-top-12">
              <span *ngIf="validatingIntegration" class="spinner-border spinner-border-sm mr-1"></span>
              Validate and Enable Integration
            </button>
          </div>
        </form>
      </div>
    </div>

    <!-- Setup RealPage Integration -->
    <div class="row max-100 margin-top-24 margin-left-0" *ngIf="selectedIntegrationType === 'realpage'">
      <div class="col-md-12 no-margin no-padding">
        <img class="logo" src="/assets/realpage-logo.png" />
        <div class="alert alert-warning margin-top-0 margin-bottom-36">
          Please ensure the Nurture Boss has been added in the App Marketplace. <a href="https://info.nurtureboss.io/hubfs/RealPage-Nurture-Boss-App-Marketplace.pdf" target="_blank">Click here for the setup guide.</a>
        </div>
        <form [formGroup]="integrationToCreateForm" (ngSubmit)="createIntegration($event, 'realpage', modal)">
          <h6 class="title">RealPage PMC ID:</h6>
          <div class="form-group">
            <input appTrim type="text" autocomplete="off" formControlName="pmcId" class="form-control" id="pmcId">
          </div>
          <h6 class="title">RealPage Site ID:</h6>
          <div class="form-group">
            <input appTrim type="text" autocomplete="off" class="form-control" formControlName="siteId" id="siteId">
          </div>

          <!-- Leave Disabled Until RealPage Resident Data Integration Is Done -->
          <!-- <h6 class="title">Has Resident Data:</h6>
          <label class="switch">
            <input type="checkbox" formControlName="hasResidentData" id="hasResidentData">
            <span class="slider round"></span>
          </label> -->
          <div class="pull-right">
            <button [disabled]="integrationToCreateForm.invalid || validatingIntegration" class="button button-dark margin-bottom-24 margin-top-12">
              <span *ngIf="validatingIntegration" class="spinner-border spinner-border-sm mr-1"></span>
              Validate and Enable Integration
            </button>
          </div>
        </form>
      </div>
    </div>

    <!-- Setup Entrata Integration -->
    <div class="row max-100 margin-top-24 margin-left-0" *ngIf="selectedIntegrationType === 'entrata'">
      <div class="col-md-12 no-margin no-padding">
        <img class="logo" src="/assets/entrata-logo.png" />
        <div class="alert alert-warning margin-top-0 margin-bottom-36">
          Please ensure the Nurture Boss has been granted API Access. For further assistance please reach out to your Entrata contact.
        </div>
        <form [formGroup]="integrationToCreateForm" (ngSubmit)="createIntegration($event, 'entrata', modal)">
          <div class="form-group">
            <label>Entrata Username</label>
            <input type="text" class="form-control" formControlName="username" id="username" aria-describedby="username" placeholder="Enter Entrata Username">
          </div>
          <div class="form-group">
            <label>Entrata Password</label>
            <input type="text" class="form-control" formControlName="password" id="password" aria-describedby="password" placeholder="Enter Entrata Password">
          </div>
          <div class="form-group">
            <label>Entrata URL</label>
            <input type="text" class="form-control" formControlName="url" id="url" aria-describedby="url" placeholder="Enter Entrata URL. DO NOT INCLUDE /api/v1/">
          </div>
          <div class="form-group">
            <label>Property ID</label>
            <input type="text" class="form-control" formControlName="propertyId" id="propertyId" aria-describedby="propertyId" placeholder="Enter Property ID">
          </div>
          <div *ngIf="isAdmin() || isPartner()">
            <button class="button button-white button-small margin-bottom-12 block" (click)="openModal($event, 'entrata', entrataIntegrationData, true, false)">Lookup Property IDs</button>
          </div>
          <h6 class="title">Has Resident Data</h6>
          <label class="switch">
            <input type="checkbox" formControlName="hasResidentData" id="hasResidentData">
            <span class="slider round"></span>
          </label>
          <div class="pull-right">
            <button [disabled]="integrationToCreateForm.invalid || validatingIntegration" class="button button-dark margin-bottom-24 margin-top-12">
              <span *ngIf="validatingIntegration" class="spinner-border spinner-border-sm mr-1"></span>
              Validate and Enable Integration
            </button>
          </div>
        </form>

      </div>
    </div>

    <!-- Setup Resman Integration -->
    <div class="row max-100 margin-top-24 margin-left-0" *ngIf="selectedIntegrationType === 'resman'">
      <div class="col-md-12 no-margin no-padding">
        <img class="logo" src="/assets/resman-logo.png" />
        <div class="alert alert-warning margin-top-0 margin-bottom-36">
          Please ensure the Nurture Boss granted access to your account and property.
        </div>
        <form [formGroup]="integrationToCreateForm" (ngSubmit)="createIntegration($event, 'resman', modal)">
          <h6 class="title">Resman Account ID:</h6>
          <div class="form-group">
            <input appTrim type="text" autocomplete="off" formControlName="accountId" class="form-control" id="accountId">
          </div>
          <h6 class="title">Resman Property ID:</h6>
          <div class="form-group">
            <input appTrim type="text" autocomplete="off" class="form-control" formControlName="propertyId" id="propertyId">
          </div>

          <!-- Leave Disabled Until RealPage Resident Data Integration Is Done -->
          <!-- <h6 class="title">Has Resident Data:</h6>
          <label class="switch">
            <input type="checkbox" formControlName="hasResidentData" id="hasResidentData">
            <span class="slider round"></span>
          </label> -->
          <div class="pull-right">
            <button [disabled]="integrationToCreateForm.invalid || validatingIntegration" class="button button-dark margin-bottom-24 margin-top-12">
              <span *ngIf="validatingIntegration" class="spinner-border spinner-border-sm mr-1"></span>
              Validate and Enable Integration
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<!-- Edit Existing Integration Modal -->
<ng-template #configureintegration let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Configure Integration</h5>
    <div class="float-right">
        <button type="button" class="button button-white button-small" (click)="exitModal($event, modal)">
          <i class="far fa-times" aria-hidden="true"></i>
        </button>
    </div>
  </div>
  <div class="modal-body enable-integration">

    <!-- Configure Integration -->
    <img class="logo" *ngIf="selectedIntegrationType === 'yardi'" src="/assets/yardi-logo.png" />
    <img class="logo" *ngIf="selectedIntegrationType === 'realpage'" src="/assets/realpage-logo.png" />
    <img class="logo" *ngIf="selectedIntegrationType === 'entrata'" src="/assets/entrata-logo.png" />
    <img class="logo" *ngIf="selectedIntegrationType === 'knock'" src="/assets/knock-logo.png" />
    <img class="logo" *ngIf="selectedIntegrationType === 'rentdynamics'" src="/assets/rentdynamics-logo.png" />
    <img class="logo" *ngIf="selectedIntegrationType === 'rentgrata'" src="/assets/rentgrata-logo.png" />
    <img class="logo" *ngIf="selectedIntegrationType === 'resman'" src="/assets/resman-logo.png" />
    <img class="logo" *ngIf="selectedIntegrationType === 'ace'" src="/assets/ace-logo.png" />

    <div class="col-md-12 no-margin no-padding">
      <form [formGroup]="integrationForm" (ngSubmit)="
        saveIntegrationConfigUpdate(
          selectedIntegrationType,
          (
            selectedIntegrationType === 'knock' ||
            selectedIntegrationType === 'rentdynamics'
          )
        )
      ">
        <!-- INTEGRATION SERVICE TOGGLES -->
        <div *ngIf="getTourSchedulingIntegrations().includes(selectedIntegrationType)">
          <div class="switch-group">
            <h6>Tour Scheduling</h6>
            <label class="switch">
              <input
                type="checkbox"
                class="form-control"
                (change)="onChangeIntegrationServiceToggle({
                  currentService: 'tourScheduling',
                  currentIntegration: propertyInfo.tourScheduling,
                  newIntegration: selectedIntegrationType
                })"
                formControlName="tourScheduling"
                id="tourScheduling"
              >
              <span class="slider round"></span>
            </label>
          </div>
        </div>

        <div *ngIf="getAvailabilitySourceIntegrations().includes(selectedIntegrationType)">
          <div class="switch-group">
            <h6>Availability Source</h6>
            <label class="switch">
              <input
                type="checkbox"
                class="form-control"
                (change)="onChangeIntegrationServiceToggle({
                  currentService: 'availabilitySource',
                  currentIntegration: propertyInfo.availabilitySource,
                  newIntegration: selectedIntegrationType
                })"
                formControlName="availabilitySource"
                id="availabilitySource"
              >
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div *ngIf="getLedgerBalanceSourceIntegrations().includes(selectedIntegrationType)">
          <div class="switch-group">
            <h6>Ledger Balance Source</h6>
            <label class="switch">
              <input
                type="checkbox"
                class="form-control"
                (change)="onChangeIntegrationServiceToggle({
                  currentService: 'ledgerBalanceSource',
                  currentIntegration: propertyInfo.ledgerBalanceSource,
                  newIntegration: selectedIntegrationType
                })"
                formControlName="ledgerBalanceSource"
                id="ledgerBalanceSource"
              >
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div *ngIf="getProspectSourceIntegrations().includes(selectedIntegrationType)">
          <div class="switch-group">
            <h6>Prospect Source</h6>
            <label class="switch">
              <input
                type="checkbox"
                class="form-control"
                formControlName="prospectSource"
                id="prospectSource"
                (change)="onChangeIntegrationServiceToggle({
                  currentService: 'prospectSource',
                  currentIntegration: propertyInfo.prospectSource,
                  newIntegration: selectedIntegrationType
                })"
              >
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div *ngIf="getResidentSourceIntegrations().includes(selectedIntegrationType)">
          <div class="switch-group">
            <h6>Resident Source</h6>
            <label class="switch">
              <input
                type="checkbox"
                class="form-control"
                formControlName="residentSource"
                id="residentSource"
                (change)="onChangeIntegrationServiceToggle({
                  currentService: 'residentSource',
                  currentIntegration: propertyInfo.residentSource,
                  newIntegration: selectedIntegrationType
                })"
              >
              <span class="slider round"></span>
            </label>
          </div>
        </div>

        <!-- AGENTS -->
        <div *ngIf="selectedIntegrationType === 'yardi'">
          <div class="form-group margin-top-24">
            <h6 class="title">Select Agent To Represent Nurture Boss Activity</h6>
            <select class="form-control" formControlName="defaultAgent" autocomplete="off">
              <option *ngFor="let agent of agents" [ngValue]="agent.id">{{agent.name}}</option>
            </select>
          </div>
        </div>
        <div *ngIf="selectedIntegrationType === 'realpage' || selectedIntegrationType === 'entrata' ||  selectedIntegrationType === 'resman' ">
          <div class="form-group margin-top-24">
            <h6 class="title">Select Agent To Represent Nurture Boss Activity</h6>
            <select class="form-control" formControlName="defaultAgentId" autocomplete="off">
              <option *ngFor="let agent of agents" [ngValue]="agent.id">{{agent.name}}</option>
            </select>
          </div>
        </div>

        <!-- SOURCES -->
        <div *ngIf="selectedIntegrationType === 'yardi'">
          <div class="form-group margin-top-24">
            <h6 class="title">Select Traffic Source for New Prospects from Nurture Boss</h6>
            <select class="form-control" formControlName="defaultSource" autocomplete="off">
              <option *ngFor="let source of sources" [ngValue]="source.id" >{{source.name}}</option>
            </select>
          </div>
        </div>
        <div *ngIf="selectedIntegrationType === 'realpage' || selectedIntegrationType === 'entrata' || selectedIntegrationType === 'resman'">
          <div class="form-group margin-top-24">
            <h6 class="title">Select Traffic Source for New Prospects from Nurture Boss</h6>
            <select class="form-control" formControlName="defaultSourceId" autocomplete="off">
              <option *ngFor="let source of sources" [ngValue]="source.id" >{{source.name}}</option>
            </select>
          </div>
        </div>

        <!-- LOST REASONS -->
        <div *ngIf="selectedIntegrationType === 'resman'">
          <div class="form-group margin-top-24">
            <h6 class="title">Select Lost Reason for Lost Prospects</h6>
            <select class="form-control" formControlName="defaultLostReasonId" autocomplete="off">
              <option *ngFor="let lostReason of lostReasons" [ngValue]="lostReason.id" >{{lostReason.name}}</option>
            </select>
          </div>
        </div>

        <!-- EVENT TYPES -->
        <div *ngIf="selectedIntegrationType === 'yardi'">
          <div class="form-group margin-top-24">
            <h6 class="title">Event Type To Use For Texts</h6>
            <select class="form-control" formControlName="textEventId" autocomplete="off">
              <option value="Appointment">Appointment</option>
              <option value="Email">Email</option>
              <option value="WalkIn">WalkIn</option>
              <option value="Other">Other</option>
              <option value="Hold">Hold</option>
              <option value="Release">Release</option>
              <option value="ReActivate">ReActivate</option>
              <option value="CallToProspect">CallToProspect</option>
              <option value="ReApply">ReApply</option>
              <option value="CallFromProspect">CallFromProspect</option>
              <option value="ReturnVisit">ReturnVisit</option>
              <option value="Chat">Chat</option>
              <option value="WebService">WebService</option>
              <option value="Text">Text</option>
            </select>
            <span class="warning-text">* Chat, Text and WebService event types can only be used with clients on 708 Residential Plug-in v7.4 (or higher). Your property also requires a permission for Chat to be a valid event type. Text will be mapped as SMS in Voyager. If you are unsure of any of this, do not choose Chat, Text, or WebService as your event type.</span>
          </div>
        </div>
        <div *ngIf="selectedIntegrationType === 'yardi'">
          <div class="form-group margin-top-24">
            <h6 class="title">Event Type To Use For Emails</h6>
            <select class="form-control" formControlName="emailEventId" autocomplete="off">
              <option value="Appointment">Appointment</option>
              <option value="Email">Email</option>
              <option value="WalkIn">WalkIn</option>
              <option value="Other">Other</option>
              <option value="Hold">Hold</option>
              <option value="Release">Release</option>
              <option value="ReActivate">ReActivate</option>
              <option value="CallToProspect">CallToProspect</option>
              <option value="ReApply">ReApply</option>
              <option value="CallFromProspect">CallFromProspect</option>
              <option value="ReturnVisit">ReturnVisit</option>
              <option value="Chat">Chat</option>
              <option value="WebService">WebService</option>
              <option value="Text">Text</option>
            </select>
            <span class="warning-text">* Chat, Text and WebService event types can only be used with clients on 708 Residential Plug-in v7.4 (or higher). Your property also requires a permission for Chat to be a valid event type. Text will be mapped as SMS in Voyager. If you are unsure of any of this, do not choose Chat, Text, or WebService as your event type.</span>
          </div>
        </div>
        <div *ngIf="selectedIntegrationType === 'entrata'">
          <div class="form-group margin-top-24">
            <h6 class="title">Event Type To Use For Outbound Texts</h6>
            <select class="form-control" formControlName="textEventId" autocomplete="off">
              <option *ngFor="let type of eventTypes" [ngValue]="type.id" >{{type.name}}</option>
            </select>
          </div>
        </div>
        <div *ngIf="selectedIntegrationType === 'entrata'">
          <div class="form-group margin-top-24">
            <h6 class="title">Event Result To Use For Outbound Texts</h6>
            <select class="form-control" formControlName="textEventResultId" autocomplete="off">
              <option *ngFor="let result of eventResults | eventResultPipe:integrationForm.value.textEventId" [ngValue]="result.id" >{{result.name}}</option>
            </select>
          </div>
        </div>
        <div *ngIf="selectedIntegrationType === 'entrata'">
          <div class="form-group margin-top-24">
            <h6 class="title">Event Type To Use For Inbound Texts</h6>
            <select class="form-control" formControlName="inboundTextEventId" autocomplete="off">
              <option *ngFor="let type of eventTypes" [ngValue]="type.id" >{{type.name}}</option>
            </select>
          </div>
        </div>
        <div *ngIf="selectedIntegrationType === 'entrata'">
          <div class="form-group margin-top-24">
            <h6 class="title">Event Result To Use For Inbound Texts</h6>
            <select class="form-control" formControlName="inboundTextEventResultId" autocomplete="off">
              <option *ngFor="let result of eventResults | eventResultPipe:integrationForm.value.inboundTextEventId" [ngValue]="result.id" >{{result.name}}</option>
            </select>
          </div>
        </div>
        <div *ngIf="selectedIntegrationType === 'entrata'">
          <div class="form-group margin-top-24">
            <h6 class="title">Event Type To Use For Emails</h6>
            <select class="form-control" formControlName="emailEventId" autocomplete="off">
              <option *ngFor="let type of eventTypes" [ngValue]="type.id" >{{type.name}}</option>
            </select>
          </div>
        </div>
        <div *ngIf="selectedIntegrationType === 'entrata'">
          <div class="form-group margin-top-24">
            <h6 class="title">Event Type To Use For Inbound Emails</h6>
            <select class="form-control" formControlName="inboundEmailEventId" autocomplete="off">
              <option *ngFor="let type of eventTypes" [ngValue]="type.id" >{{type.name}}</option>
            </select>
          </div>
        </div>
        <div *ngIf="selectedIntegrationType === 'entrata'">
          <div class="form-group margin-top-24">
            <h6 class="title">Event Type To Use For Inbound Calls</h6>
            <select class="form-control" formControlName="inboundCallEventId" autocomplete="off">
              <option *ngFor="let type of eventTypes" [ngValue]="type.id" >{{type.name}}</option>
            </select>
          </div>
        </div>

        <div *ngIf="selectedIntegrationType === 'yardi'">
          <h6 class="title">Default Unit ID</h6>
          <input appTrim type="text" autocomplete="off" class="form-control" formControlName="defaultUnitId" id="defaultUnitId" [required]="!!integrationForm.get('tourScheduling').value">
          <span class="warning-text">* Required to use Yardi Tour Scheduling</span>
        </div>

        <div  *ngIf="
          selectedIntegrationType === 'yardi' ||
          selectedIntegrationType === 'entrata' ||
          selectedIntegrationType === 'realpage' ||
          selectedIntegrationType === 'resman'
        ">
          <div class="form-group margin-top-24">
            <h6 class="title">CRM Contact Sync Frequency In Hours:</h6>
            <input appTrim type="number" autocomplete="off" min="2" max="24" class="form-control" formControlName="pmsCustomSyncFrequency" id="pmsCustomSyncFrequency" aria-describedby="pmsCustomSyncFrequency">
          </div>
        </div>

        <!-- Integration Configuration Details -->
        <div *ngIf="selectedIntegrationType === 'yardi'">
          <div class="form-group margin-top-24">
            <h6 class="title">Integration Configuration:</h6>
            <p>Import All Guest Cards: <span class="badge"
              [ngClass]="
              {
                'badge-success': integrationConfiguration.importAllGuestCards === 'true',
                'badge-danger': integrationConfiguration.importAllGuestCards === 'false',
                'badge-secondary': integrationConfiguration.importAllGuestCards === undefined
              }"
            >{{integrationConfiguration.importAllGuestCards || 'Unknown'}}</span></p>
            <p>CRM Chat Enabled: <span class="badge"
              [ngClass]="
              {
                'badge-success': integrationConfiguration.crmChatEnabled === 'true',
                'badge-danger': integrationConfiguration.crmChatEnabled === 'false',
                'badge-secondary': integrationConfiguration.crmChatEnabled === undefined
              }"
            >{{integrationConfiguration.crmChatEnabled || 'Unknown'}}</span></p>
            <p>Data Lookback: <span class="badge"
              [ngClass]="
              {
                'badge-success': integrationConfiguration.dataLookBack === '90',
                'badge-danger': integrationConfiguration.dataLookBack !== '90'
              }"
            >{{integrationConfiguration.dataLookBack}}</span></p>
            <p>Enable Tour Type Events: <span class="badge"
              [ngClass]="
              {
                'badge-success': integrationConfiguration.enableTourTypeEvents === 'true',
                'badge-danger': integrationConfiguration.enableTourTypeEvents === 'false',
                'badge-secondary': integrationConfiguration.enableTourTypeEvents === undefined
              }"
            >{{integrationConfiguration.enableTourTypeEvents || 'Unknown'}}</span></p>
            <p>Schema Version: <span class="badge"
              [ngClass]="
              {
                'badge-success': integrationConfiguration.schemaVersion === 'v4.0',
                'badge-danger': integrationConfiguration.schemaVersion === 'v2.0',
                'badge-secondary': (integrationConfiguration.schemaVersion === null || integrationConfiguration.schemaVersion === undefined)
              }"
            >{{integrationConfiguration.schemaVersion || 'Unknown'}}</span></p>
            <p>Use Chat Logs: <span class="badge"
              [ngClass]="
              {
                'badge-success': integrationConfiguration.useChatLogs === 'true',
                'badge-danger': integrationConfiguration.useChatLogs === 'false',
                'badge-secondary': integrationConfiguration.useChatLogs === undefined
              }"
            >{{integrationConfiguration.useChatLogs || 'Unknown'}}</span></p>
            <p>Use Floor Plans: <span class="badge"
              [ngClass]="
              {
                'badge-success': integrationConfiguration.useFloorPlans === 'true',
                'badge-danger': integrationConfiguration.useFloorPlans === 'false',
                'badge-secondary': integrationConfiguration.useFloorPlans === undefined
              }"
            >{{integrationConfiguration.useFloorPlans || 'Unknown'}}</span></p>
          </div>

          <div class="form-group margin-top-24">
            <p *ngIf="leaseRenewalConfig.permissions.LeaseRenewals">Lease Renewals:
              <span class="badge" [ngClass]="{ 
                'badge-success' : leaseRenewalConfig.permissions.LeaseRenewals.includes('Read'),
                'badge-danger' : !leaseRenewalConfig.permissions.LeaseRenewals.includes('Read')
              }">
                {{ leaseRenewalConfig.permissions.LeaseRenewals }}
              </span>
            </p>
            <p *ngIf="residentConfig.permissions.Ledger">Ledger:
              <span class="badge" [ngClass]="{ 
                'badge-success' : residentConfig.permissions.Ledger.includes('Read'),
                'badge-danger' : !residentConfig.permissions.Ledger.includes('Read')
              }">
                {{ residentConfig.permissions.Ledger }}
              </span>
            </p>
          </div>
        </div>

        <!-- RentCafe Specific Fields -->
        <div *ngIf="selectedIntegrationType === 'rentcafe'">
          <div class="form-group">
            <h6 class="title">RENTCafé Company Code: <i placement="right" ngbTooltip="This field is required." class="far fa-info-circle"></i></h6>
            <input appTrim type="text" autocomplete="off" class="form-control" formControlName="companyCode" id="companyCode">
          </div>
          <div class="form-group">
            <h6 class="title">RENTCafé Property Id: <i placement="right" ngbTooltip="This field is required unless using RENTCafé Property Code." class="far fa-info-circle"></i></h6>
            <input appTrim type="text" autocomplete="off" class="form-control" formControlName="propertyId" id="propertyId">
          </div>
          <div class="form-group">
            <h6 class="title">RENTCafé Property Code: <i placement="right" ngbTooltip="This field is required unless using RENTCafé Property Id." class="far fa-info-circle"></i></h6>
            <input appTrim type="text" autocomplete="off" class="form-control" formControlName="propertyCode" id="propertyCode">
          </div>
          <div class="form-group relative">
            <h6 class="title">RENTCafé API Key: <i placement="right" ngbTooltip="This field is required unless using RENTCafé Marketing API Vendor Key." class="far fa-info-circle"></i></h6>
            <input appTrim type="text" autocomplete="off" class="form-control" formControlName="apiKey" id="apiKey">
          </div>
        </div>

        <!-- Knock Specific Fields -->
        <div *ngIf="selectedIntegrationType === 'knock'">
          <div class="form-group">
            <h6 class="title">Community ID:</h6>
            <input appTrim type="text" autocomplete="off" class="form-control" formControlName="communityId" id="communityId">
          </div>
          <div class="form-group">
            <h6 class="title">Agent Id:</h6>
            <input appTrim type="text" autocomplete="off" class="form-control" formControlName="agentId" id="agentId">
          </div>
          <div *ngIf="isAdmin() || isPartner()">
            <button class="button button-white button-small margin-bottom-12 block" (click)="openModal($event, 'knock', knockIntegrationData, true, false)">Lookup Community IDs</button>
            <button [attr.disabled]="this.integrationForm.controls.communityId.value ? undefined : 'disabled'" (click)="openModal($event, 'knock', knockIntegrationData, false, true)" class="block button button-white button-small" [disabled]>Lookup Agent IDs</button>
          </div>
        </div>

        <!-- RentDynamics Specific Fields -->
        <div *ngIf="selectedIntegrationType === 'rentdynamics'">
          <div class="form-group">
            <h6 class="title">Community ID:</h6>
            <input appTrim type="text" autocomplete="off" class="form-control" formControlName="communityId" id="communityId">
          </div>
          <div class="form-group">
            <h6 class="title">Community Group ID:</h6>
            <input appTrim type="text" autocomplete="off" class="form-control" formControlName="communityGroupId" id="communityGroupId">
          </div>
          <div class="form-group">
            <h6 class="title">Ad Source Id:</h6>
            <input appTrim type="text" autocomplete="off" class="form-control" formControlName="adSourceId" id="adSourceId">
          </div>
        </div>

        <!-- Rentgrata Specific Fields -->
        <div *ngIf="selectedIntegrationType === 'rentgrata'">
          <div class="form-group">
            <h6 class="title">Widget Key:</h6>
            <input appTrim type="text" autocomplete="off" class="form-control" formControlName="widgetKey" id="widgetKey">
          </div>
          <div class="form-group" *ngIf="isAdmin() || isPartner()">
            <h6 class="title">Listing ID:</h6>
            <p-autoComplete
              styleClass="form-control form-autocomplete rentgrata-listings"
              (onSelect)="onRentgrataListingSelect($event)"
              (onClear)="onRentgrataListingCleared()"
              [dropdown]="true"
              [suggestions]="rentgrataSuggestionsListings"
              (completeMethod)="filterRentgrataAccounts($event)"
              [forceSelection]="false"
              [completeOnFocus]="true"
              field="display"
              [ngModelOptions]="{standalone: true}"
              [(ngModel)]="selectedRentgrataListing"
            >
              <ng-template let-listing pTemplate="item">
                <span>{{ listing.display }}</span>
              </ng-template>
            </p-autoComplete>
          </div>
          <div class="form-group" *ngIf="!isAdmin() && !isPartner()">
            <h6 class="title">Listing ID:</h6>
            <input appTrim type="text" autocomplete="off" class="form-control" formControlName="listingId" id="listingId">
          </div>
        </div>

        <!-- ACE Specific Fields -->
        <div *ngIf="selectedIntegrationType === 'ace'">
          <div class="form-group">
            <h6 class="title">ACE ID:</h6>
            <input appTrim type="text" autocomplete="off" class="form-control" formControlName="aceId" id="aceId">
          </div>
        </div>

        <div class="pull-right">
          <button [disabled]="integrationForm.invalid" class="button button-dark margin-bottom-24 margin-top-12">
            Save Integration Configuration
          </button>
        </div>

      </form>
    </div>
  </div>
</ng-template>
