/*
   *** See SpamLanguageGroupService for info about this service ***
*/
// Libs
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { checkTextForSpamLanguage } from '@nurtureboss/common/dist/utils/spamLanguage';

// Services
import { TokenParserService } from './tokenParser.service';

interface EmailSubjectSpamWarnings {
  spamLanguage: string[],
  excessCapitalLetters: string[],
  exclamationMarks: string[],
};

interface EmailSubjectSpamLanguageWarning {
  field: string,
  violations: string[],
}

const warningsBase = {
  spamLanguage:  [],
  excessCapitalLetters: [],
  exclamationMarks: [],
};

// Used so that token parser ignores these value
const ignoreTokens = {
  clientFirstName: '',
  propertyName: '',
  apartmentName: '',
}

@Injectable({ providedIn: 'any' })
export class EmailSubjectLineSpamLanguageService {
  constructor() {
    // no-op
  }

  private _context: { [key: string]: string; } = {};
  private _shouldDisplay: boolean = false;
  private _tokenParser = new TokenParserService();
  private _warnings: EmailSubjectSpamWarnings = { ...warningsBase };
  public detectedSpamLanguage = new BehaviorSubject<string[]>([]);
  public warningText = '';
  public warnings = new BehaviorSubject<EmailSubjectSpamLanguageWarning[]>([]);

  private emitWarnings(): void {
    const { warningsArray, spamLanguage } = this.getWarnings();

    this.warnings.next(warningsArray);
    this.detectedSpamLanguage.next(spamLanguage);
  }

  getWarnings():{ 
    warningsArray: EmailSubjectSpamLanguageWarning[];
    spamLanguage: string[];
  } {
    const warningsArray: EmailSubjectSpamLanguageWarning[] = [];
    const spamLanguage: string[] = [];

    if (this._warnings.spamLanguage.length) {
      warningsArray.push({
        field: 'Spam Language',
        violations: this._warnings.spamLanguage,
      });

      spamLanguage.push(...this._warnings.spamLanguage);
    }
    if (this._warnings.excessCapitalLetters.length) {
      warningsArray.push({
        field: 'No excess CAPS or ProperCase',
        violations: this._warnings.excessCapitalLetters
      });

      spamLanguage.push(...this._warnings.excessCapitalLetters);
    }
    if (this._warnings.exclamationMarks.length) {
      warningsArray.push({
        field: 'No exclamation marks',
        violations: this._warnings.exclamationMarks,
      });

      spamLanguage.push(...this._warnings.exclamationMarks);
    }

    return { 
      warningsArray,
      spamLanguage,
    };
  }

  resetWarnings(): void {
    this._warnings = { ...warningsBase };
    this.warnings.next([]);
  }

  getDetectedSpamLanguage(): string[] {
    const spamLanguage = [];

    if (this._warnings.spamLanguage.length) {
      spamLanguage.push(...this._warnings.spamLanguage);
    }
    if (this._warnings.excessCapitalLetters.length) {
      spamLanguage.push(...this._warnings.excessCapitalLetters);
    }
    if (this._warnings.exclamationMarks.length) {
      spamLanguage.push(...this._warnings.exclamationMarks);
    }

    return spamLanguage;
  }

  setWarningText(text: string): void {
    this.warningText = text;
  }

  getWarningText(): string {
    return this.warningText;
  }

  setContext(context: { [key: string]: string; }): void {
    this._context = { ...context, ...ignoreTokens };
  }
  
  addContext(context: { [key: string]: string; }): void {
    this._context = { ...this._context, ...context, ...ignoreTokens };
  }

  setShouldDisplay(val: boolean): void {
    this._shouldDisplay = val;
  }

  shouldDisplayWarning(): boolean {
    return this._shouldDisplay && Object.values(this._warnings).some(warnings => { return warnings.length });
  }

  checkForSpamLanguage(text = ''): void {
    // Ensure this runs if null / undefined or something weird gets passed
    // Running with empty string will clear all warnings/violations
    if (!text) {
      text = '';
    }
    const parsedText = this._tokenParser.replaceTokens(text, this._context);
    const spamLanguage = checkTextForSpamLanguage(parsedText);
    const excessCapitalLetters = parsedText.match(/[.\S]*[A-Z][.\S]*[A-Z][.\S]*/g);
    const exclamationWords = parsedText.match(/\w+!/g);
    const standAloneExclamations = parsedText.match(/\B!+/g);
    this._warnings = {
      spamLanguage: spamLanguage?.length ? spamLanguage : [],
      excessCapitalLetters: excessCapitalLetters?.length ? excessCapitalLetters : [],
      exclamationMarks: [...(exclamationWords || []), ...(standAloneExclamations || [])],
    }

    this.emitWarnings();
  }
}