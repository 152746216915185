<div class="row margin-top-12">
  <div class="col-6">
    <strong>Source</strong>
  </div>
  <div class="col-6">
    <strong>Date of Compliance</strong>
  </div>
</div>
<div class="row margin-top-12" *ngFor="let source of storedSources; let i = index">
  <div class="col-6">
    <input
      class="form-control"
      type="text"
      [ngModel]="source.id"
      placeholder="Custom Source"
      *ngIf="allowCustomSources"
      (ngModelChange)="onSourceChanged($event, i)"
    />
    <select *ngIf="!allowCustomSources" class="form-control" [ngModel]="source.id" (ngModelChange)="onSourceChanged($event, i)">
      <option *ngFor="let potentialSource of potentialSources" value="{{potentialSource.id}}">
        {{potentialSource.name}}
      </option>
      <option *ngIf="source.isCorrupted" value="{{source.id}}">{{source.name}}</option>
    </select>
  </div>
  <div class="col-6">
    <p-calendar [ngModel]="source.date" (ngModelChange)="onSourceDateChanged($event, i)" dateFormat="mm-dd-yy"></p-calendar>
    <i class="far fa-trash-alt ml-2 pointer" (click)="onRemoveSource(i)"></i>
  </div>
  <div class="error-state margin-left-12" *ngIf="source.isCorrupted">
    The above source can no longer be found in {{integrationType}}. Please change it accordingly.
  </div>
</div>
<div class="row margin-top-12">
  <div class="col-2">
    <div (click)="addNewSource()" class="button button-light">Add New Source</div>
  </div>
</div>