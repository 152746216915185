import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { APIHelperService } from './apiHelper.service';
import { BehaviorSubject } from 'rxjs';

interface ITextMessageQuery {
  skip: number;
  take: number;
  query?: string;
  property?: string;
  startingDate?: string;
  endingDate?: string;
};

@Injectable({ providedIn: 'root' })
export class TextMessagesService {

  private _unreadMessageCount = new BehaviorSubject<any>({});
  unreadMessageCount$ = this._unreadMessageCount.asObservable();
  localUnreadCount: number = 0;

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService
  ) {
    //no-op
  }

  generateSubAccount(data) {
    return this.http.post<any>(this.apiHelper.fillUrl('textSubaccounts', {}, {}), data)
      .pipe(map(data => {
        return data;
      }));
  }

  getAll(skip, take, query?, property?, startingDate?, endingDate?) {
    const payload: ITextMessageQuery = {
      skip: skip,
      take: take
    };
    if (query && property) {
      payload.query = query;
      payload.property = property;
    }
    if (startingDate && endingDate) {
      payload.startingDate = startingDate;
      payload.endingDate = endingDate;
    }
    return this.http.get<any>(this.apiHelper.fillUrl('getTextMessages', {}, payload))
      .pipe(map(data => {
        return data;
      }));
  }

  scheduleTextSend(payload) {
    return this.http.post<any>(this.apiHelper.fillUrl('schedules', {}, {}), payload)
      .pipe(map(data => {
        return data;
      }));
  }

  getConversations() {
    return this.http.get<any>(this.apiHelper.fillUrl('conversations', {}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getConversation(contactId) {
    return this.http.get<any>(this.apiHelper.fillUrl('conversation', {id: contactId}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  replyToText(data) {
    return this.http.post<any>(this.apiHelper.fillUrl('reply', {id: data.conversationId}, {}), data)
    .pipe(map(data => {
      return data;
    }));
  }

  sendText(data) {
    return this.http.post<any>(this.apiHelper.fillUrl('textMessages', {}, {}), data)
      .pipe(map(data => {
        return data;
      }));
  }

  updateUnreadCountInternally(message) {
    this._unreadMessageCount.next(message);
  }

  updateConversation(id, data) {
    return this.http.put<any>(this.apiHelper.fillUrl('conversation', {id: id}, {}), data)
      .pipe(map(data => {
        return data;
      }));
  }

  getUnreadCount() {
    return this.http.get<any>(this.apiHelper.fillUrl('unread', {}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  updateLocalUnreadCount(count:number) {
    this.localUnreadCount = count;
  }

  getLocalUnreadCount():number {
    return this.localUnreadCount;
  }
}
