<!-- Save Defaults Modal -->
<nb-modal
  [name]="name"
  [confetti]="false"
  [doOnDismiss]="buildSaveDefaultsModalDismissed()"
  header="Confirm Defaults to Update"
  class="save-defaults-modal"
  footer="true"
  [primaryButtonOptions]="saveDefaultsModalPrimaryButton"
>
  <form [formGroup]="form" id="save-defaults-body">
    <div class="checkbox-container">
      <input class="checkbox" type="checkbox" id="defaults" formControlName="defaults">
      <label class="checkbox-label" for="defaults">Update Defaults</label>
    </div>
    <div class="checkbox-container">
      <input class="checkbox" type="checkbox" id="blasts" formControlName="blasts">
      <label class="checkbox-label" for="blasts">Update Blasts</label>
    </div>
    <div class="checkbox-container">
      <input class="checkbox" type="checkbox" id="automations" formControlName="automations">
      <label class="checkbox-label" for="automations">Update Automations</label>
    </div>
  </form>
</nb-modal>
