import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APIHelperService } from './apiHelper.service';
import { User } from '@nurtureboss/common/dist/types/users';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
    private mimickingAccountSub: BehaviorSubject<string> = new BehaviorSubject('');
    public mimickingAccount: Observable<string>;

    constructor(
        private http: HttpClient,
        private apiHelper: APIHelperService,
    ) {
        this.mimicCallback = this.mimicCallback.bind(this);
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.mimickingAccountSub = new BehaviorSubject<string>(localStorage.getItem('mimic') || '');
        this.mimickingAccount = this.mimickingAccountSub.asObservable();
    }

    public get mimickingAccountValue(): string {
        return this.mimickingAccountSub.value;
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    public get isAdmin(): boolean {
        return this.currentUserValue?.user?.claims?.includes('admin') || false;
    }

    public get isGroupUser(): boolean {
        return this.currentUserValue?.user?.claims?.includes('group') || false;
    }

    public get isPartner(): boolean {
        return this.currentUserValue?.user?.claims?.includes('partner') || false;
    }

    public get token(): string | null {
        const localData = localStorage.getItem('currentUser');
        if (!localData) {
            return null;
        }

        try {
            const tokenUser = JSON.parse(localData);
            return tokenUser.token;
        } catch (e) {
            return null;
        }
    }

    login(email: string, password: string) {
        return this.http.post<any>(this.apiHelper.fillUrl('login', {}, {}), { email, password })
            .pipe(map(user => {

                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user.result));
                this.currentUserSubject.next(user.result);
                return user.result;
            }));
    }

    getPasswordResetLink(email: string) {
      return this.http.post<any>(this.apiHelper.fillUrl('resetPasswordLink', {}, {}), { email })
        .pipe(map(response => {
          return response.result;
        }));
    }

    updatePassword(password: string, token: string) {
      return this.http.post<any>(this.apiHelper.fillUrl('updatePassword', {}, { token }), { password })
        .pipe(map(response => {
          return response.result;
        }));
    }

    register(email: string, password: string, passwordMatch: string) {
        return this.http.post<any>(this.apiHelper.fillUrl('register', {}, {}), { email, password, passwordMatch })
            .pipe(map(user => {
                localStorage.setItem('currentUser', JSON.stringify(user.result));
                this.currentUserSubject.next(user.result);
                return user.result;
            }));
    }

    agreeToTerms() {
        return this.http.post<any>(this.apiHelper.fillUrl('terms', {}, {}), {})
            .pipe(map(response => {
                return response;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.clear();
        sessionStorage.clear();
        this.mimickingAccountSub.next('');
        this.currentUserSubject.next(null);
    }

    getAllAccounts() {
        return this.http.get<any>(this.apiHelper.fillUrl('adminAccounts', {}, {}))
            .pipe(map(response => {
                return response;
            }));
    }

    mimic(id) {
        if (this.isAdmin || this.isPartner) { return this.adminMimic(id); }
        if (this.isGroupUser) { return this.groupMimic(id); }
    }

    mimicCallback(response) {
        let mimicProperty = response.result.user.propertyName;
        localStorage.setItem('mimic', mimicProperty);
        if (response.result.user.churned) {
            mimicProperty = `[CHURNED] ${mimicProperty}`;
        }
        this.mimickingAccountSub.next(mimicProperty);
        localStorage.setItem('currentUser', JSON.stringify(response.result));
        this.currentUserSubject.next(response.result);
        return response;
    }

    adminMimic(id) {
        return this.http.get<any>(this.apiHelper.fillUrl('mimicAccount', {id}, {}))
            .pipe(map(this.mimicCallback));
    }

    groupMimic(id) {
        return this.http.get<any>(this.apiHelper.fillUrl('groupMimicAccount', {id}, {}))
        .pipe(map(this.mimicCallback));
    }

    generateKnowledgeBaseJWT() {
        return this.http.get<any>(this.apiHelper.fillUrl('knowledgeBaseAuthenticate', {}, {}))
            .pipe(map(response => {
            return response.result;
            }));
    }

    updateGlobalUserValue(user: User, token: string | null = null): void {
        const storage = JSON.parse(localStorage.getItem('currentUser'));
        if (token) {
            storage.token = token;
        }
        if (this.isAdmin && !user.claims.includes('admin')) {
            user.claims.push('admin');
        }
        if (this.isGroupUser && !user.claims.includes('group')) {
            user.claims.push('group');
        }
        if (this.isPartner && !user.claims.includes('partner')) {
            user.claims.push('partner');
        }
        storage.user = user;
        localStorage.setItem('currentUser', JSON.stringify(storage));
        this.currentUserSubject.next(storage);
    }

    generateDashboardHash(jwt) {
        return this.http.post<any>(this.apiHelper.fillUrl('dashboardAuthenticate', {}, {}), jwt)
            .pipe(map(response => {
                return response.result;
            }));
    }
}
