<div id="media">
  <div class="page-title">
      <h2>Media Library</h2>
  </div>
  <div class="card mt-4">
    <div class="card-body">
    <div class="row" *ngIf="!media || media.length === 0">
      <h3 class="empty-media-holder">You don't have any media yet!<i class="far fa-folder-open"></i></h3>
    </div>
    <div class="row" *ngIf="media && media.length > 0">
      <div class="col-md-3 margin-bottom-24 image-wrapper">
        <div class="image-holder add-media" (click)="onUploadMediaClicked()">
          <div *ngIf="loadingImage" class="spinner-border"></div>
          <input
            *ngIf="!loadingImage"
            id="upload-media-input"
            (change)="uploadMedia($event)"
            type="file"
            [accept]="getAcceptedFileTypes()"
          />
          <i *ngIf="!loadingImage" class="far fa-plus-square"></i>
        </div>
        <h5 (click)="onUploadMediaClicked()">Add Media</h5>
      </div>
      <div class="col-md-3 margin-bottom-24 image-wrapper" *ngFor="let image of media; let i = index">
        <div *ngIf="!isFile(image.url)" (click)="openImage(image.url)" class="image-holder" [style.background-image]="'url(' + image.url + ')'"></div>
        <i
          (click)="openImage(image.url)"
          *ngIf="isFile(image.url)"
          class="file-icon far {{getFileIconClass(image.url)}}"
         ></i>
        <h5>{{image.fileName}}</h5>
        <span class="file-size">{{image.fileSize / 1000 | number:'1.0-0'}} KB</span>
        <span class="delete-image" [class.disabled]="image.loading" (click)="deleteImage(image, i)">
          <div *ngIf="image.loading" class="spinner-border spinner-border-sm"></div>
          <i class="far fa-trash-alt"></i>
        </span>
      </div>
    </div>
  </div>
</div>