<div class="test-container">
  <app-wizard
    [initialContext]="initialContext"
    (onWizardNavigate)="onNavigate($event)"
    (onWizardExit)="onExit($event)"
    (onWizardComplete)="onComplete($event)"
  >
    <app-wizard-step stepId="choose-template" label="Choose Template">
      <app-test-inner name="Choose Template"></app-test-inner>
    </app-wizard-step>
    <app-wizard-step stepId="next-step" label="Next Step">
      <app-test-inner name="Next Step"></app-test-inner>
    </app-wizard-step>
    <app-wizard-step stepId="final-step" label="Final Step">
      <app-test-inner name="Final Step"></app-test-inner>
    </app-wizard-step>
  </app-wizard>
</div>
