import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { deepClone } from '@app/_utils/objects';

export interface ChangeStep<TContext> {
  stepId: string;
  context: TContext;
}

@Injectable()
export class WizardService<TContext extends {}> {

  // The current step of the wizard
  private currentStepId: string= '';

  // The current state of the context
  private currentContext: TContext;

  // A log of the steps taken throughout the wizard
  private stepsTaken = [];
  private stepContext = new Map<string, TContext>();
  private stepIndicies = new Map<string, number>();

  private nextStepSource = new Subject<ChangeStep<TContext>>();
  private stepChangedSource = new Subject<string>();

  nextStep$ = this.nextStepSource.asObservable();
  stepChanged$ = this.stepChangedSource.asObservable();

  /**
   * Used to cleanup the service when processing is complete.
   */
  public cleanup() {
    this.currentStepId = '';
    this.currentContext = null;
    this.stepsTaken = [];
    this.stepContext = new Map<string, TContext>();
    this.stepIndicies = new Map<string, number>();
  }

  /**
   * Allows manually setting current context.
   * The context will be shallow copied
   * So references won't bleed across steps.
   * 
   * @param context - The context to set
   */
  public setContext(context: TContext) {
    this.currentContext = deepClone(context);
  }

  /**
   * Gets the current working context
   * 
   * @returns The current context
   */
  public getContext() {
    return this.currentContext;
  }

  /**
   * Gets the current step id
   * 
   * @returns The current step id
   */
  public getStepId() {
    return this.currentStepId;
  }

  /**
   * Automatically back tracks to the last step.
   */
  public previousStep() {
    if (!this.stepsTaken.length) {
      return;
    }

    this.currentStepId = this.stepsTaken.shift();
    this.currentContext = this.stepContext.get(this.currentStepId);
    this.stepContext.delete(this.currentStepId);
    this.stepChangedSource.next(this.currentStepId);
  }

  /**
   * Moves to the next step.
   * 
   * @param stepId - The next step
   */
  public nextStep(stepId: string) {
    this.stepsTaken.unshift(this.currentStepId);
    this.currentStepId = stepId;
    this.stepContext.set(stepId, deepClone(this.currentContext));
    this.stepChangedSource.next(stepId);
  }

  /**
   * Initializes the wizard.
   * 
   * @param initialContext - The initial context used to kick off the wizard.
   * @param stepId 
   */
  public initialize(initialContext: TContext, stepId: string) {
    this.currentContext = deepClone(initialContext);
    this.stepContext.set(stepId, deepClone(initialContext))
    this.currentStepId = stepId;
    this.stepChangedSource.next(stepId);
  }

  /**
   * Completes the current step.
   * 
   * @param context - The context at the time of completing the current step.
   */
  public completeStep(context: TContext) {
    this.currentContext = deepClone(context);
    this.stepContext.set(this.currentStepId, deepClone(context));
    this.nextStepSource.next({
      stepId: this.currentStepId,
      context: this.currentContext,
    });
  }

  /**
   * Registers steps (presumably in order)
   * that render in the wizard.
   * 
   * @param stepId - The step to register
   */
  public registerStep(stepId: string) {
    this.stepIndicies.set(stepId, this.stepIndicies.size);
  }

  /**
   * Checks if the current step is before the step being tested.
   * 
   * @param stepId - The step to test
   * @returns True if the current step is before the test step
   */
  public isCurrentStepBefore(stepId: string) {
    const currentIndex = this.stepIndicies.get(this.currentStepId);
    const testStepIndex = this.stepIndicies.get(stepId) || -1;
    return currentIndex < testStepIndex;
  }
}