import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ITemplate } from '@nurtureboss/common/dist/types/templates';
import { forkJoin, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import {
  AuthenticationService,
  BrandingsService,
  ContactsService,
  LoaderService,
  RealPageService,
  SpamLanguageGroupService,
  TemplatesService,
  ToastService,
} from '@app/_services';
import { AutomationSettingsService } from '@app/_services/automationSettings.service';
import { BuildYourOwnAutomation, BuildYourOwnStep } from './byoa.types';
import { ActivatedRoute, Router } from '@angular/router';
import { AutomationOriginator, AutomationStepExecutionMode, AutomationStepSchedulingMode, SystemAutomation } from '@nurtureboss/common/dist/types/automations';
import { YardiService } from '@app/_services/yardi.service';
import { runWorker } from '../_utils/workers';
import { CanDeactivateComponent } from '@app/_helpers/pending-changes.guard';
import { EntrataService } from '@app/_services/entrata.service';
import { UserAuditsService } from '@app/_services/userAudits.service';
import { IntegrationService } from '@app/_services/integration.service';

interface MultiselectOption {
  label: string;
  value: string;
}

interface SpamLanguageGroupWarning {
  emailSubjectLineSpamLanguage: string[];
  spamLanguage: string[];
}

const RESIDENT_AUTOMATION_NAMES = [
  'Lease Renewal Automation',
  'Resident Outreach Automation',
  'Rent Reminder Automation'
]

@Component({
  selector: 'app-byoa',
  templateUrl: './byoa.component.html',
  styleUrls: ['./byoa.component.less']
})
export class ByoaComponent implements OnInit, OnDestroy, CanDeactivateComponent {

  contactTypes: MultiselectOption[] = [
    {
      label: 'Prospect',
      value: 'prospect',
    },
    {
      label: 'Resident',
      value: 'resident',
    },
  ];

  contactStages: MultiselectOption[] = [
    {
      label: 'Pre Tour',
      value: 'Pre Tour',
    },
    {
      label: 'Pre Application',
      value: 'Pre Application',
    },
    {
      label: 'Pre Move In',
      value: 'Pre Move In',
    },
    {
      label: 'Leasing/Leased',
      value: 'Leasing/Leased',
    },
    {
      label: 'Resident',
      value: 'Resident',
    },
  ];

  automationForm: FormGroup;
  steps: FormArray;
  sources: MultiselectOption[];
  statuses: MultiselectOption[];
  templates: ITemplate[] = [];
  templateDefaults: any[] = [];
  branding: { fontFamily: string; primaryColor: string; };
  stepDefaults: Array<[number, any]> = [];
  automation: BuildYourOwnAutomation | null = null;
  automationSteps: BuildYourOwnStep[] = [];
  automationId: string = null;
  automationName: string = '';
  saveErrorMessages: string[] = [];
  queryableContactCount: number = 0;
  allowQueryEdit = true;
  contactsQueryVisible: boolean = true;
  loadingContactQueryCount: boolean = false;
  isSystemAutomation = false;
  stepExecutionMode = AutomationStepExecutionMode.Chronologically;
  stepSchedulingMode = AutomationStepSchedulingMode.Optimize;
  isYardiUse = false;
  isRealPageUser = false;
  isEntrataUser = false;
  isResmanUser = false;
  isParserUser = false;
  isProspectAutomation = true;
  // Update Lost Lead Trigger Code
  isLostLeadAutomation = false;
  // Update Delinquency Trigger Code
  isDelinquencyAutomation = false;
  canUseConclusionStep = false;
  isConclusionStepDisabled = false;
  allowStepSchedulingMode = false;
  isVLA = false;
  hasLedgerBalanceSource = false;
  spamGroupWarnings: SpamLanguageGroupWarning[];
  subscription: Subscription;
  warningCollapse = {};

  constructor(
    private templateService: TemplatesService,
    private brandingService: BrandingsService,
    private contactsService: ContactsService,
    private automationService: AutomationSettingsService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private authService: AuthenticationService,
    private yardiService: YardiService,
    private realPageService: RealPageService,
    private route: ActivatedRoute,
    private router: Router,
    private entrataService: EntrataService,
    private formBuilder: FormBuilder,
    private userAuditsService: UserAuditsService,
    private integrationService: IntegrationService,
    private spamLanguageGroupService: SpamLanguageGroupService,
  ) {
    //no-op
  }

  ngOnInit(): void {
    // Group warnings are all active spam warnings for a group put togther. See SpamLanguageGroupWarning above.
    this.subscription = this.spamLanguageGroupService.groupViolations.subscribe(value => {
      // setTimeout is used to avoid this error: ExpressionChangedAfterItHasBeenCheckedError
      setTimeout(() => { this.spamGroupWarnings = value }, 0);
    });
    this.initFields();
    this.loadAutomation();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.spamLanguageGroupService.clearAll();
  }

  @HostListener('window:beforeunload')
  canDeactivate() {
    if (this.automationForm.pristine) {
      return true;
    }

    return confirm('There are some pending changes that have not been saved. If you leave the page they will be lost. Are you sure you want to leave the page?');
  }

  async loadSources() {
    try {
      let jobFunction = null;
      let checkFunction = null;

      const currentUser = this.authService.currentUserValue.user;
      if (currentUser.claims.includes('yardi')) {
        jobFunction = () => this.yardiService.getYardiSourcesQuery().toPromise();
        checkFunction = (jobId: string) => this.yardiService.checkYardiWorkers(jobId).toPromise();
      } else if (currentUser.claims.includes('realpage')) {
        jobFunction = () => this.realPageService.getSourcesQuery().toPromise();
        checkFunction = (jobId: string) => this.realPageService.checkRealPageWorkers(jobId).toPromise();
      } else if (currentUser.claims.includes('resman')) {
        const response = await this.integrationService.getSources(currentUser.integrationPropertyId, 'resman').toPromise();
        return response?.result;
      } else if (currentUser.claims.includes('entrata')) {
        jobFunction = () => this.entrataService.getSourcesQuery().toPromise();
        checkFunction = (jobId: string) => this.entrataService.checkEntrataWorkers(jobId).toPromise();
      
      // Check Knock last because if this branch is hit it means they have Knock
      // but no other integration so we cannot retrieve sources but we should not error.
      } else if (currentUser.claims.includes('knock')) {
        return [];
      } else {
        this.toastService.showError('Could not load sources for non-integrated user');
        return [];
      }
  
      const result = await runWorker(jobFunction, checkFunction);
      if (result.errors && result.errors.length) {
        this.toastService.showError(result.errors);
        return [];
      }

      if (currentUser.claims.includes('yardi')) {
        return result.result.results.sources;
      }
      return result.result.results;
    } catch (error) {
      this.toastService.showError('There was an error loading your sources');
      return [];
    }
  }

  loadAutomation() {
    this.loaderService.triggerLoader();

    const observables = [
      this.templateService.getTemplates(),
      this.brandingService.getBrandings(),
      this.contactsService.getContactMetadata(),
    ];

    this.automationId = this.route.snapshot.queryParams['id'];
    if (this.automationId) {
      this.allowQueryEdit = false;
      observables.push(this.templateService.getUserAutomationTemplateDefaults(this.automationId));
      observables.push(this.automationService.getSingleBuildYourOwnAutomation(this.automationId));
      observables.push(this.integrationService.getPropertyInfo(this.authService.currentUserValue.user.integrationPropertyId));
    } else {
      observables.push(this.templateService.getUserGlobalDefaults());
    }

    forkJoin(observables).subscribe(async (resultsArray) => {
      this.templates = resultsArray[0].result;

      const currentUser = this.authService.currentUserValue.user;
      if (currentUser.claims.includes('yardi')) {
        this.isYardiUse = true;
      } else if (currentUser.claims.includes('realpage')) {
        this.isRealPageUser = true;
      } else if (currentUser.claims.includes('entrata')) {
        this.isEntrataUser = true;
      } else if (currentUser.claims.includes('resman')) {
        this.isResmanUser = true;
      } else if (currentUser.claims.includes('parser')) {
        this.isParserUser = true;
      }
      
      // We can support templates with limited messaging, but
      // we need to test claims before allowing special templates
      this.templates = this.templates.filter((template) =>
        (!template.authorizedClaims || !template.authorizedClaims.length)
        // Does the user have at least one of the authorized claims?
        || (template.authorizedClaims.filter((claim) => currentUser.claims.includes(claim)).length > 0)
      );

      this.templates.sort((x, y) => x.templateName.localeCompare(y.templateName));

      this.branding = resultsArray[1].result || {
        fontFamily: 'Alegreya Sans',
        primaryColor: '#235587',
      };

      const sourceSet = new Set<string>();
      if (currentUser.claims.includes('parser') || currentUser.claims.includes('ilm')) {
        for (const name of resultsArray[2].result.sources) {
          sourceSet.add(name);
        }
      } else {
        try {
          const sources = await this.loadSources();
          for (const {name} of sources) {
            sourceSet.add(name);
          }
        } catch (error) {
          this.toastService.showError('Failed to load sources');
        }
      }
      
      this.sources = Array.from(sourceSet.values()).map((source) => ({
        label: source,
        value: source,
      }));
      this.sources.sort((x, y) => x.label.localeCompare(y.label));

      const rawstatuses = resultsArray[2].result.statuses;
      rawstatuses.sort((x, y) => x.localeCompare(y));

      this.statuses = rawstatuses.map((status) => ({
        label: status,
        value: status,
      }));

      this.automationForm.controls.contactStatus.setValue([{...this.statuses[0]}]);

      // Sort is used later when grabbing the most recent record.
      this.templateDefaults = resultsArray[3].result.sort((a, z) => {
        return +new Date(z.updated) - +new Date(a.updated);
      });

      if (this.automationId) {
        this.automation = resultsArray[4].result as BuildYourOwnAutomation;

        // Need the VLA property to build UI, then need to delete so it doesn't mess up the backend form
        // created for managing and updating automation properties.
        this.isVLA = !!this.automation?.isVLA;
        delete this.automation.isVLA;

        // Update Lost Lead Trigger Code
        this.isLostLeadAutomation = this.automation?.name === SystemAutomation.LostLeadAutomation;
        // Update Delinquency Trigger Code
        this.isDelinquencyAutomation = this.automation?.name.includes(SystemAutomation.DelinquencyAutomation);
        this.isProspectAutomation = !RESIDENT_AUTOMATION_NAMES.includes(this.automation.name)

        if (this.automation?.name === SystemAutomation.TextOptInAutomation) {
          this.automationForm.get('contactStage').setValidators([]);
          this.automationForm.get('contactStage').updateValueAndValidity();
        }

        const property = resultsArray[5];
        if (
          (this.automation.contactType.includes('resident') && property.residentSource === 'entrata')
          || (this.automation.contactType.includes('prospect') && property.prospectSource === 'entrata')
        ) {
          this.isEntrataUser = true;
        }

        // This works for now since Mark Lost Leads in the only option
        if (
          this.isProspectAutomation 
          && property.prospectIntegrationType !== 'ilm'
          && property.prospectSource !== 'parser'
        ) {
          this.canUseConclusionStep = true;
        }

        if (property.prospectSource === 'resman' && !property?.resman?.defaultLostReasonId) {
          this.isConclusionStepDisabled = true
        }

        if (!!property.ledgerBalanceSource) {
          this.hasLedgerBalanceSource = true;
        }

        if (this.automation.name !== 'Appointment Reminder Automation') {
          this.allowStepSchedulingMode = true;
        }

        this.automationName = this.automation.name;
        this.isSystemAutomation = this.automation.originator === AutomationOriginator.System;
        this.stepExecutionMode = this.automation.stepExecutionMode || AutomationStepExecutionMode.Chronologically;
        this.stepSchedulingMode = this.automation.stepSchedulingMode || AutomationStepSchedulingMode.Optimize;

        if (this.isSystemAutomation) {
         this.templates = this.templates.filter(({_id}) => (this.automation as any).templateRestrictions.includes(_id));
        }

        const formValues: any = {...this.automation};
        delete formValues.id;
        delete formValues.ownerId;
        delete formValues.templateRestrictions;
        delete formValues.stepExecutionMode;

        // TRUE here means Optimized and FALSE here means Immediate
        formValues.stepSchedulingMode = this.stepSchedulingMode === AutomationStepSchedulingMode.Optimize;
        formValues.stepFollowUpMode = formValues.stepFollowUpMode?.checkForFollowUps || false;
        if (!formValues.dayToRun) {
          formValues.dayToRun = 1;
        }
        if (formValues.contactCreated) {
          formValues.contactCreated = new Date(formValues.contactCreated);
        } else {
          formValues.contactCreated = null;
        }

        // We need to map to the appropriate UI values
        if (formValues.contactType) {
          formValues.contactType = formValues.contactType.map((type) => this.contactTypes.find(({value}) => value === type));
        }
        if (formValues.contactStage) {
          formValues.contactStage = formValues.contactStage.map((stage) => this.contactStages.find(({value}) => value === stage));
        }
        if (formValues.contactStatus) {
          const newStatuses: MultiselectOption[] = [];
          for (const status of formValues.contactStatus) {
            if (!this.statuses.find(({value}) => value === status)) {
              this.statuses.push({
                label: status,
                value: status,
              });
            }
            newStatuses.push({
              label: status,
              value: status,
            });
          }
          this.statuses.sort((x, y) => x.value.localeCompare(y.value));
          formValues.contactStatus = newStatuses;
        }

        const nonExistantSources = new Set<string>();
        if (formValues.contactSource) {
          const newSources: MultiselectOption[] = [];
          for (const source of formValues.contactSource) {
            if (!this.sources.find(({value}) => value === source)) {
              this.sources.push({
                label: source,
                value: source,
              });
              nonExistantSources.add(source);
            }

            newSources.push({
              label: source,
              value: source,
            });
          }
          this.sources.sort((x, y) => x.value.localeCompare(y.value));
          formValues.contactSource = newSources;
        }

        // We need to add these from the steps component
        this.automationSteps = formValues.steps || [];
        formValues.steps = [];

        // TODO: When we support automation level triggers in the UI, add triggers to
        // formValue so we don't have to re-insert here.
        if (this.automation?.trigger?.expression) {
          this.automationForm.addControl('trigger', this.formBuilder.control('', []));
        }

        // Update Lost Lead Trigger Code
        if (this.isLostLeadAutomation) {
          this.automationForm.addControl('lookbackDistance', this.formBuilder.control(180));
          this.automationForm.addControl('lookbackField', this.formBuilder.control('guestcard-created-date'));
          // @ts-ignore
          formValues.lookbackDistance = parseInt(this.automation.trigger.expression.rightOperand.rightOperand.variable);
          // @ts-ignore
          formValues.lookbackField = this.automation.trigger.expression.rightOperand.leftOperand.property;
        }

        if (this.isDelinquencyAutomation) {
          // Update Delinquency Trigger Code
          // @ts-ignore
          this.automationForm.addControl('delinquencyAmount', this.formBuilder.control(parseInt(this.automation.trigger.expression.rightOperand.variable), [Validators.required, Validators.min(1)]));
          // @ts-ignore
          formValues.delinquencyAmount = parseInt(this.automation.trigger.expression.rightOperand.variable);
        }

        this.automationForm.setValue(formValues);
        this.automationForm.markAsPristine({ onlySelf: false });
        this.testContactQuery(this.mapFormToByoa(formValues));
        if (nonExistantSources.size) {
          this.automationForm.controls.contactSource.setErrors({
            outdated: `The following sources no longer exist in your CRM: ${Array.from(nonExistantSources.values()).join(', ')}`
          });
          this.automationForm.controls.contactSource.markAsTouched({
            onlySelf: true,
          });
        }
      }

      this.automationForm.valueChanges.pipe(debounceTime(500)).subscribe((formValues) => {
        this.testContactQuery(this.mapFormToByoa(formValues));
      });

      this.loaderService.stopLoader();
    }, (error) => {
      this.showUserError('Error loading automation data');
      this.loaderService.stopLoader();
    });
  }

  toggleContactQuery() {
    this.contactsQueryVisible = !this.contactsQueryVisible;
  }

  initFields() {
    this.steps = new FormArray([]);
    this.automationForm = new FormGroup({
      isActive: new FormControl(false),
      originator: new FormControl(AutomationOriginator.User),
      name: new FormControl('', [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
      ]),
      description: new FormControl(''),
      runOnSpecificDay: new FormControl(false),
      dayToRun: new FormControl(1, [
        Validators.min(1),
        Validators.max(28),
      ]),
      contactStage: new FormControl([{...this.contactStages[0]}], [
        Validators.required,
      ]),
      contactStatus: new FormControl('', [
        Validators.required,
      ]),
      contactSource: new FormControl(''),
      contactType: new FormControl([{...this.contactTypes[0]}], [
        Validators.required,
      ]),
      contactCreatedFilter: new FormControl('ignore', [
        Validators.required,
      ]),
      contactCreated: new FormControl(''),
      steps: this.steps,
      stepSchedulingMode: new FormControl(''),
      stepFollowUpMode: new FormControl(''),
    });
  }

  // Return all violations from email subject line and spam language service in a single array
  getSpamViolationsFromGroupWarning(spamGroupWarning: SpamLanguageGroupWarning) {
    return [...spamGroupWarning.emailSubjectLineSpamLanguage, ...spamGroupWarning.spamLanguage];
  }

  groupHasSpamViolation() {
    return this.spamGroupWarnings.some(groupWarning => {
      return !![...groupWarning.emailSubjectLineSpamLanguage, ...groupWarning.spamLanguage].length
    })
  }

  testContactQuery(byoa: BuildYourOwnAutomation) {
    this.loadingContactQueryCount = true;
    this.automationService.testBuildYourOwnAutomationQuery(byoa, this.automation?.id || '').subscribe(({ result }) => {
      this.loadingContactQueryCount = false;
      this.queryableContactCount = result && result.count || 0;
    });
  }

  onStepDefaultsChanged(newDefaults: Array<[number, any]>) {
    this.stepDefaults = newDefaults;
    this.stepDefaults.sort(([indexX, ...x], [indexY, ...y]) => indexX - indexY);

    if (this.automationId) {
      this.templateService.getUserAutomationTemplateDefaults(this.automationId).subscribe((data) => {
        this.templateDefaults = data.result;
      });
    }
  }

  mapFormToByoa(formValues: any) {
    const byoa = {...formValues} as BuildYourOwnAutomation;
    byoa.contactType = (byoa.contactType as any as MultiselectOption[]).map(({value}) => value);
    byoa.contactStage = ((byoa.contactStage || []) as any as MultiselectOption[]).map(({value}) => value);
    byoa.contactStatus = (byoa.contactStatus as any as MultiselectOption[]).map(({value}) => value);
    byoa.contactSource = ((byoa.contactSource || []) as any as MultiselectOption[]).map(({value}) => value);
    
    // TRUE here means Optimized and FALSE here means Immediate
    if (byoa.stepSchedulingMode) {
      byoa.stepSchedulingMode = AutomationStepSchedulingMode.Optimize;
    } else {
      byoa.stepSchedulingMode = AutomationStepSchedulingMode.Immediate;
    }
    byoa.stepExecutionMode = this.stepExecutionMode;
    byoa.stepFollowUpMode = {
      checkForFollowUps: !!byoa.stepFollowUpMode,
      // TODO: Remove this hardcoded bit when we add to the UI.
      requiredFollowUpDelay: 24,
    };
    for (let i = 0; i < byoa.steps.length; i++) {
      if (i >= this.automationSteps.length) {
        continue;
      }
      byoa.steps[i].stepId = this.automationSteps[i].stepId;
    }

    // TODO: When we support automation level triggers in the UI, add triggers to
    // formValue so we don't have to re-insert here.
    if (this.automation?.trigger?.expression) {
      byoa.trigger = this.automation.trigger;
    }
    return byoa;
  }

  updateAutomation(stepDefaultsArray: any[]) {
    this.loaderService.triggerLoader();
    this.automationService.updateBuildYourOwnAutomation(this.automationId, this.mapFormToByoa(this.automationForm.value), stepDefaultsArray).subscribe(async () => {
      // Update Lost Lead Trigger Code
      if (this.isLostLeadAutomation) {
        await this.updateLostLeadTrigger(this.automationId).toPromise();
      }
      // Update Delinquency Trigger Code
      if (this.isDelinquencyAutomation) {
        await this.updateDelinquencyTrigger(this.automationId).toPromise();
      }
      this.automationForm.markAsPristine({ onlySelf: false });
      this.showUserSuccess('Successfully saved automation!');
      this.userAuditsService.refreshTriggeredUserAudits();
      this.loaderService.stopLoader();
    }, (err) => {
      this.displayPossibleSaveErrors(err);
      this.loaderService.stopLoader();
    });
  }

  async save() {
    this.saveErrorMessages = [];
    this.loaderService.triggerLoader();

    if (
      this.automation &&
      this.automation.name === SystemAutomation.LeaseRenewalAutomation &&
      this.automation.originator === AutomationOriginator.System &&
      // @ts-ignore
      // && this.automation.isActive === false
      this.automationForm.controls.isActive.value === true &&
      !(await this.hasLeaseRenewalPermission()) &&
      !this.isParserUser
    ) {
      this.showUserError(`Cannot activate automation: Error validating resident source lease renewal permission.`);
      this.loaderService.stopLoader();
      return;
    }

    if (
      this.automation &&
      this.automation.name.includes(SystemAutomation.DelinquencyAutomation)
      && this.automation.originator === AutomationOriginator.System
      // @ts-ignore
      // && this.automation.isActive === false
      && this.automationForm.controls.isActive.value === true
      && !this.hasLedgerBalanceSource
    ) {
      this.showUserError(`Cannot activate automation: Error validating resident source ledger data permission.`);
      this.loaderService.stopLoader();
      return;
    }
    // Null out Day To Run if not enabled.
    if (!this.automationForm.controls.runOnSpecificDay.value) {
      this.automationForm.controls.dayToRun.setValue(null);
    }
    const stepDefaultsArray = this.stepDefaults.map(([_, defaults]) => defaults);
    if (this.automationForm.controls.isActive.value) {
      for (let i = 0; i < stepDefaultsArray.length; i++) {
        const stepDefault = stepDefaultsArray[i];
        if (!this.templateService.isTemplateDefaultValid(
          this.templates.find(({ templateName }) => stepDefault.templateName === templateName),
          stepDefault,
        )) {
          this.showUserError(`Cannot turn on automation when follow up #${stepDefault.stepIndex + 1} has invalid template defaults!`);
          return;
        }
      }
    }
    if (this.automationId) {
      this.updateAutomation(stepDefaultsArray);
    } else {
      this.loaderService.triggerLoader();
      this.automationService.createBuildYourOwnAutomation(this.mapFormToByoa(this.automationForm.value), stepDefaultsArray).subscribe((data) => {
        this.automationForm.markAsPristine({ onlySelf: false });
        this.showUserSuccess('Successfully saved automation!');
        this.router.navigate(['/automation/byoa'], {queryParams: {id: data.result.id}});
        location.hash += '?id=' + data.result.id;
        location.reload();
      }, (err) => {
        this.displayPossibleSaveErrors(err);
        this.loaderService.stopLoader();
      });
    }
  }

  displayPossibleSaveErrors(err) {
    const unwrapError = (errObj) => {
      if (!errObj) {
        return;
      }

      if (typeof errObj === 'string') {
        this.saveErrorMessages = [...this.saveErrorMessages, errObj];
        return;
      }

      if (Array.isArray(errObj)) {
        errObj.forEach(unwrapError);
        return;
      }

      if (typeof errObj !== 'object') {
        return;
      }

      for (const propName of Object.keys(errObj)) {
        if (!errObj[propName]) {
          continue;
        }
  
        unwrapError(errObj[propName]);
      }
    }

    if (this.isVLAError(err)) {
      this.showUserError(err);
    } else {
      unwrapError(err);
  
      this.showUserError('There was an error saving your automation');
    }
  }

  isVLAError(err) {
    let errMessage;
    if (Array.isArray(err)) {
      errMessage = err[0];
    } else if (typeof err === 'string'){
      errMessage = err
    } else {
      return false;
    }

    return [
      'Pre Tour VLA Automation must be disabled before activing this automation.',
      'Post Tour VLA Automation must be disabled before activing this automation.',
      'Non-VLA Pre Tour Automation must be disabled before activing this automation.',
      'Non-VLA Post Tour Automation must be disabled before activing this automation.',
    ].includes(errMessage);
  }

  showUserSuccess(message) {
    this.toastService.show(message, {
      classname: 'bg-success text-light',
      delay: 5000
    });
  }

  showUserError(message) {
    this.toastService.show(message, {
      classname: 'bg-danger text-light',
      delay: 5000
    });
  }

  // This helps us force the form to be touched
  onToggleChanged(event, dayToRunChange?) {
    if (dayToRunChange) {
      this.clearDayToRun(this.automationForm.controls.runOnSpecificDay.value);
    }
    this.automationForm.markAsTouched();
  }
  
  clearDayToRun(runOnSpecificDay) {
    if (!runOnSpecificDay) {
      this.automationForm.controls.dayToRun.setValue(null);
    }
  }

  // Update Lost Lead Trigger Code
  updateLostLeadTrigger(automationId) {
    return this.automationService.updateLostLeadInfo(automationId, {
      lookbackField: this.automationForm.value.lookbackField,
      lookbackDistance: String(this.automationForm.value.lookbackDistance),
    });
  }

  // Update Delinquency Trigger Code
  updateDelinquencyTrigger(automationId) {
    return this.automationService.updateDelinquencyInfo(automationId, {
      delinquencyAmount: String(this.automationForm.value.delinquencyAmount),
    });
  }
  async hasLeaseRenewalPermission() {
    const propertyId = this.authService.currentUserValue.user.integrationPropertyId;
    try {
      // Return await so that error handling happens here
      return await this.integrationService.getLeaseRenewalPermission(propertyId).toPromise();
    } catch (err) {
      return false;
    }
  }

  expandWarning(event) {
    var target = event.target || event.srcElement || event.currentTarget;
    var idAttr = target.attributes.id;
    var value = idAttr.nodeValue;
    this.warningCollapse[value] = !this.warningCollapse?.[value];
  }
}
