import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { APIHelperService } from './apiHelper.service';

export interface IWidgetConfig {
  iconMediaId: string | null;
  screenPositionX: string;
  screenPositionY: string;
  xOffset: number | null;
  yOffset: number | null;
}

export interface ICurrentBranding {
  _id: string;
  primaryColor: string;
  fontFamily: string;
  widget: IWidgetConfig;
}

@Injectable({ providedIn: 'root' })
export class BrandingsService {

  private branding: ICurrentBranding;

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService
  ) {
    // no-op
  }

  public get currentBranding() {
    return this.branding || {};
  }

  /**
   * Fetches brandings, caches when possible
   * @param bustCache if the cache should be busted, used when mimicking accounts and login
   */
  getBrandings(bustCache = false) {
    if (this.branding && !bustCache) {
      return of({
        result: this.branding,
        errors: [],
      });
    }

    return this.http.get<any>(this.apiHelper.fillUrl('brandings', {}, {}))
      .pipe(map(data => {
        this.setBranding(data.result);
        return data;
      }));
  }

  private setBranding(data) {
    const { fontFamily, primaryColor, widget, _id } = data;
    this.branding = { fontFamily, primaryColor, widget, _id };
  }

  updateBranding(id, payload) {
    return this.http.put<any>(this.apiHelper.fillUrl('branding', { id }, {}), payload)
      .pipe(map(data => {
        this.setBranding(data.result);
        return data;
      }));
  }

  loadFonts() {
    return this.http.get<any>('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyA2jk9Fi9KXGLbIlmrqhf3SWnmB1f0h7oc&sort=popularity')
      .pipe(map(data => {
        return data;
      }));
  }
}
