/*
   *** See bottom for info about all Spam Services ***
*/
import { v4 as uuidv4 } from 'uuid';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SpamLanguageService } from './spamLanguage.service';
import { EmailSubjectLineSpamLanguageService } from './emailSubjectLineSpamLanguage.service';

interface SpamLanguageGroup {
    spamLanguageService: SpamLanguageService;
    emailSubjectLineSpamLanguageService: EmailSubjectLineSpamLanguageService;
}

interface SpamLanguageGroupViolations {
  emailSubjectLineSpamLanguage: string[];
  spamLanguage: string[];
}

@Injectable({ providedIn: 'any' })
export class SpamLanguageGroupService {
  constructor() {
    // no-op
  }

  private _groups: SpamLanguageGroup[] = [];
  private _groupViolations: SpamLanguageGroupViolations[] = [];
  private _indexedKeys: string[] = [];
  public groupViolations = new BehaviorSubject<SpamLanguageGroupViolations[]>([]);

  addGroup(): SpamLanguageGroup {
    const emailSubjectLineSpamLanguageService = new EmailSubjectLineSpamLanguageService();
    const spamLanguageService = new SpamLanguageService();

    // Keys are used to be able to update warnings to the correct group
    // since indexes can change if a group is removed
    const key = uuidv4();
    this._indexedKeys.push(key);

    // Set default value of group warning
    this._groupViolations.push({
      emailSubjectLineSpamLanguage: [],
      spamLanguage: [],
    });

    // Callback to update _groupViolations with any new violations in spamLanguageService
    spamLanguageService.detectedSpamLanguage.subscribe(spamLanguage => {
      const index = this._indexedKeys.indexOf(key);
      this._groupViolations[index].spamLanguage = spamLanguage;

      this.groupViolations.next(this._groupViolations);
    });

    // Callback to update _groupViolations with any new violations in emailSubjectLineSpamLanguageService
    emailSubjectLineSpamLanguageService.detectedSpamLanguage.subscribe(emailSubjectSpamLanguage => {
      const index = this._indexedKeys.indexOf(key);
      this._groupViolations[index].emailSubjectLineSpamLanguage = emailSubjectSpamLanguage;

      this.groupViolations.next(this._groupViolations);
    });

    this._groups.push({
      spamLanguageService,
      emailSubjectLineSpamLanguageService,
    })

    return this._groups[this._groups.length - 1];
  }

  removeGroup(index: number): void {
    if (this._groups[index]) {
      this._groups.splice(index, 1);
      this._groupViolations.splice(index, 1);
      this._indexedKeys.splice(index, 1);
      this.groupViolations.next(this._groupViolations);
    }
  }

  getGroup(index: number): SpamLanguageGroup {
    return this._groups[index];
  }

  clearAll(): void {
    this._groups = [];
    this._groupViolations = [];
    this._indexedKeys = [];
  }
}

/*
  Spam Service Terms and Relationships
  ====================================

  group - a grouping of a single instance of both EmailSubjectLineSpamLanguageService and SpamLanguageService.
  warning - data that contains a field and all detected spam violations.
  violation - a spam word or group of words detected.

  interface SpamLanguageWarning {
    field: string,
    violations: string[],
  }

  *** SpamLanguageGroupService ***
  |__  _groups -------  An array of SpamLanguageGroup
  |
  |__  _indexedKeys --- An array of unique keys (uuid) used to find the current index of a group.
  |                     Each keys represents one group. By scoping the key generated into the subscribe callbacks
  |                     you can run indexOf(key) on _indexedKeys to get the index of the related SpamLanguageGroup
  |                     This system of indexes is used since the index of the group when created will not be the same
  |                     if a group above it is removed
  |
  |__  _groupViolations - Local state of all violations from all spam groups organized by group and service.
  |                     Positioning is the same as groups so the same index can be used
  |
  |__  groupViolations -- Observable subject that emits _groupViolations whenever it is updated
  |
  |__ *** SpamLanguageService ***
  |   |__  _context ------------- Data for parsing tokens. Settable through getContext or addContext.
  |   |
  |   |__  _shouldDisplay ------- Boolean value for determining whether spam warnings should show (does not affect spam detection and will continue checking)
  |   |
  |   |__  _warnings ------------ Private variable for storing warning state
  |   |
  |   |__  warningText ---------- Text used when displaying spam language violations
  |   |
  |   |__  warnings ------------- A spam warning is a field and all detected spam violations
  |   |                           warnings in the SpamLanguageService is an Observable Subject that emits an array of SpamLanguageWarning objects
  |   |            
  |   |__  detectedSpamLanguage - An Observable Subject that emits an array of all violations from all spam language warnings without reference to the field
  |
  |__ *** EmailSubjectLineSpamLanguageService ***
      |__  _context ------------- Data for parsing tokens. Settable through getContext or addContext.
      |
      |__  _shouldDisplay ------- Boolean value for determining whether spam warnings should show (does not affect spam detection and will continue checking)
      |
      |__  _warnings ------------ Private variable for storing warning state
      |
      |__  warningText ---------- Text used when displaying spam language violations
      |
      |__  warnings ------------- A spam warning is a field and all detected spam violations.
      |                           warnings in the EmailSubjectLineSpamLanguageService is an Observable Subject that emits an array of SpamLanguageWarning objects
      |                           but the field does not refer to an input like the SpamLanguageService.
      |                           Instead it refers to the 3 possible spam language violations:
      |                             spamLanguage -         any detected spam language
      |                             excessCapitalLetters - any words with more than 1 capital letter
      |                             exclamationMarks - any exclamation marks or words with exlamations marks
      |
      |__  detectedSpamLanguage - An Observable Subject that emits an array of all violations from all spam language warnings without reference to the field

*/