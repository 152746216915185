<div>
  <div [stickAtTop]="stickyHeaderOptions" class="step-header">
    <h5 class="pt-4 pb-2 ml-4 pl-1">Verify Details</h5>
    <div class="pull-right mb-4 mr-2 margin-top-24">
      <nb-button 
        cssClass="margin-right-12"
        [loading]="loading"
        title="Schedule Send"
        color="dark"
        [disabled]="currentContext?.delivery?.emergency"
        [ngbTooltip]="currentContext?.delivery?.emergency ? 'Cannot schedule send an emergency message' : null"
        (click)="onSchedule($event)">
      </nb-button>
      <nb-button cssClass="pull-right margin-right-12" [loading]="loading" (click)="onSendNow($event)" title="Send Now" color="white"></nb-button>
    </div>
  </div>
  <div class="broadcast-scheduler">
    <div class="summary-wrapper">
      <div class="summary-wrapper-row">
        <div class="summary-wrapper-row-inner margin-bottom-24">
          <div class="delivery-method">
            <h6 class="margin-bottom-24">Delivery Method</h6>
            <div *ngIf="currentContext?.delivery?.method === 'optimize'" class="margin-bottom-48 margin-top-48">
              <i class="far fa-bolt"></i> {{currentContext?.delivery?.method | titlecase}}
            </div>
            <div *ngIf="currentContext?.delivery?.method === 'text'" class="margin-bottom-48 margin-top-48">
              <i class="far fa-comment-alt-lines"></i> {{currentContext?.delivery?.method | titlecase}}
            </div>
            <div *ngIf="currentContext?.delivery?.method === 'email'" class="margin-bottom-48 margin-top-48">
              <i class="far fa-envelope"></i> {{currentContext?.delivery?.method | titlecase}}
            </div>
          </div>
          <div class="recipients">
            <h6 class="margin-bottom-24">Recipients</h6>
            <app-card-total [title]="contactSelectionMessage" [total]="contactCount" class="margin-bottom-48" [fixedWidth]="150"></app-card-total>
          </div>
        </div>
        <div class="summary-wrapper-row-inner">
          <div class="delivery-message">
            <div *ngIf="currentContext?.message?.textMessageContent" class="margin-bottom-24">
              <h6 class="margin-bottom-24">Text Message Content</h6>
              <textarea 
                rows="5"
                readonly
                class="form-control"
              >{{currentContext?.message?.textMessageContent}}</textarea>
            </div>
            <div *ngIf="currentContext?.message?.emailSubjectLine" class="margin-bottom-24">
              <app-spam-warnings
                [shouldDisplay]="emailSubjectLineSpamLanguageService.shouldDisplayWarning()"
                [warningText]="emailSubjectLineSpamLanguageService.getWarningText()"
                [spamWarnings]="spamWarnings"
                type="emailSubjectLineSpamLanguage"
              ></app-spam-warnings>
              <h6 class="margin-bottom-24">Email Subject Line</h6>
              <input
                class="form-control"
                readonly
                [value]="currentContext?.message?.emailSubjectLine"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="summary-wrapper-row">
        <div class="selected-template">
          <h6 class="margin-bottom-24">{{currentContext?.template?.templateName}} Template</h6>
          <div>
            <app-iphone-preview
              [emailUrl]="getEmailPreviewUrl(currentContext.template)"
              [nurturePageUrl]="getPagePreviewUrl(currentContext.template)"
              helperText="Loading..."
              errorText="Something went wrong when generating your preview!"
              [forceDisplayType]="previewDisplayType"
              [emailSubjectLine]="getEmailSubject()"
            ></app-iphone-preview>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-schedule-modal [timeValidator]="scheduleTimeValidator" (onScheduleCreated)="onScheduleCreated($event)"></app-schedule-modal>
<nb-modal
  name="send-broadcast-success-modal"
  [confetti]="true"
  [doOnDismiss]="closeSuccessModal()"
  class="send-success-modal"
>
  <img src="assets/message-send-confirmation.png" />
  <h2>
    Blast&nbsp;
    <span *ngIf="currentContext?.schedule?.type === 'immediate'">Sent</span>
    <span *ngIf="currentContext?.schedule?.type === 'scheduled'">Scheduled</span>!
  </h2>
  <div class="button button-dark margin-top-24" (click)="closeSuccessModal()()">Continue</div>
</nb-modal>

<nb-modal
  name="send-broadcast-status-modal"
  [doOnDismiss]="closeStatusModal()"
  class="send-success-modal"
>
  <div class="broadcast-build-status">
    <p [class]="broadcastCreateStatus.broadcast">
      <i *ngIf="broadcastCreateStatus.broadcast === 'waiting'" class="far fa-stopwatch"></i>
      <span *ngIf="broadcastCreateStatus.broadcast === 'inprogress'" class="spinner-border"></span>
      <i *ngIf="broadcastCreateStatus.broadcast === 'error'" class="far fa-exclamation-circle"></i>
      <i *ngIf="broadcastCreateStatus.broadcast === 'success'" class="far fa-check-circle"></i>
      <span [ngClass]="{'shimmer-text' : broadcastCreateStatus.broadcast === 'waiting'}" class="status-title">Creating Blast</span>
    </p>
    <p [class]="broadcastCreateStatus.emails" *ngIf="currentContext?.delivery?.method === 'email' || currentContext?.delivery?.method === 'optimize'">
      <i *ngIf="broadcastCreateStatus.emails === 'waiting'" class="far fa-stopwatch"></i>
      <span *ngIf="broadcastCreateStatus.emails === 'inprogress'" class="spinner-border"></span>
      <i *ngIf="broadcastCreateStatus.emails === 'error'" class="far fa-exclamation-circle"></i>
      <i *ngIf="broadcastCreateStatus.emails === 'success'" class="far fa-check-circle"></i>
      <span [ngClass]="{'shimmer-text' : broadcastCreateStatus.emails === 'waiting'}" class="status-title">Creating Emails</span>
    </p>
    <p [class]="broadcastCreateStatus.nurturePages" *ngIf="currentContext?.delivery?.method === 'text' || currentContext?.delivery?.method === 'optimize'">
      <i *ngIf="broadcastCreateStatus.nurturePages === 'waiting'" class="far fa-stopwatch"></i>
      <span *ngIf="broadcastCreateStatus.nurturePages === 'inprogress'" class="spinner-border"></span>
      <i *ngIf="broadcastCreateStatus.nurturePages === 'error'" class="far fa-exclamation-circle"></i>
      <i *ngIf="broadcastCreateStatus.nurturePages === 'success'" class="far fa-check-circle"></i>
      <span [ngClass]="{'shimmer-text' : broadcastCreateStatus.nurturePages === 'waiting'}" class="status-title">Creating Nurture Pages</span>
    </p>
    <p [class]="broadcastCreateStatus.sending">
      <i *ngIf="broadcastCreateStatus.sending === 'waiting'" class="far fa-stopwatch"></i>
      <span *ngIf="broadcastCreateStatus.sending === 'inprogress'" class="spinner-border"></span>
      <i *ngIf="broadcastCreateStatus.sending === 'error'" class="far fa-exclamation-circle"></i>
      <i *ngIf="broadcastCreateStatus.sending === 'success'" class="far fa-check-circle"></i>
      <span [ngClass]="{'shimmer-text' : broadcastCreateStatus.sending === 'waiting'}" class="status-title">{{currentContext?.schedule?.type === 'immediate' ? 'Sending Messages' : 'Creating Schedules'}} </span>
    </p>
    <p *ngIf="filteredContactCount > 0" class="error">{{filteredContactCount}} contact(s) were skipped due to invalid data.</p>
    <div *ngIf="createBroadcastFailed">
      <nb-button (click)="onRetrySend($event)" title="Retry" color="dark"></nb-button>
    </div>
  </div>
</nb-modal>
