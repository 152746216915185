import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APIHelperService } from './apiHelper.service';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {

  private accessTokenSubject = new BehaviorSubject<string>('');

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService
  ) {}

  public get accessToken(): string {
    return this.accessTokenSubject.value;
  }

  resetAccessToken(): void {
    const sub = this.getAccessToken().subscribe(() => {
      sub.unsubscribe();
    });
  }

  getAccessToken(): Observable<string> {
    return this.http.get<any>(this.apiHelper.fillUrl('generateWidgetToken', {}, {}))
      .pipe(map(data => {
        this.accessTokenSubject.next(data.result);
        return data.result;
      }));
  }

  generateWidgetToken() {
    return this.http.post<any>(this.apiHelper.fillUrl('generateWidgetToken', {}, {}), {})
    .pipe(map(data => {
      this.accessTokenSubject.next(data.result.token);
      return data;
    }));
  }
}
