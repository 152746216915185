import { Injectable } from '@angular/core';
import { FormGroup, ValidatorFn } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class RentCafeValidator {
  public validateRentCafeForm(): ValidatorFn {
    return (formGroup: FormGroup) => {
      const companyCodeControl = formGroup.get('companyCode');
      const propertyIdControl = formGroup.get('propertyId');
      const propertyCodeControl = formGroup.get('propertyCode');
      const apiKey = formGroup.get('apiKey');

      if (
        !companyCodeControl.value
        || (!propertyIdControl.value && !propertyCodeControl.value)
        || !apiKey.value
      ) {
        return { invalidRentCafeForm: true };
      }
      return null;
    };
  }
}
