<div id="sendTextMessageContainer" [ngClass]="{'d-flex': !hideRightPane}">
  <div class="left-pane pr-0" [ngClass]="{'left-pane-small': !hideRightPane}">
    <!-- Other form values inserted by the parent -->
    <ng-content select="[singleTextMessageContainer]"></ng-content>

    <div class="form-group" [formGroup]="formGroup" [ngClass]="{'pr-2': hideRightPane}">
      <label for="textMessageContent">Text Message Content</label>
      <textarea
        [id]="textElementId"
        rows="5"
        formControlName="textMessageContent"
        class="form-control {{ submitted && errors ? 'is-invalid' : ''}}"
        [maxlength]="maxLength"
        (click)="onMouseClick()"
      >
      </textarea>
      <app-character-count [control]="formGroup.get('textMessageContent')" [maxCharacters]="maxLength"></app-character-count>
      <div *ngIf="submitted && errors" class="invalid-feedback">
        <div *ngIf="sendTextMessageForm.controls.textMessageContent.errors.required">Text Message Content is required
        </div>
      </div>

      <div class="template-actions-container col-12">
        <nb-button type="button"
          title="Save As Template" color="gray" cssClass="pull-right mr-2" size="sm"
          (click)="openModal($event, 'save-text-template-modal')"></nb-button>
        <nb-button type="button"
          title="Insert Template" size="sm" color="gray" cssClass="pull-right"
          (click)="openModal($event, 'load-text-template-modal')"></nb-button>
      </div>
      <app-print-errors controlName="Text Message Content" [control]="formGroup.controls.textMessageContent"></app-print-errors>
      <app-token-container 
        *ngIf="!hideClickableTokens" 
        [maxLength]="maxLength" 
        title="Use tokens to customize your message" 
        [formGroup]="formGroup"
        [tokenSource]="tokens" 
        controlName="textMessageContent" 
        [textSourceElementId]="textElementId" 
        [tokensToHide]="tokensToHide"
        [validators]="validators"
      >
      </app-token-container>
    </div>
  </div>


  <div class="right-pane pr-0 mt-4" *ngIf="!hideRightPane">
    <app-card-total [title]="'Number of Contacts Selected'" [total]="totalContacts ? totalContacts : 1">
    </app-card-total>
    <app-tips-and-tricks [links]="tipsAndTricksLinks"></app-tips-and-tricks>
  </div>
</div>

<!-- Save Text Template Modal -->
<nb-modal
  name="save-text-template-modal"
  header="Save New Text Template"
  [confetti]="false"
  [primaryButtonOptions]="primaryButtonOptions"
  footer="true"
>
  <div class="clear save-text-template-form form-group margin-bottom-24">
    <div class="row form-group">
      <label for="pageName" class="ml-2 pl-1">Text Template Name</label>
      <input placeholder="Name your template!" type="text" [(ngModel)]="textTemplateName"
        [ngModelOptions]="{standalone: true}" class="ml-2 form-control no-active-state"
        (ngModelChange)="updateTextTemplateSaveDisabledState()" />
    </div>
  </div>
</nb-modal>

<!-- Load Existing Text Template Modal -->
<nb-modal
  name="load-text-template-modal"
  header="Use Existing Text Template"
  [confetti]="false"
  footer="false"
  >
  <p class="empty-template" *ngIf="loadingTextTemplates">
    Loading Templates...
  </p>
  <div *ngIf="textTemplates" class="clear text-template-picker-wrap">
    <h6 class="sub-title">Custom Templates</h6>

    <p class="empty-template" *ngIf="textTemplates && textTemplates.length === 0">
      No Templates Saved
    </p>

    <div class="text-template-picker-list" *ngIf="textTemplates && textTemplates.length > 0">
      <div class="text-template-picker" *ngFor="let template of textTemplates; let i = index"
        (click)="selectTextTemplate($event, template, actionModal)">
        <span class="pull-left">{{template.name}}</span>
        <span class="pull-left template-message">
          {{template.message}}
        </span>
        <span class="pull-right" (click)="deleteTextTemplate(template._id, i, $event)"><i class="far fa-trash-alt"
            aria-hidden="true"></i></span>
      </div>
    </div>

    <h6 class="sub-title">Default Templates</h6>

    <div class="text-template-picker-list">
      <div class="text-template-picker" *ngFor="let template of defaultTexTemplate; let i = index"
        (click)="selectTextTemplate($event, template, actionModal)">
        <span class="pull-left">{{template.name}}</span>
        <span class="pull-left template-message">
          {{template.message}}
        </span>
      </div>
    </div>
  </div>
</nb-modal>