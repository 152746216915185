import { Component } from '@angular/core';
import { AuthenticationService, ToastService, LoaderService, UsersService, RealPageService } from '@app/_services';
import { YardiService } from '@app/_services/yardi.service';
import { AutomationSettingsService } from '@app/_services/automationSettings.service';
import { IntegrationService } from '@app/_services/integration.service';
import { forkJoin } from 'rxjs';
import { EntrataService } from '@app/_services/entrata.service';
import { IProperty } from '@app/_types/integration.types';
import { UserAuditsService } from '@app/_services/userAudits.service';


@Component({
  selector: 'app-compliance',
  templateUrl: './compliance.component.html',
  styleUrls: ['./compliance.component.less']
})
export class ComplianceComponent {

  loadingUserSettings: boolean = false;
  userData: any = {
    claims: []
  };
  userSmsSettings: any = {};
  localUserData: any;
  hasIntegration: boolean = false;
  integrationType: string = 'your integration';
  sources: any = [];
  storedSources: any = [];
  savingSettings: boolean = false;
  allowCustomSources: boolean = false;
  property: IProperty | null = null;

  constructor(
    private authService: AuthenticationService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private yardiService: YardiService,
    private automationSettingsService: AutomationSettingsService,
    private usersService: UsersService,
    private realPageService: RealPageService,
    private entrataService: EntrataService,
    private integrationService: IntegrationService,
    private userAuditsService: UserAuditsService,
  ) {
      this.localUserData = this.authService.currentUserValue;
      this.loadData();
  }

  loadAutomationSettings() {
    this.automationSettingsService.getAutomationSettings(this.userData._id).subscribe((autoSettings) => {
      this.storedSources = autoSettings.result.sources;
      this.formatDates(this.storedSources);
      this.backfillSourceId(this.storedSources);
      this.loaderService.stopLoader();
    }, (e) => {
      this.displayError('There was an error loading your Yardi sources');
      this.loaderService.stopLoader();
    })
  }

  // TODO: If we add more sources (which we will) we should re-evaluate how we handle integrations.
  // Potentially expose these endpoints from some integration service so we only query CRMs/PMSs
  // from a single location.
  loadYardiSources() {
    this.yardiService.getYardiSources().subscribe((data) => {
      try {
        this.sources = data.result.sources;
        this.sortSourcesByName();
        this.loadAutomationSettings();
      } catch (e) {
        this.loaderService.stopLoader();
        this.displayError('There was an error loading your Yardi sources');
      }
    }, (e) => {
      this.loaderService.stopLoader();
      this.displayError('There was an error loading your Yardi sources');
    });
  }

  loadRealPageSources() {
    forkJoin([
      this.realPageService.getSources(),
      this.automationSettingsService.getAutomationSettings(this.userData._id),
    ])
    .subscribe((output) => {
      const realpageSources = output[0];
      if (realpageSources.errors.length) {
        this.loaderService.stopLoader();
        this.displayError('There was an error loading your RealPage Traffic sources.');
        return;
      }

      const settings = output[1];
      if (settings.errors.length) {
        this.loaderService.stopLoader();
        this.displayError('There was an error loading your stored opt-in sources.');
        return;
      }

      this.sources = realpageSources.result;
      this.sortSourcesByName();
      this.storedSources = settings.result.sources;
      this.formatDates(this.storedSources);
      this.backfillSourceId(this.storedSources);
      this.loaderService.stopLoader();
    }, (e) => {
      this.loaderService.stopLoader();
      this.displayError('There was an error loading your RealPage Traffic sources.');
    });
  }

  loadEntrataSources() {
    forkJoin([
      this.entrataService.getSources(),
      this.automationSettingsService.getAutomationSettings(this.userData._id),
    ])
    .subscribe((output) => {
      const entrataSources = output[0];
      if (entrataSources.errors.length) {
        this.loaderService.stopLoader();
        this.displayError('There was an error loading your Entrata Traffic sources.');
        return;
      }

      const settings = output[1];
      if (settings.errors.length) {
        this.loaderService.stopLoader();
        this.displayError('There was an error loading your stored opt-in sources.');
        return;
      }

      this.sources = entrataSources.result;
      this.sortSourcesByName();
      this.storedSources = settings.result.sources;
      this.formatDates(this.storedSources);
      this.backfillSourceId(this.storedSources);
      this.loaderService.stopLoader();
    }, (e) => {
      this.loaderService.stopLoader();
      this.displayError('There was an error loading your Entrata Traffic sources.');
    });
  }

  loadResmanSources() {
    forkJoin([
      this.integrationService.getSources(this.userData.integrationPropertyId, 'resman'),
      this.automationSettingsService.getAutomationSettings(this.userData._id),
    ])
    .subscribe((output) => {
      const resmanSources = output[0];
      if (resmanSources.errors.length) {
        this.loaderService.stopLoader();
        this.displayError('There was an error loading your Resman Marketing sources.');
        return;
      }

      const settings = output[1];
      if (settings.errors.length) {
        this.loaderService.stopLoader();
        this.displayError('There was an error loading your stored opt-in sources.');
        return;
      }

      this.sources = resmanSources.result;
      this.sortSourcesByName();
      this.storedSources = settings.result.sources;
      this.formatDates(this.storedSources);
      this.backfillSourceId(this.storedSources);
      this.loaderService.stopLoader();
    }, (e) => {
      this.loaderService.stopLoader();
      this.displayError('There was an error loading your Resman Marketing sources.');
    });
  }

  sortSourcesByName() {
    this.sources.sort((x, y) => x.name.localeCompare(y.name));
  }

  displayError(errorMessage: string) {
    this.toastService.show(errorMessage, { classname: 'bg-danger text-light', delay: 5000 });
  }

  displaySuccess(successMessage: string) {
    this.toastService.show(successMessage, { classname: 'bg-success text-light', delay: 5000 });
  }

  loadData() {
    this.loaderService.triggerLoader();
    forkJoin({
      data: this.usersService.getUserData(this.localUserData.user._id),
      smsSettings: this.usersService.getSmsSettings(),
    })
    .subscribe((userDataSettings) => {
      this.userData = userDataSettings.data.result;
      const localStorageUser = this.authService.currentUserValue;
      const isAdmin = localStorageUser.user.claims.includes('admin');
      const isPartner = localStorageUser.user.claims.includes('partner');
      // update local user record
      if (isAdmin) {
        userDataSettings.data.result.claims.push('admin');
      } else if (isPartner) {
        userDataSettings.data.result.claims.push('partner');
      }
      this.localUserData.user = userDataSettings.data.result;
      this.loadingUserSettings = false;

      if (this.userData.integrationPropertyId) {
        this.integrationService.getPropertyInfo(this.userData.integrationPropertyId).subscribe((property: any) => {
          this.property = property;
          switch (property.prospectSource) {
            case 'yardi':
              this.hasIntegration = true;
              this.allowCustomSources = false;
              this.integrationType = 'Yardi';
              this.loadYardiSources();
              break;
            case 'realpage':
              this.hasIntegration = true;
              this.allowCustomSources = false;
              this.integrationType = 'RealPage';
              this.loadRealPageSources();
              break;
            case 'entrata':
              this.hasIntegration = true;
              this.allowCustomSources = false;
              this.integrationType = 'Entrata';
              this.loadEntrataSources();
              break;
            case 'resman':
              this.hasIntegration = true;
              this.allowCustomSources = false;
              this.integrationType = 'Resman';
              this.loadResmanSources();
              break;
            case 'knock':
              this.hasIntegration = true;
              this.allowCustomSources = true;
              // load automation settings
              this.loadAutomationSettings();
              break;
            case 'parser':
              this.hasIntegration = true;
              this.allowCustomSources = true;
              // load automation settings
              this.loadAutomationSettings();
              break;
          }
        })
      }
      if (!Array.isArray(userDataSettings.smsSettings.result) && userDataSettings.smsSettings.result) {
        this.userSmsSettings = userDataSettings.smsSettings.result;
      }
    }, (_err) => {
      this.loaderService.stopLoader();
      this.displayError('There was an error retrieving your user settings');
      this.loadingUserSettings = false;
    })
  }

  sendSourcesUpdateCall(sources) {
    this.savingSettings = true;
    this.automationSettingsService.updateSources(this.userData._id, sources).subscribe((data) => {
      this.storedSources = data.result.sources;
      this.formatDates(this.storedSources);
      this.backfillSourceId(this.storedSources);
      this.savingSettings = false;
      this.displaySuccess('Approved sources updated!');
      this.userAuditsService.refreshTriggeredUserAudits();
    }, (err) => {
      this.savingSettings = false;
      this.displayError('There was an error updating your approved sources');
    });
  }

  isSourceValid(source) {
    if (
      !source.id ||
      !source.id.length ||
      !source.name ||
      !source.name.length ||
      !source.date ||
      source.isCorrupted
    ) {
        return false;
    }
    return true;
  }

  updateSources() {
    let valid = true;
    for (const source of this.storedSources) {
      delete source.isCorrupted;
      if (!this.isSourceValid(source)) {
        valid = false;
      }
    }
    if (!valid) {
      this.displayError('Set Name and Date for all sources.');
    } else {
      this.sendSourcesUpdateCall(this.storedSources);
    }
  }

  updateUserSettings() {
    this.loaderService.triggerLoader();
    this.usersService.updateUser(this.userData._id, {
      optIn: this.userData.optIn,
      doubleOptIn: this.userData.doubleOptIn,
      keyword: this.userData.keyword
    }).subscribe(() => {
      this.loaderService.stopLoader();
      this.displaySuccess('Settings updated!');
    }, (e) => {
      this.loaderService.stopLoader();
      this.displayError('There was an error updating settings');
    });
  }

  updateSmsSettings() {
    this.loaderService.triggerLoader();
    this.usersService.setSmsSettings(this.userSmsSettings).subscribe((result) => {
      this.loaderService.stopLoader();
      this.displaySuccess('Settings updated!');
    },
    (err) => {
      this.loaderService.stopLoader();
      this.displayError('There was an error updating settings');
    })
  }

  formatDates(list) {
    for (const listItem of list) {
      listItem.date = new Date(listItem.date);
    }
  }

  // This will help backfill existing
  // yardi sources with source ids as they used
  // to only have names.
  backfillSourceId(automationSourceList) {
    for (const automationSource of automationSourceList) {
      if (!automationSource.id) {
        automationSource.id = automationSource.name;
      }
    }
  }

  public get isAdmin(): boolean {
    return this.localUserData.user.claims.includes('admin');
  }
}
