import { Injectable } from '@angular/core';
import { FormControl, ValidatorFn, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { ITemplate, ITemplateField, TemplateFieldSource } from '@nurtureboss/common/dist/types/templates';
import { TemplateDefault } from '@nurtureboss/common/dist/types/templateDefaults';
import { FIELD_AUTO_GENERATORS } from '@nurtureboss/common/dist/utils/template';
import { AuthenticationService } from './authentication.service';
import { removeKeysFromStringArray, addKeysToStringArray } from '@app/_utils/arrays';
import { WidgetService } from './widget.service';

export interface IFormData {
  fields: ITemplateField[];
  name: string;
  order: number;
  controlName: string;
}

@Injectable()
export class TemplateFormService {
  constructor(private authService: AuthenticationService, private widgetService: WidgetService) {
    this.user = this.authService.currentUserValue.user;
  }

  template: ITemplate;
  form: FormGroup;
  templateDefaults: TemplateDefault;
  user: any;

  private getValidators(input: ITemplateField): ValidatorFn[] {
    const validators: ValidatorFn[] = [];
    if (input.required) {
      validators.push(Validators.required);
    }
    return validators;
  }

  private shouldTemplateFieldBeDisabled(input: ITemplateField): boolean {
    if (FIELD_AUTO_GENERATORS[input.name] && FIELD_AUTO_GENERATORS[input.name].isAvailableToUser(this.user) && this.widgetService.accessToken) {
      return true;
    }

    return input.dataSource === TemplateFieldSource.Property;
  }

  private getFormFieldValue(field: ITemplateField): string {
    let val: string = '';
    const defaultValue = this.templateDefaults[field.name] || '';
    if (field.dataSource === TemplateFieldSource.Property) {
      val = this.user[field.name] || ''
    } else if (FIELD_AUTO_GENERATORS[field.name] && FIELD_AUTO_GENERATORS[field.name].isAvailableToUser(this.user) && this.widgetService.accessToken) {
      val = FIELD_AUTO_GENERATORS[field.name].templateValuePlaceholder;
    } else {
      val = defaultValue;
    }
    return val;
  }

  makeForm(template: ITemplate, templateDefaults: TemplateDefault): FormGroup {
    this.template = template;
    this.templateDefaults = templateDefaults;
    const formGroup = template.groups.reduce((acc, group) => {
      const fields = group.fields.reduce((fieldAcc, field) => {
        if (field.dataSource === TemplateFieldSource.Contact) {
          return fieldAcc;
        }
        fieldAcc[field.name] = new FormControl({
          value: this.getFormFieldValue(field), 
          disabled: this.shouldTemplateFieldBeDisabled(field),
        }, this.getValidators(field));
        return fieldAcc;
      }, {});

      if (Object.keys(fields).length > 0) {
        acc[group.name] = new FormGroup(fields);
      }

      return acc;
    }, {});
    
    this.form = new FormGroup(formGroup);
    return this.form;
  }

  getSortedFormData(template: ITemplate): IFormData[] {
    const formDataArray: IFormData[] = template.groups.reduce((acc, group) => {
      const fields = group.fields.filter((field) => {
        return field.dataSource !== TemplateFieldSource.Contact;
      });
      if (fields.length > 0) {
        acc.push({display: group.display, name: group.name, fields});
      }
      return acc;
    }, []);

    return formDataArray;
  }

  getControlByKeys(groupKey: string, controlKey: string): AbstractControl {
    const formGroup = this.getFormGroupByKey(groupKey);
    return formGroup['controls'][controlKey];
  }

  getFormGroupByKey(key: string): FormGroup {
    return this.form.controls[key] as FormGroup;
  }

  getTemplateGroup(key: string) {
    return this.template.groups.find(group => {
      return group.name === key;
    });
  }

  getRawValues(): {[key: string]: string} {
    const rawValues = this.form.getRawValue();
    return Object.keys(rawValues).reduce((acc, value) => {
      acc = {...acc, ...rawValues[value]};
      return acc;
    }, {});
  };

  getTokensToHide(): string[] {
    return [
      'mainImage',
      'bannerImage',
      'centerImage',
      'centerLogo',
      'leftLogo',
      'onlineApplicationUrl',
      'onlineApplicationLinkText',
      'fileAttachment',
      'footerContent',
      'type',
      'created',
      'updated',
      '__v',
      '_id',
      'ownerId',
      'templateName',
      'pageName',
      'label',
      'yardiGuestCardId',
      'realPageGuestCardId',
      'knockProspectId',
      'entrataGuestCardId',
      'entrataApplicationId',
      'entrataApplicantId',
      'yardiTenantId',
      'realPageTenantId',
      'entrataTenantId',
      'resmanPersonId',
      'resmanLeaseId',
      'mainText',
      'headerText',
    ];
  }

  getTokensToHideForEmailInput(): string[] {
    let tokens = this.getTokensToHide();
    tokens = addKeysToStringArray(tokens, ['yourPhoneNumber']);
    tokens = removeKeysFromStringArray(tokens, ['yourEmailAddress']);
    return tokens;
  };

  getTokensToHideForTextInput(): string[] {
    let tokens = this.getTokensToHide();
    tokens = addKeysToStringArray(tokens, ['yourEmailAddress']);
    tokens = removeKeysFromStringArray(tokens, ['yourPhoneNumber']);
    return tokens;
  };

  getUserSpecificTokenValues(): {[key: string]: string} {
    return {
      apartmentName: this.user.propertyName,
    };
  };
}
