<div id="contact" [ngStyle]="{'min-height': adjustedHeight}">
  <div class="row">
    <div class="col-sm-2" [ngStyle]="{'max-height': adjustedHeight}">
      <ul class="innder-side-nav" [ngStyle]="{'min-height': adjustedHeight}">
        <li [class.active]="router.url.includes('/contact/events')">
          <i class="far fa-calendar"></i> <a (click)="navigateTo('events')">Events</a>
        </li>
        <li [class.active]="router.url.includes('/contact/info')">
          <i class="far fa-user-circle"></i> <a (click)="navigateTo('info')">Contact Info</a>
        </li>
      </ul>
    </div>
    <div class="col-sm-10">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>