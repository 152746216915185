import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { 
  AuthenticationService, 
  BrandingsService, 
  TemplatesService, 
  ToastService, 
  UsersService,
  SettingsService,
  SettingType, 
} from '@app/_services';
import { NurtureBossModalService } from '@app/_services/modal.service';
import { ITemplate, TemplateMessagingTypes, TemplateTypes } from '@nurtureboss/common/dist/types/templates';
import { TemplateSettings } from '@nurtureboss/common/dist/types/settings';
import { LegacyTemplateOption, TemplateSelectEvent } from '../template-list/template-list.component';
import { PagePreviewService } from '../../_services/pagePreview.service';
import { APIHelperService } from '../../_services/apiHelper.service';

enum LegacyTemplateFilters {
  NurturePage = 'Nurture Page',
  Email = 'Email'
}

enum BroadcastTemplateFilters {
  All = 'All',
  Prospect = 'Prospect',
  Resident = 'Resident',
}

@Component({
  selector: 'app-template-picker',
  templateUrl: './template-picker.component.html',
  styleUrls: ['./template-picker.component.less']
})
export class TemplatePickerComponent implements OnInit {

  constructor(
    private authService: AuthenticationService,
    private templateService: TemplatesService,
    private toastService: ToastService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private nbModalService: NurtureBossModalService,
    private sanitizer: DomSanitizer,
    private templatesService: TemplatesService,
    private brandingService: BrandingsService,
    private pagePreviewService: PagePreviewService,
    private apiHelper: APIHelperService,
    private usersService: UsersService,
    private settingsService: SettingsService,
  ) {}

  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

  @Output() onTemplateSelected = new EventEmitter<ITemplate>();

  isLegacy = false;
  templateFilters: string[] = [];
  templates: ITemplate[] = [];
  filteredTemplates: ITemplate[] = [];
  user: { claims: string[]; _id: string; propertyName: string; fheo: boolean; } = null;
  selectedFilter = '';
  searchValue = '';
  emailPreviewUrl: SafeResourceUrl;
  pagePreviewUrl: SafeResourceUrl;
  forcePreviewType: string;
  branding: any;
  templateSettings: TemplateSettings;
  chooseImageSourceFunction = (template: ITemplate) => template.previewPageImageUrl || template.previewEmailPageUrl;

  async ngOnInit(): Promise<void> {
    this.checkIsLegacy();
    this.checkForFilter();
    this.user = this.authService.currentUserValue.user;
    await this.loadTemplates();
    this.filterTemplates();
    this.branding = await this.brandingService.getBrandings().toPromise();
    this.usersService.loadGlobalDefaults();
    this.templateSettings = (await this.settingsService.getSettingsByType(SettingType.Template).toPromise()).result
  }

  checkForFilter() {
    const defaultFilter = this.activeRoute.snapshot.queryParams.filter;
    if (defaultFilter && this.isLegacy) {
      switch ((defaultFilter as string).toLowerCase()) {
        case 'email':
          this.selectedFilter = LegacyTemplateFilters.Email;
          this.chooseImageSourceFunction = (template) => template.previewEmailPageUrl;
          break;
        case 'nurturepage':
          this.selectedFilter = LegacyTemplateFilters.NurturePage;
          this.chooseImageSourceFunction = (template) => template.previewPageImageUrl;
          break;
      }
    }
  }

  checkIsLegacy() {
    this.isLegacy = this.activeRoute.snapshot.url[0].path === 'legacy';
    if (this.isLegacy) {
      this.templateFilters = Object.values(LegacyTemplateFilters);
      this.selectedFilter = LegacyTemplateFilters.NurturePage;
    } else {
      this.templateFilters = Object.values(BroadcastTemplateFilters);
      this.selectedFilter = BroadcastTemplateFilters.All;
    }
  }

  // TODO: As we finish broadcast, we will need to add the "empty template"
  // to support free-form messaging.
  async loadTemplates(): Promise<void> {
    try {
      const { result: allTemplates } = await this.templateService.getTemplates().toPromise();
      this.templates = allTemplates.filter((template) => {
        if (!template.authorizedClaims.length) {
          return true;
        }
        return template.authorizedClaims.some((claim) => this.user.claims.includes(claim));
      });
    } catch (error) {
      this.toastService.showError('There was an error loading templates');
    }
  }

  legacyFilterTemplates() {
    this.filteredTemplates = this.templates.filter((template) => {

      // Temporarily hide alert templates.
      if (
        template.templateName === 'Availability' || 
        template.templateName === 'Price Drop'
      ) {
        return false
      }
      
      if (
        this.selectedFilter === LegacyTemplateFilters.NurturePage
        && !template.supportedMessaging.includes(TemplateMessagingTypes.NurturePage)
      ) {
        return false;
      } else if (
        this.selectedFilter === LegacyTemplateFilters.Email
        && !template.supportedMessaging.includes(TemplateMessagingTypes.Email)
      ) {
        return false;
      }

      if (this.searchValue && !template.display.toLocaleLowerCase().includes(this.searchValue.toLocaleLowerCase())) {
        return false;
      }
      return true;
    });
  }

  broadcastFilterTemplates() {
    this.filteredTemplates = this.templates.filter((template) => {

      // Temporarily hide alert templates.
      if (
        template.templateName === 'Availability' || 
        template.templateName === 'Price Drop'
      ) {
        return false
      }

      switch (this.selectedFilter) {
        case BroadcastTemplateFilters.Prospect:
          if (template.type === TemplateTypes.Resident) {
            return false;
          }
          break;
        case BroadcastTemplateFilters.Resident:
          if (template.type === TemplateTypes.Prospect) {
            return false;
          }
          break;
        case BroadcastTemplateFilters.All:
        default:
          break;
      }

      if (this.searchValue && !template.display.toLocaleLowerCase().includes(this.searchValue.toLocaleLowerCase())) {
        return false;
      }
      return true;
    });
  }

  filterTemplates() {
    if (this.isLegacy) {
      this.legacyFilterTemplates();
    } else {
      this.broadcastFilterTemplates();
    }
  }

  onFilterSelected(filter: string) {
    this.selectedFilter = filter;
    if (this.isLegacy) {
      if (filter === LegacyTemplateFilters.Email) {
        this.chooseImageSourceFunction = (template) => template.previewEmailPageUrl;
      } else {
        this.chooseImageSourceFunction = (template) => template.previewPageImageUrl;
      }
    }
    this.filterTemplates();
  }

  onSearchClick(event: MouseEvent) {
    event.preventDefault();
    this.searchInput.nativeElement.focus();
  }

  onSearchChange(searchVal: string) {
    this.searchValue = searchVal;
    this.filterTemplates();
  }

  buildNurturePageLink(template: ITemplate) {
    return this.pagePreviewService.getPagePreviewByPreviewId(template);
  }

  buildEmailTemplateLink(template: ITemplate) {
    return this.pagePreviewService.getEmailPreviewUrl(template);
  }

  async openTemplatePreview(template: ITemplate) {
    let url = template.supportedMessaging.includes(TemplateMessagingTypes.NurturePage)
      ? this.buildNurturePageLink(template)
      : this.buildEmailTemplateLink(template);
    if (this.isLegacy) {
      switch (this.selectedFilter) {
        case LegacyTemplateFilters.NurturePage:
          url = this.buildNurturePageLink(template);
          break;
        case LegacyTemplateFilters.Email:
          url = this.buildEmailTemplateLink(template);
          break;
        default:
          this.toastService.showError(`Invalid template filter '${this.selectedFilter}'! Cannot open template preview!`);
          return;
      }
      window.open(url, '_blank');
    } else {

      // Resetting here b/c resetting on close does not update modals understanding of preview values.
      this.emailPreviewUrl = null;
      this.pagePreviewUrl = null;

      // We need to only show applicable previews
      this.forcePreviewType = null;
      if (!template.supportedMessaging.includes(TemplateMessagingTypes.NurturePage)) {
        this.forcePreviewType = 'email';
      } else if (!template.supportedMessaging.includes(TemplateMessagingTypes.Email)) {
        this.forcePreviewType = 'nurturepage';
      }

      // Open modal immediately so loading UI can show in phone preview.
      this.nbModalService.open('preview-template-modal');
      const defaults = await this.templatesService.getTemplateDefaults(template.templateName).toPromise();
      const dataForPreview = {
        yourWebsite: 'https://example.website.com',
        onlineApplicationUrl: 'https://example.application.com',
        yourPhoneNumber: '5555555555',
        yourEmailAddress: 'example@example.com',
        type: template.templateName,
        apartmentName: this.user.propertyName,
        clientFirstName: 'Nelli',
        mainText: 'Your Message To Your Prospect or Resident Goes Here!',
        headerText: 'Your Header Here!',
        appointmentDate: '01/15/2024',
        appointmentTime: '1:30 pm',
        eventName: 'Taco Tuesday',
        eventDate: '01/15/2024',
        eventTime: '1:30 pm',
        eventLocation: 'Outside The Leasing Office',
        apartmentNumber: '1032',
        scheduleATourUrl: this.pagePreviewService.generateFieldForPreview('scheduleATourUrl') || 'https://example.website.com',
        ledgerBalance: '$325.50',
        floorPlanOne: 'AL',
        floorPlanTwo: 'AU',
        availability: {
          beds: '1',
          baths: '2',
          sqft: '956',
          unitImageURL: 'https://app.nurtureboss.io/assets/sample-floorplan.jpg',
          price: '1,899.00',
          applyOnlineUrl: defaults?.result?.applyOnlineUrl || defaults?.result?.onlineApplicationUrl || '#',
        },
        priceDrop: {
          beds: '1',
          baths: '2',
          sqft: '956',
          unitImageURL: 'https://app.nurtureboss.io/assets/sample-floorplan.jpg',
          price: '1,899.00',
          applyOnlineUrl: defaults?.result?.applyOnlineUrl || defaults?.result?.onlineApplicationUrl || '#',
        },
        // This complex validation seeks that the FHEO is only disabled when it is manually disabled
        fheo: !(this.user.fheo === false),
        ...defaults.result,
        ...this.branding.result,
        ...this.usersService.currentGlobalDefaultSocialLinks
      };
      this.emailPreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.apiHelper.emailTemplateBaseUrlWithoutProtocol}?id=` + this.user._id + '&data=' + encodeURIComponent(JSON.stringify(dataForPreview)));
      this.pagePreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.apiHelper.pageTemplateBaseUrlWithoutProtocol}?data=` + encodeURIComponent(JSON.stringify(dataForPreview)));
    }

  }

  onTemplateClick({ template, legacyOption }: TemplateSelectEvent) {
    if (this.isLegacy) {
      const email = this.selectedFilter === LegacyTemplateFilters.Email;
      const queryParams: any = {
        pageType: template.templateName,
      };
      if (email) {
        queryParams.email = email;
      }
      switch (legacyOption) {
        case LegacyTemplateOption.Bulk:
          this.router.navigate(['/templates/new'], { queryParams: {...queryParams, bulk: true} });
          return;
        case LegacyTemplateOption.Single:
          this.router.navigate(['/templates/new'], { queryParams: {...queryParams} });
          return;
        default:
          this.openTemplatePreview(template);
          return;
      }
    }

    this.onTemplateSelected.emit(template);
  }
}
