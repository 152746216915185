// Angular:
import { Injectable } from '@angular/core';
// Libs:
import { take } from 'rxjs/operators';
// Services:
import { APIHelperService } from './apiHelper.service';
import { AuthenticationService } from './authentication.service';
import { WidgetService } from './widget.service';
// Commons:
import { ITemplate } from '@nurtureboss/common/dist/types/templates';
import { FIELD_AUTO_GENERATORS } from '@nurtureboss/common/dist/utils/template';
import { UsersService } from '@app/_services';

@Injectable({ providedIn: 'root' })
export class PagePreviewService {

  constructor(
    private apiHelper: APIHelperService,
    private authenticationService: AuthenticationService,
    private widgetService: WidgetService,
    private usersService: UsersService,
  ) {
    // no-op
  }

  /**
   * Returns the url to preview an email
   * @param template  The template
   * @param values The template values
   * @returns string
   */
  getEmailPreviewUrl(template: ITemplate, values?: any): string {
    const user = this.authenticationService.currentUserValue.user;
    if (!template || !user._id) {
      return '';
    }
    const previewData = this.getPreviewData(template);
    const templateValues = values || {};
    const finalData = { ...previewData, ...templateValues };

    for (const fieldName of Object.keys(finalData)) {
      const value = this.generateFieldForPreview(fieldName);
      if (value) {
        finalData[fieldName] = value;
      }
    }

    const encodedData = encodeURIComponent(JSON.stringify(finalData));
    return `${this.apiHelper.emailTemplateBaseUrl}?id=${user._id}&data=${encodedData}`;
  }

  /**
   * Return the preview url using the previewPageId from the template
   * @param template The template
   * @return string
   */
  getPagePreviewByPreviewId(template: ITemplate): string {
    return `${this.apiHelper.pageTemplateBaseUrl}?id=${template.previewPageId}&track=false`;
  }

  /**
   * Returns the preview URL for pages
   * @param template The template
   * @param values The template values
   * @returns string
   */
  getPagePreviewUrl(template: ITemplate, values: any): string {
    const user = this.authenticationService.currentUserValue.user;
    if (!template || !user._id) {
      return '';
    }
    const previewData = this.getPreviewData(template);
    const templateValues = values || {};
    const finalData = {...previewData, ...templateValues};

    for (const fieldName of Object.keys(finalData)) {
      const value = this.generateFieldForPreview(fieldName);
      if (value) {
        finalData[fieldName] = value;
      }
    }

    const encodedData = encodeURIComponent(JSON.stringify(finalData));
    return `${this.apiHelper.pageTemplateBaseUrl}?data=${encodedData}&track=false`;
  }

  public generateFieldForPreview(fieldName: string) {
    if (
      fieldName !== 'scheduleATourUrl' ||
      !FIELD_AUTO_GENERATORS[fieldName].isAvailableToUser(this.authenticationService.currentUserValue.user)
    ) {
      return '';
    }

    if (!this.widgetService.accessToken) {
      return '';
    }

    return FIELD_AUTO_GENERATORS[fieldName].valueGenerator(
      { token: this.widgetService.accessToken },
      { firstName: 'Nelli', lastName: 'Robot', emailAddress: 'example@email.com', phoneNumber: '6025551234' },
    );
  }

  private getPreviewData(template: ITemplate) {
    const user = this.authenticationService.currentUserValue.user;
    if (!template || !user._id) {
      return '';
    }
    const previewData = {
      type: template.templateName,
      apartmentName: user.propertyName,
      templateName: template.templateName,
      fheo: user.fheo,
      aceId: user.aceId,
      claims: user.claims,
      ownerId: user._id.toString()
    };

    for (const group of template.groups) {
      for (const field of group.fields) {
        const value = this.generateFieldForPreview(field.name);
        if (value) {
          previewData[field.name] = value;
          continue;
        }

        if (!field.required) {
          continue;
        }

        let fieldData = field.meta.previewValue;
        if (typeof fieldData !== 'string') {
          fieldData = JSON.stringify(fieldData);
        }

        previewData[field.name] = fieldData;
      }
    }

    if (user.lat) {
      // tslint:disable-next-line: no-string-literal
      previewData['lat'] = user.lat;
    }
    if (user.long) {
      // tslint:disable-next-line: no-string-literal
      previewData['long'] = user.long;
    }

    return previewData;
  }
}
