// Angular:
import { Component, OnDestroy, OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// Libs:
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
// Commons:
import { IHyperPersonalizationSettings } from '@nurtureboss/common/dist/types/settings';
// Services:
import { NurtureBossModalService } from '@app/_services/modal.service';
import {
  AuthenticationService,
  IntegrationService,
  LoaderService,
  MediaService,
  SpamLanguageService,
  ToastService,
  UsersService,
} from '@app/_services';
// Components:
import MODAL_NAMES from '@app/_components/nb-modal/modalTypes';
import { addDays } from '@app/_utils/dates';

const HP_TYPES = {
  Pets: 'pets',
  Availability: 'availability',
};
const CUSTOM_MODAL_STYLE = 'custom-modal-styles-new enable-integration-modal';

@Component({
  selector: 'app-defaults',
  templateUrl: './defaults.component.html',
  styleUrls: ['./defaults.component.less'],
  providers: [NgbActiveModal],
})
export class DefaultsComponent implements OnInit, OnDestroy {

  headerTextRemainingChars = 100;
  generalTokens: any = {
    yourWebsite: null,
    yourEmailAddress: null,
    yourPhoneNumber: null,
    apartmentName: null,
  };
  templateDefaults: any = {
    bannerImage: null,
    leftLogo: null,
    concession: null,
    concessionExpirationDate: null,
    yourWebsite: null,
    onlineApplicationUrl: null,
    onlineApplicationLinkText: null,
    residentPortalUrl: null,
    yourEmailAddress: null,
    yourPhoneNumber: null,
    specialInsuranceInstructions: null,
    specialPowerProviderInstructions: null,
    specialPaymentInstructions: null,
    powerProvider: null,
    moveInCosts: null,
    renewalSpecial: null,
    surveyLink: null,
    eventTime: null,
    eventImage: null,
    eventText: null,
    eventDate: null,
    eventLocation: null,
    eventName: null,
    fileAttachmentText: null,
    fileAttachment: null,
    scheduleATourUrl: null,
    mainText: null,
    headerText: null,
  };
  globalDefaults = new FormGroup({
    bannerImage: new FormControl('', [Validators.required]),
    leftLogo: new FormControl('', [Validators.required]),
    concession: new FormControl(''),
    concessionExpirationDate: new FormControl(null),
    yourWebsite: new FormControl('', [Validators.required]),
    residentPortalUrl: new FormControl('', [Validators.required]),
    yourEmailAddress: new FormControl('', [Validators.required]),
    yourPhoneNumber: new FormControl('', [Validators.required]),
    scheduleATourUrl: new FormControl('', [Validators.required]),
    instagramHandle: new FormControl(''),
    facebookUrl: new FormControl(''),
    onlineReviewUrl: new FormControl(''),
    floorPlansUrl: new FormControl(''),
    amenitiesUrl: new FormControl(''),
    imageGalleryUrl: new FormControl(''),
    applyOnlineUrl: new FormControl(''),
  });
  hyperpersonalizationData: FormGroup;
  loadingImage: any = {
    bannerImage: false,
    leftLogo: false,
    eventImage: false,
    fileAttachment: false,
  }
  savingDefaults = false;
  activeMediaKey: string;
  imageForm = '';
  currentUser: any;
  userHpData: IHyperPersonalizationSettings;
  // Dates
  tomorrow: Date = addDays(new Date(), 1);
  canUseAvailabilityHP = false;
  propertyInfo: any;
  spamWarnings = [];
  subscriptions: Subscription[] = [];

  constructor(
    private toastService: ToastService,
    private mediaService: MediaService,
    private usersService: UsersService,
    private nbModalService: NurtureBossModalService,
    private authService: AuthenticationService,
    private loaderService: LoaderService,
    private modalService: NgbModal,
    private integrationService: IntegrationService,
    private spamLanguageService: SpamLanguageService,
  ) {
    this.spamLanguageService.setShouldDisplay(true);
    // Reset to avoid carrying over warnings from another area (like blasts)
    this.spamLanguageService.resetWarnings();
  }

  ngOnInit() {
    this.currentUser = this.authService.currentUserValue.user;
    this.loaderService.triggerLoader();

    Object.keys(this.globalDefaults.controls).forEach(key => {
      // For now concession is the only default here that makes sense to check
      if (key === 'concession') {
        this.subscriptions.push(this.globalDefaults.controls[key].valueChanges.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(val => {
          this.spamLanguageService.checkForSpamLanguage('Concession', val);
        }));
      }
    });

    this.subscriptions.push(this.spamLanguageService.warnings.subscribe(warnings => {
      this.spamWarnings = warnings;
    }));

    this.subscriptions.push(this.usersService.getGlobalDefaults().subscribe((data) => {
      // TODO: Improve with some destructuring and reduce so much duplicate code
      this.globalDefaults.controls.bannerImage.setValue(data.result.bannerImage || '');
      this.globalDefaults.controls.leftLogo.setValue(data.result.leftLogo || '');
      this.globalDefaults.controls.concession.setValue(data.result.concession || '');
      this.globalDefaults.controls.concessionExpirationDate.setValue(
        data.result.concessionExpirationDate ? new Date(data.result.concessionExpirationDate) : null
      );
      this.globalDefaults.controls.yourWebsite.setValue(data.result.yourWebsite || '');
      this.globalDefaults.controls.residentPortalUrl.setValue(data.result.residentPortalUrl || '');
      this.globalDefaults.controls.yourEmailAddress.setValue(data.result.yourEmailAddress || '');
      this.globalDefaults.controls.yourPhoneNumber.setValue(data.result.yourPhoneNumber || '');
      this.globalDefaults.controls.scheduleATourUrl.setValue(data.result.scheduleATourUrl || '');
      this.globalDefaults.controls.instagramHandle.setValue(data.result.instagramHandle || '');
      this.globalDefaults.controls.facebookUrl.setValue(data.result.facebookUrl || '');
      this.globalDefaults.controls.onlineReviewUrl.setValue(data.result.onlineReviewUrl || '');
      this.globalDefaults.controls.floorPlansUrl.setValue(data.result.floorPlansUrl || '');
      this.globalDefaults.controls.amenitiesUrl.setValue(data.result.amenitiesUrl || '');
      this.globalDefaults.controls.imageGalleryUrl.setValue(data.result.imageGalleryUrl || '');
      this.globalDefaults.controls.applyOnlineUrl.setValue(data.result.applyOnlineUrl || '');

      this.globalDefaults.markAllAsTouched();
    }, () => {
      this.toastService.showError('Error retrieving your global defaults');
    }, () => {
      this.loaderService.stopLoader();
    }));
    this.usersService.getHyperpersonalizationData().subscribe((data) => {
      this.userHpData = data.result;
    }, () => {
      this.toastService.showError('Error retrieving your hyper personalization settings.');
    }, () => {
      this.loaderService.stopLoader();
    });
    this.integrationService.getPropertyInfo(this.currentUser.integrationPropertyId).subscribe((data) => {
      this.propertyInfo = data;
      this.canUseAvailabilityHP = !!data?.availabilitySource;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  isAdmin() {
    return this.currentUser.claims.includes('admin');
  }

  onHeaderTextChange(text: string) {
    this.onHeaderTextLengthChange(100 - text.length);
  }

  onHeaderTextLengthChange(remainingCharacters) {
    this.headerTextRemainingChars = remainingCharacters;
  }

  uploadMedia(files, property, form) {
    this.loadingImage[property] = true;
    this.mediaService.getSignedRequestHelper(files, false, property).
        then((data) => {
          setTimeout(() => {
            if (form) {
              this[form].controls[property].setValue(data);
            }
            this.loadingImage[property] = false;
          }, 2000)
        }).
        catch((err) => {
          this.toastService.show('There was an error uploading media: ' + err, { classname: 'bg-danger text-light', delay: 5000 });
          this.loadingImage[property] = false;
        });
  }

  openMediaModal(event, key, imageForm) {
    event.preventDefault();
    event.stopPropagation();
    this.activeMediaKey = key;
    this.imageForm = imageForm;
    this.nbModalService.open(MODAL_NAMES.MEDIA_MANAGER);
  }

  openSaveDefaultsModal(event) {
    event.preventDefault();
    event.stopPropagation();
    this.nbModalService.open(MODAL_NAMES.SAVE_DEFAULTS);
  }

  resetImage(prop, form) {
    if (form) {
      this[form].controls[prop].setValue(null);
    } else {
      this.templateDefaults[prop] = null;
    }
  }

  saveGlobalDefaults() {
    this.savingDefaults = true;
    this.usersService.setGlobalDefaults({...this.globalDefaults.getRawValue()}).subscribe(() => {
      this.savingDefaults = false;
      this.toastService.showSuccess('Your global defaults have been updated!');
    }, (err) => {
      this.savingDefaults = false;
      this.toastService.showError('There was an error setting your global defaults: ' + err);
    });
  }

  saveDefault() {
    this.savingDefaults = true;
    this.usersService.setTemplateDefaults(this.templateDefaults).subscribe(() => {
      this.savingDefaults = false;
      this.toastService.showSuccess('Your template defaults have been updated!');
      this.templateDefaults = {};
    }, (err) => {
      this.savingDefaults = false;
      this.toastService.showError('There was an setting your defaults: ' + err);
    });
  }

  /**
   * Update a users HP settings.
   *
   * @param modal (NbgModal) modal we should exit when saving.
   */
  saveHyperpersonalization(modal) {
    this.savingDefaults = true;
    this.usersService.setHyperpersonalization({...this.hyperpersonalizationData.getRawValue()}).subscribe((res) => {
      this.userHpData = { ...this.userHpData, ...this.hyperpersonalizationData.getRawValue()};
      this.savingDefaults = false;
      this.exitModal(null, modal);
      this.toastService.showSuccess('Your Hyper-Personalization settings have been updated!');
    }, (err) => {
      this.savingDefaults = false;
      this.toastService.showError('There was an error setting your Hyper-Personalization settings: ' + err);
    });
  }

  handleImageSubmitEvent(image) {
    let url = image.url;
    if (this.activeMediaKey === 'bannerImage' && image.bannerUrl) {
      url = image.bannerUrl;
    } else if (this.activeMediaKey === 'leftLogo' && image.logoUrl) {
      url = image.logoUrl;
    }

    if (this.imageForm) {
      this[this.imageForm].controls[this.activeMediaKey].setValue(url);
    } else {
      this.templateDefaults[this.activeMediaKey] = url;
    }
    this.activeMediaKey = '';
    this.imageForm = '';
    this.nbModalService.close(MODAL_NAMES.MEDIA_MANAGER);
  }

  /**
   * Create form and open modal for HP configuration.
   *
   * @param type {string} type of HP data
   * @param modal {NbModal} modal to open
   */
  async configureHpData(type, modal) {
    this.loaderService.triggerLoader();
    if (type === HP_TYPES.Pets) {
      try {
        this.hyperpersonalizationData = new FormGroup({
          petImage: new FormControl(this.userHpData.petImage || ''),
          petImageLabel: new FormControl(this.userHpData.petImageLabel || ''),
          petPolicy: new FormControl(this.userHpData.petPolicy || ''),
          petActive: new FormControl(this.userHpData.petActive ||  false),
        });
        this.loaderService.stopLoader();
        this.modalService.open(modal, { windowClass : CUSTOM_MODAL_STYLE});
      } catch (e) {
        this.toastService.showError('There was an error loading Hyper-Personalization settings.');
      }
    } else if (type === HP_TYPES.Availability) {
      try {
        this.hyperpersonalizationData = new FormGroup({
          availabilityActive: new FormControl({ value: this.userHpData.availabilityActive || false, disabled: !this.propertyInfo.availabilitySource }),
        });
        this.loaderService.stopLoader();
        this.modalService.open(modal, { windowClass : CUSTOM_MODAL_STYLE});
      } catch (e) {
        // no-op
      }
    } else {
      this.toastService.showError('We do not support this type of personalization.');
      this.loaderService.stopLoader();
    }
  }

  /**
   * Used to exit HP configuration modals.
   *
   * @param e (Event) DOM event used to prevent default acitons.
   * @param modal (Modal) Modal that you are dismissing.
   */
  exitModal(e, modal) {
    if (e) {
      e.preventDefault();
    }
    modal.dismiss();
  }
}
