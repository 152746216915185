import { Component, EventEmitter, Input, OnInit, Output, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { FormGroup, AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { noInvalidTokens } from '@app/_directives/check-valid-tokens.directive';

export interface TokenSource {
  [key: string]: any;
}

export interface ValidatorListItem {
  name: string;
  value: any;
}

@Component({
  selector: 'app-token-container',
  styleUrls: ['./token-container.component.less'],
  templateUrl: './token-container.component.html',
})
export class TokenContainerComponent implements OnInit, OnChanges, OnDestroy {
  formValueSubscription: Subscription;

  @Input() title = 'Use tokens to customize your message';
  @Input() tokenSource: TokenSource;
  @Input() tokensArray: string[];
  @Input() tokensToHide: string[] = [];
  @Input() textSourceElementId: string;
  @Input() maxLength: number;
  @Input() validators: ValidatorFn[];
  @Input() validatorsList: ValidatorListItem[];

  // Use these if you have a form group
  @Input() formGroup: FormGroup = null;
  @Input() controlName: string = '';

  // Use these if you don't have a form group
  @Input() model: any;
  @Input() modelPropertyName: string;

  @Output() textLengthChange = new EventEmitter<number>();

  keys: string[];
  characterLimitReached = false;
  usesFormGroup = false;
  control: AbstractControl;

  ngOnInit() {
    // setValidators below overwrites existing validators
    // this checks to see component was consciously added with validators (if they are any)
    // to avoid accidently overwriting validators
    if (!this.validators && !this.validatorsList) {
      throw new Error(`[token-container] Attribute 'validators' is required`);
    }
    this.updateControl();
    this.usesFormGroup = !!this.formGroup && !!this.controlName;
    if (this.usesFormGroup) {
      this.formValueSubscription = this.control.valueChanges.subscribe(text => {
        const left: number = this.maxLength - text.length;
        this.textLengthChange.emit(left);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const tokensArray = changes.tokensArray;
    if (tokensArray && tokensArray.currentValue && tokensArray.previousValue) {
      const current = JSON.parse(JSON.stringify(tokensArray.currentValue));
      const previous = JSON.parse(JSON.stringify(tokensArray.previousValue));
      current.sort();
      previous.sort();
      if (JSON.stringify(current) !== JSON.stringify(previous)) {
        this.updateControl();
      }
    }
  }

  ngOnDestroy() {
    if (this.formValueSubscription) this.formValueSubscription.unsubscribe();
  }


  updateControl() {
    this.setKeysArray();
    if (this.controlName && this.formGroup) {
      this.control = this.formGroup.get(this.controlName);
      const validators = this.validators ? this.validators : this.getValidatorsFromList();
      this.control.setValidators([...validators, noInvalidTokens(this.keys)]);
      this.control.updateValueAndValidity();
      this.control.markAsDirty();
    }
  }


  getValidatorsFromList() {
    return this.validatorsList.map(validator => {
      if (validator.name === 'required') {
        return Validators.required;
      }
      if (validator.name === 'maxLength') {
        return Validators.maxLength(validator.value);
      }
      if (validator.name === 'minLength') {
        return Validators.minLength(validator.value);
      }
    });
  }

  handleTokenClick(event: any) {
    const textSourceElement: any = document.getElementById(this.textSourceElementId);
    const clickedValue = event.target.value;
    const element = textSourceElement.nativeElement || textSourceElement;
    const start = element.selectionStart;
    const end = element.selectionEnd;
    const elementValue = element.value;
    const newText = elementValue.substring(0, start) + clickedValue + elementValue.substring(end, elementValue.length);

    if (this.maxLength && (newText.length >= this.maxLength)) {
      element.focus();
      return;
    }

    if (this.usesFormGroup) {
      this.formGroup.controls[this.controlName].setValue(newText);
    } else {
      this.model[this.modelPropertyName] = newText;
      this.textLengthChange.emit(this.maxLength - newText.length);
    }
    element.selectionStart = clickedValue.length + start;
    element.selectionEnd = clickedValue.length + start;
    element.focus();
  }

  showToken(token: string) {
    return this.tokensToHide.indexOf(token) === -1;
  }

  private setKeysArray() {
    this.keys = [];
    if (this.tokenSource) {
      Object.keys(this.tokenSource).forEach((key: string) => {
        if (this.tokensToHide.indexOf(key) === -1) {
          this.keys.push(key);
        }
      });
    } else if (this.tokensArray) {
      this.tokensArray.forEach((key: string) => {
        if (this.tokensToHide.indexOf(key) === -1) {
          this.keys.push(key);
        }
      });
    } else {
      // NOTE: this should not be reached ever. If it is, there is an issue.
    }
  }
}
