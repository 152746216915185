import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-total',
  templateUrl: './card-total.component.html',
  styleUrls: ['./card-total.component.less'],
})
export class CardTotalComponent {
  @Input() total: number;
  @Input() title: string;
  @Input() fixedWidth: number;

  constructor() {}
}
