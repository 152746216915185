import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-toggle-visible-password',
  templateUrl: './toggle-visible-password.component.html',
  styleUrls: ['./toggle-visible-password.component.less'],
})

export class ToggleVisiblePasswordComponent implements OnInit, OnDestroy, OnChanges {
  @Input() elemId: string;
  @Input() control: AbstractControl;
  @Input() value: string;
  @Input() insideInput: boolean;

  inputSub: Subscription;
  hasText = false;
  isHidden = true;

  ngOnInit() {
    if (this.control) {
      this.inputSub = this.control.valueChanges.subscribe(text => {
        this.hasText = !!text.length;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.control) {
      this.hasText = !!changes.value.currentValue;
    }
  }

  ngOnDestroy() {
    if (this.inputSub) {
      this.inputSub.unsubscribe();
    }
  }

  onClick() {
    const input = document.getElementById(this.elemId) as HTMLInputElement;
    if (input.type === 'password') {
      input.type = 'text';
      this.isHidden = false;
    } else {
      input.type = 'password';
      this.isHidden = true;
    }
  }

}
