/**
 * returns URI encoded string
 * @param object any key value pair object
 * @returns URI encoded string
 */
export const uriEncodeObject = (object: {[key: string]: string}): string => {
  return encodeURIComponent(JSON.stringify(encodeObject(object)));
}

/**
 * URI encoded all values in an object
 * @param object any key value pair object
 * @returns Object with all values URI encoded
 */
export const encodeObject = (object: {[key: string]: string}): {[key: string]: string} => {
    const newObject = {};
    for (const [key, value] of Object.entries(object)) {
      newObject[key] = encodeURIComponent(value as string);
    }
    return newObject;
};

/**
 * Performs a "deep" copy of an object.
 * This algorithm has some limitations:
 * - Function references are copied
 * - Number-based property names are converted to strings (e.g. 1 -> '1')
 * - prototype and __proto__ are skipped
 * 
 * @param object - The object to copy
 * @returns A deep copy of the object
 */
export const deepClone = <TObj>(object: TObj): TObj => {
  // Use this to determine if a property path has already been visited.
  // Prevents a circular reference.
  const visitedProps = new Set<any>();
  visitedProps.add(object);

  const skipNode = (val: any): boolean => {
    if (typeof val !== 'object' || val === null) {
      return false;
    }

    if (visitedProps.has(val)) {
      return true;
    }
    visitedProps.add(val);
    return false;
  }

  const clone = (node: any): any => {
    // Anything that can't be copied should just be returned
    if (
      typeof node === 'string'
      || typeof node === 'number'
      || typeof node === 'boolean'
      || typeof node === 'undefined'
      || typeof node === 'symbol'
      || typeof node === 'function'
      || typeof node === 'bigint'
      || node === null
    ) {
      return node;
    }

    if (node instanceof Date || Object.prototype.toString.call(node) === '[object Date]') {
      return new Date(node);
    }
    
    // If an array copy the array items
    if (Array.isArray(node)) {
      return node.map(clone);
    }

    const newObj: any = {};
    // First copy the normal keys
    for (const key of Object.getOwnPropertyNames(node)) {
      // Skip prototype
      if (key === 'prototype' || key === '__proto__') {
        continue;
      }

      const val = node[key];
      if (skipNode(val)) {
        continue;
      }

      newObj[key] = clone(val);
    }
    // Now copy the symbols
    for (const sym of Object.getOwnPropertySymbols(node)) {
      const val = node[sym];
      if (skipNode(val)) {
        continue;
      }

      newObj[sym] = clone(val);
    }  
    return newObj;
  };
  
  return clone(object) as TObj;
};