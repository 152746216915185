<!-- Schedule Text Modal -->
<nb-modal 
  name="schedule-messages-modal"
  [confetti]="false"
  class="schedule-messages-modal"
  header="Schedule Message"
  [primaryButtonOptions]="primaryButtonOptions"
  footer="true"
>
  <div class="schedule-text-modal modal-body">
      <p>Select the date and time to send the message.</p>
      <div class="row">
          <div class="col-sm-6">
              <form class="single-date-selection form-inline calendar-padding">
                  <div class="form-group">
                    <div class="input-group">
                      <input class="form-control"
                          placeholder="yyyy-mm-dd"
                          name="dp"
                          [dayTemplate]="t"
                          [(ngModel)]="sendDate"
                          (ngModelChange)="updateScheduleData()"
                          ngbDatepicker
                          #d="ngbDatepicker">
                      <ng-template #t let-date let-focused="focused">
                          <span class="custom-day"
                                  [class.focused]="focused"
                                  [class.range]="isToday(date)"
                                  [class.faded]="isHovered(date) || isInside(date)"
                                  (mouseenter)="hoveredDate = date"
                                  (mouseleave)="hoveredDate = null">
                              {{ date.day }}
                          </span>
                      </ng-template>
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"><i class="far fa-calendar-alt"></i></button>
                      </div>
                    </div>
                  </div>
              </form>
          </div>
          <div class="col-sm-6">
              <ngb-timepicker [(ngModel)]="sendTime" (ngModelChange)="updateScheduleData()" [meridian]="true"></ngb-timepicker>
          </div>
      </div>
      <div class="alert alert-success mt-0" *ngIf="sendDate">
          <span>
              Your text<!--
              --><span *ngIf="true">s</span> 
              will send on {{sendDate.month}}/{{sendDate.day}}/{{sendDate.year}} 
              <span *ngIf="sendTime && sendTime.hour%12 === 0">at 12:{{("0" + sendTime.minute).slice(-2)}}</span>
              <span *ngIf="sendTime && sendTime.hour%12 !== 0">at {{sendTime.hour%12}}:{{("0" + sendTime.minute).slice(-2)}}</span>
              <span *ngIf="sendTime && (sendTime.hour === 0 || (sendTime.hour && sendTime.hour <= 11))"> AM</span>
              <span *ngIf="sendTime && sendTime.hour && sendTime.hour > 11"> PM</span>
          </span>
      </div>
      <div *ngIf="invalidSchedule" class="alert alert-danger mt-0">
        <span>{{invalidScheduleMessage}}</span>
      </div>
  </div>
</nb-modal>