<ng-container>
  <label *ngIf="!!label" [for]="id">{{label}}</label>

  <textarea
    *ngIf="!isNgModel"
    [id]="id"
    [rows]="rows"
    class="form-control"
    [maxLength]="maxLength"
    [disabled]="disabled"
    [value]="value"
    (input)="onTextareaChange($event)"
  >
  </textarea>
  
  <!-- Support the use of standalone ngModels -->
  <textarea
    *ngIf="isNgModel"
    [id]="id"
    [rows]="rows"
    class="form-control"
    [maxLength]="maxLength"
    [disabled]="disabled"
    [value]="value"
    (input)="onTextareaChange($event)"
    [(ngModel)]="ngModelHook"
    [ngModelOptions]="{standalone: true}"
    [placeholder]="placeholder"
  >
  </textarea>
  <span
    class="char-count"
    [ngClass]="{'text-warning': remainingCharacters < warningThreshold, 'text-danger': remainingCharacters <= 0}"
  >
    {{remainingCharacters}} characters remaining
  </span>
</ng-container> 
