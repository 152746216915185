<div cdkDrag [cdkDragDisabled]="this.isDisabled" [ngClass]="{'disabled-question': this.isDisabled}">
  <div class="question" [formGroup]="question">
    <i *ngIf="!this.isDisabled" class="fa-solid fa-grip-dots-vertical grip-icon"></i>
    <div class="left-box">
      <label for="questionText">Question:</label>
      <input
        placeholder="Write question here..."
        class="form-control question-text"
        type="text"
        formControlName="questionText"
        id="questionText"
        (mousedown)="$event.stopPropagation()"
      />
      <div class="question-body" [ngSwitch]="question.get('questionType').value">
        <!-- Single Choice -->
        <div class="options-container" *ngSwitchCase="'singlechoice'">
          <div class="option" *ngFor="let option of getOptionControls(); index as i">
            <input class="display-input" type="radio" disabled />
            <input
              id="{{id + i}}"
              class="form-control borderless-input"
              type="text"
              placeholder="Write option..."
              [formControl]="option"
              (mousedown)="$event.stopPropagation()"
              (keyup)="addOptionOnPressEnter($event, i)"
              (input)="onInput($event, option)"
            />
            <i *ngIf="i !== 0 && !this.isDisabled" class="fa-solid fa-x" (click)="removeOption(i)"></i>
          </div>
          <div *ngIf="!this.isDisabled && getOptionControls().length < 20" class="add-option" (click)="addOption()">
            <i class="fa-solid fa-plus"></i>Add Option
          </div>
        </div>
        <!-- Checkbox -->
        <div class="options-container" *ngSwitchCase="'checkbox'">
          <div class="option" *ngFor="let option of getOptionControls(); index as i">
            <input class="display-input" type="checkbox" disabled />
            <input
              id="{{id + i}}"
              class="form-control borderless-input"
              type="text"
              placeholder="Write option..."
              [formControl]="option"
              (mousedown)="$event.stopPropagation()"
              (keyup)="addOptionOnPressEnter($event, i)"
              (input)="onInput($event, option)"
            />
            <i *ngIf="i !== 0 && !this.isDisabled" class="fa-solid fa-x" (click)="removeOption(i)"></i>
          </div>
          <div *ngIf="!this.isDisabled && getOptionControls().length < 20" class="add-option" (click)="addOption()">
            <i class="fa-solid fa-plus"></i>Add Option
          </div>
        </div>
        <!-- Dropdown -->
        <div class="options-container" *ngSwitchCase="'dropdown'">
          <div class="option" *ngFor="let option of getOptionControls(); index as i">
            <span class="checkbox-option-num">{{i+1}}.</span>
            <input
              id="{{id + i}}" 
              class="form-control borderless-input" 
              type="text" 
              placeholder="Write option..."
              [formControl]="option"
              (mousedown)="$event.stopPropagation()"
              (keyup)="addOptionOnPressEnter($event, i)"
              (input)="onInput($event, option)"
            />
            <i *ngIf="i !== 0 && !this.isDisabled" class="fa-solid fa-x" (click)="removeOption(i)"></i>
          </div>
          <div *ngIf="!this.isDisabled && getOptionControls().length < 20" class="add-option" (click)="addOption()">
            <i class="fa-solid fa-plus"></i>Add Option
          </div>
        </div>
        <!-- Linear -->
        <div class="linear-container" *ngSwitchCase="'linear'">
          <div class="linear-min-max">
            <input
              class="form-control linear-input"
              type="number"
              formControlName="linearMin"
              (mousedown)="$event.stopPropagation()"
              min="{{question.get('linearMax').value - 10 >= 0 ? question.get('linearMax').value - 10 : 0}}"
              max="{{question.get('linearMax').value - 1}}"
            />
            <span class="linear-separator">to</span>
            <input
              class="form-control linear-input"
              type="number"
              formControlName="linearMax"
              (mousedown)="$event.stopPropagation()"
              min="{{question.get('linearMin').value + 1}}"
              max="{{question.get('linearMin').value + 10}}"
            />
          </div>
          <div class="linear-label-container">
            <span class="linear-label-num">{{question.get('linearMin').value || '1'}}.</span>
            <input 
              class="form-control linear-label-input" 
              type="text" 
              placeholder="Label (Optional)"
              formControlName="minLabel"
              (mousedown)="$event.stopPropagation()"
            />
          </div>
          <div class="linear-label-container">
            <span class="linear-label-num">{{question.get('linearMax').value || 10}}.</span>
            <input 
              class="form-control linear-label-input" 
              type="text" 
              placeholder="Label (Optional)"
              formControlName="maxLabel"
              (mousedown)="$event.stopPropagation()"
            />
          </div>
        </div>
        <!-- Short Answer -->
        <ng-container *ngSwitchCase="'shortanswer'">
          <input
            *ngIf="!this.isDisabled || question.get('placeholder').value"
            type="text"
            class="form-control placeholder-input"
            placeholder="(Optional) Write placeholder text..."
            formControlName="placeholder"
            (mousedown)="$event.stopPropagation()"
          />
        </ng-container>
        <!-- Long Answer -->
        <ng-container *ngSwitchCase="'longanswer'">
          <input
          *ngIf="!this.isDisabled || question.get('placeholder').value"  
            type="text"
            class="form-control placeholder-input"
            placeholder="(Optional) Write placeholder text..."
            formControlName="placeholder"
            (mousedown)="$event.stopPropagation()"
          />
        </ng-container>
      </div>
      <div class="require-toggle">
        <label class="switch mr-2">
          <input 
            type="checkbox"
            formControlName="required"
            id="required"
          >
          <span class="slider round"></span>
        </label>
        <span>Required</span>
      </div>
    </div>
    <div class="right-box">
      <div class="question-type">{{getQuestionTypeDisplay(question.get('questionType').value)}}</div>
      <div *ngIf="!this.isDisabled">
        <i class="far fa-thin fa-copy" ngbTooltip="Duplicate Question" (click)="duplicateQuestion()"></i>
        <i class="far fa-trash-alt" (click)="confirmDeleteQuestion()"></i>
      </div>
    </div>
    <div *ngIf="hasDuplicateOptions" class="no-duplicate-options-warning">Warning: Cannot have duplicate options</div>
  </div>
  <div class="drag-placeholder" *cdkDragPlaceholder></div>
</div>