<div>
  <div class="row" *ngIf="hasFormsPermission">
    <div class="col-sm-2">
      <ul class="inder-side-nav">
        <li [class.active]="router.url === '/forms/create'">
          <i class="far fa-user-circle"></i> <a routerLink="/forms/create">New Survey</a>
        </li>
        <li [class.active]="router.url === '/forms/view'">
          <i class="fa-regular fa-eye"></i> <a routerLink="/forms/view">View Survey</a>
        </li>
      </ul>
    </div>
    <div class="col-sm-10">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="row enable-surveys-wrap" *ngIf="!hasFormsPermission">
    <p class="text-center"><img src="/assets/enable-surveys-placeholder.gif" /></p>
    <h3 class="text-center">
      <a target="_blank" [href]="emailHref">Click Here</a> to enable Nurture Boss Surveys!
    </h3>
  </div>
</div>
