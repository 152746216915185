<div>
  <span *ngIf="!maxCharacters && type === 'subject'" class="helper-text" [ngClass]="{'text-warning': numCharacters > 50, 'text-danger': numCharacters >= 65}">
    {{numCharacters}} characters
    <span class="helper-text text-warning" *ngIf="numCharacters > 50 && numCharacters < 65">Your subject line is approacing the recommended max length of 65 characters.</span>
    <span class="helper-text text-danger" *ngIf="numCharacters >= 65">Your subject line is long. This may be okay, but consider shortening it.</span>
  </span>
  <span
    *ngIf="maxCharacters"
    [ngClass]="{'text-warning': numCharacters < 25, 'text-danger': numCharacters <= 0}"
    class="helper-text"
  >
    {{numCharacters}} characters remaining
  </span>
</div>