/**
 * Add N amounts of months to a initial date
 * This function does not mutate the original date
 *
 * @param {Date} date - Original Date
 * @param {number} months - Number of months to add to the date
 * @returns {number} - New date with months added
*/
export const addMonths = (date: Date, months: number): Date => {
  const newDate: Date = new Date(date);
  newDate.setMonth(newDate.getMonth() + months);

  return newDate;
}

/**
 * Add N amounts of minutes to a initial date
 * This function does not mutate the original date
 *
 * @param {Date} date - Original Date
 * @param {number} minutes - Number of minutes to add to the date
 * @returns {number} - New date with minutes added
*/
export const addMinutes = (date: Date, minutes: number): Date => {
  const newDate: Date = new Date(date);
  newDate.setMinutes(newDate.getMinutes() + minutes);

  return newDate;
}

/**
 * Add N amounts of days to a initial date
 * This function does not mutate the original date
 *
 * @param {Date} date - Original Date
 * @param {number} minutes - Number of days to add to the date
 * @returns {number} - New date with minutes added
*/
export const addDays = (date: Date, days: number): Date => {
  const newDate: Date = new Date(date);
  newDate.setDate(newDate.getDate() + days);

  return newDate;
}
