import { Component, forwardRef, Input, OnChanges, OnDestroy, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export type LimitedTextAreaOnChanged = (text: string) => void;
export type LimitedTextAreaOnTouched = () => void;

@Component({
  selector: 'app-limited-textarea',
  templateUrl: './limited-textarea.component.html',
  styleUrls: ['./limited-textarea.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => LimitedTextareaComponent),
    }
  ]
}) 
export class LimitedTextareaComponent implements ControlValueAccessor, OnDestroy, OnChanges {

  @Input() id: string;
  @Input() label: string;
  @Input() rows = 2;
  @Input() maxLength = 160;
  @Input() value: string;
  @Input() isNgModel = false;
  @Input() ngModelHook: any;
  @Output() ngModelHookChange = new EventEmitter<string>();
  @Input() placeholder: string;

  public disabled = false;
  public touched = false;
  public remainingCharacters = this.maxLength;
  public warningThreshold = 25;

  private onChangedHandler?: LimitedTextAreaOnChanged = null;
  private onTouchedHandler?: LimitedTextAreaOnTouched = null;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.maxLength) {
      this.warningThreshold = Math.max(changes.maxLength.currentValue / 4, 25);
      this.remainingCharacters = this.maxLength;
    }
  }

  // TODO: Maybe add some validations?
  ngOnDestroy() {
    this.onChangedHandler = null;
    this.onTouchedHandler = null;
  }

  onTextareaChange(event: Event) {
    this.writeValue((event.target as HTMLTextAreaElement).value);
  }

  writeValue(text: string) {
    if (!text) {
      this.value = '';
    } else {
      this.value = text;
    }

    if (this.value.length > this.maxLength) {
      this.value = this.value.substring(0, this.maxLength);
    }

    this.remainingCharacters = this.maxLength - this.value.length;
    if (this.onChangedHandler) {
      this.onChangedHandler(this.value);
    }

    if (this.isNgModel) {
      this.ngModelHookChange.emit(this.value);
    }
    this.markAsTouched();
  }

  registerOnChange(fn: LimitedTextAreaOnChanged) {
    this.onChangedHandler = fn;
  }

  registerOnTouched(fn: LimitedTextAreaOnTouched) {
    this.onTouchedHandler = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  markAsTouched() {
    if (this.touched) {
      return;
    }

    if (this.onTouchedHandler) {
      this.onTouchedHandler();
    }
    this.touched = true;
  }

  markAsUntouched() {
    if (!this.touched) {
      return;
    }
    this.touched = false;
  }
}
