import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { APIHelperService } from './apiHelper.service';

interface IBroadcastQuery {
  skip: number;
  take: number;
  query?: string;
  property?: string;
  startingDate?: string;
  endingDate?: string;
};

@Injectable({ providedIn: 'root' })
export class BroadcastsService {

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService
  ) {
    // no-op
  }

  // TODO: Add Type.
  createBroadcast(payload) {
    return this.http.post<any>(this.apiHelper.fillUrl('broadcasts', {}, {}), payload)
      .pipe(map(data => {
        return data;
      }));
  }
  
  getBroadcasts(skip, take, query?, property?, startingDate?, endingDate?) {
    const payload: IBroadcastQuery = {
      skip: skip,
      take: take
    };
    if (query && property) {
      payload.query = query;
      payload.property = property;
    }
    if (startingDate && endingDate) {
      payload.startingDate = startingDate;
      payload.endingDate = endingDate;
    }
    return this.http.get<any>(this.apiHelper.fillUrl('broadcasts', {}, payload))
      .pipe(map(data => {
        return data;
      }));
  }

  getBroadcastRecipients(id) {
    return this.http.get<any>(this.apiHelper.fillUrl('broadcastRecipients', {id}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getAll(skip, take, query?, property?, startingDate?, endingDate?) {
    const payload:IBroadcastQuery = {
      skip: skip,
      take: take
    };
    if (query && property) {
      payload.query = query;
      payload.property = property;
    }
    if (startingDate && endingDate) {
      payload.startingDate = startingDate;
      payload.endingDate = endingDate;
    }
    return this.http.get<any>(this.apiHelper.fillUrl('getTextMessages', {}, payload))
      .pipe(map(data => {
        return data;
      }));
  }

  deleteBroadcast(id) {
    return this.http.delete<any>(this.apiHelper.fillUrl('broadcast', {id}, {}))
      .pipe(map(data => {
        return data;
      }));
  }
}
