import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { APIHelperService } from './apiHelper.service';

@Injectable({ providedIn: 'root' })
export class SubscriptionsService {

    constructor(
        private http: HttpClient,
        private apiHelper: APIHelperService
    ) {
        //no-op
    }

    subscribe(payload) {
        return this.http.post<any>(this.apiHelper.fillUrl('subscribes', {}, {}), payload)
            .pipe(map(subscription => {
                return subscription;
            }));
    }

    getSubscription() {
        return this.http.get<any>(this.apiHelper.fillUrl('subscribes', {}, {}))
            .pipe(map(subscription => {
                return subscription;
            }));
    }
    
    getInvoices() {
        return this.http.get<any>(this.apiHelper.fillUrl('invoices', {}, {}))
            .pipe(map(subscription => {
                return subscription;
            }));
    }
}