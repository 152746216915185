import { Directive, ElementRef, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { fromEvent } from 'rxjs';

export enum StickyMethod {
  Sticky = 'sticky',
  Fixed = 'fixed',
}

export interface IStickAtTopStyles {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}

export interface IStickAtTopOptions {
  useParent?: boolean;
  sameParentWidth?: boolean;
  topThreshold?: number;
  applyStyles?: IStickAtTopStyles;
  method: StickyMethod;
}

@Directive({
  // TODO: Add directive selector appStickAtTop
  // tslint:disable-next-line: directive-selector
  selector: '[stickAtTop]'
})
export class StickAtTopDirective implements OnDestroy, AfterViewInit {

  @Input() stickAtTop: IStickAtTopOptions;
  eventSubscription;
  topThreshold: number;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    // Set defaults.
    this.topThreshold = this.stickAtTop.topThreshold || 0;
    this.eventSubscription = fromEvent(window, 'scroll').subscribe(e => {
      let element;
      if (this.stickAtTop.useParent) {
        element = this.el.nativeElement.parentNode;
      } else {
        element = this.el.nativeElement;
      }
      if (this.stickAtTop.sameParentWidth) {
        this.el.nativeElement.style.width = `${this.el.nativeElement.parentNode.offsetWidth}px`;
      }
      const position = element.getBoundingClientRect();
      if (position.top <= this.topThreshold) {
        if (this.stickAtTop.applyStyles.top) {
          this.el.nativeElement.style.top = this.stickAtTop.applyStyles.top;
        }
        if (this.stickAtTop.applyStyles.left) {
          this.el.nativeElement.style.left = this.stickAtTop.applyStyles.left;
        }
        if (this.stickAtTop.applyStyles.bottom) {
          this.el.nativeElement.style.bottom = this.stickAtTop.applyStyles.bottom;
        }
        if (this.stickAtTop.applyStyles.right) {
          this.el.nativeElement.style.right = this.stickAtTop.applyStyles.right;
        }
        this.el.nativeElement.style.position = this.stickAtTop.method;
      } else {
        this.el.nativeElement.style.position = 'static';
      }
    });
  }

  ngOnDestroy() {
    this.eventSubscription.unsubscribe();
  }
}
