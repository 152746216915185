import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { APIHelperService } from './apiHelper.service';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AlertsService {

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService
  ) {
    // no-op
  }

  getAlertByName(name) {
    return this.http.get<any>(this.apiHelper.fillUrl('alertByName', { name }, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getUserAlerts() {
    return this.http.get<any>(this.apiHelper.fillUrl('alerts', {}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getSentAlertsCount(alertId) {
    return this.http.get<any>(this.apiHelper.fillUrl('sentAlertsCount', { alertId }, {}))
    .pipe(map(data => {
      return data;
    }));
  }

  updateAlert(alertId, payload) {
    return this.http.put<any>(this.apiHelper.fillUrl('alert', { alertId }, {}), payload)
      .pipe(map(data => {
        return data;
      }));
  } 
}
