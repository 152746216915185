// Angular:
import { Component, OnInit, HostListener } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// Services:
import {
  AuthenticationService,
  ToastService,
  LoaderService,
  FormsService,
} from '@app/_services';
import { NurtureBossModalService } from '@app/_services/modal.service';

// Components
import { CanDeactivateComponent } from '@app/_helpers/pending-changes.guard';

@Component({
  selector: 'app-create-form',
  templateUrl: './create-form.component.html',
  styleUrls: ['./create-form.component.less']
})
export class CreateFormComponent implements OnInit, CanDeactivateComponent {
  canEditQuestions: boolean = true;
  form: FormGroup;
  formId: string;
  originalActiveStatus: boolean;
  questions: FormArray;
  questionIndexToDelete: number | null = null;
  user: any;

  constructor(
    private authenticationService: AuthenticationService,
    private formsService: FormsService,
    private loaderService: LoaderService,
    private nbModalService: NurtureBossModalService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
  ) {
    // no-op
  }

  ngOnInit(): void {
    this.formId = this.route.snapshot.params['id'] || null;

    this.initFields();
  }
  
  async initFields() {
    // this.questions is a separate variable to make it easier to access.
    // Since this.questions and this.form.questions will reference the same FormArray
    // changes made through this.questions will be reflected in this.form.questions.
    this.questions = new FormArray([], [
        Validators.required,
        Validators.minLength(1),
    ]);

    this.form = new FormGroup({
      active: new FormControl(false),
      name: new FormControl('', [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
      ]),
      description: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
    ]),
      questions: this.questions,
    });

    if (this.formId) {
      this.loadExistingForm();
    }
  }

  async loadExistingForm() {
    this.loaderService.triggerLoader();
    try {
      var form = await this.formsService.getForm(this.formId).toPromise();
      if (!form) {
        throw new Error('Error getting survey');
      }
    } catch (err){
      // Reroute if no form is found
      this.loaderService.stopLoader();
      this.toastService.showError('Error getting survey.');
      this.form.markAsPristine();
      this.router.navigate([`/forms/view`], {});
    }

    this.canEditQuestions = form.canEditQuestions;
    this.originalActiveStatus = form.active;

    this.form.controls['active'].setValue(form.active);
    this.form.controls['name'].setValue(form.name);
    this.form.controls['description'].setValue(form.description);
    this.initExistingFormQuestions(form.questions);

    this.loaderService.stopLoader();
  }

  initExistingFormQuestions(questions) {
    questions.map(question => {
      this.questions.push(new FormGroup({
        questionText: new FormControl(question.questionText, [
          Validators.required,
          Validators.minLength(3),
        ]),
        questionType: new FormControl(question.questionType), // Not editable, just a store of the data
        questionId: new FormControl(question.questionId),
        required: new FormControl(question.required),
        ...(question.options?.length && { 
          options: new FormArray(question.options.map(option => {
            return  new FormControl(option, [
              Validators.required,
              Validators.minLength(1),
            ])
          }), [
            Validators.required,
            Validators.minLength(1),
          ])
        }),
        ...(['shortanswer', 'longanswer'].includes(question.questionType) && { placeholder: new FormControl(question.placeholder)}),
        ...(question.questionType === 'linear' && {
          linearMin: new FormControl(question.linearMin, [Validators.required]),
          linearMax: new FormControl(question.linearMax, [Validators.required]),
          minLabel: new FormControl(question.minLabel || ''),
          maxLabel: new FormControl(question.maxLabel || ''),
        }),
      }));
    })
  }

  onSaveClickHandler() {
    if (this.originalActiveStatus !== this.form.controls['active'].value) {
      this.openModal('save-form-modal');
    } else {
      this.saveForm();
    }
  }

  openFormPreview() {
    const formData = this.form.getRawValue();
    formData.ownerId = this.authenticationService.currentUserValue.user._id;

    // Stringify data to be able to pass object with nested values to URLSearchParams
    // if not URLSearchParams will parse nested values as [Object object]
    const queryParams = new URLSearchParams({ formData: JSON.stringify(formData) }).toString();

    window.open(`https://forms.nurtureboss.io/#/preview?${queryParams}`, '_blank').focus();

    // Dev Code
    // window.open(`http://localhost:4202/#/preview/?${queryParams}`, '_blank').focus();
  }

  async saveForm() {
    if (this.formId) {
      await this.updateForm();
    } else {
      await this.createNewForm()
    }
  }

  async updateForm() {
    this.loaderService.triggerLoader();
    const formData = this.form.getRawValue();

    if (!this.canEditQuestions) {
      delete formData.questions;
    }

    try {
      const res = await this.formsService.updateForm(this.formId, formData).toPromise();
      if (res.error) {
        throw new Error();
      }
      this.loaderService.stopLoader();
      this.toastService.show('Successfully updated survey!', {
        classname: 'bg-success text-light',
        delay: 5000
      });      
      this.form.markAsPristine();
      this.ngOnInit(); // refresh DOM
    } catch (err) {
      this.loaderService.stopLoader();
      this.toastService.showError('Error saving the survey.');
    }
    this.closeModal('save-form-modal')
  }

  async createNewForm() {
    this.loaderService.triggerLoader();
    const formData = this.form.getRawValue();

    try {
      const res = await this.formsService.createForm(formData).toPromise();
      if (res.error) {
        throw new Error();
      }
      this.loaderService.stopLoader();
      this.toastService.show('Successfully created survey!', {
        classname: 'bg-success text-light',
        delay: 5000
      });
      this.form.markAsPristine();
      this.router.navigate([`/forms/${res._id}/edit/`]);
    } catch (err) {
      this.loaderService.stopLoader();
      this.toastService.showError('Error saving the survey.');
    }
    this.closeModal('save-form-modal')
  }

  addNewQuestion(questionType, index = this.questions.length) {
    const newQuestion = new FormGroup({
      questionText: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
      ]),
      questionType: new FormControl(questionType), // Not editable, just a store of the data
      required: new FormControl(true),
      ...(['singlechoice', 'checkbox', 'dropdown'].includes(questionType) && { 
        options: new FormArray([
          new FormControl('', [
            Validators.required,
            Validators.minLength(1),
          ])
        ], [
          Validators.required,
          Validators.minLength(1),
        ])
      }),
      ...(['shortanswer', 'longanswer'].includes(questionType) && { placeholder: new FormControl('')}),
      ...(questionType === 'linear' && {
        linearMin: new FormControl(1, [Validators.required]),
        linearMax: new FormControl(10, [Validators.required]),
        minLabel: new FormControl(''),
        maxLabel: new FormControl(''),
      }),
    });

    this.questions.insert(index, newQuestion);
  }

  moveQuestion(previousIndex, currentIndex) {
    const question = this.questions.at(previousIndex);
    
    this.questions.removeAt(previousIndex);
    this.questions.insert(currentIndex, question);
  }

  deleteQuestion() {
    this.questions.removeAt(this.questionIndexToDelete);
    this.questionIndexToDelete = null;
    this.nbModalService.close('delete-question-modal');
  }

  getQuestionControls() {
    return this.questions.controls;
  }

  // Builds fn to pass to question component so that this.questions is bound to create-form context
  getDuplicateQuestionFn(question, i) {
    return () => {
      const newQuestion = new FormGroup({
        questionText: new FormControl(question.get('questionText').value, [
          Validators.required,
          Validators.minLength(3),
        ]),
        questionType: new FormControl(question.get('questionType').value), // Not editable, just a store of the data
        required: new FormControl(question.get('required').value),
        ...(question.get('options') && { 
          options: new FormArray(question.get('options').controls.map(control => 
              new FormControl(control.value, [Validators.required])
            ), [
            Validators.required,
            Validators.minLength(1),
          ])
        }),
        ...(question.get('placeholder') && { placeholder: new FormControl(question.get('placeholder').value)}),
      });
  
      this.questions.insert(i, newQuestion);
    }
  }

  // Builds fn to pass to question component so that this.questionIndexToDelete is bound to create-form context
  getConfirmDeleteQuestion(index) {
    return () => {
      this.questionIndexToDelete = index;
      this.nbModalService.open('delete-question-modal');
    }
  }

  openModal(name) {
    this.nbModalService.open(name);
  }

  closeModal(name: string) {
    if ('delete-question-modal') {
      this.questionIndexToDelete = null;
    }
    this.nbModalService.close(name);
  }

  dropHandler(e) {
    // item.data is added in HTML e.g. [cdkDragData]="'shortanswer'"
    if (e.item.data) {
      this.addNewQuestion(e.item.data, e.currentIndex);
    } else {
      this.moveQuestion(e.previousIndex, e.currentIndex);
    }
  }

  @HostListener('window:beforeunload')
  canDeactivate() {
    if (this.form.pristine) {
      return true;
    }

    return confirm('There are some pending changes that have not been saved. If you leave the page they will be lost. Are you sure you want to leave the page?');
  }
}
