<div id="pages">
    <div class="alert alert-warning mb-0 mt-2 template-alert" *ngIf="!showAllPages">
        <p class="no-margin">By default, only pages you have not sent text messages to are shown. Toggle "Show All Pages" to see pages you have already texted or that are scheduled to be texted.</p>
    </div>
    <div class="card mt-2">
        <div class="card-body page-templates">
            <!-- <div class="card-actions">
                <div class="item">
                    <i class="far fa-list-ol"></i>
                    Grid
                </div>
                <div class="item active">
                    <i class="far fa-border-all"></i>
                    List
                </div>
            </div> -->
            <button [disabled]="!selectedPages || selectedPages.length === 0" (click)="sendNewText(sentTextModal, selectedPages)" class="clear margin-bottom-12 button button-light">
                Bulk Send Text
            </button>
            <button *ngIf="selectedPages && selectedPages.length > 0" (click)="bulkDeletePages(selectedPages, $event)" class="margin-left-12 clear margin-bottom-12 button button-red">
                Delete Pages
            </button>
            <p class="schdule-info">
                {{activeSchedules}} schedule<span *ngIf="activeSchedules !== 1">s</span><!--
                --><span *ngIf="activeSchedules === 1"> is </span><!--
                --><span *ngIf="activeSchedules !== 1"> are </span><!--
                -->pending, {{scheduledPages}} text<span *ngIf="scheduledPages !== 1">s</span>
                <span *ngIf="scheduledPages === 1"> is </span><!--
                --><span *ngIf="scheduledPages !== 1"> are </span><!--
                -->scheduled.
            </p>
            <div class="table-actions">
                <p class="table-row-indicator" *ngIf="totalRecords > 0">
                    <strong>Showing {{pagination.first}} to {{pagination.last}} of {{totalRecords}} pages</strong>
                </p>
                <p class="table-row-indicator" *ngIf="totalRecords === 0">
                    <strong>No pages found</strong>
                </p>
                <p class="table-row-toggle">
                    <strong>Show All Pages</strong>
                    <label class="switch">
                        <input type="checkbox" [(ngModel)]="showAllPages" (change)="this.loadData(null, null, true)" [checked]="showAllPages">
                        <span class="slider round"></span>
                    </label>
                </p>
            </div>
            <p-table #dt [responsive]="true" [value]="pages" class="page-listings"
                [(selection)]="selectedPages" dataKey="_id" [lazy]="true"
                [paginator]="true" rows="25" totalRecords="{{totalRecords}}" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {{totalRecords}} entries"
                [rowsPerPageOptions]="[25,50,100,250,500,1000]" (onPage)="paginate($event)"
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th>
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th>
                            Name
                        </th>
                        <!-- <th>
                            Label
                        </th> -->
                        <th>
                            Type
                        </th>
                        <th>URL</th>
                        <th>
                            Created
                        </th>
                        <th>Actions</th>
                        <th>
                            Texts Sent
                        </th>
                    </tr>
                    <tr>
                        <th>
                        </th>
                        <th>
                            <input pInputText type="text" placeholder="Search" [(ngModel)]="pageNameSearchText" (ngModelChange)="debouncedSearch(pageNameSearchText, 'data.pageName')">
                        </th>
                        <th>
                            <input pInputText type="text" placeholder="Search" [(ngModel)]="labelSearchText" (ngModelChange)="debouncedSearch(labelSearchText, 'data.label')">
                        </th>
                        <th>
                            <input pInputText type="text" placeholder="Search" [(ngModel)]="pageTypeSearchText" (ngModelChange)="debouncedSearch(pageTypeSearchText, 'data.type')">
                        </th>
                        <th></th>
                        <th>
                        </th>
                        <th></th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-page let-rowData>
                    <tr>
                        <td class="checkbox">
                            <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                        </td>
                        <td>
                            <i placement="top" ngbTooltip="Upcoming Text! {{page.scheduleTime | date:'MMM d, y, h:mm a'}}" *ngIf="page.scheduleIds && page.scheduleIds.length > 0 && !page.scheduleSent" class="far fa-alarm-clock"></i>
                            <i placement="top" ngbTooltip="Text already sent!" *ngIf="page.scheduleIds && page.scheduleIds.length > 0 && page.scheduleSent" class="far fa-alarm-clock past"></i>
                            {{page.data.pageName}}
                        </td>
                        <!-- <td>
                            {{page.data.label || 'N/A'}}
                        </td> -->
                        <td>
                            {{page.data.type}}
                        </td>
                        <td class="short-url-cell">
                            <span *ngIf="!page.shortUrl">Generating URL <span class="spinner-border spinner-border-sm mr-1"></span></span>
                            <a *ngIf="page.shortUrl" target="_blank" [href]="apiHelper.pageTemplateBaseUrl + '?id=' + page._id + '&track=false'">{{page.shortUrl}}</a>
                        </td>
                        <td>
                            {{page.updated | date:'MM-dd-yyyy hh:mm'}}
                        </td>
                        <td>
                            <p class="no-margin">
                                <!-- <i placement="top" ngbTooltip="Edit this page." class="fas fa-edit margin-right-12" (click)="onClickEditPage(page._id)"></i> -->
                                <!-- <i placement="top" ngbTooltip="Edit this page." class="fas disabled fa-edit margin-right-12"></i> -->
                                <!-- <i placement="top" ngbTooltip="Coming Soon!" class="fas disabled fa-envelope-open-text margin-right-12"></i> -->
                                <i placement="top" ngbTooltip="Send this page via text" class="far fa-sms" (click)="sendNewText(sentTextModal, page)"></i>
                                <i placement="top" *ngIf="page.textCount === 0" ngbTooltip="Delete this page!" class="far fa-trash-alt" (click)="deletePage(page, $event)"></i>
                            </p>
                        </td>
                        <td class="text-center">
                            {{page.textCount}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<!-- Send Text Modal -->
<ng-template #sentTextModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="sendTextModalLabel">Send Text Message</h5>
        <div class="float-right">
            <button type="button" class="button button-white button-small" (click)="exitModal($event, modal)"><i class="far fa-times" aria-hidden="true"></i></button>
        </div>
    </div>
    <div class="send-text-modal modal-body pr-0">
        <div class="row">
            <div class="col-md-8 mx-0 px-0">
                <div class="send-text-message-wrapper">
                    <form [formGroup]="sendTextMessageForm" (ngSubmit)="deliverText(modal, bulkText)">
                        <app-send-text-message
                            [formControlName]="'textMessageContent'"
                            [submitted]="false"
                            [formGroup]="sendTextMessageForm"
                            [maxLength]="textMessageMaxLength"
                            [totalContacts]="bulkTextSendPages.length"
                            [textElementId]="'textMessageField'"
                            [tokens]="selectedTokens"
                            tokenTitle="Available Tokens for Main Header:"
                        >
                            <div class="form-group" *ngIf="!bulkText" singleTextMessageContainer>
                                <label for="recipientPhoneNumber">Recipients Phone Number (U.S.A. Only)</label>
                                <input type="number" formControlName="recipientPhoneNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && sendTextMessageForm.controls.recipientPhoneNumber.errors }" />
                                <div *ngIf="submitted && sendTextMessageForm.controls.recipientPhoneNumber.errors" class="invalid-feedback">
                                    <div *ngIf="sendTextMessageForm.controls.recipientPhoneNumber.errors.required">Recipient Phone Number is required</div>
                                </div>
                            </div>
                        </app-send-text-message>

                        <div class="send-text-action-buttons-container">
                            <nb-button
                                [disabled]="getButtonsDisabledState()"
                                [loading]="sendingText"
                                title="Send Now"
                                color="dark"
                                cssClass="margin-right-12"
                            ></nb-button>  
                            <nb-button
                                [disabled]="getButtonsDisabledState()"
                                [loading]="sendingText"
                                title="Schedule Send"
                                color="light"
                                cssClass="margin-right-12"
                                (click)="openScheduleModal($event, scheduleTextModal, modal)"
                            ></nb-button>
                            <nb-button
                                [disabled]="sendingText"
                                [loading]="sendingText"
                                title="Cancel"
                                color="white"
                                cssClass="margin-right-12"
                                (click)="modal.dismiss('Cancel')"
                            ></nb-button>
                            <span class="consent-alert">*By clicking send you are verifying you have permission to text the individuals uploaded.</span>
                            <div *ngIf="errorMessage" class="alert alert-danger mt-3 mb-0">{{errorMessage}}</div>
                        </div>
                    </form>
                </div>
            </div>
            <!-- Iphone preview start -->
            <div class="col-md-4 pl-0 pr-0">
                <div class="relative">
                    <app-iphone-preview
                        forceDisplayType="textcontent"
                        [textContent]="messagePreview()"
                    ></app-iphone-preview>
                </div>
            </div>
            <!-- Iphone preview end -->
        </div>
    </div>
</ng-template>

<!-- Schedule Text Modal -->
<ng-template #scheduleTextModal let-scheduleTextModal>
    <div class="modal-header">
        <h5 class="modal-title">Schedule Message</h5>
        <div class="float-right">
            <button type="button" class="button button-white button-small" (click)="exitModal($event, scheduleTextModal)"><i class="far fa-times" aria-hidden="true"></i></button>
        </div>
    </div>
    <div class="schedule-text-modal modal-body">
        <p>Select the date and time to send the message.</p>
        <div class="row">
            <div class="col-sm-6">
                <form class="single-date-selection form-inline calendar-padding">
                    <div class="form-group">
                      <div class="input-group">
                        <input class="form-control"
                            placeholder="yyyy-mm-dd"
                            name="dp"
                            [dayTemplate]="t"
                            [(ngModel)]="sendDate"
                            ngbDatepicker
                            #d="ngbDatepicker">
                        <ng-template #t let-date let-focused="focused">
                            <span class="custom-day"
                                    [class.focused]="focused"
                                    [class.range]="isToday(date)"
                                    [class.faded]="isHovered(date) || isInside(date)"
                                    (mouseenter)="hoveredDate = date"
                                    (mouseleave)="hoveredDate = null">
                                {{ date.day }}
                            </span>
                        </ng-template>
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"><i class="far fa-calendar-alt"></i></button>
                        </div>
                      </div>
                    </div>
                </form>
            </div>
            <div class="col-sm-6">
                <ngb-timepicker [(ngModel)]="sendTime" [meridian]="true"></ngb-timepicker>
            </div>
        </div>
        <div class="alert alert-success mt-0" *ngIf="sendDate">
            <span>
                Your text<!--
                --><span *ngIf="true">s</span> 
                will send on {{sendDate.month}}/{{sendDate.day}}/{{sendDate.year}} 
                <span *ngIf="sendTime && sendTime.hour%12 === 0">at 12:{{("0" + sendTime.minute).slice(-2)}}</span>
                <span *ngIf="sendTime && sendTime.hour%12 !== 0">at {{sendTime.hour%12}}:{{("0" + sendTime.minute).slice(-2)}}</span>
                <span *ngIf="sendTime && (sendTime.hour === 0 || (sendTime.hour && sendTime.hour <= 11))"> AM</span>
                <span *ngIf="sendTime && sendTime.hour && sendTime.hour > 11"> PM</span>
            </span>
        </div>
        <button [disabled]="!sendTime || !sendDate || schedSaveInProgress" (click)="saveSchedule(scheduleTextModal)" class="button button-dark">
            <span *ngIf="schedSaveInProgress" class="spinner-border spinner-border-sm mr-1"></span>
            Save Schedule
        </button>
    </div>
</ng-template>

<!-- Send Text Success Modal -->
<nb-modal
    [name]="'send-text-success'"
    [confetti]="true"
    class="send-success-modal"
>
    <img src="assets/message-send-confirmation.png" />
    <h2>Message<span *ngIf="bulkText">s</span>&nbsp;<span *ngIf="!scheduleSaved">Sent</span><span *ngIf="scheduleSaved">Scheduled</span>!</h2>
    <div class="button button-dark margin-top-24" (click)="closeModal('send-text-success');">Continue</div>
</nb-modal>