<div id="admin-page">
  <div class="page" *ngIf="isAdmin() || isPartner()">
    <div class="margin-bottom-24 row">
      <div class="col-md-6">
        <h6 class="title">Create New Account</h6>
        <div>
          <div class="row no-margin">
            <div class="col-md-3">
              <div *ngIf="isAdmin()" class="button button-dark margin-bottom-24 margin-top-12" (click)="openCreateAccountModal(newAccountModal)">Create Account</div>
              <div *ngIf="isPartner()" class="button button-dark margin-bottom-24 margin-top-12" (click)="openCreateAccountModal(newPartnerAccountModal, true)">Create Account</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="page" *ngIf="isAdmin() || isPartner()">
    <div class="margin-bottom-24">
      <h6 class="title">Mimic Accounts</h6>
      <div>
        <div>
          <h6>Select Account:</h6>
          <div class="form-group">
            <p-autoComplete
              styleClass="form-control form-autocomplete"
              (onSelect)="onAccountSelect($event)"
              (onClear)="onAccountCleared($event)"
              [dropdown]="true"
              [suggestions]="suggestions"
              (completeMethod)="filterAccounts($event)"
              [forceSelection]="false"
              [completeOnFocus]="true"
              field="display"
            >
              <ng-template let-account pTemplate="item">
                <span>{{account.display}}</span>
              </ng-template>
            </p-autoComplete>
          </div>
          <div class="button button-danger margin-bottom-24 margin-top-12" (click)="mimic(selectedAccount)" *ngIf="selectedAccount">Mimic Account</div>
        </div>
      </div>
    </div>
  </div>

  <div class="page" *ngIf="isAdmin() && showChurn">
    <div class="margin-bottom-24">
      <h6 class="title">Churn Account</h6>
      <div>
        <div>
          <h6>Select Account:</h6>
          <div class="form-group">
            <p-autoComplete
              styleClass="form-control form-autocomplete"
              (onSelect)="onChurnAccountSelect($event)"
              (onClear)="onChurnAccountCleared($event)"
              [dropdown]="true"
              [suggestions]="churnSuggestions"
              (completeMethod)="filterChurnAccounts($event)"
              [forceSelection]="false"
              [completeOnFocus]="true"
              field="display"
            >
              <ng-template let-churnAccount pTemplate="item">
                <span>{{ churnAccount._id }} - {{ churnAccount.display }}</span>
              </ng-template>
            </p-autoComplete>
          </div>
          <div class="button button-danger margin-bottom-24 margin-top-12" (click)="openChurnConfirmationModal(churnAccountConfirmModal)" *ngIf="accountToChurn">Churn Account</div>
        </div>
      </div>
    </div>
  </div>

  <div class="page" *ngIf="isAdmin()">
    <div class="margin-bottom-24 row">
      <div class="col-md-6">
        <h6 class="title">Group Management</h6>
        <div class="row no-margin">
          <div class="col-md-3">
            <div class="button button-dark margin-bottom-24 margin-top-12"(click)="openGroupModal(newGroupModal)">Create Group</div>
          </div>
        </div>
        <div class="row no-margin">
          <div class="col-md-3">
            <div class="button button-dark margin-bottom-24 margin-top-12" (click)="openGroupModal(removeGroupModal)">Remove Group</div>
          </div>
        </div>
        <div class="row no-margin">
          <div class="col-md-3">
            <div class="button button-dark margin-bottom-24 margin-top-12" (click)="openGroupModal(managementGroupModal)">Manage Group</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modals -->
  <!-- NEW GROUP MODAL -->
  <ng-template #newGroupModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title">Create A New Group</h5>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <label>Group Name</label>
        <input type="text" class="form-control" [(ngModel)]="newGroupInfo.name" [ngModelOptions]="{standalone: true}" id="name" aria-describedby="name" placeholder="Enter group name">
      </div>
      <div class="form-group">
        <label>Group Email</label>
        <input type="email" class="form-control" [(ngModel)]="newGroupInfo.email" [ngModelOptions]="{standalone: true}" id="email" aria-describedby="email" placeholder="Enter group email">
      </div>
      <div class="form-group">
        <label>Group Password</label>
        <div class="input-group">
          <input type="password" class="form-control" [(ngModel)]="newGroupInfo.password" [ngModelOptions]="{standalone: true}" id="password" aria-describedby="password" placeholder="Enter group password">
          <app-toggle-visible-password elemId="password" [value]="newGroupInfo.password" [insideInput]="true"></app-toggle-visible-password>
        </div>
      </div>
      <div *ngIf="groupError" class="alert alert-danger">{{ groupError }}</div>
    </div>
    <div class="modal-footer pull-right">
      <button (click)="createGroup()" [disabled]="isLoadingGroup" class="button button-dark">
        <span *ngIf="isLoadingGroup" class="spinner-border spinner-border-sm mr-1"></span>
        Create
      </button>
      <button (click)="modal.dismiss()" [disabled]="isLoadingGroup" class="button button-white margin-left-12">
        Exit
      </button>
    </div>
  </ng-template>

  <!-- REMOVE GROUP MODAL -->
  <ng-template #removeGroupModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title">Remove Group</h5>
    </div>
    <div class="modal-body min-height">
      <div class="form-group select-group">
        <h6>Select Group:</h6>
        <div class="form-group">
          <input [style.display]="'none'" />
          <p-autoComplete
            styleClass="form-control form-autocomplete"
            (onSelect)="onGroupSelect($event)"
            (onClear)="onGroupCleared()"
            [dropdown]="true"
            [suggestions]="suggestionsGroups"
            (completeMethod)="filterGroups($event)"
            [forceSelection]="false"
            [completeOnFocus]="true"
            field="name"
          >
            <ng-template let-group pTemplate="item">
              <span>{{group.name}}</span>
            </ng-template>
          </p-autoComplete>
        </div>
      </div>
      <div *ngIf="groupError" class="alert alert-danger">{{ groupError }}</div>
    </div>
    <div class="modal-footer pull-right">
      <button (click)="openConfirmationModal(confirmRemoveGroupModal)" [disabled]="isLoadingGroup" class="button button-dark">
        <span *ngIf="isLoadingGroup" class="spinner-border spinner-border-sm mr-1"></span>
        Remove Group
      </button>
      <button (click)="modal.dismiss()" [disabled]="isLoadingGroup" class="button button-white margin-left-12">
        Exit
      </button>
    </div>
  </ng-template>

  <!-- REMOVE GROUP CONFIRM MODAL -->
  <ng-template #confirmRemoveGroupModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title">Remove Group</h5>
    </div>
    <div class="modal-body">
      <h6>Are you sure you want to remove {{ selectedGroup.name }} as a Group</h6>
      <div *ngIf="groupError" class="alert alert-danger">{{ groupError }}</div>
    </div>
    <div class="modal-footer pull-right">
      <button (click)="removeGroup()" [disabled]="isLoadingGroup" class="button button-dark">
        <span *ngIf="isLoadingGroup" class="spinner-border spinner-border-sm mr-1"></span>
        Remove Group
      </button>
      <button (click)="modal.dismiss()" [disabled]="isLoadingGroup" class="button button-white margin-left-12">
        Cancel
      </button>
    </div>
  </ng-template>

  <!-- MANAGE GROUP MODAL -->
  <ng-template #managementGroupModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title">Group Management</h5>
    </div>
    <div class="modal-body" [ngClass]="{'full-height': selectedGroup, 'min-height': !selectedGroup}">
      <div class="form-group select-group">
        <h6>Select Group:</h6>
        <div class="form-group">
          <input [style.display]="'none'" />
          <p-autoComplete
            styleClass="form-control form-autocomplete"
            (onSelect)="onGroupSelect($event)"
            (onClear)="onGroupCleared()"
            [dropdown]="true"
            [suggestions]="suggestionsGroups"
            (completeMethod)="filterGroups($event)"
            [forceSelection]="false"
            [completeOnFocus]="true"
            field="name"
          >
            <ng-template let-group pTemplate="item">
              <span>{{group.name}}</span>
            </ng-template>
          </p-autoComplete>
        </div>
      </div>
      <div class="form-group pick-list" *ngIf="selectedGroup">
        <p-pickList
          sourceHeader="Available Properties"
          targetHeader="Selected Properties"
          [source]="usersList"
          [target]="usersAddedList"
          [responsive]="true"
          [sourceStyle]="{'height':'calc(80vh - 300px)'}"
          [targetStyle]="{'height':'calc(80vh - 300px)'}"
          filterBy="propertyName,address,propertyManagementCompany"
        >
          <ng-template let-account pTemplate="item">
            <div class="ui-helper-clearfix">
              <div class="item">{{account | mimicAccountDisplayWithPMC}}</div>
            </div>
          </ng-template>
        </p-pickList>
      </div>
      <div *ngIf="groupError" class="alert alert-danger">{{groupError}}</div>
    </div>
    <div class="modal-footer pull-right">
      <button (click)="openConfirmationModal(confirmManagementGroupModal)" [disabled]="isLoadingGroup" class="button button-dark">
        <span *ngIf="isLoadingGroup" class="spinner-border spinner-border-sm mr-1"></span>
        Save Group
      </button>
      <button (click)="modal.dismiss()" [disabled]="isLoadingGroup" class="button button-white margin-left-12">
        Exit
      </button>
    </div>
  </ng-template>

  <!-- MANAGE GROUP CONFIRM MODAL -->
  <ng-template #confirmManagementGroupModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title">Group Management</h5>
    </div>
    <div class="modal-body max-height">
      <h6>Are you sure you want to save {{ selectedGroup.name }}</h6>
      <div>
        <h6 *ngIf="usersAddedList.length > 0">Users:</h6>
        <ul class="list-group">
          <li *ngFor="let user of usersAddedList">{{ user.display }}</li>
        </ul>
      </div>
      <div *ngIf="groupError" class="alert alert-danger">{{ groupError }}</div>
    </div>
    <div class="modal-footer pull-right">
      <button (click)="saveGroup()" [disabled]="isLoadingGroup" class="button button-dark">
        <span *ngIf="isLoadingGroup" class="spinner-border spinner-border-sm mr-1"></span>
        Save Group
      </button>
      <button (click)="modal.dismiss()" [disabled]="isLoadingGroup" class="button button-white margin-left-12">
        Cancel
      </button>
    </div>
  </ng-template>

  <!-- NEW ACCOUNT MODAL -->
  <ng-template #newAccountModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Create A New Account</h5>
    </div>
    <div class="modal-body">
      <div *ngIf="createAccountStep === 1">
        <div class="form-group">
          <label>Property Name</label>
          <input type="text" class="form-control" [(ngModel)]="newAccountInfo.propertyName" [ngModelOptions]="{standalone: true}" id="propertyName" aria-describedby="propertyName" placeholder="Enter property name">
        </div>
        <div class="form-group">
          <label>Legal Entity Name</label>
          <input type="text" class="form-control" [(ngModel)]="newAccountInfo.legalEntityName" [ngModelOptions]="{standalone: true}" id="legalEntityName" aria-describedby="legalEntityName" placeholder="Enter legal entity name">
        </div>
        <div class="form-group">
          <label>Login Email Address</label>
          <input type="text" class="form-control" [(ngModel)]="newAccountInfo.emailAddress" [ngModelOptions]="{standalone: true}" id="emailAddress" aria-describedby="emailAddress" placeholder="Enter leasing team's email address">
        </div>
        <div class="form-group margin-top-12">
          <label>Regional First Name - Optional</label>
          <input type="text" class="form-control" [(ngModel)]="newAccountInfo.regionalFirstName" [ngModelOptions]="{standalone: true}" id="regionalFirstName" aria-describedby="regionalFirstName" placeholder="Enter regional's email address">
        </div>
        <div class="form-group margin-top-12">
          <label>Regional Last Name - Optional</label>
          <input type="text" class="form-control" [(ngModel)]="newAccountInfo.regionalLastName" [ngModelOptions]="{standalone: true}" id="regionalLastName" aria-describedby="regionalLastName" placeholder="Enter regional's email address">
        </div>
        <div class="form-group margin-top-12">
          <label>Regional Email Address - Optional</label>
          <input type="email" class="form-control" [(ngModel)]="newAccountInfo.regionalEmailAddress" [ngModelOptions]="{standalone: true}" id="regionalEmailAddress" aria-describedby="regionalEmailAddress" placeholder="Enter regional's email address">
        </div>
        <div class="form-group">
          <label>Property Phone Number</label>
          <input type="number" class="form-control" [(ngModel)]="newAccountInfo.phoneNumber" [ngModelOptions]="{standalone: true}" id="phoneNumber" aria-describedby="phoneNumber" placeholder="Tracking number preferred">
        </div>
        <div class="form-group">
          <label>Property Address</label>
          <input type="text" class="form-control" [(ngModel)]="newAccountInfo.propertyAddress" [ngModelOptions]="{standalone: true}" id="propertyAddress" aria-describedby="propertyAddress" placeholder="Enter property address">
        </div>
        <div class="form-group">
          <label>Timezone</label>
          <select class="form-control" [(ngModel)]="newAccountInfo.timezone" [ngModelOptions]="{standalone: true}">
            <option value="America/Los_Angeles">America/Los_Angeles (Pacific)</option>
            <option value="America/Phoenix">America/Phoenix</option>
            <option value="America/Denver">America/Denver (Mountain)</option>
            <option value="America/Chicago">America/Chicago (Central)</option>
            <option value="America/New_York">America/New_York (Eastern)</option>
          </select>
        </div>
        <div class="form-group">
          <label>Unit Count</label>
          <input type="text" class="form-control" [(ngModel)]="newAccountInfo.unitCount" [ngModelOptions]="{standalone: true}" id="unitCount" aria-describedby="unitCount" placeholder="Enter unit count">
        </div>
        <div class="form-group">
          <label>Assigned CSM</label>
          <select class="form-control" [(ngModel)]="newAccountInfo.csm" [ngModelOptions]="{standalone: true}">
            <option value="Larkana Jimenez">Larkana Jimenez</option>
            <option value="Tyler Marks">Tyler Marks</option>
            <option value="Marissa Moeller">Marissa Moeller</option>
            <option value="Brittany Piggins">Brittany Piggins</option>
            <option value="Jacob Carter">Jacob Carter</option>
          </select>
        </div>
        <div class="form-group pmc-select">
          <label>PMC</label>
          <input type="text" class="form-control" [(ngModel)]="newAccountInfo.pmc" [ngModelOptions]="{standalone: true}" id="pmc" aria-describedby="property management company" placeholder="Enter PMC">
          <span class="helper-text">Ensure you are typing in the PMC exactly as it appears in Client Success. <a href="https://app.clientsuccess.com/cs/settings/custom-fields" target="_blank">Click Here</a> to view existing PMCs. Copy and paste the correct one, or add the PMC if it is a new one, and then copy and paste the value here.</span>
        </div>
        <div class="form-group">
          <label>Account Type</label>
          <select class="form-control" [(ngModel)]="newAccountInfo.accountType" [ngModelOptions]="{standalone: true}">
            <option *ngFor="let type of clientSuccessAccountTypes"
              [value]="type.title"
              [attr.selected]="newAccountInfo.accountType === type.title ? true : null">
              {{type.title}}
            </option>
          </select>
        </div>
        <hr>
        <h5>Required For Toll Free Verification</h5>
        <div class="form-group">
          <label>First Name of Property Representative</label>
          <input
            type="text" 
            class="form-control" 
            [(ngModel)]="newAccountInfo.propertyRepFirstName" 
            [ngModelOptions]="{standalone: true}" 
            id="propertyRepFirstName" 
            aria-describedby="propertyRepFirstName" 
            placeholder="Enter the first name of a property representative"
          >
          <span class="helper-text">This individual should be unique to the property. It can be any employee, but do not re-use employees across multiple properties (e.g. a Regional Manager)</span>
        </div>
        <div class="form-group">
          <label>Last Name of Property Representative</label>
          <input
            type="text" 
            class="form-control" 
            [(ngModel)]="newAccountInfo.propertyRepLastName" 
            [ngModelOptions]="{standalone: true}" 
            id="propertyRepLastName" 
            aria-describedby="propertyRepLastName" 
            placeholder="Enter the last name of a property representative"
          >
          <span class="helper-text">This individual should be unique to the property. It can be any employee, but do not re-use employees across multiple properties (e.g. a Regional Manager)</span>
        </div>
        <div class="form-group">
          <label>Property Website</label>
          <input
            type="text" 
            class="form-control" 
            [(ngModel)]="newAccountInfo.yourWebsite" 
            [ngModelOptions]="{standalone: true}" 
            id="yourWebsite" 
            aria-describedby="yourWebsite" 
            placeholder="Enter the property website"
          >
        </div>
        <hr>
        <ul class="contacts-list" *ngIf="newAccountInfo.additionalContacts?.length > 0">
          <li *ngFor="let additionalContact of newAccountInfo.additionalContacts; let index = index">
            {{ additionalContact.firstName }} {{ additionalContact.lastName }} - {{ additionalContact.email }} - {{ startCase(additionalContact.type || contactTypeOptions[4].value) }}
            <button (click)="onRemoveContact(index)" class="remove-contact button button-white">Remove</button>
          </li>
        </ul>
        <div *ngIf="hasFormAdditionalContacts">
          <div class="form-group">
            <label>Contact First Name</label>
            <input type="text" class="form-control" required [(ngModel)]="additionalContactsForm.firstName" [ngModelOptions]="{standalone: true}" id="contactFirstName" aria-describedby="contactFirstName" placeholder="Enter client first name">
          </div>
          <div class="form-group">
            <label>Contact Last Name</label>
            <input type="text" class="form-control" required [(ngModel)]="additionalContactsForm.lastName" [ngModelOptions]="{standalone: true}" id="contactLastName" aria-describedby="contactLastName" placeholder="Enter client last name">
          </div>
          <div class="form-group">
            <label>Contact Email</label>
            <input type="email" class="form-control" required [(ngModel)]="additionalContactsForm.email" [ngModelOptions]="{standalone: true}" id="contactEmail" aria-describedby="contactEmail" placeholder="Enter Email">
          </div>
          <div class="form-group">
            <label>Contact Job Title</label>
            <input type="text" class="form-control" required [(ngModel)]="additionalContactsForm.jobTitle" [ngModelOptions]="{standalone: true}" id="contactJobTitle" aria-describedby="contactJobTitle" placeholder="Enter job Title">
          </div>
          <div class="form-group">
            <label>Contact Type</label>
            <select class="form-control" [(ngModel)]="additionalContactsForm.type" [ngModelOptions]="{standalone: true}">
              <option *ngFor="let type of contactTypeOptions" [value]="type.value">
                {{type.text}}
              </option>
            </select>
          </div>
        </div>
        <div *ngIf="additionalContactsError" class="alert alert-danger">{{additionalContactsError}}</div>
        <button class="button button-dark" (click)="hasFormAdditionalContacts ? onHandleAddContacts() : toggleFormAddContacts()">
          {{ hasFormAdditionalContacts ? 'Save Additional Contact' : 'Add Additional Contact' }}
        </button>
        <button *ngIf="hasFormAdditionalContacts" class="button button-white margin-left-12" (click)="toggleFormAddContacts()">
          Cancel
        </button>
        <hr>
      </div>
      <div *ngIf="createAccountStep === 2">
        <div class="form-group">
          <label>Select A Plan</label>
          <select class="form-control" [(ngModel)]="selectedPlan" [ngModelOptions]="{standalone: true}" id="selectedPlan">
            <option *ngFor="let plan of plans" [ngValue]="plan" >{{plan.nickname}}</option>
          </select>
        </div>
      </div>
      <div *ngIf="twilioRegistrationError" class="alert alert-warning">
        <strong>OK TO CONTINUE!</strong> There was an error registering the texting phone number. Please contact support. It is okay to continue account creation. The error was: {{twilioRegistrationError}}
      </div>
      <div *ngIf="createUserError" class="alert alert-danger">{{createUserError}}</div>
      <button (click)="createAccountNextStep(modal)" [disabled]="createUserBusy" class="margin-top-48 button button-light">
          <span *ngIf="createUserBusy" class="spinner-border spinner-border-sm mr-1"></span>
          Next
      </button>
      <button (click)="modal.dismiss()" [disabled]="createUserBusy" class="button button-white margin-top-12 margin-left-12">
        Exit
      </button>
    </div>
  </ng-template>

  <!-- NEW PARTNER ACCOUNT MODAL -->
  <ng-template #newPartnerAccountModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Create A New Account</h5>
    </div>
    <div class="modal-body">
      <div *ngIf="createAccountStep === 1">
        <div class="form-group">
          <label>Property Name</label>
          <input type="text" class="form-control" [(ngModel)]="newAccountInfo.propertyName" [ngModelOptions]="{standalone: true}" id="propertyName" aria-describedby="propertyName" placeholder="Enter property name">
        </div>
        <div class="form-group">
          <label>Login Email Address</label>
          <input type="text" class="form-control" [(ngModel)]="newAccountInfo.emailAddress" [ngModelOptions]="{standalone: true}" id="emailAddress" aria-describedby="emailAddress" placeholder="Enter leasing team email address">
        </div>
        <div class="form-group">
          <label>ACE Phone Number</label>
          <input type="number" class="form-control" [(ngModel)]="newAccountInfo.phoneNumber" [ngModelOptions]="{standalone: true}" id="phoneNumber" aria-describedby="phoneNumber" placeholder="Tracking number preferred">
        </div>
        <div class="form-group">
          <label>Property Physical Address</label>
          <input type="text" class="form-control" [(ngModel)]="newAccountInfo.propertyAddress" [ngModelOptions]="{standalone: true}" id="propertyAddress" aria-describedby="propertyAddress" placeholder="Enter property address">
        </div>
        <div class="form-group">
          <label>Timezone</label>
          <select class="form-control" [(ngModel)]="newAccountInfo.timezone" [ngModelOptions]="{standalone: true}">
            <option value="America/Los_Angeles">America/Los_Angeles (Pacific)</option>
            <option value="America/Phoenix">America/Phoenix</option>
            <option value="America/Denver">America/Denver (Mountain)</option>
            <option value="America/Chicago">America/Chicago (Central)</option>
            <option value="America/New_York">America/New_York (Eastern)</option>
          </select>
        </div>
        <div class="form-group pmc-select">
          <label>PMC</label>
          <input type="text" class="form-control" [(ngModel)]="newAccountInfo.pmc" [ngModelOptions]="{standalone: true}" id="pmc" aria-describedby="property management company" placeholder="Enter PMC">
        </div>
        <div class="form-group">
          <label>Unit Count</label>
          <input type="text" class="form-control" [(ngModel)]="newAccountInfo.unitCount" [ngModelOptions]="{standalone: true}" id="unitCount" aria-describedby="unitCount" placeholder="Enter unit count">
        </div>
      </div>
      <hr>
      <h5>Required For Toll Free Verification</h5>
      <div class="form-group">
        <label>First Name of Property Representative</label>
        <input
          type="text" 
          class="form-control" 
          [(ngModel)]="newAccountInfo.propertyRepFirstName" 
          [ngModelOptions]="{standalone: true}" 
          id="propertyRepFirstName" 
          aria-describedby="propertyRepFirstName" 
          placeholder="Enter the first name of a property representative"
        >
        <span class="helper-text">This individual should be unique to the property. It can be any employee, but do not re-use employees across multiple properties (e.g. a Regional Manager)</span>
      </div>
      <div class="form-group">
        <label>Last Name of Property Representative</label>
        <input
          type="text" 
          class="form-control" 
          [(ngModel)]="newAccountInfo.propertyRepLastName" 
          [ngModelOptions]="{standalone: true}" 
          id="propertyRepLastName" 
          aria-describedby="propertyRepLastName" 
          placeholder="Enter the last name of a property representative"
        >
        <span class="helper-text">This individual should be unique to the property. It can be any employee, but do not re-use employees across multiple properties (e.g. a Regional Manager)</span>
      </div>
      <div class="form-group">
        <label>Property Website</label>
        <input
          type="text" 
          class="form-control" 
          [(ngModel)]="newAccountInfo.yourWebsite" 
          [ngModelOptions]="{standalone: true}" 
          id="yourWebsite" 
          aria-describedby="yourWebsite" 
          placeholder="Enter the property website"
        >
      </div>
      <hr>
      <div *ngIf="createUserError" class="alert alert-danger">{{createUserError}}</div>
      <button (click)="createAccountNextStep(modal, true)" [disabled]="createUserBusy" class="margin-top-48 button button-light">
          <span *ngIf="createUserBusy" class="spinner-border spinner-border-sm mr-1"></span>
          Next
      </button>
      <button (click)="modal.dismiss()" [disabled]="createUserBusy" class="button button-white margin-top-12 margin-left-12">
        Exit
      </button>
    </div>
  </ng-template>

  <!-- CHURN ACCOUNT CONFIRMATION MODAL -->
  <ng-template #churnAccountConfirmModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title">Churn Account</h5>
    </div>
    <div class="modal-body">
      <p>You are about to churn this account. Are you sure you want to do this? <strong>This cannot be undone.</strong></p>
      <button class="margin-top-48 button button-dark" (click)="churnAccountSelected()">
        <span *ngIf="savingSettings" class="spinner-border spinner-border-sm mr-1"></span>
        Confirm
      </button>
      <button (click)="modal.dismiss()" class="button button-white margin-top-12 margin-left-12">
        Cancel
      </button>
    </div>
  </ng-template>

  <!-- CHURN ACCOUNT PROGRESS MODAL -->
  <ng-template #churnStatusModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title">Churn status</h5>
    </div>
    <div class="modal-body">
      <p>This is the churn status</p>
      <div class="account-churn-status">
        <div class="churn-status-item" *ngFor="let statusProp of churnStatus | keyvalue">
          <input type="checkbox" [checked]="statusProp.value" disabled="true">
          <p>{{ statusProp.key }}</p>
        </div>
        <!-- <div class="churn-status-item">
          <input type="checkbox" checked="true" disabled="true">
          <p>status name</p>
        </div> -->
      </div>
      <button (click)="modal.dismiss()" class="button button-white margin-top-12 margin-left-12">
        OK
      </button>
    </div>
  </ng-template>
</div>