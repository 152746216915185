<nb-modal
  [name]="name + controlName"
  [confetti]="false"
  [doOnDismiss]="buildGenerateAITextModalDismissedBuilder()"
  header="Generate Text With AI"
  class="generate-ai-text-modal"
  footer="true"
  [primaryButtonOptions]="generateAITextModalPrimaryButton"
>
  <div class="help-text">
    Need Help? <a href="https://nurtureboss.io/writing-effective-ai-prompts" target="_blank">Read our article</a> on writing effective AI prompts or email our Customer Success team at <a href="mailto:customersuccess@nurtureboss.io">customersuccess@nurtureboss.io</a>
  </div>
  <textarea
    [formControl]="prompt"
    class="text-area"
    placeholder="Type Your Prompt. For Example: Write me a paragraph highlighting our downtown views encouraging prospects to tour my apartment community."
  ></textarea>
</nb-modal>