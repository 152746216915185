<div class="integration-thumbnail" [ngClass]="{'reducedTopPadding' : [integrationTypes.ACE].includes(integration.type)}">
  <div class="integration-services">
    <div class="integration-services__icon" *ngIf="tourScheduling === integration.type"><i class="far fa-calendar-days" ngbTooltip="Enabled For Tour Scheduling"></i></div>
    <div class="integration-services__icon" *ngIf="prospectSource === integration.type"><i class="fa-regular fa-id-card" ngbTooltip="Prospect Data Source"></i></div>
    <div class="integration-services__icon" *ngIf="residentSource === integration.type"><i class="fa-solid fa-house-user" ngbTooltip="Resident Data Source"></i></div>
    <div class="integration-services__icon" *ngIf="availabilitySource === integration.type"><i class="fa-regular fa-bed-empty" ngbTooltip="Availability Data Source"></i></div>
    <div class="integration-services__icon" *ngIf="ledgerBalanceSource === integration.type"><i class="fa-regular fa-list-ol" ngbTooltip="Ledger Balance Data Source"></i></div>
  </div>

  <img class="logo" [ngClass]="{'noTopBottomMargin' : [integrationTypes.ACE].includes(integration.type)}" [src]="integration.img" />
  <h6>{{ integration.title }}</h6>
  <p>{{ integration.description }}</p>
  <button *ngIf="!hasIntegration(integration.type || '') && !integrationIsCreateConfiguration[integration.title]" (click)="integration.enableNewIntegration(integration.type, enableintegration)" class="button button-white">
    <i class="far fa-plus" aria-hidden="true"></i> Enable
  </button>
  <button 
    *ngIf="!hasIntegration(integration.type || '') && integrationIsCreateConfiguration[integration.title]"
    [ngbTooltip]="(preventEnableRentCafe()) ? 'RentCafe cannot be first integration' : ''"
    [disabled]="preventEnableRentCafe()"
    (click)="integration.createIntegration($event, integration.type)"
    class="button button-white"
  >
    <i class="far fa-plus" aria-hidden="true"></i> Enable
  </button>
  <button *ngIf="hasIntegration(integration.type || '')" (click)="integration.configureIntegration(integration.type, configureintegration)" class="button button-dark">
    <i class="far fa-robot" aria-hidden="true"></i> Configure
  </button>
</div>