import { Contact } from '@nurtureboss/common/dist/types/contacts';
import { ITemplate } from '@nurtureboss/common/dist/types/templates';

export interface BroadcastExistingContactRecipient {
  contacts: Contact[];
}

export interface BasicUploadedContact {
  pageName: string;
  clientFirstName: string;
  clientEmailAddress: string;
  type: string;
  [prop: string]: string;
}

export interface BroadcastUploadedContactRecipient {
  uploadedContacts: BasicUploadedContact[];
}

export interface BroadcastSingleRecipient {
  pageName: string;
  clientFirstName: string;
  clientEmailAddress?: string;
  clientPhoneNumber?: string;
  [otherProperty: string]: any;
}

export enum BroadcastDeliveryMethod {
  Text = 'text',
  Email = 'email',
  Optimize = 'optimize',
}

export interface BroadcastDelivery {
  method: BroadcastDeliveryMethod;
  // This model should be normalized to a broadcastrecipients collection in the DB
  // that links back to the broadcast by id.
  recipient: BroadcastSingleRecipient | BroadcastExistingContactRecipient | BroadcastUploadedContactRecipient;
  emergency: boolean;
}

export enum BroadcastScheduleType {
  Immediate = 'immediate',
  Scheduled = 'scheduled',
}

export interface BroadcastSchedule {
  type: BroadcastScheduleType;
  sendDateTime: Date;
}

export interface BroadcastMessage {
  emailSubjectLine?: string;
  textMessageContent?: string;
}

export interface BroadcastContext {
  template?: ITemplate;
  templateValues?: {[key: string]: string};
  delivery?: BroadcastDelivery;
  message?: BroadcastMessage; 
  schedule?: BroadcastSchedule;
  isComplete?: boolean;
}