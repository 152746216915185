/**
 * checks a string against an array of valid bracket tokens and returns an array of invalid tokens
 * returns empty array if no invalid tokens are found
 * ex. ['[phoneNuumber]','[prompteryName]']
 * @param validTokens array of brackets tokens as strings ex. ['[phoneNumber]','[propertyName]']
 * @param string string to check to invalid tokens
 * @returns URI encoded string
 */
 export const checkStringForInvalidBracketTokens = (
  str: string,
  validTokens: string[],
  returnTokensWithBrackets: boolean = false
 ): string[] => {
    const tokenMatches = str.match(/\[([^\]]+)]/g);
    if (!tokenMatches?.length) {
      return [];
    }

    return tokenMatches.reduce((acc, match) => {
      // remove brackets from match
      const cleanedMatch = match.slice(1, -1);
      if (!validTokens.includes(cleanedMatch)) {
        return [...acc, returnTokensWithBrackets ? match : cleanedMatch];
      } else {
        return acc;
      }
  }, []);
};
