import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '@app/_services';

@Injectable({ providedIn: 'root' })
export class AdminAuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser && (currentUser.user.claims.includes('admin') || currentUser.user.claims.includes('partner'))) {

            // logged in so return true
            return true;
        }

        if (!currentUser) {
          // If not logged in redirect to login
          this.router.navigate(['/login']);
        } else {
          // If logged in redirect home
          this.router.navigate(['/']);
        }

        return false;
    }
}