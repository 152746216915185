import { AfterContentChecked, AfterContentInit, Component, Input, OnInit } from '@angular/core';
import { WizardService } from '@app/wizard/wizard.service';
import { WizardCompleteEvent, WizardNavigationEvent } from '@app/wizard/wizard.types';
import { ToastService } from '@app/_services';

/**
 * This is a component we can use to develop components.
 * It should not be exposed to any users.
 */

interface TestContext {
  name: string;
}

@Component({
  selector: 'app-test-inner',
  template: '<div>{{name}}</div><button (click)="onClick($event)">Click Me!</button>',
})
export class TestInnerCompnent implements OnInit {

  @Input() name = '';

  constructor(
    private wizService: WizardService<TestContext>,
  ) {}

  ngOnInit(): void {
    // no-op
  }

  onClick(_: any) {
    this.wizService.completeStep({ name: this.name });
  }
}

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.less']
})
export class TestComponent implements OnInit {

  initialContext: TestContext = {
    name: 'hello',
  };

  constructor(private toastService: ToastService) { }

  ngOnInit(): void {
  }

  onNavigate(event: WizardNavigationEvent<TestContext>) {

    // Validation goes here
    const navigated = event.continueNavigation();
    if (!navigated) {
      this.toastService.showError('Could not move on to the next step.');
    }
  }

  onExit(event: WizardCompleteEvent<TestContext>) {
    this.toastService.showSuccess(`Exited wizard with value: ${event.finalContext.name}`);
  }

  onComplete(event: WizardCompleteEvent<TestContext>){
    this.toastService.showSuccess(`Completed wizard with value: ${event.finalContext.name}`);
  }
}
