import { ComponentFactoryResolver, ComponentRef, Directive, Type, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * See https://angular.io/guide/dynamic-component-loader for
 * an example of how this directive is supposed to be used.
 */
@Directive({
  selector: '[appComponentInjector]'
})
export class ComponentInjectorDirective {

  constructor(
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) { }

  /**
   * Injects a new component of your choosing into the
   * element adorned by the directive.
   * 
   * .e.g <div appComponentInjector></div>
   */
  injectComponent<TComponent>(clazz: Type<TComponent>): Observable<ComponentRef<TComponent>> {
    this.viewContainerRef.clear();
    return new Observable((observer) => {
      // This is used to prevent component lifecycles
      // from overlapping. Without this, components
      // being destroyed can adversely affect components
      // being created.
      setTimeout(() => {
        try {
          const factory = this.componentFactoryResolver.resolveComponentFactory<TComponent>(clazz);
          const component = this.viewContainerRef.createComponent<TComponent>(factory);
          observer.next(component);
        } catch (error) {
          observer.error(error);
        } finally {
          observer.complete();
        }
      });
    });
  }
}
