import { Component } from '@angular/core';
  
@Component({
  templateUrl: 'knowledge-base.component.html',
  styleUrls: ['knowledge-base.component.less']
})
export class KnowledgeBaseComponent {

  constructor() {
    //no-op
  }
}
