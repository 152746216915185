// Angular:
import { Component, Input, TemplateRef } from '@angular/core';
// Consts:
import { INTEGRATION_IS_CREATE_CONFIGURATION, INTEGRATION_TYPES } from '@app/_consts/integration.consts';
// Services:
import { IntegrationService } from '@app/_services/integration.service';

export interface IntegrationCard {
  title: string;
  description: string;
  type: string;
  img: string;
  enableNewIntegration?: (integration: string, modal: TemplateRef<Element>) => void;
  createIntegration?: (event: Event, integration: string) => void;
  configureIntegration: (integration: string, modal: TemplateRef<Element>) => void;
}

@Component({
  selector: 'app-integration-card',
  templateUrl: './integration-card.component.html',
  styleUrls: ['./integration-card.component.less']
})
export class IntegrationCardComponent {
  @Input('integration') integration: IntegrationCard;
  @Input('enableintegration') enableintegration: TemplateRef<Element>;
  @Input('configureintegration') configureintegration: TemplateRef<Element>;
  @Input('tourScheduling') tourScheduling: string;
  @Input('prospectSource') prospectSource: string;
  @Input('residentSource') residentSource: string;
  @Input('availabilitySource') availabilitySource: string;
  @Input('ledgerBalanceSource') ledgerBalanceSource: string;

  integrationIsCreateConfiguration = INTEGRATION_IS_CREATE_CONFIGURATION;
  integrationTypes = INTEGRATION_TYPES;

  constructor(private integrationService: IntegrationService) {
    // no-op
  }

  hasIntegration(integration: string): boolean {
    return this.integrationService.hasIntegration(integration);
  }
  
  preventEnableRentCafe(): boolean {
    return this.integration.type === INTEGRATION_TYPES.RentCafe && !this.integrationService.isIntegratedUser
  }
}
