import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ITemplate } from '@nurtureboss/common/dist/types/templates';
import { Subscription } from 'rxjs';

import { WizardService } from '@app/wizard/wizard.service';
import { PagePreviewService } from '@app/_services/pagePreview.service';
import { PreviewView } from '@app/shared/iphone-preview/iphone-preview.component';
import { TemplateFormService, TemplatesService, TokenParserService, UsersService } from '@app/_services';
import { BroadcastContext, BroadcastMessage, BroadcastDelivery } from '@app/broadcast/broadcast.types';
import { IStickAtTopOptions, StickyMethod } from '@app/_directives/stick-at-top.directive';

const TOKEN_CONTAINER_PROPS = {
  text: {
    maxLength: 290,
    controlName: 'textMessageContent',
  },
  email: {
    maxLength: 60,
    controlName: 'emailSubjectLine',
  },
};

enum ActiveInputEnum {
  Email = 'email',
  Text = 'text',
}

@Component({
  selector: 'app-broadcast-send-message',
  templateUrl: './broadcast-send-message.component.html',
  styleUrls: ['./broadcast-send-message.component.less'],
  providers: [TemplateFormService],
})
export class BroadcastSendMessageComponent implements OnInit, OnDestroy {

  setupComplete = false;
  template: ITemplate;
  templateValues: any;
  textMessageTemplateValues: any = [];
  delivery: BroadcastDelivery;
  totalContacts = 1;
  form: FormGroup;
  tokensToHide: string[];
  showTextComponent = false;
  showEmailComponent = false;
  previewDisplayType: string;
  previewUrl: string;
  tokenContainerMaxLength: number = TOKEN_CONTAINER_PROPS.text.maxLength;
  tokenContainerActiveControlName: string = TOKEN_CONTAINER_PROPS.text.controlName;
  stickyHeaderOptions: IStickAtTopOptions = {
    useParent: true,
    topThreshold: 0,
    applyStyles: {
      top: '0px',
    },
    method: StickyMethod.Sticky,
  };
  stickyPhoneOptions: IStickAtTopOptions = {
    useParent: true,
    sameParentWidth: true,
    topThreshold: 95,
    applyStyles: {
      top: '95px',
    },
    method: StickyMethod.Fixed,
  };
  stepChangeSubscription: Subscription;

  constructor(
    private wizardService: WizardService<BroadcastContext>,
    private pagePreviewService: PagePreviewService,
    private templateFormService: TemplateFormService,
    private templatesService: TemplatesService,
    private tokenService: TokenParserService,
    private usersService: UsersService,
  ) {
    this.tokensToHide = this.templateFormService.getTokensToHide();
  }

  ngOnInit() {
    this.usersService.loadGlobalDefaults();
    this.stepChangeSubscription = this.wizardService.stepChanged$.subscribe((_) => {
      if (this.wizardService.isCurrentStepBefore('edit-message')) {
        this.teardown();
      } else {
        this.setup();
      }
    });
  }

  ngOnDestroy() {
    this.stepChangeSubscription?.unsubscribe();
  }

  /**
   * Resets component to it's initial state
   * This gets called if the user decides to go to the `delivery` screen and change information
   */
  private teardown() {
    this.showTextComponent = false;
    this.showEmailComponent = false;
    this.previewDisplayType = null;
    this.totalContacts = 1;
    this.setupComplete = false;
    this.previewUrl = null;
    this.tokenContainerActiveControlName = TOKEN_CONTAINER_PROPS.text.controlName;
    this.tokenContainerMaxLength = TOKEN_CONTAINER_PROPS.text.maxLength;
  }

  /**
   * Bootstrap the component
   * Set form/initial values
   */
  private setup() {
    // do nothing if the setup was already done (keep state between page changes)
    // if needed `this.teardown()` will reset the component and this method will run again
    // the use case is if the user changes content in the `Delivery` tab
    if (this.setupComplete) {
      return;
    }
    const { template, templateValues, delivery } = this.wizardService.getContext() as BroadcastContext;
    this.template = template;

    this.delivery = delivery;
    const recipient = this.delivery.recipient;
    let recipientTemplateData: any = {};
    if ('contacts' in recipient) {
      this.totalContacts = recipient.contacts.length;
      recipientTemplateData = {...recipient.contacts[0]};
    } else if ('uploadedContacts' in recipient) {
      this.totalContacts = recipient.uploadedContacts.length;
      recipientTemplateData = {...recipient.uploadedContacts[0]};
    } else {
      recipientTemplateData = {...recipient};
    }
    // Clean up data for previews
    const fieldsToKeep = new Set<string>(
      [
        ...this.templatesService.extractRequiredContactFields(template),
        ...this.templatesService.extractOptionalContactFields(template)
      ].map(({name}) => name)
    );
    for (const property of Object.keys(recipientTemplateData)) {
      if (!fieldsToKeep.has(property)) {
        delete recipientTemplateData[property];
      }
    }
    this.templateValues = {
      ...templateValues,
      ...recipientTemplateData,
      ...this.templateFormService.getUserSpecificTokenValues(),
      ...this.usersService.currentGlobalDefaultSocialLinks,
    };

    // Need to allow shortUrl token for text messages even though it hasn't been generated yet.
    this.textMessageTemplateValues = { ...this.templateValues, shortUrl: '[shortUrl]'};
    this.previewUrl = this.pagePreviewService.getEmailPreviewUrl(this.template, this.templateValues);

    switch (this.delivery.method) {
      case 'text':
        this.showEmailComponent = false;
        this.showTextComponent = true;
        this.setTextMessageInputTokens();
        this.previewDisplayType = PreviewView.TextContent;
        break;
      case 'email':
        this.showEmailComponent = true;
        this.showTextComponent = false;
        this.setEmailInputTokens();
        this.previewDisplayType = PreviewView.EmailSubject;
        break;
      default:
        this.showEmailComponent = true;
        this.showTextComponent = true;
        this.setTextMessageInputTokens();
        this.previewDisplayType = '';
        break;
    }

    this.form = new FormGroup({});
    if (this.showEmailComponent) {
      this.form.addControl('emailSubjectLine', new FormControl(this.template.defaultEmailSubjectLine || '', [Validators.required]));
    }
    if (this.showTextComponent) {
      this.form.addControl('textMessageContent', new FormControl(this.template.defaultTextMessageContent || '', [Validators.required]));
    }

    this.setupComplete = true;
  }

  getEmailSubjectLine(): string {
    let previewValue = 'Add a message';
    if (this.showEmailComponent) {
      const { emailSubjectLine } = this.form.controls;
      previewValue = emailSubjectLine.value ? emailSubjectLine.value : 'Add a message';
    }

    return this.tokenService.replaceTokens(previewValue, this.templateValues);
  }

  getTextContent(): string {
    let previewValue = 'Add a message';

    if (this.showTextComponent) {
      const { textMessageContent } = this.form.controls;
      previewValue = textMessageContent.value ? textMessageContent.value : 'Add a message';
    }

    return this.tokenService.replaceTokens(previewValue, this.templateValues);
  }

  handleInputActive(type: ActiveInputEnum) {
    switch (type) {
      case ActiveInputEnum.Email:
        this.tokenContainerActiveControlName = TOKEN_CONTAINER_PROPS.email.controlName;
        this.tokenContainerMaxLength = TOKEN_CONTAINER_PROPS.email.maxLength;
        this.previewDisplayType = PreviewView.EmailSubject;
        this.setEmailInputTokens();
        break;
      case ActiveInputEnum.Text:
        this.tokenContainerActiveControlName = TOKEN_CONTAINER_PROPS.text.controlName;
        this.tokenContainerMaxLength = TOKEN_CONTAINER_PROPS.text.maxLength;
        this.previewDisplayType = PreviewView.TextContent;
        this.setTextMessageInputTokens();
        break;
    }
    // this resets the force display type
    // without this the preview component will be stuck in whichever got passed to it
    // resetting the display type too early causes the component to not switch views
    // TODO: find a better solution
    setTimeout(() => {
      this.previewDisplayType = '';
    }, 100);
  }

  setEmailInputTokens() {
    if (this.showEmailComponent && this.showTextComponent) {
      this.tokensToHide = this.templateFormService.getTokensToHideForEmailInput();
    }
  }

  setTextMessageInputTokens() {
    if (this.showEmailComponent && this.showTextComponent) {
      this.tokensToHide = this.templateFormService.getTokensToHideForTextInput();
    }
  }

  canMoveNext(): boolean {
    if (!this.form) {
      return false;
    }
    return this.form.valid;
  }

  onMoveNext(event: MouseEvent) {
    event.preventDefault();
    if (!this.form.valid) {
      return;
    }
    const currentContext = this.wizardService.getContext();
    const { emailSubjectLine, textMessageContent } = this.form.getRawValue();
    const message: BroadcastMessage = {};

    if (this.showEmailComponent && emailSubjectLine) {
      message.emailSubjectLine = emailSubjectLine;
    }
    if (this.showTextComponent && textMessageContent) {
      message.textMessageContent = textMessageContent;
    }

    currentContext.message = message;
    this.wizardService.completeStep(currentContext);
  }
}
