const IMAGE_MIME_MAP = {
  JPG: 'file-image',
  JPEG: 'file-image',
  GIF: 'file-image',
  PNG: 'file-image',
  TIFF: 'file-image',
};

const DOCUMENT_MIME_MAP = {
  WORD: 'file-word',
  DOC: 'file-word',
  DOCX: 'file-word',
  XLSX: 'file-excel',
  XLS: 'file-excel',
  PDF: 'file-pdf',
  CSV: 'file-csv',
};

export const FILE_ICON_MAP = {
  ...DOCUMENT_MIME_MAP,
  DEFAULT: 'file',
}

export const getFileExt = (file: string): string => {
  const regExp: RegExp = /(?:\.([^.]+))?$/;
  return regExp.exec(file)[1];
}

export const getFileIconClass = (file: string): string => {
  const fileExt = getFileExt(file);
  const found = FILE_ICON_MAP.hasOwnProperty(fileExt.toUpperCase());
  if (!found) {
    return FILE_ICON_MAP.DEFAULT;
  }
  return `fa-${FILE_ICON_MAP[fileExt.toUpperCase()]}`;
}

export const isFile = (file: string): boolean => {
  if (!file) {
    return false;
  }
  const fileExt = getFileExt(file);
  return FILE_ICON_MAP.hasOwnProperty(fileExt.toUpperCase());
}

/**
 * Get the allowed image file types for the `accept` property in <input />.
 * @returns string[] Returns an array with the file extensions for allowed images extensions ['.png', '.jpg']
 */
export const getAllowedImageExtensions = (): string[] => {
    return getImageTypes();
};

/**
 * Get the allowed file types for the `accept` property in <input />.
 * @returns string[] Returns an array with the file extensions for allowed files, excluding images. ['.docx', '.pdf']
 */
export const getAllowedFileExtensions = (): string[] => {
  return getFileTypes();
};

/**
 * Get all the allowed file types for the `accept` property in <input />.
 * @returns string[] Returns an array with the file extensions for images and other allowed files ['.png', '.jpg']
 */
export const getAllAllowedExtensions = (): string[] => {
  return [...getFileTypes(), ...getImageTypes()];
};

const getFileTypes = (): string[] => {
  const keys = Object.keys(DOCUMENT_MIME_MAP);
  return keys.reduce(formatMimeTypes, []);
};

const getImageTypes = (): string[] => {
  const keys = Object.keys(IMAGE_MIME_MAP);
  return keys.reduce(formatMimeTypes, []);
};

const formatMimeTypes = (arr: string[], type: string): string[] => {
  arr.push(`.${type.toLocaleLowerCase()}`);
  return arr;
};
