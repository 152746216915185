<div class="nb-modal" (keyup.enter)="onPrimaryButtonClick($event)">
  <div class="nb-modal-body" [style]="additionalStyles" #modalBody tabindex="0">
    <div class="nb-modal-header" *ngIf="header">
      <span class="nb-modal-exit">
        <h5>{{header}}</h5>
        <button type="submit" class="nb-modal-button pull-right" (click)="dismiss()">
          <i class="far fa-times"></i>
        </button>
      </span>
    </div>
    <div class="nb-modal-content" [class]="{'has-footer': footer}">
      <ng-content></ng-content>
    </div>
    <div class="nb-modal-footer" *ngIf="footer">
      <button
        *ngIf="primaryButtonOptions"
        class="button button-dark pull-right"
        [disabled]="primaryButtonOptions.disabled"
        (click)="onPrimaryButtonClick($event)"
        (keyup.enter)="onPrimaryButtonClick($event)"
      >
      <span *ngIf="primaryButtonOptions.showSpinner" class="spinner-border spinner-border-sm mr-1"></span>
        {{primaryButtonOptions.label}}
      </button>
    </div>
  </div>
</div>
<div class="nb-modal-background"></div>