<div id="broadcast-builder">
  <app-wizard [initialContext]="initialContext" (onWizardNavigate)="onNavigate($event)" (onWizardExit)="onExit($event)">
    <app-wizard-step stepId="choose-template" label="Choose Template">
      <app-template-picker (onTemplateSelected)="onTemplateSelected($event)"></app-template-picker>
    </app-wizard-step>
    <app-wizard-step stepId="fill-template" label="Edit Template">
      <div class="relative">
        <div [stickAtTop]="stickyHeaderOptions" class="step-header">
          <h5 class="pt-4 pb-2 ml-4 pl-1">Selected {{selectedTemplate?.templateName}}</h5>
          <button [disabled]="!templateFormValid" class="button button-dark next-button mb-4 mr-2" type="button" (click)="handleTemplateFormSubmit()">Next</button>
        </div>
        <div *ngIf="selectedTemplate && templateDefaults" class="pb-2 pt-2 col-12 d-flex" id="template-form-container">
          <div class="col-8">
            <app-template-form-component
              [template]="selectedTemplate"
              [templateDefaults]="templateDefaults"
              [showCancelButton]="false"
              [showSubmitButton]="false"
              [enableDefaults]="true"
              (formChangeEvent)="handleTemplateFormChange($event)"
            ></app-template-form-component>
          </div>
          <div class="col-4 iphone-preview">
            <div>
              <app-iphone-preview
                [stickAtTop]="stickyPhoneOptions"
                [emailUrl]="getEmailPreviewUrl(selectedTemplate)"
                [nurturePageUrl]="getPagePreviewUrl(selectedTemplate)"
                [forceDisplayType]="forcePreviewType"
                helperText="Loading..."
                errorText="Something went wrong when generating your preview!"
              ></app-iphone-preview>
            </div>
          </div>
        </div>
      </div>
    </app-wizard-step>
    <app-wizard-step stepId="delivery" label="Delivery">
      <app-delivery-method></app-delivery-method>
    </app-wizard-step>
    <app-wizard-step stepId="edit-message" label="Edit Message">
      <app-broadcast-send-message></app-broadcast-send-message>
    </app-wizard-step>
    <app-wizard-step stepId="send" label="Send">
      <app-broadcast-scheduler></app-broadcast-scheduler>
    </app-wizard-step>
  </app-wizard>
</div>