<div class="col-12">
    <div *ngIf="!forceDisplayType && (displayType === 'email' || displayType === 'nurturepage') && !allowEmailPreviewAndTextMessageToggle" class="absolute-center mb-4 template-container">
        <span class="button-selector-container">
            <button
                (click)="toggleDisplayType('email')"
                class="button button-small mr-2"
                [ngClass]="{'button-basic': displayType === 'email', 'button-text inactive': displayType !== 'email'}"
            >
                Email
            </button>
        </span>
        <span class="button-selector-container" *ngIf="!removeNurturePagePreview">
            <button
                (click)="toggleDisplayType('nurturepage')"
                class="button button-small mr-2"
                [ngClass]="{'button-basic': displayType === 'nurturepage', 'button-text inactive': displayType !== 'nurturepage'}"
            >
                Nurture Page
            </button>
        </span>
    </div>
    <app-spam-warnings
        [shouldDisplay]="spamLanguageService.shouldDisplayWarning()"
        [warningText]="spamLanguageService.getWarningText()"
        [spamWarnings]="spamWarnings"
    ></app-spam-warnings>
    <div *ngIf="allowEmailPreviewAndTextMessageToggle" class="absolute-center mb-4 template-container">
        <span class="button-selector-container">
            <button
                (click)="toggleDisplayType('textcontent')"
                class="button button-small mr-2"
                [ngClass]="{'button-basic': displayType === 'textcontent', 'button-text inactive': displayType !== 'textcontent'}"
            >
                Text Message
            </button>
        </span>
        <span class="button-selector-container">
            <button
                (click)="toggleDisplayType('emailsubject')"
                class="button button-small mr-2"
                [ngClass]="{'button-basic': displayType === 'emailsubject', 'button-text inactive': displayType !== 'emailsubject'}"
            >
                Email
            </button>
        </span>
    </div>

    <div class="preview-label absolute-center">
        <i class="far fa-eye"></i>&nbsp;Preview
    </div>
    <div class="iphone-preview-container absolute-center">
        <div class="marvel-device iphone-x">
            <div class="notch">
                <div class="camera"></div>
                <div class="speaker"></div>
            </div>
            <div class="top-bar"></div>
            <div class="sleep"></div>
            <div class="bottom-bar"></div>
            <div class="volume"></div>
            <div class="overflow">
                <div class="shadow shadow--tr"></div>
                <div class="shadow shadow--tl"></div>
                <div class="shadow shadow--br"></div>
                <div class="shadow shadow--bl"></div>
            </div>
            <div class="inner-shadow"></div>
            <div class="screen {{displayType !== 'textcontent' ? 'logo-background' : ''}}" id="iPreviewContainer">
                <p *ngIf="!safeUrl && displayType !== 'textcontent'" class="helper-text absolute-center">{{helperText}}</p>
                <p *ngIf="hasError && displayType !== 'textcontent'" class="helper-text absolute-center">{{errorText}}</p>
                <div class="top-info-container">
                    <div class="top-left">
                        <small>4:28</small>
                    </div>
                    <div class="top-right">
                        <small><i class="far fa-signal"></i></small>
                        <small><i class="far fa-wifi"></i></small>
                        <small><i class="far fa-battery-full"></i></small>
                    </div>
                </div>
                <div class="email-subject" *ngIf="displayType === 'emailsubject'">
                    <div class="subject-decoration">
                        <span class="avatar"><i class="far fa-user"></i></span>
                        <div class="email-recipient">
                            <span><strong>Your Recipient</strong>&nbsp;&nbsp;&nbsp;12:43 PM</span>
                            <span>to me ^</span>
                        </div>
                        <div class="email-actions">
                            <i class="far fa-reply"></i>
                            <i class="far fa-ellipsis-h"></i>
                        </div>
                    </div>
                    <div class="subject-content" [ngbTooltip]="emailSubjectLine">
                        <span class="subject-header">Subject&nbsp;</span>{{emailSubjectLine}}
                    </div>
                </div>
                <div *ngIf="displayType === 'textcontent'" class="iphone-bg">
                    <div class="chat">
                        <div class="mine messages">
                            <div class="message last">
                                {{textContent}}
                            </div>
                        </div>
                    </div>
                </div>
                <iframe
                    id="iphone-preview-iframe"
                    *ngIf="!loading && displayType !== 'textcontent' && !!safeUrl"
                    [src]="safeUrl"
                    [ngClass]="{'email-preview': displayType === 'email', 'windows': isWindows}"
                    frameborder="0"
                    sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                >
                </iframe>
            </div>
        </div>
    </div>
</div>
