<div id="send-email-container" [ngClass]="{'d-flex': !hideRightPane}">
  <div class="left-pane" [ngClass]="{'left-pane-small': !hideRightPane}">
    <app-spam-warnings
      [shouldDisplay]="emailSubjectLineSpamLanguageService.shouldDisplayWarning()"
      [warningText]="emailSubjectLineSpamLanguageService.getWarningText()"
      [emailSubjectLineSpamWarnings]="spamWarnings"
    ></app-spam-warnings>
    <div class="form-group" [formGroup]="formGroup" [ngClass]="{'pr-2': hideRightPane}">
      <label for="emailSubjectLine">Email Subject Line</label>
      <app-subject-line-grader-input
        [inputFormGroup]="formGroup"
        [inputId]="'emailSubjectLine'"
        [inputFormControlName]="'emailSubjectLine'"
        [control]="formGroup.controls.emailSubjectLine"
        (click)="onMouseClick()"
      ></app-subject-line-grader-input>
      <app-character-count [control]="formGroup.controls.emailSubjectLine" [type]="'subject'"></app-character-count>
      <app-print-errors controlName="Email Subject Line" [control]="formGroup.controls.emailSubjectLine"></app-print-errors>
      <app-token-container 
        *ngIf="!hideClickableTokens" 
        maxLength="60" 
        title="Use tokens to customize your message" 
        [formGroup]="formGroup"
        [tokenSource]="tokens" 
        controlName="emailSubjectLine"
        textSourceElementId="emailSubjectLine" 
        [tokensToHide]="tokensToHide" 
        [validators]="validators">
      </app-token-container>
    </div>
  </div>
  <div class="right-pane mt-4" *ngIf="!hideRightPane">
    <app-card-total [title]="'Number of Contacts Selected'" [total]="totalContacts ? totalContacts : 1">
    </app-card-total>
    <app-tips-and-tricks [links]="tipsAndTricksLinks"></app-tips-and-tricks>
  </div>
</div>