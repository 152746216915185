<div *ngIf="!loading" class="template-form-group-container">
  <div
    class="form-group"
    [ngClass]="{'mt-0 mb-4' : field.type === 'list', 'mb-0' : field.type === 'tokentext'}"
    *ngFor="let field of viewData.fields"
  >
    <label for="{{field.name}}">
      {{field.display}}
      <span *ngIf="!field.required" class="helper-text"> - optional</span>
    </label>
    <div class="row">
      <div [ngClass]="enableDefaults ? 'col-9' : 'col-12' ">
        <!-- Image inputs -->
        <div *ngIf="fileInputTypes.includes(field.type)">
          <div *ngIf="loadingFormControl === field.name && mediaUploading" class="spinner-border spinner-border-sm mr-1"></div>
          <!-- Media with a value -->
          <div *ngIf="templateFormService.getControlByKeys(activeControl, field.name).value && !mediaUploading">
            <i
              *ngIf="isFile(templateFormService.getControlByKeys(activeControl, field.name).value)"
              class="far {{getFileIconClass(templateFormService.getControlByKeys(activeControl, field.name).value)}} file-attachment"
            ></i>
            <img
              id="image-preview-{{field.name}}"
              src="{{getImageSource(templateFormService.getControlByKeys(activeControl, field.name).value, field.name)}}"
              alt="Image Preview"
              class="preview-image"
              *ngIf="!isFile(templateFormService.getControlByKeys(activeControl, field.name).value)"
            />
            <p class="remove-default" (click)="removeImageClick(field.name)"><i class="far fa-trash-alt"></i>
              {{isFile(templateFormService.getControlByKeys(activeControl, field.name).value) ? ' Remove File' : ' Remove Image'}}
            </p>
            <input
              id="{{field.name}}"
              type="hidden"
              [formControl]="templateFormService.getControlByKeys(activeControl, field.name)"
            />
          </div>

            <!-- Media with no value -->
          <div *ngIf="!templateFormService.getControlByKeys(activeControl, field.name).value && !mediaUploading" class="media-upload-container">
            <app-file-type-image
                *ngIf="field.type === 'image'"
                [inputId]="field.name"
                [required]="field.required"
                (onFilesPicked)="handleFileUpload(field.name)"
            ></app-file-type-image>
            <app-file-type-all
                *ngIf="field.type !== 'image'"
                [inputId]="field.name"
                [required]="field.required"
                (onFilesPicked)="handleFileUpload(field.name)"
            ></app-file-type-all>
            <div class="separator">or</div>
            <button
              (click)="openModal(field.name)"
              class="button button-white"
            >
              <i class="far fa-image"></i> Select From Media Manager
            </button>
          </div>
        </div>

        <!-- Dropdown input -->
        <select [formControl]="templateFormService.getControlByKeys(activeControl, field.name)" class="form-control" *ngIf="field.type === 'list'">
          <option *ngFor="let item of field.meta.listItems" [value]="item.value">
            {{item.label}}
          </option>
        </select>

        <!-- Text/Number inputs -->
        <div *ngIf="!fileInputTypes.includes(field.type)">
          <div [ngClass]="{'input-group-append': prependInputNames.includes(field.name)}">
            <input
              *ngIf="field.type !== 'list'"
              id="{{field.name}}"
              class="form-control"
              [formControl]="templateFormService.getControlByKeys(activeControl, field.name)"
              placeholder="{{field.meta.placeholder || ''}}"
              required="{{field.required}}"
              type="{{field.type === 'tokentext' ? 'text' : field.type}}"
              [class.is-invalid]="templateFormService.getControlByKeys(activeControl, field.name).touched && templateFormService.getControlByKeys(activeControl, field.name).invalid"
            />
            <span class="input-group-text" *ngIf="prependInputNames.includes(field.name)">!</span>
            <app-generate-ai-text 
              *ngIf="generateAITextNames.includes(field.name)"
              [controlName]="field.name"
              [control]="templateFormService.getControlByKeys(activeControl, field.name)"
            ></app-generate-ai-text>
          </div>
          <!-- Token Container -->
          <span *ngIf="field.type === 'tokentext'">
            <app-print-errors [controlName]="field.display" [control]="templateFormService.getControlByKeys(activeControl, field.name)"></app-print-errors>
            <app-token-container
              [maxLength]="290"
              title="Use tokens to customize your message"
              [formGroup]="templateFormService.getFormGroupByKey(activeControl)"
              [tokenSource]="getTokensToShow()"
              [controlName]="field.name"
              [textSourceElementId]="field.name"
              [tokensToHide]="templateFormService.getTokensToHide()"
              [validators]="templateFormService.getValidators(field)"
            ></app-token-container>
          </span>
        </div>
      </div>
      <div class="col-3 padding-top-8" *ngIf="enableDefaults">
        <div *ngIf="field.dataSource === 'template' && !templateFormService.getControlByKeys(activeControl, field.name).disabled">
          <div class="default-actions" *ngIf="enableDefaults">
            <span class="default-label">Save as default</span>
            <input type="checkbox" (change)="updateDefault($event, field.name)" [disabled]="!templateFormService.getControlByKeys(activeControl, field.name).value" [checked]="userDefaults[field.name]" />
          </div>
          <span *ngIf="defaultUpdated[field.name]" class="defaultUpdated">Default Updated!</span>
          <span *ngIf="defaultRemoved[field.name]" class="defaultRemoved">Default Removed!</span>
          <span *ngIf="defaultAdded[field.name]" class="defaultAdded">Default Added!</span>
        </div>
      </div>
    </div>
  </div>
</div>

<app-media-modal (imageSubmittedEvent)="handleImageSubmitEvent($event)"></app-media-modal>
