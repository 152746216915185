import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { APIHelperService } from './apiHelper.service';
import { ISettings } from '@nurtureboss/common/dist/types/settings';

export enum SettingType {
  Bot = 'bot',
  Template = 'template',
  Integration = 'integration',
  EmailConfig = 'emailConfig',
  TourScheduling = 'tourScheduling',
}

export interface EmailSettingsInterface {
  fromAddress?: string | null;
  mailgunRouteId?: string | null;
}

@Injectable({ providedIn: 'root' })
export class SettingsService {

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService
  ) {}

  getSettingsByType<TSettings>(type: SettingType) {
    return this.http.get<{ result: TSettings }>(this.apiHelper.fillUrl('settingsByType', {type}, {}))
      .pipe(map((data) => data));
  }

  getAllSettings() {
    return this.http.get<{ result: ISettings }>(this.apiHelper.fillUrl('allSettings', {}, {}))
      .pipe(map((data) => data));
  }

  saveSettingsByType<TSettings>(type: SettingType, data: TSettings) {
    return this.http.put(this.apiHelper.fillUrl('settingsByType', {type}, {}), data)
      .pipe(map(res => res));
  }

  saveAllSettings<TSettings>(data: TSettings) {
    return this.http.put(this.apiHelper.fillUrl('allSettings', {}, {}), data)
      .pipe(map(res => res));
  }
}
