import { Injectable, TemplateRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: any[] = [];

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  showError(message: string | string[] | { message: string }) {
    let errMessage = 'Something went wrong';
    if (Array.isArray(message)) {
      errMessage = message.join(', ');
    } else if (typeof message === 'object' && message.message) {
      errMessage = message.message;
    } else if (typeof message === 'string') {
      errMessage = message;
    }

    this.show(errMessage, {
      classname: 'bg-danger text-light',
      delay: 5000,
    });
  }

  showSuccess(message: string | string[] | { message: string }) {
    let errMessage = 'Success!';
    if (Array.isArray(message)) {
      errMessage = message.join(', ');
    } else if (typeof message === 'object' && message.message) {
      errMessage = message.message;
    } else if (typeof message === 'string') {
      errMessage = message;
    }

    this.show(errMessage, {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }

  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
}