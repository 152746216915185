// Angular:
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Libs:
import { map } from 'rxjs/operators';
// Services:
import { APIHelperService } from './apiHelper.service';
export interface Group {
  _id?: string;
  name?: string;
  email?: string;
  password?: string;
  users?: any[];
}

interface GroupResponse {
  errors: [];
  result: Group;
}

interface GroupsResponse {
  errors: [];
  result: Group[];
}

@Injectable({ providedIn: 'root' })
export class GroupsService {
  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService,
  ) {
    // no-op
  }

  getGroup(id: string) {
    return this.http.get<GroupResponse>(this.apiHelper.fillUrl('group', { id }, {}))
      .pipe(map(response => {
        return response.result;
      }));
  }

  getGroups() {
    return this.http.get<GroupsResponse>(this.apiHelper.fillUrl('groups', {}, {}))
      .pipe(map(response => {
        return response.result;
      }));
  }

  updateGroup(id: string, data: Group) {
    return this.http.put<GroupResponse>(this.apiHelper.fillUrl('group', { id }, {}), data)
      .pipe(map((response) => {
        return response.result;
      }));
  }

  createGroup(data: Group) {
    return this.http.post<GroupResponse>(this.apiHelper.fillUrl('groups', {}, {}), data)
      .pipe(map(response => {
        return response.result;
      }));
  }

  deleteGroup(id: string) {
    return this.http.delete<GroupResponse>(this.apiHelper.fillUrl('group', { id }, {}))
      .pipe(map(response => {
        return response.result;
      }));
  }
}
