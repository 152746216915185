import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { APIHelperService } from './apiHelper.service';

@Injectable({ providedIn: 'root' })
export class OpenAIService {

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService
  ) {
    // no-op
  }

  gradeSubjectLine(subjectLine) {
    return this.http.post<any>(this.apiHelper.fillUrl('openAIGradeSubjectLine', {}, {}), { subjectLine })
      .pipe(map(data => {
        return data;
      }));
  }

  generateSubjectLine(subjectLine) {
    return this.http.post<any>(this.apiHelper.fillUrl('openAIGenerateSubjectLine', {}, {}), { subjectLine })
      .pipe(map(data => {
        return data;
      }));
  }

  generateTextWithPropertyContext(text) {
    return this.http.post<any>(this.apiHelper.fillUrl('openAIGenerateTextWithPropertyContext', {}, {}), { text })
      .pipe(map(data => {
        return data;
      }));
  }
}