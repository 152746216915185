import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { APIHelperService } from './apiHelper.service';

@Injectable({ providedIn: 'root' })
export class ResmanService {

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService
  ) {
    // no-op
  }

  verifyAndCreateResmanIntegration(data) {
    return this.http.post<any>(this.apiHelper.fillUrl('verifyIntegration', {type: 'resman'}, {}), data)
    .pipe(map(data => {
      return data;
    }));
  }
}
