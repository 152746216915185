const MODAL_NAMES = {
  MEDIA_MANAGER: 'media-manager-modal',
  SCHEDULE_MESSAGES: 'schedule-messages-modal',
  SAVE_DEFAULTS: 'save-default-options-modal',
  BUILD_AUTOMATIONS: 'build-automations-modal',
  WARNING: 'warning-modal',
  ENABLE_INTEGRATION: 'enable-integration-modal',
  GENERATE_AI_TEXT: 'generate-ai-text-modal',
  CONTACT_TABLE_COLUMN_SELECTOR: 'contact-table-column-selector'
};

export default MODAL_NAMES;
