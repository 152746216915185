import { Injectable } from "@angular/core";
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { Observable, Subject, Subscription } from 'rxjs';
import { APIHelperService } from './apiHelper.service';
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class WebsocketsService {

  private websocketMessageSubject = new Subject<any>();

  private socketConnectionSubject: WebSocketSubject<any>;
  private socketSubscription: Subscription;

  constructor(
    private apiHelper: APIHelperService,
    private authService: AuthenticationService
  ) {
  }

  websocketMessages(): Observable<any> {
    return this.websocketMessageSubject.asObservable();
  }

  startWebsocketListener() {
    this.authService.currentUser.subscribe((userData) => {
      if (this.socketSubscription) {
        this.socketSubscription.unsubscribe();
      }
      if (this.socketConnectionSubject) {
        this.socketConnectionSubject.unsubscribe();
      }

      if (!userData || !userData.user) {
        return;
      }

      const token = this.authService.token;
      if (!token) {
        return;
      }

      this.socketConnectionSubject = webSocket(this.apiHelper.getWebSocketEndpoint().texting + '?token=' + token);
      this.socketSubscription = this.socketConnectionSubject.asObservable().subscribe((dataFromServer) => {
        if (dataFromServer.ping) {
          // Skip ping events
          return;
        }

        this.websocketMessageSubject.next(dataFromServer);
      });
    });
  }
}