type JobResults = { result: { isComplete: boolean; results: any; }; errors: any; };
type JobFunction = () => Promise<{ result: { jobId: string }; errors: any; }>;
type CheckJobFunction = (jobId: string) => Promise<JobResults>;
type RetryableHandler = () => Promise<JobResults>;
const retryIn = (handler: RetryableHandler, retryInterval: number, retryCount: number): Promise<JobResults> => new Promise((resolve, reject) => {
  let numOfRetries = 0;

  async function innerHandler() {
    try {
      const result = await handler();
      if (result.result.isComplete) {
        resolve(result);
      } else {
        if (numOfRetries >= retryCount) {
          return reject(new Error('Could not finish request'));
        }

        numOfRetries++;
        _retryIn();
      }
    } catch (error) {
      reject(error);
    }
  };

  function _retryIn() {
    setTimeout(innerHandler, retryInterval);
  };

  _retryIn();
});

export const runWorker = async (jobFunction: JobFunction, checkFunction: CheckJobFunction): Promise<JobResults> => {
  if (!jobFunction || !checkFunction) {
    throw new Error('A job function and a check function are required');
  }

  const result = await jobFunction();
  if (result.errors && result.errors.length) {
    throw new Error(result.errors);
  }

  const results = await checkFunction(result.result.jobId);
  if (results.result.isComplete) {
    return results;
  }

  return await retryIn(() => checkFunction(result.result.jobId), 3000, 10);
};