/*
  ###################### NOTE #################

  When integrations are used as a value for a field they should be all lower case,
  but when used as field names such as `property.rentCafe.apiKey` they are camelcase.

  Field names are as follows (* mark camelcased integrations):
    property.yardi
  * property.realPage
    property.ilm
    property.entrata
    property.knock
  * property.renyDynamics
    property.rentgrata
  * property.rentCafe
    property.resman

  #############################################
*/

interface GenericObjectStrings {
  [key: string]: string
};

interface GenericObjectBoolean {
  [key: string]: boolean
};

interface IntegrationServices {
  [key: string]: {
    [key: string]: boolean
  }
};

export const INTEGRATION_DESCRIPTIONS: GenericObjectStrings = {
  Entrata: 'Full API Access',
  Knock: 'Prospect data and tour scheduling access',
  RealPage: 'Prospect Data Access',
  RentCafe: 'Pricing, Availability, Property Images, and Tour Scheduling',
  RentDynamics: 'Tour scheduling access',
  Rentgrata: 'Resident referral API access',
  Resman: 'Full API Access',
  Yardi: 'ILS and Common Data Interface Access',
  ACE: 'Add ACE chat to Nurture Pages',
};

export const INTEGRATION_TYPES: GenericObjectStrings = {
  Yardi: 'yardi',
  RealPage: 'realpage',
  ILM: 'ilm',
  Entrata: 'entrata',
  Knock: 'knock',
  RentDynamics: 'rentdynamics',
  Rentgrata: 'rentgrata',
  RentCafe: 'rentcafe',
  Resman: 'resman',
  ACE: 'ace',
};

export const DISPLAY_NAME_INTEGRATION: GenericObjectStrings = {
  [INTEGRATION_TYPES.RentDynamics]: 'RentDynamics',
  [INTEGRATION_TYPES.RealPage]: 'RealPage',
  [INTEGRATION_TYPES.RentCafe]: 'RentCafe',
  [INTEGRATION_TYPES.Yardi]: 'Yardi',
  [INTEGRATION_TYPES.Entrata]: 'Entrata',
  [INTEGRATION_TYPES.Knock]: 'Knock',
  [INTEGRATION_TYPES.Rentgrata]: 'Rentgrata',
  [INTEGRATION_TYPES.ILM]: 'Ilm',
  [INTEGRATION_TYPES.Resman]: 'Resman',
  [INTEGRATION_TYPES.ACE]: 'ACE',
};

export const INTEGRATION_SERVICE_TYPES: GenericObjectStrings = {
  tourScheduling: 'tourScheduling',
  residentSource: 'residentSource',
  prospectSource: 'prospectSource',
  availabilitySource: 'availabilitySource',
  ledgerBalanceSource: 'ledgerBalanceSource',
};

export const DISPLAY_NAME_INTEGRATION_SERVICE: GenericObjectStrings = {
  tourScheduling: 'Tour Scheduling Source',
  residentSource: 'Resident Source',
  prospectSource: 'Prospect Source',
  availabilitySource: 'Availability Source',
  ledgerBalanceSource: 'Ledger Balance Source',
};

export const TOUR_SCHEDULING_ENABLED_INTEGRATIONS: string[] = [
  INTEGRATION_TYPES.RentCafe,
  INTEGRATION_TYPES.Knock,
  INTEGRATION_TYPES.RentDynamics,
  INTEGRATION_TYPES.Entrata,
  INTEGRATION_TYPES.RealPage,
  INTEGRATION_TYPES.Yardi
];

export const RESIDENT_SOURCE_ENABLED_INTEGRATIONS: string[] = [
  INTEGRATION_TYPES.Yardi,
  INTEGRATION_TYPES.Entrata,
  INTEGRATION_TYPES.Resman,
  INTEGRATION_TYPES.RealPage,
];

export const PROSPECT_SOURCE_ENABLED_INTEGRATIONS: string[] = [
  INTEGRATION_TYPES.Yardi,
  INTEGRATION_TYPES.RealPage,
  INTEGRATION_TYPES.Entrata,
  INTEGRATION_TYPES.Knock,
  INTEGRATION_TYPES.Resman,
];

export const AVAILABILITY_SOURCE_ENABLED_INTEGRATIONS: string[] = [
  INTEGRATION_TYPES.RentCafe,
  INTEGRATION_TYPES.Resman,
  INTEGRATION_TYPES.Entrata,
  INTEGRATION_TYPES.RealPage
];

export const LEDGER_BALANCE_SOURCE_ENABLED_INTEGRATIONS: string[] = [
  INTEGRATION_TYPES.Yardi,
  INTEGRATION_TYPES.RealPage,
  INTEGRATION_TYPES.Entrata,
  INTEGRATION_TYPES.Resman,
];

export const INTEGRATION_IS_CREATE_CONFIGURATION: GenericObjectBoolean = {
  Yardi: false,
  RealPage: false,
  Entrata: false,
  RentCafe: true,
  Knock: true,
  RentDynamics: true,
  Rentgrata: true,
  Resman: false,
  ACE: true,
};

export const INTEGRATIONS_SERVICES: IntegrationServices = {
  yardi: {
    residentSource: true,
    prospectSource: true,
    availabilitySource: false,
    ledgerBalanceSource: true,
    tourScheduling: true,
  },
  realPage: {
    residentSource: true,
    prospectSource: true,
    availabilitySource: true,
    ledgerBalanceSource: true,
    tourScheduling: true,
  },
  entrata: {
    residentSource: true,
    prospectSource: true,
    availabilitySource: true,
    ledgerBalanceSource: true,
    tourScheduling: true,
  },
  rentCafe: {
    residentSource: false,
    prospectSource: false,
    availabilitySource: true,
    ledgerBalanceSource: false,
    tourScheduling: true,
  },
  knock: {
    residentSource: false,
    prospectSource: true,
    availabilitySource: false,
    ledgerBalanceSource: false,
    tourScheduling: true,
  },
  rentDynamics: {
    residentSource: false,
    prospectSource: false,
    availabilitySource: false,
    ledgerBalanceSource: false,
    tourScheduling: true,
  },
  resman: {
    residentSource: true,
    prospectSource: true,
    availabilitySource: true,
    ledgerBalanceSource: true,
    tourScheduling: false,
  }
};

export const RENTGRATA_PUBLIC_URL: string = 'https://www.rentgrata.com/m/';

export const WIDGET_BASE_URL: string = 'https://widget.nurtureboss.io/widget.js';
