// Angular:
import { Router } from '@angular/router';
import { AfterContentChecked, ChangeDetectorRef, Component, HostListener, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { Location } from '@angular/common';
// Libs:
import { Subscription } from 'rxjs';
// Components:
import { WizardComponent } from '@app/wizard/wizard.component';
// Types:
import { WizardCompleteEvent, WizardNavigationEvent } from '@app/wizard/wizard.types';
import { BroadcastContext } from '@app/broadcast/broadcast.types';
import { TemplateSettings } from '@nurtureboss/common/dist/types/settings';
// Helpers:
import { CanDeactivateComponent } from '@app/_helpers/pending-changes.guard';
// Commons:
import { ITemplate, TemplateMessagingTypes } from '@nurtureboss/common/dist/types/templates';
import { TemplateDefault } from '@nurtureboss/common/dist/types/templateDefaults';
// Services:
import { TemplatesService } from '@app/_services/templateDefaults.service';
import { 
  BrandingsService,
  EmailSubjectLineSpamLanguageService,
  LoaderService,
  SettingsService,
  SettingType,
  SpamLanguageService,
  ToastService,
  UsersService,
} from '@app/_services';
import { PagePreviewService } from '@app/_services/pagePreview.service';
// Directives:
import { IStickAtTopOptions, StickyMethod } from '@app/_directives/stick-at-top.directive';

@Component({
  selector: 'app-broadcast-builder',
  templateUrl: './broadcast-builder.component.html',
  styleUrls: ['./broadcast-builder.component.less'],
})
export class BroadcastBuilderComponent implements AfterContentChecked, CanDeactivateComponent, OnDestroy, OnInit {

  @ViewChild(WizardComponent) wizard: WizardComponent<BroadcastContext>;

  selectedTemplate?: ITemplate = null;
  initialContext: BroadcastContext = {};
  templateDefaults?: TemplateDefault[];
  selectedTemplateValues: {[key: string]: string};
  templateFormValid: boolean;
  stickyHeaderOptions: IStickAtTopOptions = {
    useParent: true,
    topThreshold: 0,
    applyStyles: {
      top: '0px',
    },
    method: StickyMethod.Sticky,
  };
  stickyPhoneOptions: IStickAtTopOptions = {
    useParent: true,
    sameParentWidth: true,
    topThreshold: 100,
    applyStyles: {
      top: '95px',
    },
    method: StickyMethod.Fixed,
  };
  forcePreviewType = '';
  stepChangedSubscription: Subscription;
  templateSettings: TemplateSettings;

  constructor(
    private router: Router,
    private templatesService: TemplatesService,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private changeDetector: ChangeDetectorRef,
    private pagePreviewService: PagePreviewService,
    private location: Location,
    private brandingsService: BrandingsService,
    private settingsService: SettingsService,
    private usersService: UsersService,
    private spamLanguageService: SpamLanguageService,
    private emailSubjectLineSpamLanguageService: EmailSubjectLineSpamLanguageService,
  ) {
    // We use the changeDetector to ensure everything is
    // loaded before we start checking for changes. This is
    // mainly because we rely on the WizardService which isn't
    // available until after the ngAfterContentChecked hook.
    this.changeDetector.detach();
    this.spamLanguageService.setWarningText('WARNING: Your Blast contains words that are known to trigger Spam Filters for various Email Service Providers. Problomatic words found:');
    this.emailSubjectLineSpamLanguageService.setWarningText('WARNING: Your Email Subject contains words and symbols known to trigger Spam Filters for various Email Service Providers. The following issues were found:');
  }

  ngOnInit() {
    this.usersService.loadGlobalDefaults();
    this.settingsService.getSettingsByType(SettingType.Template).subscribe(data => {
      this.templateSettings = data.result;
    });
  }

  @HostListener('window:beforeunload')
  canDeactivate() {
    if (!this.wizard?.wizardService) {
      return true;
    }

    const context = this.wizard.wizardService.getContext();
    if (!Object.keys(context).length) {
      return true;
    }

    if (context.isComplete) {
      return true;
    }

    return confirm('In progress changes will not be saved. Are you sure you want to exit?');
  }

  ngOnDestroy() {
    if (!this.wizard?.wizardService) {
      return;
    }

    this.wizard!.wizardService.cleanup();
    this.stepChangedSubscription?.unsubscribe();
  }

  ngAfterContentChecked() {
    this.changeDetector.reattach();
    this.changeDetector.detectChanges();

    if (this.wizard?.wizardService && !this.stepChangedSubscription) {
      this.stepChangedSubscription = this.wizard.wizardService.stepChanged$.subscribe(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      });
    }
  }

  onNavigate(wizardEvent: WizardNavigationEvent<BroadcastContext>) {
    this.location.replaceState('/blasts/builder?step=' + wizardEvent.nextStepId);
  
    // Decide which spam warnings should show depending on current step
    if (wizardEvent.nextStepId === 'fill-template') {
      // Reset to prevent bug where warnings from another template show if you back out and choose a different template
      this.spamLanguageService.resetWarnings();
      this.emailSubjectLineSpamLanguageService.resetWarnings();

      this.spamLanguageService.setShouldDisplay(true);
      this.emailSubjectLineSpamLanguageService.setShouldDisplay(false);
    } else if (wizardEvent.nextStepId === 'edit-message') {
      this.spamLanguageService.setShouldDisplay(false);
      this.emailSubjectLineSpamLanguageService.setShouldDisplay(true);
    } else if (wizardEvent.nextStepId === 'send') {
      this.spamLanguageService.setShouldDisplay(true);
      this.emailSubjectLineSpamLanguageService.setShouldDisplay(true);
    }

    wizardEvent.continueNavigation();
  }

  onExit(_: WizardCompleteEvent<BroadcastContext>) {
    this.router.navigate(['blasts']);
  }

  onTemplateSelected(template: ITemplate) {
    // reset fields
    this.selectedTemplateValues = null;
    this.selectedTemplate = null;
    this.templateDefaults = null;
    this.loaderService.triggerLoader();
    // get template defaults
    this.templatesService.getTemplateDefaults(template.templateName).subscribe((data) => {
      this.templateDefaults = data.result;
      this.selectedTemplate = template;

      // Set context for parsing tokens
      this.spamLanguageService.setContext(data.result);
      this.emailSubjectLineSpamLanguageService.setContext(data.result);

      this.forcePreviewType = '';
      if (!template.supportedMessaging.includes(TemplateMessagingTypes.Email)) {
        this.forcePreviewType = 'textcontent';
      } else if (!template.supportedMessaging.includes(TemplateMessagingTypes.NurturePage)) {
        this.forcePreviewType = 'emailsubject';
      }

      this.wizard.wizardService.completeStep({
        template,
      });
      this.loaderService.stopLoader();
    }, (error) => {
      this.toastService.showError('There was an error fetching template defaults, please try again.');
      this.loaderService.stopLoader();
    });
  }

  handleTemplateFormSubmit() {
    if (!this.templateFormValid) {
      return;
    }
    this.moveNext();
  }

  handleTemplateFormChange(data: {values: {[key: string]: string}, valid: boolean}) {
    this.selectedTemplateValues = data.values;
    this.templateFormValid = data.valid;
  }

  moveNext() {
    const currentContext = this.wizard.wizardService.getContext();
    currentContext.templateValues = this.selectedTemplateValues;
    this.wizard.wizardService.completeStep(currentContext);
  }

  private addThemeToTemplateValues(templateValues): any {
    return {
      ...templateValues,
      ...this.brandingsService.currentBranding,
      ...this.usersService.currentGlobalDefaultSocialLinks,
      ...this.templateSettings
    };
  }

  getPagePreviewUrl(template: ITemplate): string {
    if (!template) {
      return '';
    }
    const templateValues = this.selectedTemplateValues || {};
    return this.pagePreviewService.getPagePreviewUrl(template, this.addThemeToTemplateValues(templateValues));
  }

  getEmailPreviewUrl(template: ITemplate): string {
    if (!template) {
      return '';
    }
    const templateValues = this.selectedTemplateValues || {};
    return this.pagePreviewService.getEmailPreviewUrl(template, this.addThemeToTemplateValues(templateValues));
  }
}
