// TODO: Move to a place where it can be reused, like Helpers
import { Pipe, PipeTransform } from '@angular/core';

export const deriveAddressDisplay = (address: string) => {
  try {
    if (!address) {
      return '';
    }

    const parts = address.split(',');
    let endIndex = parts.length - 1;
    let lastPart = parts[endIndex];

    // If we find the country, we can back up one
    if (lastPart.toUpperCase().includes('USA')) {
      endIndex -= 1;
      lastPart = parts[endIndex];
    }

    // If there is nothing else, let's just return
    if (endIndex === 0) {
      return ` - ${lastPart}`;
    }

    // Should return City, State Zip
    return ` - ${parts[endIndex - 1]}, ${lastPart}`;
  } catch (err) {
    // If this fails for some reason due to index parsing, don't kill the
    // entire list of accounts.
    return '';
  }
};

@Pipe({
  name: 'mimicAccountDisplay'
})
export class MimicAccountDisplayPipe implements PipeTransform {

  transform(value: { propertyName: string; address: string; churned?: boolean; }, ...args: unknown[]): string {
    let response = value.propertyName + deriveAddressDisplay(value.address);

    if (value.churned) {
      response = `[CHURNED] ${response}`;
    }

    return response;
  }
}

@Pipe({
  name: 'churnAccountDisplay'
})
export class ChurnAccountDisplayPipe implements PipeTransform {

  transform(value: { propertyName: string; address: string; churned?: boolean; }, ...args: unknown[]): string {
    let response = value.propertyName + deriveAddressDisplay(value.address);

    if (value.churned) {
      response = `[CHURNED] ${response}`;
    }

    return response;
  }
}

@Pipe({
  name: 'mimicAccountDisplayWithPMC'
})
export class MimicAccountDisplayWithPMCPipe implements PipeTransform {

  transform(
    value: {
      propertyName: string; address: string; churned?: boolean; propertyManagementCompany?: string
    },
    ...args: unknown[]
  ): string {

    let response = value.propertyName + deriveAddressDisplay(value.address);

    if (value.propertyManagementCompany) {
      response = response + ` - ${value.propertyManagementCompany}`;
    }

    if (value.churned) {
      response = `[CHURNED] ${response}`;
    }

    return response;
  }
}