<div class="subject-line-grader-container" [formGroup]="inputFormGroup">
  <input
    class="form-control"
    [id]="inputId"
    [formControlName]="inputFormControlName"
    type="text"
  />
  <ng-container *ngIf="aiIsActive && !disabled">
    <div class="grade-container">
      <div *ngIf="grade" class="grade" [ngClass]="grade.toLowerCase()">{{grade}}</div>
      <i *ngIf="gradeReason" placement="top" [ngbTooltip]="gradeReason" class="far fa-info-circle grade-reason"></i>
    </div>
    <button 
      *ngIf="grade"
      class="button button-dark make-it-better-button"
      [ngClass]="!!disabledText && 'btn-disabled'"
      (click)="getAIGeneratedSubjectLine()"
      [disabled]="!!disabledText"
      [ngbTooltip]="disabledText"
    >
      Make it Better!
    </button>
  </ng-container>
  <button 
    *ngIf="!disabled && !aiIsActive"
    class="button button-dark grade-subject-line-button"
    [ngClass]="{ 'btn-disabled': !!disabledText }"
    (click)="turnOnAI()"
    [disabled]="!!disabledText"
    [ngbTooltip]="disabledText"
  >
    <i class="fa-sharp fa-light fa-microchip-ai"></i> Grade Subject Line AI
  </button>
</div>