import { Component, OnInit} from '@angular/core';
import { AuthenticationService, LoaderService, ToastService, VLAService } from '@app/_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vla',
  templateUrl: './vla.component.html',
  styleUrls: ['./vla.component.less']
})
export class VLAComponent implements OnInit {

  userData: any;
  adjustedHeight: string;
  isAdmin = false;
  vlaHref = '';

  constructor(
    private authService: AuthenticationService,
    public router: Router,
    private vlaService: VLAService,
    private toastService: ToastService,
    private loaderService: LoaderService,
  ) {
    this.userData = this.authService.currentUserValue.user;
    this.vlaHref = `mailto:sales@nurtureboss.io?subject=Id like to enable the VLA on my account&body=Please contact me to enable the VLA on my account at property ${this.userData.propertyName}!`
  }

  ngOnInit(): void {

    // Adjust CSS to give CS a good experience too :)
    this.isAdmin = this.userData.claims.includes('admin')
    if (this.isAdmin) {
      this.adjustedHeight = 'calc(100vh - 152px)';
    } else {
      this.adjustedHeight = 'calc(100vh - 68px)';
    }
  }

  vlaEnabled() {
    return this.authService.currentUserValue?.user?.claims?.includes('vla');
  }

  async enableVLA() {
    try {
      this.loaderService.triggerLoader();
      await this.vlaService.enableVLA().toPromise();
      this.loaderService.stopLoader();
      location.reload();
    } catch (e) {
      this.loaderService.stopLoader();
      this.toastService.showError('Could not enable VLA');
    }
  }
}
