import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

enum ButtonSize {
  sm = 'button-small',
  md = 'button-medium',
}

enum ButtonColor {
  dark = 'button-dark',
  gray = 'button-gray',
  basic = 'button-basic',
  text = 'button-text',
  light = 'button-light',
  white = 'button-white',
  red = 'button-red',
  danger = 'button-danger',
}

@Component({
  selector: 'nb-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.less'],
})
export class ButtonComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() loading: boolean;
  @Input() title: string;
  @Input() size: ButtonSize;
  @Input() color: ButtonColor;
  @Input() cssClass: string;

  constructor() {}

  ngOnInit() {
    if (this.color) {
      this.color = ButtonColor[this.color];
    }
    if (this.size) {
      this.size = ButtonSize[this.size];
    }
  }
}
