import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastService, TextMessagesService, LoaderService, AuthenticationService } from '@app/_services';
import { EmailsService } from '@app/_services/emails.service';
import * as _ from 'lodash';
import { Calendar } from 'primeng/calendar';
import { Table } from 'primeng/table';
import { APIHelperService } from '../_services/apiHelper.service';

const TrackingType = {
  TextMessages: 'textMessages',
  Emails: 'emails'
}

@Component({
  templateUrl: 'tracking.component.html',
  styleUrls: ['tracking.component.less'],
})
export class TrackingComponent implements OnInit {

  @ViewChild('sentTimeCalendar') sentTimeCalendar: Calendar;
  @ViewChild('createdCalendar') createdCalendar: Calendar;
  @ViewChild('dt') table: Table;

  activeState: string = TrackingType.TextMessages;
  searchText: string = '';
  showPageData: boolean = false;
  pages: any[] = [];
  messages: any[] = [];
  totalClicks: number = 0;
  totalDelivered: number = 0;
  totalRecords: any;
  labelSearchText: string = '';
  pageTypeSearchText: string = '';
  statusSearchText: string = '';
  debouncedSearch: any;
  activeQuery: any = {
    query: '',
    property: ''
  };
  pagination: any = {
    first: 1,
    last: 25
  };
  dateRange: Date[] = [];
  userData: any;

  constructor(
    private toastService: ToastService,
    private textMessagesService: TextMessagesService,
    private emailsService: EmailsService,
    private loaderService: LoaderService,
    public apiHelper: APIHelperService,
    private authService: AuthenticationService,
  ) {
    this.debouncedSearch = _.debounce(this.searchFunc, 300);
    this.userData = authService.currentUserValue?.user;
  }

  searchFunc(query, property) {
    this.activeQuery = {
      query: query,
      property: property
    }
    this.loadData(query, property);
  }

  ngOnInit() {
    this.loadData(null, null, true);
  }

  getAllData(skip, take, query, property) {
    let startingDate = null;
    let endingDate = null;

    if (this.dateRange.length === 2) {
      startingDate = this.dateRange[0].getTime();

      // Add one day so we can get everything before the next day at midnight
      const endDate = new Date(this.dateRange[1]);
      endDate.setDate(endDate.getDate() + 1);
      endingDate = endDate.getTime();
    }

    if (this.activeState === TrackingType.TextMessages) {
      return this.textMessagesService.getAll(skip, take, query, property, startingDate, endingDate);
    } else {
      return this.emailsService.getAllEmails(skip, take, query, property, startingDate, endingDate);
    }
  }

  showErrorToast() {
    const type = this.activeState === TrackingType.TextMessages ? 'text message' : 'email';
    this.toastService.show(`There was an error loading your ${type} data`, { classname: 'bg-danger text-light', delay: 5000 });
  }

  loadData(query?, property?, _load?) {
    this.loaderService.triggerLoader();
    this.pagination.first = 1;
    this.pagination.last = 25;
    this.getAllData(0, 25, query, property).subscribe(messages => {
      this.messages = messages.result.data;
      this.totalRecords = messages.result.totalRecords;
      this.setTableHeading();
      this.loaderService.stopLoader();
    }, (_error) => {
      this.loaderService.stopLoader();
      this.showErrorToast();
    });
  }

  paginate(event) {
    this.loaderService.triggerLoader();
    this.pagination.first = event.first;
    this.pagination.last = event.first + Number(event.rows);
    this.getAllData(event.first, event.rows, this.activeQuery.query, this.activeQuery.property).subscribe(messages => {
      this.messages = messages.result.data;
      this.totalRecords = messages.result.totalRecords;
      this.setTableHeading();
      this.loaderService.stopLoader();
    }, (error) => {
      this.loaderService.stopLoader();
      this.showErrorToast();
    });
  }

  setTableHeading() {
    this.pagination.first = this.table._first;
    this.pagination.last = this.table._rows;
    
    if (this.pagination.first === 0) {
      this.pagination.first = 1;
    }
  }

  onTypeChange(event, type) {
    event.preventDefault();
    this.activeState = type;
    this.dateRange = [];
    this.loadData(null, null, true);
  }

  // Allows us to automationcally close the
  // calendar when selecting date ranges.
  deferCalendarToggle(calendar: Calendar) {
    setTimeout(() => {
      calendar.toggle();
    }, 200);
  }

  onDateClear(event) {
    this.dateRange = [];
    this.loadData(this.activeQuery.query, this.activeQuery.property);
  }

  onDateSelect(date, field) {
    if (this.dateRange.length === 0 || this.dateRange.length === 2) {
      this.dateRange = [date];
      return;
    } else {
      this.dateRange.push(date);
    }
    if (this.dateRange.length === 2) {
      if (field === 'created') {
        this.deferCalendarToggle(this.createdCalendar);
      } else {
        this.deferCalendarToggle(this.sentTimeCalendar);
      }

      this.dateRange.sort(function(a, b) {
        return +new Date(a) - +new Date(b);
      });

      this.loadData(this.activeQuery.query, this.activeQuery.property);
    }
  }
}
