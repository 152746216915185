// Angular:
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

// Libs
import * as _ from 'lodash';

// Services:
import { FormsService, LoaderService, ToastService } from '@app/_services';
import { NurtureBossModalService } from '@app/_services/modal.service';

// Types:
import { IForm } from '@nurtureboss/common/dist/types/forms';
import { Table } from 'primeng/table';
import { Calendar } from 'primeng/calendar';
import { LazyLoadEvent } from 'primeng/api';

interface IFormResponse {
  errors: string[];
  result: {
    count: number,
    data: IForm[]
  };
}

interface IPaginate {
  first: number;
  last: number;
}

interface IActiveQuery {
  query: string;
  property: string;
}

@Component({
  selector: 'app-view-forms',
  templateUrl: './view-forms.component.html',
  styleUrls: ['./view-forms.component.less']
})
export class ViewFormsComponent implements OnInit {
  @ViewChild('dt') table: Table;
  @ViewChild('createdCalendar') createdCalendar: Calendar;

  activeQuery: IActiveQuery = {
    query: '',
    property: ''
  };
  dateRange: Date[] = [];
  debouncedSearch: any;
  forms: IForm[] = [];
  pagination: IPaginate = {
    first: 1,
    last: 25
  };
  isLoading = true;
  stagedForm: any = null;
  totalRecords = null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private formsService: FormsService,
    private router: Router,
    private nbModalService: NurtureBossModalService,
  ) {
    this.debouncedSearch = _.debounce(this.searchFunc, 300);
  }

  ngOnInit() { 
    this.loadFormsBaseData();
  }

  async loadFormsBaseData(query?: string, property?: string): Promise<void> {
    this.loaderService.triggerLoader();
    this.pagination.first = 1;
    this.pagination.last = 25;

    try {
      const data = await this.getForms(0, 25, query, property);
      this.forms = data.result.data;
      this.totalRecords = data.result.count;
      this.setTableHeading();
    } catch (e) {
      this.toastService.showError('There was an error loading forms.');
    }
    this.isLoading = false;
    this.loaderService.stopLoader();
  }

  async paginate(event: LazyLoadEvent): Promise<void> {
    this.loaderService.triggerLoader();
    this.pagination.first = event.first;
    this.pagination.last = event.first + Number(event.rows);
    try {
      const data = await this.getForms(event.first, event.rows, this.activeQuery.query, this.activeQuery.property);
      this.forms = data.result.data;
      this.totalRecords = data.result.count;
      this.setTableHeading();
    } catch (e) {
      this.toastService.showError('There was an error loading past forms.');
    }
    this.loaderService.stopLoader();
  }

  getForms(skip: number, take: number, query: string, property: string): Promise<IFormResponse> {
    let startingDate = null;
    let endingDate = null;
    if (this.dateRange.length === 2) {
      startingDate = this.dateRange[0].getTime();

      // Add one day so we can get everything before the next day at midnight
      const endDate = new Date(this.dateRange[1]);
      endDate.setDate(endDate.getDate() + 1);
      endingDate = endDate.getTime();
    }
    return this.formsService.getPaginatedForms(skip, take, query, property, startingDate, endingDate).toPromise();
  }

  setTableHeading(): void {
    this.pagination.first = this.table._first;
    this.pagination.last = this.table._rows;
    if (this.pagination.first === 0) {
      this.pagination.first = 1;
    }
  }

  searchFunc(query: string, property: string): void {
    this.activeQuery = {
      query: query,
      property: property
    }
    this.loadFormsBaseData(query, property);
  }

  deferCalendarToggle(calendar: Calendar): void {
  
    // TODO: Not sure why we put this in a setTimeout, maybe to force an Angular cycle?
    setTimeout(() => {
      calendar.toggle();
    }, 100);
  }

  onDateSelect(date: Date): void {
  if (this.dateRange.length === 0 || this.dateRange.length === 2) {
    this.dateRange = [date];
    return;
  } else {
    this.dateRange.push(date);
  }
  if (this.dateRange.length === 2) {
    this.deferCalendarToggle(this.createdCalendar);
    this.dateRange.sort(function(a, b) {
      return +new Date(a) - +new Date(b);
    });
    this.loadFormsBaseData(this.activeQuery.query, this.activeQuery.property);
  }
  }

  openEditFormPage(formId: string) {
    this.router.navigate([`/forms/${formId}/edit`]);
  };

  openCloneFormModal(form) {
    this.stagedForm = form;
    this.nbModalService.open('clone-form-modal');
  }

  openFormResultsPage(formId) {
    this.router.navigate([`/forms/${formId}/results`]);
  };

  openFormPage(formUrl) {
    window.open(formUrl, '_blank').focus();
  };

  async cloneForm() {
    try {
      this.loaderService.triggerLoader();
      const clonedForm = await this.formsService.cloneForm(this.stagedForm._id).toPromise();
      this.toastService.showSuccess('Form successfully cloned!');
      this.closeModal('clone-form-modal');
      this.router.navigate([`/forms/create/${clonedForm._id}`]);
    } catch (err) {
      this.toastService.showError('Error cloning form.');
    }
    this.loaderService.stopLoader();
  }

  async copyToClipboard(text) {
    await navigator.clipboard.writeText(text as any);
    this.toastService.showSuccess('Copied to clipboard!');
  }

  generateViewFormUrl(formId, isPreview = false) {
    if (isPreview) {
      return 'https://forms.nurtureboss.io/#/preview/' + formId;
    } else {
      return 'https://forms.nurtureboss.io/#/forms/' + formId;
    }
    
    // Dev Code
    // if (isPreview) {
    //   return 'http://localhost:4202/#/preview/' + formId;
    // } else {
    //   return 'http://localhost:4202/#/forms/' + formId;
    // }

  }

  async toggleFormActiveStatus(form, confirmSave = false) {
    // Show warning modal if form will become active
    const newActiveStatus = !form.active;
    if (newActiveStatus && !confirmSave) {
      this.stagedForm = form;
      this.nbModalService.open('set-form-active-modal');
      return;
    } else if (newActiveStatus && confirmSave) {
      this.stagedForm = null;
      this.nbModalService.close('set-form-active-modal')
    }

    this.loaderService.triggerLoader();
    
    try {
      await this.formsService.updateForm(form._id, { active: newActiveStatus }).toPromise();
      form.active = newActiveStatus;
  
      this.toastService.show(`Survey: ${form.name} has been successfully ${newActiveStatus === true ? 'activated' : 'deactivated'}.`, {
        classname: 'bg-success text-light',
        delay: 5000
      });
    } catch (err) {
      this.toastService.showError('Error updating survey.');
    }

    this.loaderService.stopLoader();
  }

  cancelCloseSetFormActiveModal() {
    // @ts-ignore
    document.getElementById(this.stagedForm._id).checked = false;
    this.closeModal('set-form-active-modal');
  }

  closeModal(name) {
    this.nbModalService.close(name);
    this.stagedForm = null;
  }
}