import { Component, EventEmitter, Input, Output } from '@angular/core';
import { getAllowedFileExtensions, getAllowedImageExtensions } from '@app/_utils/fileIcons';

@Component({
  selector: 'app-file-type-all',
  template: `
    <label
      [for]="inputId"
      class="button-override button-white">
        <i class="far fa-cloud-upload"></i> Upload A New File
    </label>
    <input
      (change)="onChange($event)"
      type="file"
      [accept]="acceptedFileExtensions"
      [id]="inputId"
      [class.is-invalid]="!isValid"
      [required]="required"
    />
  `,
  styleUrls: ['./file-type-all.component.less'],
})
export class FileTypeAllComponent {

  @Input() isValid: boolean;
  @Input() required: boolean = false;
  @Input() inputId: string = 'file-type-all-component';
  @Output() onFilesPicked = new EventEmitter<MouseEvent>();

  acceptedFileExtensions: string[] = [];

  constructor() {
    this.acceptedFileExtensions = [...getAllowedImageExtensions(), ...getAllowedFileExtensions()];
  };

  onChange(event: MouseEvent) {
    return this.onFilesPicked.emit(event);
  }
}
