import { Component, OnInit} from '@angular/core';
import { AuthenticationService } from '@app/_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vla',
  templateUrl: './vlaautomations.component.html',
  styleUrls: ['./vlaautomations.component.less']
})
export class VLAAutomationsComponent implements OnInit {

  userData: any;
  adjustedHeight: string;

  constructor(
    private authService: AuthenticationService,
    public router: Router,
  ) {
    this.userData = this.authService.currentUserValue.user;
  }

  ngOnInit(): void {

    // Adjust CSS to give CS a good experience too :)
    if (this.userData.claims.includes('admin')) {
      this.adjustedHeight = 'calc(100vh - 202px)';
    } else {
      this.adjustedHeight = 'calc(100vh - 118px)';
    }
  }
}
