<div id="template-list">
  <div class="template-card" *ngFor="let template of templates">
    <div class="template-thumbnail" (click)="onPreviewClick($event, template)">
      <div *ngIf="!isLegacy" class="hover-actions">
        <button type="button" class="button button-white" (click)="onTemplateClick($event, template, 'none')">
          Select
        </button>
        <button type="button" class="button button-white" (click)="onPreviewClick($event, template)">
          Preview
        </button>
      </div>
      <img [src]="chooseImageSourceFunction(template)" [alt]="template.display"/>
    </div>
    <span class="display">{{template.display}}</span>
    <button *ngIf="isLegacy" class="template-action left" (click)="onTemplateClick($event, template, 'single')">Single</button>
    <button *ngIf="isLegacy" class="template-action right" (click)="onTemplateClick($event, template, 'bulk')">Bulk</button>
  </div>
</div>