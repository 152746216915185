// Angular:
import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// Services:
import { GroupsService, AuthenticationService, LoaderService, BrandingsService} from '@app/_services';
import { deriveAddressDisplay } from '../admin/mimic-account-display.pipe';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.less'],
})
export class GroupComponent implements OnInit {
  registeredUserData = {};
  dropdownSettings = {};
  loading = false;
  userData: any = {};
  accounts: any = {};
  suggestions: any = {};
  selectedAccount: null;

  constructor(
    private groupsService: GroupsService,
    private authService: AuthenticationService,
    private loaderService: LoaderService,
    private brandingsService: BrandingsService,
    private router: Router,
  ) {
    // no-op
  }

  ngOnInit() {
    this.loadData();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true
    };
  }

  loadData() {
    // Get user settings
    this.loading = true;
    this.loaderService.triggerLoader();
    this.userData = this.authService.currentUserValue;

    // Check if group, if so fetch all accounts.
    if (this.userData.user.claims && this.userData.user.claims.indexOf('group') > -1) {
      this.groupsService.getGroup(this.userData.user._id).subscribe(data => {
        const filteredArray = data.users ? data.users.filter(user => {
          return !user.churned && user.propertyName && user.propertyName.length > 0;
        }).map((account: any) => {
          account.display = `${account.propertyName}${deriveAddressDisplay(account.address)}`;
          return account;
        }) : [];
        this.accounts = filteredArray.sort((a, b) => {
          return (a.propertyName > b.propertyName) ? 1 : -1;
        });
        this.suggestions = [...this.accounts];
        this.loading = false;
        this.loaderService.stopLoader();
      }, (_e) => {
        this.loading = false;
        this.loaderService.stopLoader();
      });
    }
  }

  mimic(account) {
    this.authService.groupMimic(account._id).subscribe(async () => {
      await this.brandingsService.getBrandings(true).toPromise();
      this.router.navigate(['/dashboard']);
    }, (_e) => {
      // no-op
    });
  }

  filterAccounts({ query }) {
    const normalQuery = (query || '').toLowerCase();
    this.suggestions = this.accounts.filter((account) => {
      const propertyName = (account.propertyName || '').toLowerCase();
      const address = (account.address || '').toLowerCase();
      return propertyName.includes(normalQuery) || address.includes(normalQuery);
    });
  }

  onAccountSelect(event) {
    this.selectedAccount = event;
  }

  onAccountCleared() {
    this.selectedAccount = null;
  }
}
