import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { LoaderService, OpenAIService, ToastService } from '@app/_services';

@Component({
  selector: 'app-subject-line-grader-input',
  templateUrl: './subject-line-grader-input.component.html',
  styleUrls: ['./subject-line-grader-input.component.less'],
})
export class SubjectLineGraderInputComponent {
  // ##########################################################################################
  // Hardcoded value for quickly disabling AI components, they will be hidden from UI
  disabled = false; 
  // ##########################################################################################

  @Input() control: FormControl;
  @Input() inputFormControlName: string;
  @Input() inputFormGroup: FormGroup;
  @Input() inputId: string;
  @Input() borderless: boolean;

  aiIsActive = false;
  grade = '';
  gradeReason = '';
  inputSub: Subscription = null;
  // Different than disabled above ^^^
  // Disabled here triggers if user has reached token limit OR if AI services in the backend have been disabled
  // If disabled here, buttons still show, but display tooltip explaining why
  disabledText = ''; 

  constructor(
    private toastService: ToastService,
    private openAIService: OpenAIService,
    private loaderService: LoaderService,
  ) {
    // no-op
  }

  async getSubjectLineGrade(subjectLine) {
    try {
      // Check session storage for previous request response
      const storedRes = sessionStorage.getItem(`GRADE: ${subjectLine}`);
      if (storedRes) {
        const parsedStoredRes = JSON.parse(storedRes);
        this.grade = parsedStoredRes.grade;
        this.gradeReason = parsedStoredRes.reason;
      } else {
        // Get subject line grade
        const res = await this.openAIService.gradeSubjectLine(subjectLine).toPromise();
        this.grade = res.result.grade;
        this.gradeReason = res.result.reason;
        sessionStorage.setItem(`GRADE: ${subjectLine}`, JSON.stringify(res.result));
      }
    } catch(err) {
      if (Array.isArray(err) && err.includes('User AI Request Limit Reached')) {
        this.toastService.showError('User Request Limit Reached, Please wait to use AI services again.');
        this.disabledText = 'API request limit reached, please wait to use AI services again';
      } else if (Array.isArray(err) && err.includes('Open AI services are currently disabled.')) {
        this.toastService.showError('AI services are currently disabled.');
        this.disabledText = 'AI services are currently disabled.';
      } else {
        this.toastService.showError('There was an error grading the subject line');
      }
      throw err;
    }
  }

  async getAIGeneratedSubjectLine() {
    try {
      const subjectLine = this.control.value;

      // Check session storage for previous response
      const storedRes = sessionStorage.getItem(`GENERATE: ${subjectLine}`);
      if (storedRes) {
        this.control.setValue(storedRes);
      } else {
        // Generate new subject line
        this.loaderService.triggerLoader();
        const res = await this.openAIService.generateSubjectLine(subjectLine).toPromise();
        this.control.setValue(res.result.subjectLine);
        sessionStorage.setItem(`GENERATE: ${subjectLine}`, res.result.subjectLine);
        this.loaderService.stopLoader();
        this.toastService.show('Successfully generate new email subject line!');
      }
    } catch(err) {
      this.loaderService.stopLoader();
      if (Array.isArray(err) && err.includes('User AI Request Limit Reached')) {
        this.toastService.showError('User Request Limit Reached, please wait to use AI services again.');
        this.disabledText = 'API request limit reached, please wait to use AI services again';
      } else if (Array.isArray(err) && err.includes('Open AI services are currently disabled.')) {
        this.toastService.showError('AI services are currently disabled.');
        this.disabledText = 'AI services are currently disabled.';
      } else {
        this.toastService.showError('There was an error grading the subject line');
      }
      throw err;
    }
  }

  resetSubjectLineGrader() {
    this.grade = '';
    this.aiIsActive = false;
  }

  async turnOnAI() {
    try {
      this.loaderService.triggerLoader();
      await this.getSubjectLineGrade(this.control.value)
      this.aiIsActive = true;
      this.inputSub = this.control.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value) => {
        this.resetSubjectLineGrader();
      });
      this.loaderService.stopLoader();
    } catch (err) {
      this.loaderService.stopLoader();
    }
  }
}
