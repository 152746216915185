import { Component, OnInit} from '@angular/core';
import { AuthenticationService, ToastService, LoaderService, ContactsService, AnalyticsService } from '@app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { APIHelperService } from '../../_services/apiHelper.service';

@Component({
  selector: 'app-contactevents',
  templateUrl: './contactevents.component.html',
  styleUrls: ['./contactevents.component.less']
})
export class ContactEventsComponent implements OnInit {

  userData: any;
  activeContact: any = {
    phoneNumber: 0
  };
  activeContactId: String;
  allEvents = [];
  thirdPartyGuestCardId: string;
  integrationType: string;
  sanityCheck;
  pageHeight = 'calc(100vh - 160px)';

  // Integration Event Types
  APPOINTMENT = 'Appointment Scheduled';
  APPLICATION = 'Application';
  APPICATION_PROGRESS = 'Application Progress';
  APPLICATION_DENIED = 'Application Denied';
  CALL = 'Phone Call';
  CALL_MADE = 'Call Made';
  CALL_RECEIVED = 'Call Received';
  EMAIL = 'Email Message';
  EMAIL_RECEIVED = 'Email Received';
  EMAIL_SENT = 'Email Sent';
  FOLLOW_UP = 'Follow Up Occurred';
  GUEST_CARD_CLOSED = 'Guest Card Closed/Cancelled/Lost';
  LEASE_UPDATE = 'Updates Made To Lease Status';
  NO_SHOW = 'No Show';
  OTHER = 'Other';
  TOUR = 'Tour Completed';
  TEXT_MESSAGE = 'Text Message';
  TEXT_MESSAGE_SENT = 'Text Message Sent';
  TEXT_MESSAGE_RECEIVED = 'Text Message Received';
  WALK_IN = 'Walk In';
  WEBSERVICE = 'Webservice/Internet';
  WEB_MESSAGE_SENT = 'Web Message Sent';
  WEB_MESSAGE_RECEIVED = 'Web Message Received';

  INBOUND_COMM: Array<string>;
  OUTBOUND_COMM: Array<string>;
  GENERAL_COMM: Array<string>;

  constructor(
    private authService: AuthenticationService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private contactsService: ContactsService,
    public apiHelper: APIHelperService,
    private analyticsService: AnalyticsService,
  ) {
    this.INBOUND_COMM = [this.CALL_RECEIVED, this.EMAIL_RECEIVED, this.TEXT_MESSAGE_RECEIVED, this.WEB_MESSAGE_RECEIVED];
    this.OUTBOUND_COMM = [this.CALL_MADE, this.EMAIL_SENT, this.TEXT_MESSAGE_SENT, this.WEB_MESSAGE_SENT];
    this.GENERAL_COMM = [this.CALL, this.EMAIL, this.TEXT_MESSAGE, this.FOLLOW_UP]
    this.userData = this.authService.currentUserValue.user;
    this.activatedRoute.queryParams.subscribe(params => {
      this.activeContactId = params['id'];
      if (!this.activeContactId) {
        this.router.navigate(['dashboard']);
      } else {
        this.loadData(this.activeContactId);
      }
    });
  }

  ngOnInit() {

    // We dynamically set height here to give CS team a good experience too :)
    if (this.userData.claims.includes('admin')) {
      this.pageHeight = 'calc(100vh - 243px)';
    }
  }
    
  async loadData(id) {
    this.loaderService.triggerLoader(true);
    try {
      const contact = await this.contactsService.getContact(id).toPromise();
      this.activeContact = contact.result;
      this.determineContactSystemType(this.activeContact);
      const events = await this.analyticsService.getContactEvents(
        this.activeContactId,
        this.integrationType,
        this.thirdPartyGuestCardId,
      ).toPromise();
      this.allEvents = events.result.sort((a,b) => {
        return +new Date(a.eventDate) - +new Date(b.eventDate);
      });

      // Covert ASCII to HTML
      for (const event of this.allEvents) {
        if (event.eventNotes) {
          event.eventNotes = event.eventNotes.replace(/&amp;/g, '&').replace(/&gt;/g, '>').replace(/&lt;/g, '<').replace(/&quot;/g, '"');
        }
      }
      setTimeout(function() {
        var elem = document.getElementById('event-scroll');
        if (elem) {
          elem.scrollTop = elem.scrollHeight;
        }
      });
      this.loaderService.stopLoader();
    } catch (err) {
      this.loaderService.stopLoader();
      this.toastService.showError('There was an error loading contact data');
    }
  }

  determineContactSystemType(contact) {
    
    // Check Knock first. Replace this with integration service
    // prospect and resident source when ready.
    if (contact.knockProspectId) {
      this.thirdPartyGuestCardId = contact.knockProspectId;
      this.integrationType = 'knock';
    } else if (contact.yardiGuestCardId) {
      this.thirdPartyGuestCardId = contact.yardiGuestCardId;
      this.integrationType = 'yardi';
    } else if (contact.realPageGuestCardId) {
      this.thirdPartyGuestCardId = contact.realPageGuestCardId;
      this.integrationType = 'realpage';
    } else if (contact.entrataGuestCardId) {
      this.thirdPartyGuestCardId = contact.entrataGuestCardId;
      this.integrationType = 'entrata';
    } else if (contact.resmanPersonId) {
      this.thirdPartyGuestCardId = contact.resmanPersonId;
      this.integrationType = 'resman';
    }
  }
}