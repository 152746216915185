import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-sidebar-filter',
  templateUrl: './sidebar-filter.component.html',
  styleUrls: ['./sidebar-filter.component.less']
})
export class SidebarFilterComponent implements OnInit, OnChanges {

  @Input() header: string;
  @Input() subheader?: string = null;
  @Input() filters: string[] = [];
  @Input() initialFilter: string = '';
  @Input() useInverseColors;

  @Output() selectionChanged = new EventEmitter<string>();
  
  selectedFilter: string = '';

  constructor() { }

  ngOnInit() {
    this.selectedFilter = this.initialFilter;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.filters
      && changes.filters.firstChange
      && changes.filters.currentValue.length
      && !this.selectedFilter
    ) {
      this.selectedFilter = changes.filters.currentValue[0];
    }
  }

  onClickFilter(filter: string) {
    this.selectionChanged.emit(filter);
    this.selectedFilter = filter;
  }
}
