<!-- Build Automations Modal -->
<nb-modal
  [name]="name"
  [confetti]="false"
  [doOnDismiss]="buildAutomationsModalDismissedBuilder()"
  header="Confirm Automations to Build"
  class="build-automations-modal"
  footer="true"
  [primaryButtonOptions]="buildAutomationsModalPrimaryButton"
>
  <div class="warning-container">
    Continuing will overwrite any existing System Automations. Custom Automations will be unaffected.
  </div>
  <form [formGroup]="form" id="build-automations-body">
    <div *ngFor="let availableAutomation of availableAutomations" class="checkbox-container">
      <input class="checkbox" type="checkbox" ng-attr-id="{{availableAutomation}}" formControlName="{{availableAutomation}}">
      <label class="checkbox-label" ng-attr-for="{{availableAutomation}}">{{getAutomationDisplayName(availableAutomation)}}</label>
    </div>
  </form>
</nb-modal>