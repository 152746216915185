// Angular:
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
// Libs:
import { map } from 'rxjs/operators';
// Services:
import { APIHelperService } from './apiHelper.service';
import { AuthenticationService } from './authentication.service';

// TODO: Move to types
interface GlobalDefaults {
  concession?: string;
  concessionExpirationDate?: Date;
  created?: string;
  facebookUrl?: string;
  instagramHandle?: string;
  facebook?: string;
  instagram?: string;
  updated?: string;
  bannerImage?: string;
  leftLogo?: string;
  yourWebsite?: string;
  residentPortalUrl?: string;
  yourEmailAddress?: string;
  yourPhoneNumber?: string;
  yourName?: string;
  scheduleATourUrl?: string;
  onlineReviewUrl?: string;
  floorPlansUrl?: string;
  amenitiesUrl?: string;
  imageGalleryUrl?: string;
  applyOnlineUrl?: string;
}

export interface hyperPersonalizationSettings {
  petImage?: string;
  petImageLabel?: string;
  petPolicy?: string;
  availabilityActive?: boolean;
}

export interface hyperPersonalizationResponse {
  errors: [];
  result: hyperPersonalizationSettings;
}

export interface smsSettings {
  optInLanguage?: string;
  preferToText?: boolean;
}

export interface smsSettingsResponse {
  errors: [];
  result: smsSettings | Array<any>;
}

export interface GlobalDefaultsSocialLinks {
  facebook?: string;
  instagram?: string;
}

interface GlobalDefaultsResponse {
  errors: [];
  result: GlobalDefaults;
}

@Injectable({ providedIn: 'root' })
export class UsersService {

  private globalDefaultsSocialLinks: GlobalDefaultsSocialLinks;

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService,
    private authService: AuthenticationService,
    private location: Location
  ) {
    // no-op
  }

  public set currentGlobalDefaultSocialLinks(defaults: GlobalDefaults) {
    this.globalDefaultsSocialLinks = {
      facebook: defaults?.facebookUrl,
      instagram: defaults?.instagramHandle,
    };
  }

  public get currentGlobalDefaultSocialLinks() {
    return this.globalDefaultsSocialLinks || {};
  }

  loadGlobalDefaults(): void {
    this.getGlobalDefaults().subscribe();
  }

  loadUser(): void {
    if (!this.authService.currentUserValue || !this.authService.currentUserValue.user) {
      return;
    }

    this.getUserData(this.authService.currentUserValue.user._id).subscribe((data) => {
      const user = data.result;
      this.authService.updateGlobalUserValue(user);
    }, (_err) => {
      // no-op
    });
  }

  getUserData(id) {
    return this.http.get<any>(this.apiHelper.fillUrl('user', { id }, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  updateUser(id, data, skipUpdate?) {
    return this.http.put<any>(this.apiHelper.fillUrl('user', { id }, {}), data)
      .pipe(map((response) => {
        const token = response.result.token;
        if (token) {
          delete response.result.token;
        }
        const user = response.result;
        if (!skipUpdate) {
          this.authService.updateGlobalUserValue(user, this.authService.isAdmin || this.authService.isGroupUser || this.authService.isPartner ? null : token);
        }
        return response;
      }));
  }

  updateUserPassword(id, data) {
    return this.http.put<any>(this.apiHelper.fillUrl('userPassword', { id }, {}), data)
      .pipe(map((response) => {
        return data;
      }));
  }

  createNewAccount(data) {
    return this.http.post<any>(this.apiHelper.fillUrl('users', {}, {}), data)
      .pipe(map(response => {
        return response;
      }));
  }

  setTemplateDefaults(data) {
    return this.http.post<any>(this.apiHelper.fillUrl('defaults', {}, {}), data)
      .pipe(map(response => {
        return response;
      }));
  }

  setTemplateSelectedDefaults(data) {
		return this.http.post<any>(this.apiHelper.fillUrl('selectedDefaults', {}, {}), data)
      .pipe(map(response => {
        return response;
      }));
	}

  setGlobalDefaults(data) {
    return this.http.post<GlobalDefaultsResponse>(this.apiHelper.fillUrl('globalDefaults', {}, {}), data)
        .pipe(map(response => {
            this.currentGlobalDefaultSocialLinks = response?.result;
            return response;
        }));
  }

  getGlobalDefaults() {
    return this.http.get<GlobalDefaultsResponse>(this.apiHelper.fillUrl('globalDefaults', {}, {}))
        .pipe(map(response => {
            this.currentGlobalDefaultSocialLinks = response?.result;
            return response;
        }));
  }

  getHyperpersonalizationData() {
    return this.http.get<hyperPersonalizationResponse>(this.apiHelper.fillUrl('settingsByType', { type: 'hyperPersonalization' }, {}))
        .pipe(map(response => {
            return response;
        }));
  }

  setHyperpersonalization(settings: hyperPersonalizationSettings) {
    return this.http.put<hyperPersonalizationResponse>(this.apiHelper.fillUrl('settingsByType', { type: 'hyperPersonalization' }, {}), settings)
      .pipe(map(response => {
          return response;
      }));
  }
 
  getSmsSettings() {
    return this.http.get<smsSettingsResponse>(this.apiHelper.fillUrl('settingsByType', { type: 'sms' }, {}))
      .pipe(map(response => {
          return response;
      }));
  }

  setSmsSettings(settings: smsSettings) {
    return this.http.put<smsSettingsResponse>(this.apiHelper.fillUrl('settingsByType', { type: 'sms' }, {}), settings)
      .pipe(map(response => {
          return response;
      }));
  }

  updateUsage(data) {
    return this.http.post<any>(this.apiHelper.fillUrl('usages', {}, {}), data)
      .pipe(map(response => {
        return response;
      }));
  }

  updateWidgetFeatures(features: string[]) {
    return this.http.post<any>(this.apiHelper.fillUrl('widgetFeatures', {}, {}), { features })
      .pipe(map((response) => {
        const { user, token } = response.result;
        this.authService.updateGlobalUserValue(user, this.authService.isAdmin || this.authService.isPartner ? null : token);
      }));
  }

  churn(accountId) {
    return this.http.post<any>(this.apiHelper.fillUrl('churn', {id: accountId}, {}), {})
      .pipe(map((response) => {
        return response;
      }));
  }
}
