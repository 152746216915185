import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, Injector } from '@angular/core';
import { FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { TemplateFormService, EmailSubjectLineSpamLanguageService } from '@app/_services';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';

const MAX_EMAIL_SUBJECT_LENGTH = 60;

@Component({
  selector: 'app-send-email-component',
  templateUrl: './send-email-component.component.html',
  styleUrls: ['./send-email-component.component.less'],
  providers: [TemplateFormService],
})
export class SendEmailComponentComponent implements OnInit, OnDestroy {

  @Input() formGroup: FormGroup;
  @Input() totalContacts: number;
  @Input() tokens: string[];
  @Input() hideClickableTokens: boolean = false;
  @Input() hideRightPane: boolean = false;
  @Input() _emailSubjectLineSpamLanguageService: EmailSubjectLineSpamLanguageService | null = null;
  @Output() inputOnEnterEvent = new EventEmitter<any>();

  tipsAndTricksLinks = []; // TODO: get links
  maxCharacters = MAX_EMAIL_SUBJECT_LENGTH;
  tokensToHide: string[];
  validators: ValidatorFn[] = [ Validators.required ];
  spamWarnings = [];
  emailSubjectLineSpamLanguageService: EmailSubjectLineSpamLanguageService;
  subscriptions: Subscription[] = [];

  constructor(
    private templateFormService: TemplateFormService,
    private injector: Injector,
  ) {
    // no-op
  }

  ngOnInit(): void {
    this.tokensToHide = this.templateFormService.getTokensToHideForEmailInput();

    // When using the SpamLanguageGroupService the SpamLanguageService needs to passed as a param
    // since there are several instances and not one "global" instance
    if (this._emailSubjectLineSpamLanguageService) {
      // Use service passed as a param
      this.emailSubjectLineSpamLanguageService = this._emailSubjectLineSpamLanguageService;
    } else {
      // Inject "global" instance
      this.emailSubjectLineSpamLanguageService = this.injector.get(EmailSubjectLineSpamLanguageService);
    }

    // Update local values with new warnings
    this.subscriptions.push(this.emailSubjectLineSpamLanguageService.warnings.subscribe(warnings => {
      this.spamWarnings = warnings;
    }));

    // Check for spam language when the subject line value changes
    this.subscriptions.push(this.formGroup.controls.emailSubjectLine.valueChanges.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(val => {
      this.emailSubjectLineSpamLanguageService.checkForSpamLanguage(val);
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onMouseClick() {
    this.inputOnEnterEvent.emit();
  }
}
