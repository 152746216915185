<div id="byoa-page">
  <form [formGroup]="automationForm" class="form margin-bottom-48">
    <div class="header">
      <div class="automation-name">
        <input
          autofocus
          placeholder="Give Your Automation A Name! Type Here..."
          class="form-control"
          type="text"
          formControlName="name"
          id="name"
          [attr.disabled]="isSystemAutomation ? 'disabled' : undefined"
        />
        <i placement="right" ngbTooltip="The name of your automation" class="far fa-info-circle"></i>
      </div>
      <div class="actions-wrapper">
        <h6>Off/On</h6>
        <label class="switch mr-2">
          <input type="checkbox" formControlName="isActive" id="isActive" (change)="onToggleChanged($event)">
          <span class="slider round"></span>
        </label>
        <button class="button button-dark pull-right" type="submit" [disabled]="automationForm.invalid" (click)="save()">Save Automation</button>
      </div>
    </div>
    <app-print-errors class="automation-name-error" controlName="Automation Name" [control]="automationForm.controls.name"></app-print-errors>
    <div class="alert-wrapper" *ngIf="saveErrorMessages.length > 0">
      <div class="save-errors alert alert-danger">
        <ul class="error-list">
          <li *ngFor="let error of saveErrorMessages">{{error}}</li>
        </ul>
      </div>
    </div>
    <div *ngIf="groupHasSpamViolation()" class="alert-wrapper alert alert-warning margin-top-24">
      <div class="mb-0 spam-filter-header d-flex justify-content-between">
        <div class="spam-filter-header-title text-warning">
          WARNING: One or more of your automation Follow Ups contain phrases that are known to trigger Spam Filters for various Email Service Providers
        </div>
        <div class="spam-filter-header-icon text-warning">
          <i 
            [class]="{'open': warningCollapse[automationId]}"
            class="fa-regular fa-chevron-right"
            (click)="expandWarning($event)"
            [id]="automationId"
          ></i>
        </div>
      </div>
      <div
        class="mb-0 text-warning spam-filter-body d-flex justify-content-between align-items-center collapsed"
        [class]="{'open': warningCollapse[automationId], 'collapsed': !warningCollapse[automationId]}"
      >
          <ul class="margin-bottom-0">
            <ng-container *ngFor="let spamGroupWarning of spamGroupWarnings; let i = index;">
              <li *ngIf="getSpamViolationsFromGroupWarning(spamGroupWarning).length">
                Follow Up #{{i + 1}}: 
                <ng-container *ngFor="let violation of getSpamViolationsFromGroupWarning(spamGroupWarning); let i = index;">
                    <ng-container *ngIf="i !== 0">, </ng-container>
                    <b>{{violation}}</b>
                </ng-container>
              </li>
            </ng-container>
          </ul>
      </div>
    </div>
    <div class="contacts-query-wrapper">
      <div class="contacts-query-header" (click)="toggleContactQuery()">
          <p>Select Contacts Group <i placement="right" ngbTooltip="Select the criteria of which contacts this automation track should run for" class="far fa-info-circle"></i></p>
          <!-- <i [ngClass]="{'hide': !contactsQueryVisible}" class="far fa-chevron-down"></i> -->
          <span [ngClass]="{'hide': !contactsQueryVisible}" class="ui-accordion-toggle-icon pi pi-fw pi-chevron-down"></span>
      </div>
      <div class="contacts-query" [ngClass]="{'show': contactsQueryVisible}">
        <div class="contacts-query-inner">
          <div *ngIf="!isSystemAutomation || (isSystemAutomation && automation.name === 'Rent Reminder Automation') || (isSystemAutomation && automation.name.includes('Delinquency Automation'))" class="row mb-4">
            <div class="col-md-6">
              <div class="form-group">
                <label class="block">Run on a specifc day of the month? <i placement="bottom" ngbTooltip="This automation track will only be triggered on the day you specify, this means, at most, 1 follow up per month will run" class="far fa-info-circle"></i></label>
                <label class="switch" for="runOnSpecificDay">
                  <input type="checkbox" formControlName="runOnSpecificDay" id="runOnSpecificDay" (change)="onToggleChanged($event, true)">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="col-md-6">
              <div *ngIf="automationForm.value.runOnSpecificDay" class="form-group">
                <label for="dayToRun">Day to Run <i placement="right" ngbTooltip="The day of the month to run the automation" class="far fa-info-circle"></i></label>
                <input
                  class="form-control"
                  type="number"
                  formControlName="dayToRun"
                  id="dayToRun"
                  min="1"
                  max="28"
                />
                <app-print-errors controlName="Day to Run" [control]="automationForm.controls.dayToRun"></app-print-errors>
              </div>
            </div>
          </div>
          <div *ngIf="automation && !automation.dayToRun && allowStepSchedulingMode" class="row mb-4">
            <div class="col-md-6">
              <div class="form-group">
                <label class="block">Follow Up Scheduling Mode 
                  <i 
                    placement="bottom" 
                    ngbTooltip="Follow up sending mode can be 'Immediate' or 'Optimized'. When Immediate is chosen the next follow up in the automation will send as soon as the criteria is met. When Optimized is chosen, the next follow up will send at the optimal time once the criteria has been met. This can be up to 24 hours after the follow up's criteria is met."
                    class="far fa-info-circle"
                  ></i>
                </label>
                <label class="margin-right-12">Immediate</label>
                <label class="switch" for="stepSchedulingMode">
                  <input type="checkbox" formControlName="stepSchedulingMode" id="stepSchedulingMode" (change)="onToggleChanged($event)">
                  <span class="slider round"></span>
                </label>
                <label class="margin-left-12">Optimized</label>
              </div>
            </div>
            <div class="col-md-6" *ngIf="(isYardiUse || isRealPageUser || isEntrataUser || isResmanUser) && isProspectAutomation">
              <div class="form-group">
                <label class="block">Follow Up Aware
                  <i 
                    placement="bottom" 
                    ngbTooltip="Your automations can be aware of the last time a prospect received a follow up, whether from Nurture Boss or your onsite team. By turning this on, you are asking your automation to wait until 24 hours have gone by with no follow up before a new message is sent."
                    class="far fa-info-circle"
                  ></i>
                </label>
                <label class="switch" for="stepFollowUpMode">
                  <input type="checkbox" formControlName="stepFollowUpMode" id="stepFollowUpMode" (change)="onToggleChanged($event)">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-4">
              <div class="form-group">
                <label for="contactType">Contact Types <i placement="right" ngbTooltip="The contact types to target with this automation" class="far fa-info-circle"></i></label>
                <br/>
                <p-multiSelect
                  placeholder="Contact type"
                  [options]="contactTypes"
                  formControlName="contactType"
                  id="contactType"
                  optionLabel="label"
                  optionValue="value"
                  styleClass="form-multiselect"
                  [filter]="false"
                  [showHeader]="false"
                  [disabled]="!allowQueryEdit"
                >
                </p-multiSelect>
                <app-print-errors controlName="Contact Type" [control]="automationForm.controls.contactType"></app-print-errors>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div class="form-group">
                <label for="contactStage">Contact Stages <i placement="right" ngbTooltip="The contact stages to target with this automation" class="far fa-info-circle"></i></label>
                <br/>
                <p-multiSelect
                  placeholder="Contact stages"
                  [options]="contactStages"
                  formControlName="contactStage"
                  id="contactStage"
                  optionLabel="label"
                  optionValue="value"
                  styleClass="form-multiselect"
                  [filter]="false"
                  [disabled]="!allowQueryEdit"
                  maxSelectedLabels="6"
                >
                </p-multiSelect>
                <app-print-errors controlName="Contact Stage" [control]="automationForm.controls.contactStage"></app-print-errors>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div class="form-group">
                <label for="contactStatus">Contact Statuses <i placement="right" ngbTooltip="The contact statuses to target with this automation" class="far fa-info-circle"></i></label>
                <br/>
                <p-multiSelect
                  placeholder="Contact statuses"
                  [options]="statuses"
                  formControlName="contactStatus"
                  id="contactStatus"
                  optionLabel="label"
                  optionValue="value"
                  styleClass="form-multiselect"
                  [filter]="false"
                  [disabled]="!allowQueryEdit"
                  maxSelectedLabels="6"
                >
                </p-multiSelect>
                <app-print-errors controlName="Contact Status" [control]="automationForm.controls.contactStatus"></app-print-errors>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div class="form-group">
                <label for="contactSource">Contact Sources (optional) <i placement="right" ngbTooltip="The contact sources to target with this automation" class="far fa-info-circle"></i></label>
                <br/>
                <p-multiSelect
                  placeholder="Contact sources"
                  [options]="sources"
                  formControlName="contactSource"
                  id="contactSource"
                  optionLabel="label"
                  optionValue="value"
                  styleClass="form-multiselect"
                  [filter]="false"
                  [disabled]="!allowQueryEdit"
                >
                </p-multiSelect>
                <app-print-errors controlName="Contact Source" [control]="automationForm.controls.contactSource"></app-print-errors>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div class="form-group">
                <label for="contactCreatedFilter">Contact Created Date Filter (optional) <i placement="right" ngbTooltip="How should contact created date be used when searching contacts? Use 'Ignore' to search all contacts. The 'Before' and 'After' options are exclusive, meaning they do NOT include the date you select." class="far fa-info-circle"></i></label>
                <select
                  class="form-control"
                  formControlName="contactCreatedFilter"
                  id="contactCreatedFilter"
                  [attr.disabled]="allowQueryEdit ? undefined : 'disabled'"
                >
                  <option value="ignore">N/A</option>
                  <option value="before">Before</option>
                  <option value="after">After</option>
                </select>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div class="form-group">
                <label for="contactCreated">Contact Created Date (optional)</label>
                <br/>
                <p-calendar selectionMode="single" [showButtonBar]="true"
                  placeholder="Contact Created Date"
                  [readonlyInput]="true" dateFormat="mm-dd-yy"
                  formControlName="contactCreated" id="contactCreated"
                  [disabled]="!allowQueryEdit"
                >
                </p-calendar>
              </div>
            </div>
            <!-- Update Lost Lead Trigger Code -->
            <div *ngIf="isLostLeadAutomation" class="col-md-6">
              <div class="form-group">
                <label for="lookbackDistance">Lookback Distance (Days)</label>
                <input
                  class="form-control"
                  type="number"
                  formControlName="lookbackDistance"
                  id="lookbackDistance"
                />
              </div>
            </div>
            <!-- Update Lost Lead Trigger Code -->
            <div *ngIf="isLostLeadAutomation" class="col-md-6">
              <div class="form-group">
                <label for="lookbackField">Lookback Field</label>
                <select
                  class="form-control"
                  formControlName="lookbackField"
                  id="lookbackField"
                >
                  <option value="guestCardCreatedDate">Guest Card Created Date</option>
                  <option value="created">Nurture Boss Contact Created Date</option>
                  <option value="updated">Nurture Boss Contact Updated Date</option>
                  <option value="recentActivityDate">Recent Activity Date</option>
                  <option value="expectedMoveInDate">Expected Move In Date</option>
                  <option value="lostDate">Lost Date</option>
                </select>
              </div>
            </div>
            <!-- Update Delinquency Trigger Code -->
            <div *ngIf="isDelinquencyAutomation" class="col-md-6">
              <div class="form-group">
                <label for="delinquencyAmount">Delinquency Amount <i placement="right" ngbTooltip="Minimum accumulated delinquency amount for contact to get notification" class="far fa-info-circle"></i></label>
                <input
                  class="form-control"
                  type="number"
                  formControlName="delinquencyAmount"
                  id="delinquencyAmount"
                  min="0"
                />
              </div>
            </div>
          </div>
          <div class="selected-contacts">
            <div *ngIf="loadingContactQueryCount" class="spinner-border spinner-border-sm"></div>
            <p *ngIf="!loadingContactQueryCount" class="selected-contacts-number">
              {{queryableContactCount}}
            </p>
            <p>Number of Contacts Selected</p>
          </div>
        </div>
      </div>
    </div>
    <app-step
      [automationId]="automationId"
      [automationSteps]="automationSteps"
      [steps]="steps"
      [isVLA]="isVLA"
      [templates]="templates"
      [branding]="branding"
      [templateDefaults]="templateDefaults"
      [isSystemAutomation]="isSystemAutomation"
      [automationName]="automationName"
      [stepExecutionMode]="stepExecutionMode"
      [isEntrataUser]="isEntrataUser"
      [canUseConclusionStep]="canUseConclusionStep"
      [isConclusionStepDisabled]="isConclusionStepDisabled"
      (onTemplateDefaultsChanged)="onStepDefaultsChanged($event)"
    ></app-step>
  </form>
</div>