import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { 
  AuthenticationService, 
  LoaderService,
  SettingsService,
  SettingType, 
  TemplatesService, 
  ToastService 
} from '@app/_services';
import { BroadcastsService } from '@app/_services/broadcasts.service';
import { PagePreviewService } from '@app/_services/pagePreview.service';
import { IBroadcast } from '@nurtureboss/common/dist/types/broadcasts';
import { NurtureBossModalService } from '@app/_services/modal.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Contact } from '@nurtureboss/common/dist/types/contacts';
import { User } from '@nurtureboss/common/dist/types/users';
import { Table } from 'primeng/table';
import * as _ from 'lodash';
import { Calendar } from 'primeng/calendar';
import { LazyLoadEvent } from 'primeng/api';
import { Observable } from 'rxjs';
import { ITemplate } from '@nurtureboss/common/dist/types/templates';

interface IBroadcastResponse {
  errors: string[];
  result: {
    count: number,
    data: IBroadcast[]
  };
}

interface IPaginate {
  first: number;
  last: number;
}

interface IActiveQuery {
  query: string;
  property: string;
}

@Component({
  selector: 'app-broadcast-component',
  styleUrls: ['./broadcast.component.less'],
  templateUrl: './broadcast.component.html',
})
export class BroadcastComponent implements OnInit {

  @ViewChild('dt') table: Table;
  @ViewChild('deliveredCalendar') deliveredCalendar: Calendar;

  broadcasts: IBroadcast[] = [];
  pagePreviewUrl: SafeResourceUrl;
  emailPreviewUrl: SafeResourceUrl;
  forcePreviewType: string = '';
  recipientData: Contact[];
  currentUser: User;
  totalRecords = 0;
  pagination: IPaginate = {
    first: 1,
    last: 25
  };
  activeQuery: IActiveQuery = {
    query: '',
    property: ''
  };
  dateRange: Date[] = [];
  debouncedSearch: any;
  dashboardId: string;
  userToken: string;
  knowiApi = (window as any).Knowi;

  constructor(
    private router: Router,
    private broadcastsService: BroadcastsService,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private pagePreviewService: PagePreviewService,
    private templateService: TemplatesService,
    private nbModalService: NurtureBossModalService,
    private sanitizer: DomSanitizer,
    private authService: AuthenticationService,
    private settingsService: SettingsService,
  ) {
    this.currentUser = this.authService.currentUserValue.user;
    this.debouncedSearch = _.debounce(this.searchFunc, 300);
  }

  ngOnInit() {
    this.dashboardId = '3Sd27NPwaNVKgisgApUvBPJaGuGPnip5Ig4OO3JItgLrwie';
    this.userToken = this.authService.currentUserValue.token;
    this.loadData();
  };

  /**
   * Compare to dates to see if the provided date is in the future
   * 
   * @param date (Date) date we want to check if in the future.
   * @returns (Boolean)
   */
  futureCheck(date: Date|string): Boolean {
    return new Date(date) > new Date();
  }

  /**
   * Reusable method to query data needed to build the table in the UI.
   * 
   * @param query (string) The query we want to search for (e.g. 'text').
   * @param property (string) The property we are running the query against in the data set (e.g. 'deliveryMethod').
   */
  async loadData(query?: string, property?: string): Promise<void> {
    this.loaderService.triggerLoader();
    this.pagination.first = 1;
    this.pagination.last = 25;
    try {
      const data = await this.getAllData(0, 25, query, property).toPromise();
      this.broadcasts = data.result.data;
      this.totalRecords = data.result.count;
      this.setTableHeading();
      this.loaderService.stopLoader();
    } catch (e) {
      this.loaderService.stopLoader();
      this.toastService.showError('There was an error loading broadcasts.');
    }
  }

  /**
   * Method used to set the pagination data for the UI.
   */
  setTableHeading(): void {
    this.pagination.first = this.table._first;
    this.pagination.last = this.table._rows;
    if (this.pagination.first === 0) {
      this.pagination.first = 1;
    }
  }

  /**
   * Method to paginate the data using skip/take.
   * 
   * @param event (LazyLoadEvent) Data passed into the event from the p-table.
   */
  async paginate(event: LazyLoadEvent): Promise<void> {
    this.loaderService.triggerLoader();
    this.pagination.first = event.first;
    this.pagination.last = event.first + Number(event.rows);
    try {
      const data = await this.getAllData(event.first, event.rows, this.activeQuery.query, this.activeQuery.property).toPromise();
      this.broadcasts = data.result.data;
      this.totalRecords = data.result.count;
      this.setTableHeading();
      this.loaderService.stopLoader();
    } catch (e) {
      this.loaderService.stopLoader();
      this.toastService.showError('There was an error loading past broadcasts.');
    }
  }
  
  /**
   * Method used to execute skip/take query on Broadcast data.
   * 
   * @param skip (number) Number of items to skip when querying.
   * @param take (number) Number of items to take when querying.
   * @param query (string) The query we want to search for (e.g. 'text').
   * @param property (string) The property we are running the query against in the data set (e.g. 'deliveryMethod').
   * @returns Observable
   */
  getAllData(skip: number, take: number, query: string, property: string): Observable<IBroadcastResponse> {
    let startingDate = null;
    let endingDate = null;
    if (this.dateRange.length === 2) {
      startingDate = this.dateRange[0].getTime();

      // Add one day so we can get everything before the next day at midnight
      const endDate = new Date(this.dateRange[1]);
      endDate.setDate(endDate.getDate() + 1);
      endingDate = endDate.getTime();
    }
    return this.broadcastsService.getBroadcasts(skip, take, query, property, startingDate, endingDate);
  }

  /**
   * Method triggered when user inputs search text in a table column.
   * 
   * @param query (string) The query we want to search for (e.g. 'text').
   * @param property (string) The property we are running the query against in the data set (e.g. 'deliveryMethod').
   */
  searchFunc(query: string, property: string): void {
    this.activeQuery = {
      query: query,
      property: property
    }
    this.loadData(query, property);
  }

  /**
   * Allows us to automationcally close the calendar when selecting date ranges.
   * 
   * @param calendar (Calendar) PrimeNG Calendar component.
   */
  deferCalendarToggle(calendar: Calendar): void {
    
    // TODO: Not sure why we put this in a setTimeout, maybe to force an Angular cycle?
    setTimeout(() => {
      calendar.toggle();
    }, 100);
  }

  /**
   * Method triggered when a day is selected on the date range calendar.
   * 
   * @param date (Date) Day that user selected on the calendar.
   * @returns void
   */
  onDateSelect(date: Date): void {
    if (this.dateRange.length === 0 || this.dateRange.length === 2) {
      this.dateRange = [date];
      return;
    } else {
      this.dateRange.push(date);
    }
    if (this.dateRange.length === 2) {
      this.deferCalendarToggle(this.deliveredCalendar);
      this.dateRange.sort(function(a, b) {
        return +new Date(a) - +new Date(b);
      });
      this.loadData(this.activeQuery.query, this.activeQuery.property);
    }
  }

  /**
   * Navigate user to create a new broadcast.
   */
  handleNewBroadcastClick():void {
    this.router.navigate(['/blasts/builder']);
  }

  /**
   * Generate a preview of the email/text that was sent.
   * 
   * @param broadcast (IBroadcast): Broadcast we want to generate a preview for.
   */
  async previewBlastTemplate(broadcast: IBroadcast): Promise<void> {
    this.loaderService.triggerLoader();
    try {
      const { result: template } = await this.templateService.getTemplateByName(broadcast.templateName).toPromise();
      let templateSettings = null
      let settingsRes = await this.settingsService.getSettingsByType(SettingType.Template).toPromise();

      if (settingsRes.result) {
        templateSettings = settingsRes.result
      }

      if (broadcast.deliveryMethod === 'text') {
        this.generateNurturePagePreview(template, { ...broadcast.templateData, ...templateSettings });
        this.nbModalService.open('preview-template-modal');
      } else if (broadcast.deliveryMethod === 'email') {
        this.generateEmailPreview(template, broadcast.templateData)
        this.nbModalService.open('preview-template-modal');
      } else {
        this.generateNurturePagePreview(template, { ...broadcast.templateData, ...templateSettings });
        this.generateEmailPreview(template, broadcast.templateData);
        this.forcePreviewType = '';
        this.nbModalService.open('preview-template-modal');
      }
      this.loaderService.stopLoader();
    } catch (e) {
      this.loaderService.stopLoader();
      this.toastService.showError('Could not load template preview.');
    }
  }

  /**
   * Generate a preview in the UI to see the message sent to end user.
   * 
   * @param template (ITemplate) Template that was sent to end user.
   * @param values (Nurture Page) The data that was used to populate the template.
   */
  generateNurturePagePreview(template: ITemplate, values: any): void {
    const nurturePagePreviewUrl = this.pagePreviewService.getPagePreviewUrl(template, values)
    this.forcePreviewType = 'nurturepage';
    this.pagePreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(nurturePagePreviewUrl);
  }

  /**
   * Generate a preview in the UI to see the message sent to end user.
   * 
   * @param template (ITemplate) Template that was sent to end user.
   * @param values (Email Object) The data that was used to populate the template.
   */
  generateEmailPreview(template: ITemplate, values: any): void {
    const emailPreviewUrl = this.pagePreviewService.getEmailPreviewUrl(template, values);
    this.forcePreviewType = 'email';
    this.emailPreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(emailPreviewUrl);
  }

  /**
   * Reset values to generate preview.
   */
  resetTemplatePreviewModal(): void {
    this.forcePreviewType = '';
    this.pagePreviewUrl = '';
    this.emailPreviewUrl = '';
  }

  /**
   * Prepare and open modal to show contacts who were sent broadcast.
   * 
   * @param broadcast (IBroadcast) The broadcast we are retrieving contacts for.
   */
  async previewRecipients(broadcast: IBroadcast): Promise<void> {
    this.loaderService.triggerLoader(true);
    const { result: recipients } = await this.broadcastsService.getBroadcastRecipients(broadcast._id).toPromise();
    this.recipientData = recipients;
    this.loaderService.stopLoader();
    this.nbModalService.open('preview-recipients-modal');
  }

  /**
   * Delete a Broadcast and it's associated Schedules.
   * 
   * @param broadcast (IBroadcast) The broadcast you want to delete.
   */
  async deleteScheduledBroadcast(broadcast: IBroadcast): Promise<void> {
    this.loaderService.triggerLoader();
    try {
      const results = await this.broadcastsService.deleteBroadcast(broadcast._id).toPromise();
      this.loaderService.stopLoader();
      this.toastService.showSuccess('Scheduled Blast deleted.');
      this.loadData(this.activeQuery.query, this.activeQuery.property);
    } catch (e) {
      this.loaderService.stopLoader();
      this.toastService.showError('Could not delete scheduled Blast.');
    }

  }

  /**
   * Open modal and load analytics for viewing a blasts analytics.
   * 
   * @param broadcast (IBroadcast) The broadcast you want to view analytics for.
   */
  async viewBlastAnalytics(broadcast: IBroadcast) {
    this.loaderService.triggerLoader();
    this.nbModalService.open('view-blast-analytics-modal');
    const hash = await this.authService.generateDashboardHash({
      token: this.userToken,
      broadcastId: broadcast._id.toString(),
    }).toPromise();
    this.knowiApi.render('#broadcast-analytics-loader', {
        type: 'secure',
        dashboard: this.dashboardId,
        hash: hash,
        url: "https://www.knowi.com/",
        view: {
          title: true,
          header: true,
          filter: true,
          setting: false,
          chat: false,
          dashFiltersBar: false,
        }
    }, function () {
      //no-op
    });
    this.loaderService.stopLoader();
  }

  resetAnalyticsModal(): void {
    // no-op
  }
}
