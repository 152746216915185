import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import MODAL_NAMES from '@app/_components/nb-modal/modalTypes';
import { NurtureBossModalService } from '@app/_services/modal.service';
import { IPrimaryButtonOptions } from '@app/_components/nb-modal/nb-modal.component';
import { Subscription } from 'rxjs';

export type SendTimeValidatorFn = (sendTime: { hour: number; minute: number; }) => { valid: boolean; message?: string; };

@Component({
  selector: 'app-schedule-modal',
  templateUrl: './schedule-modal.component.html',
  styleUrls: ['./schedule-modal.component.less']
})
export class ScheduleModalComponent implements OnInit, OnDestroy {

  @Input() timeValidator: SendTimeValidatorFn = (_) => ({ valid: true });
  @Output() onScheduleCreated = new EventEmitter<Date>();

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  sendDate: { year: number; month: number; day: number; } | null = null;
  sendTime: { hour: number; minute: number; } | null = null;
  schedSaveInProgress = false;
  primaryButtonOptions: IPrimaryButtonOptions
  invalidSchedule = false;
  invalidScheduleMessage = '';
  modalClosedSubscription: Subscription;

  constructor(
    private nbModalService: NurtureBossModalService,
    private calendar: NgbCalendar,
  ) {
    this.fromDate = this.calendar.getPrev(calendar.getToday(), 'd', 30);
    this.toDate = this.calendar.getToday();
    this.primaryButtonOptions = {
      label: 'Continue',
      disabled: !!(!this.sendTime || !this.sendDate),
      action: this.buildSaveSchedule(),
    }
  }

  ngOnInit(): void {
    this.modalClosedSubscription = this.nbModalService.modalClosed$.subscribe((name) => {
      if (name === MODAL_NAMES.SCHEDULE_MESSAGES) {
        this.fromDate = null;
        this.toDate = null;
        this.sendDate = null;
        this.sendTime = null;
        this.schedSaveInProgress = false;
        this.hoveredDate = null;
        this.invalidScheduleMessage = '';
        this.invalidSchedule = false;
      }
    });
  }

  ngOnDestroy() {
    this.modalClosedSubscription?.unsubscribe();
  }

  buildSaveSchedule() {
    return () => {
      this.saveSchedule();
    }
  }

  updateScheduleData() {
    this.primaryButtonOptions.disabled = !!(!this.sendTime || !this.sendDate);
  }

  saveSchedule() {
    if (!this.sendDate || !this.sendTime) {
      return;
    }

    this.invalidSchedule = false;
    this.invalidScheduleMessage = '';
    const { valid, message } = this.timeValidator({...this.sendTime});
    if (!valid) {
      this.invalidSchedule = !valid;
      this.invalidScheduleMessage = message || 'The time you have chosen is invalid!';
      return;
    }

    this.primaryButtonOptions.disabled = true;
    const date = new Date(this.sendDate.year, this.sendDate.month - 1, this.sendDate.day, this.sendTime.hour, this.sendTime.minute)
    this.onScheduleCreated.emit(date);
  }

  exitModal() {
    this.nbModalService.close(MODAL_NAMES.SCHEDULE_MESSAGES);
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isToday(date: NgbDate) {
    return date.equals(this.toDate);
  }
}
