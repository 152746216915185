// Angular:
import { Component, Input, OnDestroy, OnInit, Self, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, FormGroup, NgControl, Validators, ValidatorFn } from '@angular/forms';
// Libs
import { Subscription } from 'rxjs';
// Components:
import { IPrimaryButtonOptions } from '@app/_components/nb-modal/nb-modal.component';
// Services:
import { TemplatesService, ToastService, TokenParserService, TemplateFormService } from '@app/_services';
import { NurtureBossModalService } from '@app/_services/modal.service';
// Constants:
import {
  SAVE_TEXT_TEMPLATE_MODAL,
  LOAD_TEXT_TEMPLATE_MODAL,
  DEFAULT_TEXT_TEMPLATE,
} from './send-text-message.constants';
// Types:
import { DefaultTexTemplate, TextTemplate } from './send-text-message.types';

@Component({
  selector: 'app-send-text-message',
  templateUrl: './send-text-message.component.html',
  styleUrls: ['./send-text-message.component.less'],
  providers: [TemplateFormService],
})
export class SendTextMessageComponent implements OnInit, OnDestroy, ControlValueAccessor {
  formValueSubscription: Subscription;

  @Input() formControlName: string;
  @Input() formGroup: FormGroup;
  @Input() maxLength: number;
  @Input() totalContacts: number;
  @Input() textElementId: string;
  @Input() tokens: string[];
  @Input() tokensToHide: string[] = [];
  @Input() hideClickableTokens = false;
  @Input() hideRightPane = false;

  @Output() inputOnEnterEvent = new EventEmitter<any>();

  loadTextTemplateActive: boolean;
  modalActionTitle: string;
  textTemplates: TextTemplate[];
  defaultTexTemplate: DefaultTexTemplate[];
  value: string;
  textTemplateName: string;
  tipsAndTricksLinks = []; // TODO: get links
  errors = [];
  submitted = false;
  primaryButtonOptions: IPrimaryButtonOptions;
  loadingTextTemplates:boolean = false;
  validators: ValidatorFn[];

  constructor(
    @Self() private ngControl: NgControl,
    private templatesService: TemplatesService,
    private toastService: ToastService,
    private tokenService: TokenParserService,
    private templateFormService: TemplateFormService,
    private nbModalService: NurtureBossModalService
  ) {
    this.defaultTexTemplate = DEFAULT_TEXT_TEMPLATE;
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
    this.primaryButtonOptions = {
      label: 'Save',
      disabled: true,
      action: this.buildSaveTextAsTemplate(),
    };
    this.validators = [ Validators.required, Validators.maxLength(this.maxLength) ];
  }

  ngOnInit() {
    this.tokensToHide = this.templateFormService.getTokensToHideForTextInput();
    this.formValueSubscription = this.formGroup.get(this.formControlName).valueChanges.subscribe(text => {
      this.value = text;
    });
  }

  ngOnDestroy() {
    this.formValueSubscription.unsubscribe();
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  private onChange() {}

  private onTouched() {}

  getRawValueLength(): number {
    return this.tokenService.replaceTokens(this.value, this.tokens).length;
  }

  openModal(e, modalName) {
    e.preventDefault();
    this.textTemplateName = null;
    this.textTemplates = null;
    this.nbModalService.open(modalName);
    if (modalName === LOAD_TEXT_TEMPLATE_MODAL) {
      this.loadingTextTemplates = true;
      this.templatesService.getTextTemplates().subscribe((data) => {
        this.textTemplates = data.result;
        this.loadingTextTemplates = false;
      }, (error) => {
        this.loadingTextTemplates = false;
        this.toastService.showError('There was an error loading your text templates');
      });
    }
  }

  updateTextTemplateSaveDisabledState() {
    this.primaryButtonOptions.disabled = !this.textTemplateName;
  }

  buildSaveTextAsTemplate() {
    return () => {
      this.saveTextAsTemplate();
    };
  }

  saveTextAsTemplate() {
    this.primaryButtonOptions.disabled = true;
    this.templatesService.newTextTemplate({
      message: this.value,
      name: this.textTemplateName
    }).subscribe(() => {
      this.primaryButtonOptions.disabled = false;
      this.nbModalService.close(SAVE_TEXT_TEMPLATE_MODAL);
      this.toastService.show('Text template saved.', {
        classname: 'bg-success text-light',
        delay: 5000
      });
    }, (_error) => {
      this.toastService.showError('There was an error saving text template, please try again.');
      this.primaryButtonOptions.disabled = false;
    });
  }

  selectTextTemplate(e, template) {
    e.preventDefault();
    this.formGroup.controls[this.formControlName].setValue(template.message);
    this.nbModalService.close(LOAD_TEXT_TEMPLATE_MODAL);
    this.textTemplates = null;
  }

  deleteTextTemplate(templateId, index, e) {
    e.stopPropagation();
    this.textTemplates.splice(index, 1);
    this.templatesService.deleteTextTemplate(templateId).subscribe(() => {
      this.toastService.show('Text template deleted', {
        classname: 'bg-success text-light',
        delay: 5000
      });
    }, () => {
      this.toastService.show('There was an error deleting your text template', {
        classname: 'bg-danger text-light',
        delay: 5000
      });
    });
  }

  onMouseClick() {
    this.inputOnEnterEvent.emit();
  }
}
