<div *ngIf="shouldDisplay && spamWarnings.length" class="alert alert-warning d-flex justify-content-between align-items-center">
    <div class="mb-0 text-warning">
        <p class="mb-2">{{warningText}}</p>
        <ul class="margin-bottom-0">
            <li *ngFor="let spamWarning of spamWarnings; let i = index;">
                {{spamWarning.field}}: 
                <ng-container *ngIf="spamWarning.violations.length > 1">
                    <ng-container *ngFor="let violation of spamWarning.violations; let i = index;">
                        <ng-container *ngIf="i !== 0">, </ng-container>
                        <b>{{violation}}</b>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="spamWarning.violations.length === 1"><b>{{spamWarning.violations}}</b></ng-container>
            </li>
        </ul>
    </div>
</div>

<div *ngIf="shouldDisplay && emailSubjectLineSpamWarnings.length" class="alert alert-warning d-flex justify-content-between align-items-center margin-top-4">
    <div class="mb-0 text-warning">
        <p class="mb-2">{{warningText}}</p>
        <ul class="margin-bottom-0">
            <li *ngFor="let spamWarning of emailSubjectLineSpamWarnings; let i = index;">
                {{spamWarning.field}}:
                <ng-container *ngIf="spamWarning.violations.length > 1">
                <ng-container *ngFor="let violation of spamWarning.violations; let i = index;">
                    <ng-container *ngIf="i !== 0">, </ng-container>
                    <b>{{violation}}</b>
                </ng-container>
                </ng-container>
                <ng-container *ngIf="spamWarning.violations.length === 1"><b>{{spamWarning.violations}}</b></ng-container>
            </li>
        </ul>
    </div>
</div>