import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

import { checkStringForInvalidBracketTokens } from '@app/_utils/strings';

const universalValidTokens = ['apartmentName'];

export function noInvalidTokens(validTokens: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const invalidTokens = checkStringForInvalidBracketTokens(control.value, [...validTokens, ...universalValidTokens], true);

    return invalidTokens.length ? { invalidTokens: { value: invalidTokens } } : null;
  };
}