import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NurtureBossModalService {

  private modalOpenedSubject = new Subject<string>();
  private modalClosedSubject = new Subject<string>();
  private modals: any[] = [];

  public modalOpened$ = this.modalOpenedSubject.asObservable();
  public modalClosed$ = this.modalClosedSubject.asObservable();

  add(modal: any) {
    this.modals.push(modal);
  }

  remove(name: string) {
    this.modals = this.modals.filter(x => x.name !== name);
  }

  open(name: string) {
    const modal = this.modals.find(x => x.name === name);
    if (modal) {
      modal.open();
      this.modalOpenedSubject.next(name);
    }
  }

  close(name: string) {
    const modal = this.modals.find(x => x.name === name);
    if (modal) {
      modal.close();
      this.modalClosedSubject.next(name);
    }
  }
}
