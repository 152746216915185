<div class="page-title">
  <h2 *ngIf="form">{{form?.name}} Survey Results</h2>
</div>

<div *ngIf="!isLoading && !formResponses.length" class="row">
  <p class="text-center full-width margin-top-48">
    <img src="/assets/no-messaging.png" />
  </p>
  <h3 class="text-center full-width margin-top-48">No survey results found.</h3>
</div>

<div *ngIf="!isLoading && formResponses.length" class="page">
  <div class="results-container">
    <div class="results-content">
      <div *ngIf="stagedResponse" class="staged-response-info">
        <div>{{stagedResponse.firstName}} {{stagedResponse.lastName}} - {{stagedResponse.emailAddress}}</div>
        <button 
          class="button button-dark"
          (click)="clearStagedResponse()"
        >
          View All Results
        </button>
      </div>
      <div *ngFor="let question of questionResponseAggregateData; index as i" class="question">
        <div class="question-title" [ngClass]="{ 'bar-chart-margin': (barChartQuestions.includes(question.questionType) && !stagedResponse) }">
          <div class="question-text" [ngClass]="{ 'response-margin': stagedResponse }">{{question.questionText}}</div>
          <div *ngIf="!stagedResponse" class="total-answers">{{question.totalAnswers}} Answers</div>
        </div>
        <div *ngIf="chartQuestions.includes(question.questionType)" class="chart-container" [ngClass]="{ 'hidden': stagedResponse }">
          <div [id]="'legend' + question.questionId" class="legend-container"></div>
          <canvas [id]="question.questionId" width="400" height="400" class="chart" [ngClass]="{ 'bar-chart': barChartQuestions.includes(question.questionType), 'many-options-chart': question.options.length > 10 }"></canvas>
        </div>
        <div *ngIf="!stagedResponse && !chartQuestions.includes(question.questionType)" class="text-answers-list">
          <ng-container *ngIf="question.answers.length" >
            <div *ngFor="let answer of question.answers; index as i" class="text-answer">{{answer}}</div>
          </ng-container>
        </div>
        <div *ngIf="stagedResponse?.questionIdMap[question.questionId]">
          <div *ngIf="!isArray(stagedResponse?.questionIdMap[question.questionId])" class="staged-response-answer">{{stagedResponse?.questionIdMap[question.questionId]}}</div>
          <ng-container *ngIf="isArray(stagedResponse?.questionIdMap[question.questionId]) && stagedResponse?.questionIdMap[question.questionId].length">
            <div *ngFor="let responseAnswer of stagedResponse?.questionIdMap[question.questionId]" class="staged-response-answer">{{responseAnswer}}</div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="search-results-sidebar">
      <div class="search-input-container">
        <label for="name">Search Individual Results
          <i 
            placement="top" 
            ngbTooltip="Click a result to see answers"
            class="far fa-info-circle"
          ></i>
        </label>
        <input
          pInputText
          type="text"
          placeholder="Search by name or email"
          [(ngModel)]="responsesSearchText"
          (ngModelChange)="debouncedResponsesSearch(responsesSearchText)"
          class="form-control"
        />
      </div>
      <div class="responses-list">
        <div *ngFor="let response of responseSearchResults; index as i" (click)="viewResponse(response)" class="response-search-result">
          <div>{{response.firstName}} {{response.lastName}}</div>
          <div>{{response.emailAddress}}</div>
        </div>
      </div>
    </div>
  </div>
</div>