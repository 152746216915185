import { Component,EventEmitter, Input, Output } from '@angular/core';
import { getAllowedImageExtensions } from '@app/_utils/fileIcons';

@Component({
  selector: 'app-file-type-image',
  template: `
    <label
      [for]="inputId"
      class="button-override button-white">
        <i class="far fa-cloud-upload"></i> Upload A New File
    </label>
    <input
      (change)="onChange($event)"
      type="file"
      [accept]="acceptedFileExtensions"
      [id]="inputId"
      [class.is-invalid]="!isValid"
      [required]="required"
    />
  `,
  styleUrls: ['./file-type-image.component.less'],
})
export class FileTypeImageComponent {

  @Input() show: boolean = true;
  @Input() isValid: boolean;
  @Input() required: boolean = false;
  @Input() inputId: string = 'file-type-image-component';
  @Output() onFilesPicked = new EventEmitter<MouseEvent>();

  acceptedFileExtensions: string[] = [];

  constructor() {
    this.acceptedFileExtensions = getAllowedImageExtensions();
  }

  onChange(event: MouseEvent) {
    return this.onFilesPicked.emit(event);
  }

}
