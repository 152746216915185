import { Component, ViewEncapsulation, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SystemAutomation } from '@nurtureboss/common/dist/types/automations';
import { NurtureBossModalService } from '@app/_services/modal.service';
import { AutomationSettingsService } from '@app/_services/automationSettings.service';
import { AuthenticationService } from '@app/_services/authentication.service';
import { UserAuditsService } from '@app/_services/userAudits.service';
import { ToastService } from '@app/_services';
import MODAL_NAMES from '@app/_components/nb-modal/modalTypes';
import { IPrimaryButtonOptions } from '@app/_components/nb-modal/nb-modal.component';
import { FormGroup, FormControl } from '@angular/forms';

@Component({ 
  selector: 'app-build-automations-modal', 
  templateUrl: 'build-automations-modal.component.html', 
  styleUrls: ['build-automations-modal.component.less'],
  encapsulation: ViewEncapsulation.None,
  providers: [NgbActiveModal],
})
export class BuildAutomationsModalComponent implements OnInit {
  @Input() availableAutomations: string[];

  buildAutomationsModalPrimaryButton: IPrimaryButtonOptions;
  loading = false;
  form: FormGroup;
  name: string = MODAL_NAMES.BUILD_AUTOMATIONS;

  constructor(
    private nbModalService: NurtureBossModalService,
    private authService: AuthenticationService,
    private automationSettingsService: AutomationSettingsService,
    private userAuditsService: UserAuditsService,
    private toastService: ToastService,
  ) {
    this.onValueChange = this.onValueChange.bind(this);
    this.buildAutomationsModalPrimaryButton = {
      label: 'Build Automations',
      disabled: false,
      action: this.buildAutomationsConstructor(),
      showSpinner: false,
    };
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      'pre-tour': new FormControl(false),
      'post-tour': new FormControl(false),
      'interest-list': new FormControl(false),
      'lost-lead': new FormControl(false),
      'appointment-reminder': new FormControl(false),
      'resident-outreach': new FormControl(false),
      'rent-reminder': new FormControl(false),
      'lease-renewal': new FormControl(false),
      'delinquency': new FormControl(false),
      'text-opt-in': new FormControl(false),
    });
  }

  onValueChange(): void {
    let hasValue = false;
    for (const control in this.form.controls){
      if (this.form.controls[control].value) {
        hasValue = true;
        break;
      }
    }
    this.buildAutomationsModalPrimaryButton.disabled = !hasValue;
  }

  buildAutomationsConstructor(): () => void {
    return () => this.buildAutomations();
  }

  getAutomationDisplayName(automationName): string {
    const automationNameMap = {
      'pre-tour': SystemAutomation.PreTourAutomation,
      'post-tour': SystemAutomation.PostTourAutomation,
      'interest-list': SystemAutomation.InterestListAutomation,
      'lost-lead': SystemAutomation.LostLeadAutomation,
      'resident-outreach': SystemAutomation.ResidentOutreachAutomation,
      'rent-reminder': SystemAutomation.RentReminderAutomation,
      'lease-renewal': SystemAutomation.LeaseRenewalAutomation,
      'appointment-reminder': SystemAutomation.AppointmentReminderAutomation,
      'delinquency': SystemAutomation.DelinquencyAutomation,
      'text-opt-in': SystemAutomation.TextOptInAutomation,
    };

    return automationNameMap[automationName];
  }

  buildAutomations(): void {
    this.buildAutomationsModalPrimaryButton.disabled = true;
    this.buildAutomationsModalPrimaryButton.showSpinner = true;

    const automationsToBuild = this.availableAutomations.reduce((acc, controlName) => {
      const control = this.form.get(controlName);
      if (control.value) {
        acc.push(controlName);
      }
      return acc;
    }, []);

    this.automationSettingsService.upsertUserAutomations(
      this.authService.currentUserValue.user._id, { automations: automationsToBuild }
    ).subscribe(() => {
      this.userAuditsService.refreshTriggeredUserAudits();
      this.nbModalService.close(this.name);
      this.toastService.showSuccess('Automations built successfully!');
      this.buildAutomationsModalPrimaryButton.disabled = false;
      this.buildAutomationsModalPrimaryButton.showSpinner = false;
    }, (err) => {
      this.toastService.showError('There was an error building your automations: ' + err);
      this.buildAutomationsModalPrimaryButton.disabled = false;
      this.buildAutomationsModalPrimaryButton.showSpinner = false;
    });
  }

  // TODO: make this not required
  buildAutomationsModalDismissedBuilder(): (event: Event) => void {
    return () => {};
  }
}