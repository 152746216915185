/**
 * Deletes an entry from a map that uses indexes for keys 
 * and rearranges remaining keys to maintain the order.
 * 
 * @param index - The integer key of the item to be deleted from the map
 * @param map - An integer key based map that hosts it
 * @returns - The reference to the map given in the parameters
*/
export const deleteFromIndexedMapAndRearrangeOrder = (index: number, map: Map<number, any>) => {
  const length = map.size;

  for (let i = index; i + 1 < length; i++) {
    map.set(i, map.get(i + 1));
  }
  map.delete(length - 1);
  return map;
}