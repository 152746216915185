import { DefaultTexTemplate } from './send-text-message.types';

const SAVE_TEXT_TEMPLATE_MODAL = 'save-text-template-modal';
const LOAD_TEXT_TEMPLATE_MODAL = 'load-text-template-modal';

const DEFAULT_TEXT_TEMPLATE: DefaultTexTemplate[] = [{
  message: 'Hi [clientFirstName]!  [apartmentName] is excited to announce that we are now accepting applications! Click here for more info [shortUrl]',
  name: 'Accepting Applications'
}, {
  message: 'Hey [clientFirstName]! This is a friendly reminder of your upcoming appointment on [appointmentDate] at [appointmentTime]! Click here for more details [shortUrl]',
  name: 'Appointment Reminder'
}, {
  message: 'Hey [clientFirstName]! [apartmentName] is offering [concession] on new move ins! Take a look at this deal we\'ve made just for you! [shortUrl]',
  name: 'Concession Offering'
}, {
  message: 'Hi [clientFirstName]! Please take a look at our COVID screening checklist before your appointment at [apartmentName]: [shortUrl]',
  name: 'COVID-19 Pre-Screen'
}, {
  message: 'Hey [clientFirstName]! [apartmentName] is hosting [eventName] on [eventDate] and you\'re invited! Click here for more details! [shortUrl]',
  name: 'Event Reminder'
}, {
  message: 'Hi [clientFirstName]! Hello from [apartmentName]! Take a look at this page we made just for you! [shortUrl]',
  name: 'General Template'
}, {
  message: 'Hi [clientFirstName]! [apartmentName] would like to thank you for joining our VIP waiting list! Click here to learn more [shortUrl]',
  name: 'Interest List'
}, {
  message: 'Hey [clientFirstName]! I wanted to follow up on your recent maintenance request, please share your experience and any feedback! [shortUrl]',
  name: 'Maintenance Follow Up'
}, {
  message: 'Hi [clientFirstName]! As a reminder, your lease at [apartmentName] is set to expire soon. Click here for a renewal offer created just for you! [shortUrl]',
  name: 'Lease Renewal'
}, {
  message: 'Hey [clientFirstName]! This is the team @ [apartmentName]. I wanted to follow up on our tour, I\'ve prepared this page just for you! [shortUrl]',
  name: 'Tour Follow Up'
}, {
  message: 'Hey [clientFirstName]! [apartmentName] has some new availability and rates! Take a look at this page we made just for you! [shortUrl]',
  name: 'Guest Card Follow Up'
}, {
  message: 'Hey [clientFirstName]! Congratulations on your new apartment at [apartmentName]! Click this link for the move in checklist I created for you! [shortUrl]',
  name: 'Move In Checklist'
}, {
  message: 'Hey [clientFirstName]! This is a friendly rent reminder, click here to see more details! [shortUrl]',
  name: 'General Rent Reminder'
}, {
  message: 'Hi [clientFirstName]! [apartmentName] is excited to invite you to tour your new home! Click here to learn more: [shortUrl]',
  name: 'Schedule A Tour'
}];

export {
  SAVE_TEXT_TEMPLATE_MODAL,
  LOAD_TEXT_TEMPLATE_MODAL,
  DEFAULT_TEXT_TEMPLATE,
};
