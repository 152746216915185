<!-- Media Manager Modal -->
<nb-modal
  [name]="name"
  [confetti]="false"
  [doOnDismiss]="buildMediaModalDismissed()"
  header="Select From Media Manager"
  class="media-manager-modal"
  footer="true"
  [primaryButtonOptions]="mediaModalPrimaryButton"
>
  <div id="media-manager-modal">
    <div class="modal-body">
      <div class="row" *ngIf="!media || media.length === 0">
        <h3 *ngIf="!mediaImagesLoading" class="empty-media-holder">You don't have any media yet!<i class="far fa-folder-open"></i></h3>
        <div *ngIf="mediaImagesLoading" class="spinner-border spinner-border-sm empty-media-holder"></div>
      </div>
      <div class="row" *ngIf="media && media.length > 0">
        <div class="col-md-2 margin-bottom-24" *ngFor="let image of media; let i = index">
          <div [class.selected]="selectedImage && (selectedImage._id === image._id)" class="image-wrapper">
            <img *ngIf="!isFile(image.url)" (click)="selectImage(image)" class="image-preview" [src]="image.url" />
            <i
              (click)="selectImage(image)"
              *ngIf="isFile(image.url)"
              class="far {{getFileIconClass(image.url)}} file-attachment image-preview"
              [class.selected]="selectedImage && (selectedImage._id === image._id)"
            ></i>
          </div>
          <h5 class="file-name truncate">{{image.fileName}}</h5>
          <span class="file-size">{{image.fileSize / 1000 | number:'1.0-0'}} KB</span>
        </div>
      </div>
    </div>
  </div>
</nb-modal>
