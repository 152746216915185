<div id="password-reset" class="app-entry">
  <div class="row">
      <div class="col-md-7 form-wrapper">
          <p class="text-center logo-wrapper"><img src="/assets/nurturebossFULLcolor.png" /></p>
          <div class="col-md-6 offset-md-3">
              <h2>Reset Password</h2>
              <form [formGroup]="passwordResetForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                  <label for="password">Password</label>
                  <div class="input-group mb-3">
                    <input id="password" type="password" placeholder="Start typing..." formControlName="password" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div class="input-group-append">
                      <span class="input-group-text"><app-toggle-visible-password elemId="password" [control]="passwordResetForm.controls.password"></app-toggle-visible-password></span>
                      <span class="input-group-text"><i class="far fa-lock"></i></span>
                    </div>
                  </div>
                  <div *ngIf="submitted && f.password.errors" class="form-errors">
                    <div *ngIf="f.password.errors.required">Password is required</div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="passwordMatch">Re-Enter Password</label>
                  <div class="input-group mb-3">
                    <input id="passwordMatch" type="password" placeholder="Start typing..." formControlName="passwordMatch" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.passwordMatch.errors }" />
                    <div class="input-group-append">
                      <span class="input-group-text"><app-toggle-visible-password elemId="passwordMatch" [control]="passwordResetForm.controls.passwordMatch"></app-toggle-visible-password></span>
                      <span class="input-group-text"><i class="far fa-lock"></i></span>
                    </div>
                  </div>
                  <div *ngIf="submitted && f.passwordMatch.errors" class="form-errors">
                    <div *ngIf="f.passwordMatch.errors.required || f.passwordMatch.errors.noMatch">Passwords must match</div>
                  </div>
                </div>
                <div class="row accessories-bar">
                  <div class="col-sm-6">
                    <button [disabled]="loading" class="pull-left button button-dark">
                      Confirm
                    </button>
                  </div>
                  <div class="col-sm-6">
                    <button [disabled]="loading" routerLink="/login" class="pull-right button button-light">
                      Cancel
                    </button>
                  </div>
                </div>
                <div *ngIf="error" class="alert alert-danger">{{error}}</div>
              </form>
          </div>
      </div>
      <div class="col-md-5 login-bg"></div>
  </div>
</div>